import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlotsService {

  fetchedPlotData;

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  markPlots(body): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/update-plots-facing', {
      params: body
    });
  }

  updatePlot(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-plot' , body);

  }

  addPlot(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/add-plot' , body);

  }

  deletePlot( projectId , plotNo ) : any{
    let body = new HttpParams();
    body = body.set('projectId', projectId );
    body = body.set('plotNo', plotNo );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-plot', {
      params: body
    });
  }


}
