import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';
import { SwiperOptions } from 'swiper';
import panzoom from "panzoom";
import { BookingsServiceService } from './../bookings-service.service';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
// import { iif } from 'rxjs';
// import * as SvgPanZoom from 'svg-pan-zoom';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.css']
})
export class ProjectPageComponent implements OnInit {

  fullScreenMode : boolean = false;

  plotDetailsActive: boolean = false;

  plotDetails: any;

  supportedVideoExtensions = ["mp4","mov","wmv","avi","mkv","webm"];

  // @ViewChild('scene', { static: false }) scene: ElementRef;


  panZoomController;

  Object = Object;

  authenticated = false;

  defaultPlotFiltersConfig: any = {
    plots: ["", [Validators.minLength(1)]],
    plotFacing: ["-1", [Validators.required]],
    bookingStatus: ["-1", [Validators.required]],

  };

  plotFilter: FormGroup;

  config: SwiperOptions = {
    slidesPerView: 1,
    zoom: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: true
    },
    speed: 500,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  constructor(
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private router: Router,
    private bookingsServiceService: BookingsServiceService,
    private formBuilder: FormBuilder,
    private eleRef: ElementRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
  };

  }

  project: any;

  plotsAggregateData: any = null;

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.project = null;
      this.project = this.dataHolderService.projectMetaDeta.PROJECT_PAGE_CONTENTS['id' + params["projectId"].split("-").pop()];
      this.dataHolderService.updatePageMeta(`${this.project.name}, ${this.project.main_town} | ${this.dataHolderService.projectMetaDeta.default_title}`, this.project.description);

    });

    this.route.queryParams.subscribe(queryParams => {
      if ("digital" in queryParams) {
        this.fullScreenMode = true;
      }
    });

    this.plotFilter = this.formBuilder.group(this.defaultPlotFiltersConfig);

    // console.log(this.project);



  }



  initPanZoom(): void {
    if (this.project.has_plots == '1') {

      this.panZoomController = panzoom(document.querySelector('#scene'), {
        maxZoom: 3,
        minZoom: 0.5,
        onTouch: function (e) {
          // `e` - is current touch event.

          return false; // tells the library to not preventDefault.
        }
      });

    }
  }



  loadjs(e): void {

    if (this.project.has_plots != '1') {
      return;
    }


    // this.panZoomController = panzoom(this.scene.nativeElement, {
    //   maxZoom: 3,
    //   minZoom: 0.5,
    //   onTouch: function (e) {
    //     // `e` - is current touch event.

    //     return false; // tells the library to not preventDefault.
    //   }
    // });



    document.querySelectorAll<HTMLElement>('svg path.plot').forEach((ele) => {
      ele.onclick = (e) => {

        try {
          this.plotDetails = {};
          let path = (e.target as HTMLElement);
          let plotId = path.getAttribute("id");
          if (!plotId) {
            return;
          }
          this.plotDetails.plot_no = plotId.replace("plot", "");
          this.plotDetails.plot_facing = [];
          this.plotDetails.plot_measurements = [];
          this.plotDetails.area = '-';
          this.plotDetails.booked = '';

          let northFacing = path.getAttribute("data-plot-facing-north");
          let southFacing = path.getAttribute("data-plot-facing-south");
          let westFacing = path.getAttribute("data-plot-facing-west");
          let eastFacing = path.getAttribute("data-plot-facing-east");

          if (northFacing == "true") {
            this.plotDetails.plot_facing.push("North");
          }
          if (southFacing == "true") {
            this.plotDetails.plot_facing.push("South");
          }
          if (westFacing == "true") {
            this.plotDetails.plot_facing.push("West");
          }
          if (eastFacing == "true") {
            this.plotDetails.plot_facing.push("East");
          }

          this.plotDetails.plot_measurements.push('North : ' + path.getAttribute("data-measurement-north"));
          this.plotDetails.plot_measurements.push('East : ' + path.getAttribute("data-measurement-east"));
          this.plotDetails.plot_measurements.push('South : ' + path.getAttribute("data-measurement-south"));
          this.plotDetails.plot_measurements.push('West : ' + path.getAttribute("data-measurement-west"));
          this.plotDetails.area = path.getAttribute("data-area");
          this.plotDetailsActive = true;
          this.plotDetails.booked = path.getAttribute("data-booked");
          this.plotDetails.booking_id = path.getAttribute("data-booking-id");

          let minPrice = path.getAttribute("data-min-price") != '0' && path.getAttribute("data-min-price") != ''  ? path.getAttribute("data-min-price") : '-';
          let maxPrice = path.getAttribute("data-max-price") != '0' && path.getAttribute("data-max-price") != '' ? path.getAttribute("data-max-price") : '-';
          this.plotDetails.pricing = minPrice == maxPrice ?  minPrice :  ` ${minPrice} to ${maxPrice}`;

          // console.log(this.plotDetails);
        } catch (e) {
          console.log(e);
          return;
        }

      }
    });

    document.querySelectorAll<HTMLElement>('svg text.plotNumber').forEach((ele) => {
      ele.onclick = (e) => {

        try {
          this.plotDetails = {};
          let plotNumber = (e.target as HTMLElement).innerHTML.trim();
          let plot = document.getElementById("plot" + plotNumber);
          plot.dispatchEvent(new Event('click'));
        }
        catch (e) {
          console.log(e);
        }
      }
    });

    this.getPlotsStatus();
  }

  getPlotsStatus(): void {
    let data = {
      projectId: this.project.id
    };
    this.bookingsServiceService.plotsStatus(data).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.authenticated = response.authenticated;
      this.plotsAggregateData = {};
      this.plotsAggregateData.total_plots = response.total_plots;
      this.plotsAggregateData.available_plots = response.available_plots;
      this.plotsAggregateData.booked_plots = response.booked_plots;

      this.updateSvgPlotsStatus(response);
      // this.notificationsService.toggleSucceeded(true, response.message);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }


  updateSvgPlotsStatus(response): void {
    try {
      let plots = response.data;
      for (let plotIndex of Object.keys(plots)) {

        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-booked", !plots[plotIndex]['booking_id'] ? 'Available' : 'Booked');
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-booking-id", !plots[plotIndex]['booking_id'] ? null : plots[plotIndex]['booking_id']);

        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-plot-facing-north", plots[plotIndex]['is_north_facing'] == '1' ? 'true' : 'false');
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-plot-facing-east", plots[plotIndex]['is_east_facing'] == '1' ? 'true' : 'false');
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-plot-facing-south", plots[plotIndex]['is_south_facing'] == '1' ? 'true' : 'false');
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-plot-facing-west", plots[plotIndex]['is_west_facing'] == '1' ? 'true' : 'false');

        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-measurement-north", plots[plotIndex]['north_dimensions'] && plots[plotIndex]['north_dimensions'] != 'null' && plots[plotIndex]['north_dimensions']!='NULL' ? plots[plotIndex]['north_dimensions'] : '' );
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-measurement-east",  plots[plotIndex]['east_dimensions'] && plots[plotIndex]['east_dimensions'] != 'null' && plots[plotIndex]['east_dimensions']!='NULL' ? plots[plotIndex]['east_dimensions'] : '' );
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-measurement-south",  plots[plotIndex]['south_dimensions'] && plots[plotIndex]['south_dimensions'] != 'null' && plots[plotIndex]['south_dimensions']!='NULL' ? plots[plotIndex]['south_dimensions'] : '' );
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-measurement-west", plots[plotIndex]['west_dimensions'] && plots[plotIndex]['west_dimensions'] != 'null' && plots[plotIndex]['west_dimensions']!='NULL' ? plots[plotIndex]['west_dimensions'] : '');

        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-area",  plots[plotIndex]['area'] && plots[plotIndex]['area'] != 'null' && plots[plotIndex]['area']!='NULL' ? plots[plotIndex]['area'] : '' );

        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-min-price", plots[plotIndex]['min_price'] && plots[plotIndex]['min_price'] != 'null' && plots[plotIndex]['min_price']!='NULL' ? plots[plotIndex]['min_price'] : '');
        document.getElementById('plot' + plots[plotIndex]['plot_no']).setAttribute("data-max-price", plots[plotIndex]['max_price'] && plots[plotIndex]['max_price'] != 'null' && plots[plotIndex]['max_price']!='NULL' ? plots[plotIndex]['max_price'] : '');



        if (plots[plotIndex]['booking_id']) {
          document.getElementById('plot' + plots[plotIndex]['plot_no']).classList.add("booked");
        }
      }
    }
    catch (e) {
      this.notificationsService.toggleFailed(true, "There was an error in displaying plots status");

    }

  }


  onFilterLayout(): void {
    if (this.plotFilter.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, 'Applying filter..');
    this.removBookedMatchedClass();

    let numberOfResults = 0;

    if (this.plotFilter.value.plots.trim().length > 0) {
      let plots = this.plotFilter.value.plots.split(",");
      plots.forEach(plot => {
        if (document.getElementById("plot" + plot)) {
          document.getElementById("plot" + plot).classList.add("matched");
          numberOfResults++;
        }
      });
    }

    let attr = null;
    let status = null;

    if (this.plotFilter.value.plotFacing != "-1" && ["north", "south", "west", "east"].includes(this.plotFilter.value.plotFacing)) {
      attr = "data-plot-facing-" + this.plotFilter.value.plotFacing;
    }

    if (this.plotFilter.value.bookingStatus != "-1" && ["Available", "Booked"].includes(this.plotFilter.value.bookingStatus)) {
      status = this.plotFilter.value.bookingStatus;
    }

    document.querySelectorAll<HTMLElement>('svg path.plot').forEach((ele) => {
      if (attr != null && status != null) {
        if (ele.getAttribute(attr) == "true" && ele.getAttribute("data-booked") == status) {
          ele.classList.add("matched");
          numberOfResults++;
        }
      }
      if (attr != null && status == null) {
        if (ele.getAttribute(attr) == "true") {
          ele.classList.add("matched");
          numberOfResults++;
        }
      }
      if (status != null && attr == null) {
        if (ele.getAttribute("data-booked") == status) {
          ele.classList.add("matched");
          numberOfResults++;
        }
      }
    });

    this.notificationsService.toggleLoading(false);

    if (numberOfResults < 1) {
      this.notificationsService.toggleFailed(true, "No results found");
      return;
    }
    this.notificationsService.toggleSucceeded(true, 'Layout updated..');


  }

  onResetLayout(): void {
    document.querySelectorAll<HTMLElement>('svg path.plot').forEach((ele) => {
      ele.classList.remove("booked");
      ele.classList.remove("matched");
      if (ele.getAttribute("data-booked") == "Booked") {
        ele.classList.add("booked");
      }
    });
    this.notificationsService.toggleSucceeded(true, 'Layout updated..');

  }

  removBookedMatchedClass(): void {
    document.querySelectorAll<HTMLElement>('svg path.plot').forEach((ele) => {
      ele.classList.remove("booked");
      ele.classList.remove("matched");
    });
  }

}
