import { NotificationsService } from './notifications.service';
import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  fetchedUserInfo: any;

  dashboardData: any;

  constructor(
    private httpClient: HttpClient,
    private dataHolderService: DataHolderService,
    private notificationsService: NotificationsService,
    private router: Router) { }

  checkAuthentication(): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/check-auth', {});
  }

  fetchMyProfile(): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/my-profile');
  }

  updateMyProfile(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/my-profile', body);
  }


  fetchUserProfile(userId, isBroker = null): any {
    let body = new HttpParams();
    body = body.set('userId', userId.split("-").pop());
    if (isBroker != null) {
      body = body.set('isBroker', isBroker);
    }
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/get-user-info', { params: body });
  }

  addProfile(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/add-user', body);
  }


  updateUserProfile(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/update-user-info', body);

  }

  deleteMyProfilePicture(): any {
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/delete-profile-picture');
  }


  deleteUserProfilePicture(userId): any {
    let body = new HttpParams();
    body = body.set('userId', userId);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/delete-user-profile-picture', {
      params: body
    });
  }

  searchUsers(body): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/users', {
      params: body
    });
  }

  accountSearchSuggestions(body): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/load-search-suggestions', {
      params: body
    });
  }

  logoutUser(): any {
    this.notificationsService.toggleLoading(true, 'Logging out...');
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/logout', {}).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      window.location.reload();
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 500);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  getDashboardData(): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/dashboard', {});

  }

  changePassword(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/change-password', body);
  }


  deleteAccount(userId): any {
    let body = new HttpParams();
    body = body.set('userId', userId);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/delete-account', {
      params: body
    });
  }


}
