import { Injectable } from '@angular/core';
import { Title , Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DataHolderService {

  apiBaseUrl : string = "/api";
  serverBaseUrl : string = "/";
  userInfo : any = null;

  projectMetaDeta;

  constructor(private metaTagService: Meta , private titleService: Title ) {

  }

  public updatePageMeta( pageTitle = null , pageDescription = null , ogImage = null) : void{

    this.titleService.setTitle( pageTitle!=null ? pageTitle : this.projectMetaDeta.default_title);
    this.metaTagService.updateTag( { name:'description', content: pageDescription!=null ? pageDescription : this.projectMetaDeta.default_description  },"name='description'");
    this.metaTagService.updateTag( { name:'og:image', content: ogImage!=null ? ogImage : this.projectMetaDeta.default_og_image },"name='og:image'");

  }


}
