import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from './../notifications.service';

@Component({
  selector: 'app-property-types',
  templateUrl: './property-types.component.html',
  styleUrls: ['./property-types.component.css']
})
export class PropertyTypesComponent implements OnInit {

  @Input() pageIsEmbedded: boolean = false;


  constructor(public dataHolderService: DataHolderService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {

    if (!this.pageIsEmbedded) {
      this.dataHolderService.updatePageMeta(`Property Types | ${this.dataHolderService.projectMetaDeta.default_title}`);

    }


  }

}
