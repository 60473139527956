<!-- <div class="fullWidthSection projectsList">

</div> -->

<section id="locations" class="project-Container projectLocations">
  <h3 class="border-heading">OUR PROJECTS</h3>
  <section class="twoColumnsGrid project-list">
    <ng-container *ngFor="let project of dataHolder.projectMetaDeta.PROJECTS">
      <app-project-card [project]="project"></app-project-card>
    </ng-container>
  </section>
</section>
