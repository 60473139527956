<section class="responsiveContainer">
  <h2>Home Page Slides</h2>
  <p>These are visible on the landing page of your site</p>
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr>
          <th style="text-align: right;" colspan="6">
            <a data-attachment-is-create="true" (click)="onAddEditProjectImage($event)" class="grad1"
              style="color:white;"><span><i aria-hidden="true" class="fa fa-plus"></i>Add Slide </span></a>
          </th>
        </tr>
        <tr>
          <th></th>
          <th>Order</th>
          <th>Title</th>
          <th>Image</th>
          <th>Link1</th>
          <th>Link2</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let slide of slides;index as img">
          <td>
            <a data-attachment-is-create="false" attr.data-attachment-data="{{ slides[img] | json }}"
              (click)="onAddEditProjectImage($event)"><span><i aria-hidden="true" class="fa fa fa-pencil"></i> Edit
              </span></a>

          </td>
          <td>{{ slides[img]['sort_order'] ?  slides[img]['sort_order'] : 0 }}</td>
          <td>{{ slides[img]['heading'] }}</td>
          <td>
            <a target="_blank" href="{{ slides[img]['image_url']  }}"><img class="thum" src="{{ slides[img]['image_url']  }}" />
            </a>
          </td>
          <td>
            <a target="_blank" href="{{ slides[img]['links'][0]  }}">{{ slides[img]['linkText'][0]  }}
            </a>
          </td>
          <td>
            <a target="_blank"  href="{{ slides[img]['links'][1]  }}">{{ slides[img]['linkText'][1]  }}
            </a>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</section>

<section class="fullScreenPopup" *ngIf="projectImageFormVisible">

  <div class="container">

    <div (click)="projectImageFormVisible = !projectImageFormVisible;" class="closeFullScreenPopup">
      <button> <i class="fa fa-times" aria-hidden="true"></i> CANCEL </button>
    </div>

    <app-add-edit-home-page-slide [isCreate]="projectImageFormIsCreate" [attachmentData]="projectImageFormData"
      (latestFetchedProjectInfo)="updateProjectPage($event)">
    </app-add-edit-home-page-slide>


  </div>



</section>
