<section *ngIf="filteredProjects" id="locations" class="projectLocations">
  <h3 class="border-heading">{{ filteredProjects.heading }}</h3>

  <section class="twoColumnsGrid project-list">

    <ng-container *ngFor="let project of filteredProjects.projects">
      <app-project-card [project]="project"></app-project-card>
    </ng-container>

  </section>
</section>

<app-cities *ngIf="!pageIsEmbedded" [pageIsEmbedded]="true"></app-cities>
