<app-home-page-slide-show></app-home-page-slide-show>
<!-- <app-location-image></app-location-image> -->

<app-render-custom-page [fetchConfig]="{ url_key : 'INTERNAL_HOME_PAGE_CONTENT' , type_of_content : '2' , is_visible_on_page_tabs : '0' ,   sortBy : 'sort_order' , sortOrder : 'ASC' , pagination : '500' }"></app-render-custom-page>

<!-- <app-projects-list [pageIsEmbedded]="true"></app-projects-list> -->

<app-project-status-projects [pageIsEmbedded]="true" [projectStatusId]="'1'"></app-project-status-projects>
<app-property-types [pageIsEmbedded]="true"></app-property-types>
<app-cities [pageIsEmbedded]="true"></app-cities>

<!-- <app-project-status-projects [pageIsEmbedded]="true" [projectStatusId]="'2'"></app-project-status-projects> -->

<app-contact-us [pageIsEmbedded]="true"></app-contact-us>
