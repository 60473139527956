<div class="fullWidthSection bkgs" style="margin-top: 0em;">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin: 0; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">BOOKINGS
  </h3>

  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="radio-toggler noselect">
      <div class="radio-container">
        <input (change)="setViewVisibility('listView')" name="viewType" [checked]="viewType == 'listView'" type="radio"
          value="listView" id="listView">
        <label for="listView">
          <i class="fa fa-table" aria-hidden="true"></i> LIST
        </label>
        <input (change)="setViewVisibility('reportView')" name="viewType" [checked]="viewType == 'reportView'"
          type="radio" value="reportView" id="reportView">
        <label for="reportView">
          <i class="fa fa-th-large" aria-hidden="true"></i> SUMMARY
        </label>
      </div>
    </div>

    <pre></pre>

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS & REPORTS</span>
    </div>

  </div>

</section>

<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchBookings" (ngSubmit)="onFilterBookings()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-select">
              <label for="projectId">Project</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterBookings();"
                placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
                <option value="">Select project(*required)</option>
                <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                  [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
                </option>
              </select>
              <p class="errors">
                <span *ngIf="submitted &&  searchBookings.controls['projectId'].errors?.required"> *required</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>BOOKING ID</label>
              <input name="bookingId" formControlName="bookingId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['bookingId'].errors?.minlength"> *Minimum 1 charcter
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Payment Status</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterBookings();"
                placeholder="Select option.." formControlName="bookingStatus" name="bookingStatus">
                <option [value]="0">Pending Payment
                </option>
                <option [value]="1">Full Payment received
                </option>
                <option [value]="-1">Pending & Full Payment received
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Price</label>
              <input formControlName="minimumPrice" type="number" step="any" name="minimumPrice"
                placeholder="Minimum Total ₹">
              <ng-container *ngIf="searchBookings.value.minimumPrice > 0">
                <ul>
                  <li> <strong> {{ searchBookings.value.minimumPrice | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{ searchBookings.value.minimumPrice | numberToWords | titlecase }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['minimumPrice'].errors?.numeric"> *Enter valid numeric
                  price</span>
                <span *ngIf="submitted && searchBookings.controls['minimumPrice'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Price</label>
              <input formControlName="maximumPrice" type="number" step="any" name="maximumPrice"
                placeholder="Maximum Total ₹">
              <ng-container *ngIf="searchBookings.value.maximumPrice > 0">
                <ul>
                  <li> <strong> {{ searchBookings.value.maximumPrice | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{ searchBookings.value.maximumPrice | numberToWords }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['maximumPrice'].errors?.numeric"> *Enter valid numeric
                  price</span>
                <span *ngIf="submitted && searchBookings.controls['maximumPrice'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Brokerage</label>
              <input formControlName="minimumBrokerage" type="number" step="any" name="minimumBrokerage"
                placeholder="Minimum Brokerage ₹">
              <ng-container *ngIf="searchBookings.value.minimumBrokerage > 0">
                <ul>
                  <li> <strong> {{ searchBookings.value.minimumBrokerage | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{ searchBookings.value.minimumBrokerage | numberToWords | titlecase }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['minimumBrokerage'].errors?.numeric"> *Enter valid
                  numeric
                  price</span>
                <span *ngIf="submitted && searchBookings.controls['minimumBrokerage'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Brokerage</label>
              <input formControlName="maximumBrokerage" type="number" step="any" name="maximumBrokerage"
                placeholder="Maximum Brokerage ₹">
              <ng-container *ngIf="searchBookings.value.maximumBrokerage > 0">
                <ul>
                  <li> <strong> {{ searchBookings.value.maximumBrokerage | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{ searchBookings.value.maximumBrokerage | numberToWords }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['maximumBrokerage'].errors?.numeric"> *Enter valid
                  numeric
                  price</span>
                <span *ngIf="submitted && searchBookings.controls['maximumBrokerage'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes"
                placeholder="Any notes you had added previously"></textarea>

              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['additionalNotes'].errors?.minlength"> *Minimum 1
                  character
                </span>
                <span *ngIf="submitted && searchBookings.controls['additionalNotes'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>

        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>User ID</label>
              <ul class="inline">
                <li>
                  <a routerLink="/user-accounts" class="textualLink">Search users </a>
                </li>
                <li>
                  ID of the buyer
                </li>
              </ul>
              <input formControlName="userId" type="text" name="userId" placeholder="Buyer's User id"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />

              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['userId'].errors?.minlength"> *Minimum 1
                  character</span>
              </p>

            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label for="brokeredDeals">Only Brokered deals?</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterBookings();"
                formControlName="brokeredDeals" name="brokeredDeals" id="brokeredDeals">
                <option [value]="-1">No</option>
                <option [value]="1">Yes</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Agent ID</label>
              <ul class="inline">
                <li>
                  <a routerLink="/user-accounts" [queryParams]="{ isBroker : '1' , isFormVisible : '1' }"
                    class="textualLink">Search agents </a>
                </li>
                <li>
                  ID of the agent
                </li>
              </ul>

              <input formControlName="brokerId" type="text" name="brokerId"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['brokerId'].errors?.minLength"> *Minimum 1 character
                </span>
              </p>

            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Partner ID</label>
              <ul class="inline">
                <li>ID of the partner who registered the booking</li>
              </ul>
              <input formControlName="bookedBy" type="text" name="bookedBy"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['bookedBy'].errors?.minLength"> *Minimum 1 character
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Booking Date </label>
              <input formControlName="minimumDate" type="date" name="minimumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['minimumDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Booking Date </label>
              <input formControlName="maximumDate" type="date" name="maximumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchBookings.controls['maximumDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort by</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterBookings();"
                placeholder="Select option.." formControlName="sortBy" name="sortBy">
                <option value="booked_on">Booking Date</option>
                <option value="price">Price</option>
                <option value="payment_status">Payment received</option>
                <option value="brokerage">Brokerage</option>

              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort order</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterBookings();"
                placeholder="Select option.." formControlName="sortOrder" name="sortOrder">
                <option value="ASC">Low to high</option>
                <option value="DESC">High to low</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterBookings();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>

        <div>
          <a class="submit" target="_blanc" [routerLink]="dataHolderService.apiBaseUrl + '/bookings-report'"
            [routerLink]="" [queryParams]="{
            projectId :  searchBookings.value.projectId,
            minimumPrice :  searchBookings.value.minimumPrice,
            maximumPrice :  searchBookings.value.maximumPrice,
            minimumBrokerage :  searchBookings.value.minimumBrokerage,
            maximumBrokerage :  searchBookings.value.maximumBrokerage,
            bookingId :  searchBookings.value.bookingId,
            userId :  searchBookings.value.userId,
            bookedBy : searchBookings.value.bookedBy,
            brokerId :  searchBookings.value.brokerId,
            brokeredDeals :  searchBookings.value.brokeredDeals,
            additionalNotes :  searchBookings.value.additionalNotes,
            minimumDate :  searchBookings.value.minimumDate,
            maximumDate :  searchBookings.value.maximumDate,
            bookingStatus :  searchBookings.value.bookingStatus,
            sortBy :  searchBookings.value.sortBy,
            sortOrder :  searchBookings.value.sortOrder,
            viewType : viewType
          }">Download Report</a>
        </div>
      </div>


    </form>
  </section>
</main>

<section class="responsiveContainer single-booking-cards" *ngIf="totalResults > 0 && viewType == 'reportView'">
  <div class="horizontal-card" *ngFor="let bkg of Object.keys(bookings)">
    <div class="twoColumnsGrid">
      <div>
        <h1><a [routerLink]="['/bookings', bkg ]" class="grad1"> {{ bkg }}</a></h1>
        <p class="blurred-title">{{ bookings[bkg].project.name }}</p>
        <p class="blurred-title"> Plots </p>
        <p *ngIf="bookings[bkg].bookingData.plots">
          <span *ngFor="let plotNo of bookings[bkg].bookingData.plots.split(',')" [routerLink]="['/plots']"
            [queryParams]="{ projectId: bookings[bkg].project.id ,  plots : plotNo  }"> <i class="fa fa-home"
              aria-hidden="true"></i> {{ plotNo }} </span>
        </p>
        <p *ngIf="!bookings[bkg].bookingData.plots">*No plots added*</p>
        <p>Buyer -
          {{ bookings[bkg].user.first_name + " " + (bookings[bkg].user.middle_name!=null ?
          bookings[bkg].user.middle_name : "") + " " + bookings[bkg].user.last_name }}
        </p>
        <p>
          <span [routerLink]="[ '/user-accounts/edit' , bookings[bkg].user.url ]"> <i class="fa fa-user-o"
              aria-hidden="true"></i> {{ bookings[bkg].user.id }}</span>
          <span> <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ bookings[bkg].user.email }}</span>
          <span> <i class="fa fa-phone" aria-hidden="true"></i> {{ bookings[bkg].user.mobile_number }}</span>
        </p>

        <ng-container *ngIf="bookings[bkg].broker?.id">
          <p>Agent -
            {{ bookings[bkg].broker.first_name + " " + (bookings[bkg].broker.middle_name!=null ?
            bookings[bkg].broker.middle_name : "") + " " + bookings[bkg].broker.last_name }}
          </p>
          <p>
            <span [routerLink]="[ '/user-accounts/edit' , bookings[bkg].broker.url ]"> <i class="fa fa-link"
                aria-hidden="true"></i> {{ bookings[bkg].broker.id }}</span>
            <span> <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ bookings[bkg].broker.email }}</span>
            <span> <i class="fa fa-phone" aria-hidden="true"></i> {{ bookings[bkg].broker.mobile_number }}</span>
          </p>
          <p> Brokerage: {{ bookings[bkg].bookingData.brokerage | properCurrencyFormat }} </p>
          <p> ({{ bookings[bkg].bookingData.brokerage | numberToWords | titlecase }})</p>
        </ng-container>

        <p>Booked by -
          {{ bookings[bkg].bookedBy.first_name + " " + (bookings[bkg].bookedBy.middle_name!=null ?
          bookings[bkg].bookedBy.middle_name : "") + " " + bookings[bkg].bookedBy.last_name }}
        </p>
        <p>
          <span [routerLink]="[ '/user-accounts/edit' , bookings[bkg].bookedBy.url ]"> <i class="fa fa-users"
              aria-hidden="true"></i> {{ bookings[bkg].bookedBy.id }}</span>
          <span> <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ bookings[bkg].bookedBy.email }}</span>
          <span> <i class="fa fa-phone" aria-hidden="true"></i> {{ bookings[bkg].bookedBy.mobile_number }}</span>
        </p>
      </div>
      <div>
        <p class="blurred-title"> Payment status </p>
        <h1 *ngIf="bookings[bkg].bookingData.price == bookings[bkg].bookingData.total_payment_done">
          <a [routerLink]="['/bookings', bkg ]" class="grad2"> <i class="fa fa-check" aria-hidden="true"></i>FULL
            PAYMENT DONE </a>
        </h1>
        <h1 *ngIf="bookings[bkg].bookingData.price != bookings[bkg].bookingData.total_payment_done">
          <a [routerLink]="['/bookings', bkg ]" class="grad2"> <i class="fa fa-hourglass-half" aria-hidden="true"></i>
            PENDING PAYMENT </a>
        </h1>

        <p>
          <span>
            {{ ((bookings[bkg].bookingData.total_payment_done / bookings[bkg].bookingData.price) * 100).toFixed(2) }} %
            payment received
          </span>
        </p>
        <p> Price : {{ bookings[bkg].bookingData.price | properCurrencyFormat }} </p>
        <p> ({{ bookings[bkg].bookingData.price | numberToWords | titlecase }})</p>

        <p> Received : {{ bookings[bkg].bookingData.total_payment_done | properCurrencyFormat }} </p>
        <p> ({{ bookings[bkg].bookingData.total_payment_done | numberToWords | titlecase }}) </p>

        <p><span> Booked on {{ bookings[bkg].bookingData.booked_on | momentDateTransformPipe : ('MMM Do, YYYY ') }}
          </span> </p>

      </div>
    </div>

    <div class="tabularData">
      <table>
        <thead>
          <tr>
            <th colspan="3">
              TRANSACTIONS
            </th>
          </tr>
          <tr>
            <th>TRANSACTION ID</th>
            <th>DATE</th>
            <th>CREDIT</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trn of Object.keys(bookings[bkg].transactions)">
            <td [routerLink]="['/bookings', bkg ]">
              {{ trn }}
            </td>
            <td>
              {{ bookings[bkg].transactions[trn].added_on | momentDateTransformPipe : ('MMM Do, YYYY ') }}
            </td>
            <td>
              {{ bookings[bkg].transactions[trn].credit | properCurrencyFormat }}

            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <div class="tabularData">
      <table>
        <thead>
          <tr>
            <th colspan="3">
              REMAINDERS
            </th>
          </tr>
          <tr>
            <th>REMAINDER ID</th>
            <th>DATE</th>
            <th>NOTES</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rmd of Object.keys(bookings[bkg].remainders)">
            <td [routerLink]="['/bookings', bkg ]">
              {{ rmd }}
            </td>
            <td>
              {{ bookings[bkg].remainders[rmd].remainder_date | momentDateTransformPipe : ('MMM Do, YYYY ') }} <br />
              ( {{ bookings[bkg].remainders[rmd].remainder_date | momentDateDiffPipe }} )
            </td>
            <td>
              {{ bookings[bkg].remainders[rmd].additional_notes ? bookings[bkg].remainders[rmd].additional_notes : '-'
              }}

            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <div class="tabularData">
      <table>
        <thead>
          <tr>
            <th colspan="4">
              ATTACHMENTS
            </th>
          </tr>
          <tr>
            <th>ATTACHMENT ID</th>
            <th>NAME</th>
            <th>DESCRIPTION</th>
            <th>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aht of Object.keys(bookings[bkg].attachments)">
            <td [routerLink]="['/bookings', bkg ]">
              {{ aht }}
            </td>
            <td>
              {{ bookings[bkg].attachments[aht].attachment_name ? bookings[bkg].attachments[aht].attachment_name : '-'
              }}
            </td>
            <td>
              {{ bookings[bkg].attachments[aht].attachment_description ?
              bookings[bkg].attachments[aht].attachment_description : '-' }}
            </td>
            <td>
              <a
                href="{{ dataHolderService.apiBaseUrl + '/download-attachment?bookingId=' + bkg + '&attachmentId=' + aht  }}">
                {{ bookings[bkg].attachments[aht].client_side_attachment_name }} </a>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</section>

<section class="fullWidthTable" *ngIf="totalResults > 0 && viewType == 'listView'">
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>Booking ID</th>
          <th>Booked On</th>
          <th>Weekday</th>
          <th>Project Name</th>
          <th>Plots</th>
          <th>Agent</th>
          <th>Brokerage</th>
          <th>Price</th>
          <th>Received Amount</th>
          <th>Pending Amount</th>
          <th>Payment Status</th>
          <th>Buyer</th>
          <th>Buyer - Email</th>
          <th>Buyer - Mobile</th>
          <th>Booked By</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bkg of Object.keys(bookings)">
          <td>
            <a [routerLink]="['/bookings', bkg ]">
              <span> <i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>
          <td>
            {{ bkg }}
          </td>


          <td>{{ bookings[bkg].bookingData.booked_on | momentDateTransformPipe : (' DD-MM-YYYY ') }}</td>

          <td>{{ bookings[bkg].bookingData.booked_on | date : ('EEEE') }} ({{ bookings[bkg].bookingData.booked_on |
            momentDateDiffPipe }})</td>


          <td>{{ bookings[bkg].project.name }}</td>

          <td>
            <p *ngIf="bookings[bkg].bookingData.plots">
              <span [routerLink]="['/plots']" *ngFor="let plotNo of bookings[bkg].bookingData.plots.split(',')"
                [queryParams]="{ projectId: bookings[bkg].project.id ,  plots : plotNo  }"> <i class="fa fa-home"
                  aria-hidden="true"></i> {{ plotNo }} </span>
            </p>
            <p *ngIf="!bookings[bkg].bookingData.plots">*No plots added*</p>
          </td>

          <td>
            <p *ngIf="bookings[bkg].broker.first_name">
              <a [routerLink]="['/user-accounts/edit', bookings[bkg].broker.url ]" class="main-link" target="_blanc">
                {{ bookings[bkg].broker.first_name + " " + (bookings[bkg].broker.middle_name!=null ?
              bookings[bkg].broker.middle_name : "") + " " + bookings[bkg].broker.last_name }}</a>
            </p>
            <p *ngIf="!bookings[bkg].broker.first_name"> - </p>
          </td>

          <td>{{ bookings[bkg].bookingData.brokerage | properCurrencyFormat }}</td>

          <td>{{ bookings[bkg].bookingData.price | properCurrencyFormat }}</td>


          <td>{{ bookings[bkg].bookingData.total_payment_done | properCurrencyFormat }}</td>

          <td>{{ bookings[bkg].bookingData.price - bookings[bkg].bookingData.total_payment_done | properCurrencyFormat}}
          </td>
          <td>{{ ((bookings[bkg].bookingData.total_payment_done / bookings[bkg].bookingData.price) * 100).toFixed(2) }}
            %
            <p *ngIf="bookings[bkg].bookingData.price == bookings[bkg].bookingData.total_payment_done">FULL PAYMENT DONE
            </p>
            <p *ngIf="bookings[bkg].bookingData.price != bookings[bkg].bookingData.total_payment_done">PENDING PAYMENT
            </p>
          </td>

          <td><a [routerLink]="['/user-accounts/edit', bookings[bkg].user.url ]" class="main-link" target="_blanc">{{
              bookings[bkg].user.first_name + " " + (bookings[bkg].user.middle_name!=null ?
              bookings[bkg].user.middle_name :
              "") + " " + bookings[bkg].user.last_name }}</a>
          </td>

          <td>
            <p *ngIf="!bookings[bkg].user.email"> - </p>
            <p>{{ bookings[bkg].user.email }}</p>
          </td>

          <td>
            <p *ngIf="!bookings[bkg].user.mobile_number"> - </p>
            <p>{{ bookings[bkg].user.mobile_number }}</p>
          </td>

          <td>
            <a [routerLink]="[ '/user-accounts/edit' , bookings[bkg].bookedBy.url ]"> {{ bookings[bkg].bookedBy.first_name + " " + (bookings[bkg].bookedBy.middle_name!=null ?
              bookings[bkg].bookedBy.middle_name : "") + " " + bookings[bkg].bookedBy.last_name }} </a>

            </td>

        </tr>
      </tbody>
    </table>
  </div>
</section>


<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found </p>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchBookings.value.page }" [routerLink]="['./']" [queryParams]="{
        projectId :  searchBookings.value.projectId,
        minimumPrice :  searchBookings.value.minimumPrice,
        maximumPrice :  searchBookings.value.maximumPrice,
        minimumBrokerage :  searchBookings.value.minimumBrokerage,
        maximumBrokerage :  searchBookings.value.maximumBrokerage,
        bookingId :  searchBookings.value.bookingId,
        userId :  searchBookings.value.userId,
        bookedBy :  searchBookings.value.bookedBy,
        brokerId :  searchBookings.value.brokerId,
        brokeredDeals :  searchBookings.value.brokeredDeals,
        additionalNotes :  searchBookings.value.additionalNotes,
        minimumDate :  searchBookings.value.minimumDate,
        maximumDate :  searchBookings.value.maximumDate,
        bookingStatus :  searchBookings.value.bookingStatus,
        sortBy :  searchBookings.value.sortBy,
        sortOrder :  searchBookings.value.sortOrder,
        pagination : searchBookings.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink,
        viewType : viewType

      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
