<main>
  <section class="responsiveContainer">
    <form [id]="isCreate ? 'addRemainder' : remainderForm.value.remainderId " [formGroup]="remainderForm"
      (ngSubmit)="onRemainder()" #form="ngForm" class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>{{ isCreate ? 'CREATE REMAINDER' : remainderForm.value.remainderId  }}</span>
        </h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>BOOKING ID</label>
              <input readonly name="bookingId" formControlName="bookingId" type="text" readonly [value]="bookingId">
            </div>
          </div>
        </div>
        <div class="form-middle">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Remainder Date </label>
              <input formControlName="remainderDate" type="date" name="remainderDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="fetchedRemainder && remainderForm.controls['remainderDate'].errors?.invalidMsg">
                  *Select a valid
                   date</span>
              </p>
            </div>
          </div>
          <ng-container *ngIf="!isCreate">
          <div class="form-field">
            <div class="input-container with-label">
              <ul>
                <li>Remainder last updated :
                </li>
                <li>
                  {{ remainderData.updated_on | momentDateDiffPipe }}
                </li>
                <li>( {{ remainderData.updated_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>
              </ul>
            </div>
          </div>
          </ng-container>
        </div>
        <div class="form-right">
          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <label>Remainder ID</label>
                <input readonly name="remainderId" formControlName="remainderId" type="text" readonly>
              </div>
            </div>

          </ng-container>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes"
                placeholder="Anything you want to add to the remainder?"></textarea>
              <p class="errors">
                <span *ngIf="fetchedRemainder && remainderForm.controls['additionalNotes'].errors?.minlength">
                  *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedRemainder && remainderForm.controls['additionalNotes'].errors?.maxlength">
                  *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <button (click)="deleteRemainder();" *ngIf="!isCreate" type="button">DELETE</button>
        <p  style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> Booking data will be refreshed, if you make changes to remainders </p>

      </div>
    </form>
  </section>
</main>
