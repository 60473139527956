import { ProfilesService } from './../profiles.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit , Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, FormGroupExtension, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-add-or-edit-user',
  templateUrl: './add-or-edit-user.component.html',
  styleUrls: ['./add-or-edit-user.component.css']
})
export class AddOrEditUserComponent implements OnInit {

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedProfile: boolean = false;

  hasProfilePicture: boolean = false;

  profilePictureUrl: string = "";

  myProfileForm: FormGroup;

  hasPreview: boolean = false;

  imageUri: any;

  disabled : boolean = false;

  passwordFieldHidden : boolean = true;

  constructor(
    private formBuilder: RxFormBuilder, public notificationsService: NotificationsService,
    public myProfileService: ProfilesService,
    public dataHolderService: DataHolderService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }


  ngOnInit(): void {
    if(this.isCreate){
      this.dataHolderService.updatePageMeta(`Add User Account | ${this.dataHolderService.projectMetaDeta.default_title}`);
    }
    else{
        this.dataHolderService.updatePageMeta(`Edit - ${this.myProfileService.fetchedUserInfo['first_name']} ${this.myProfileService.fetchedUserInfo['middle_name']} ${this.myProfileService.fetchedUserInfo['last_name']} | User Accounts | ${this.dataHolderService.projectMetaDeta.default_title}`);
    }

    this.myProfileForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(200) ]],

      // lastName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30), this.customPatternValid({ pattern: /^[a-zA-Z]+$/, msg: 'Only alphabets with no spaces are allowed' })]],
      // middleName: ["", [ Validators.minLength(2), Validators.maxLength(30), this.customPatternValid({ pattern: /^[a-zA-Z]+$/, msg: 'Only alphabets with no spaces are allowed' })]],
      gender : ["-1"],
      broker : ["-1"],
      partner : ["-1"],
      password: ["", [Validators.minLength(8), Validators.maxLength(128)]],
      userId : [""],
      about: ["", [Validators.minLength(2), Validators.maxLength(250)]],
      address: ["", [Validators.minLength(3), Validators.maxLength(512)]],
      whatsAppNumber: ["", [Validators.minLength(10), Validators.maxLength(20), this.customPatternValid({ pattern: /^[0-9+]+$/, msg: 'Mobile number can contain only digits and an optional + prefix' })]],
      mobileNumber: ["", [  Validators.minLength(10), Validators.maxLength(20), this.customPatternValid({ pattern: /^[0-9+]+$/, msg: 'Mobile number can contain only digits and an optional + prefix' })]],
      email: ["", [ Validators.email]],
      profilePicture: ["", [
        RxwebValidators.extension({ extensions: ['png', 'jpg', 'jpeg', 'bmp', 'gif'] }),
        RxwebValidators.file({ minFiles: 1, maxFiles: 1 }),
        RxwebValidators.fileSize({ minSize: 10000, maxSize: 1e+7 }),
      ]]
    });

    if(!this.isCreate){
      this.updateForm();
    }
    else{
      this.checkForQueryParams();

    }

  }


  checkForQueryParams(): void {
    this.route.queryParams.subscribe(queryParams => {

      if ("broker" in queryParams) {
        this.myProfileForm.patchValue({
          broker : 1
        });
        this.dataHolderService.updatePageMeta(`Add Agent Account | ${this.dataHolderService.projectMetaDeta.default_title}`);

      }

    });
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      if (control.value && !control.value.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  updateForm(response = null): void {
    this.fetchedProfile = true;
    if(response!=null){
        this.notificationsService.toggleSucceeded(true, response.message );
    }

    this.imageUri = null;
    this.hasPreview = false;
    this.hasProfilePicture = false;

    this.myProfileForm.controls.firstName.setValue(this.myProfileService.fetchedUserInfo.first_name);
    // this.myProfileForm.controls.middleName.setValue(this.myProfileService.fetchedUserInfo.middle_name);
    // this.myProfileForm.controls.lastName.setValue(this.myProfileService.fetchedUserInfo.last_name);
    this.myProfileForm.controls.gender.setValue(this.myProfileService.fetchedUserInfo.gender);
    this.myProfileForm.controls.about.setValue(this.myProfileService.fetchedUserInfo.about);
    this.myProfileForm.controls.address.setValue(this.myProfileService.fetchedUserInfo.address);
    this.myProfileForm.controls.email.setValue(this.myProfileService.fetchedUserInfo.email);
    this.myProfileForm.controls.whatsAppNumber.setValue(this.myProfileService.fetchedUserInfo.whatsapp_number);
    this.myProfileForm.controls.mobileNumber.setValue(this.myProfileService.fetchedUserInfo.mobile_number);
    this.myProfileForm.controls.broker.setValue(this.myProfileService.fetchedUserInfo.is_broker);

    if(!this.isCreate){
      this.myProfileForm.controls.userId.setValue(this.myProfileService.fetchedUserInfo.id);

    }
    if (this.myProfileService.fetchedUserInfo.profile_picture_url.length > 5) {
      this.hasProfilePicture = true;
      this.profilePictureUrl = this.dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + this.myProfileService.fetchedUserInfo.id + "&timestamp=" + new Date().getTime();
    }

    if(this.dataHolderService.userInfo.is_super_admin == 'true'){
      this.myProfileForm.controls.partner.setValue(this.myProfileService.fetchedUserInfo.is_partner == 'true' ? '1' : '0');

    }
  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  preventDefaultOnDrag(e): void {
    e.preventDefault();
  }

  onFilesDrop(e): void {
    e.preventDefault();
    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    let files = e.dataTransfer.files;
    profilePictureInput.files = files;
    if (!this.validateFiles()) {
      this.hasPreview = false;

      profilePictureInput.value = "";

    }
  }
  onFilesChanged(e): void {
    e.preventDefault();
    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    if (!this.validateFiles()) {
      this.hasPreview = false;
      profilePictureInput.value = "";
    }
  }

  validateFiles(): boolean {

    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    let files = profilePictureInput.files;

    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Only 1 file can be uploaded");
      return false;
    }

    let extensions = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
    let sizes = { minSize: 10000, maxSize: 1e+7 };

    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name))) {
        this.notificationsService.toggleFailed(true, "Only png, jpg/jpeg, bmp, gif files are accepted");
        return false;
      }

      if (files[i].size < sizes.minSize || files[i].size > sizes.maxSize) {
        this.notificationsService.toggleFailed(true, "Minimum file size is 10KB and maximum file size is 10000KB");
        return false;
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (_event) => {
        this.imageUri = reader.result;
        this.onProfileEdit();
      }
    }

    this.hasPreview = true;

    return true;

  }

  cancelProfilePicture() : void{
    this.hasPreview = false;
    this.imageUri = null;
    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    profilePictureInput.value = "";
  }

  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

  onProfileEdit(): void {
    if(!this.isCreate && !this.myProfileService.fetchedUserInfo.canEdit &&  this.myProfileService.fetchedUserInfo.is_visible == '1'){
      this.notificationsService.toggleFailed(true,"You don't have permissions to edit this account");
      return;
    }
    const formData = new FormData(<HTMLFormElement>document.getElementById('myProfileForm'));
    if(this.myProfileForm.invalid){
      this.fetchedProfile = true;
      this.notificationsService.toggleFailed(true,"Invalid data, make sure to follow guidelines before filling data");
      return;
    }
    this.disabled = true;

    if(this.isCreate){
      this.notificationsService.toggleLoading(true, "Adding user, please wait...");
      this.newProfile(formData);
      return;

    }
    else{
      this.notificationsService.toggleLoading(true, "Updating user profile...");
      this.updateProfile(formData);
      return;

    }



  }

  newProfile(formData) : void{
    this.myProfileService.addProfile(formData).subscribe((response : any)=>{
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message );

      // this.dataHolderService.userInfo = response["user"];


      setTimeout(()=>{
        this.router.navigate(['/user-accounts/edit',response.data['url']]);
      },500);
      // this.updateForm(response);
      // this.categoryName = "";
    },
    error =>{
      this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }

  updateProfile(formData) : void{

    this.myProfileService.updateUserProfile(formData).subscribe((response : any)=>{
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }

      this.myProfileService.fetchedUserInfo = response.data;

      this.updateForm(response);
      // this.categoryName = "";
    },
    error =>{
      this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }

  deletePicture():void{
    if(!this.isCreate && !this.myProfileService.fetchedUserInfo.canEdit &&  this.myProfileService.fetchedUserInfo.is_visible == '1'){
      this.notificationsService.toggleFailed(true,"This is a partner account, you can not edit this account");
      return;
    }
    this.notificationsService.toggleLoading(true, "Deleting user profile picture");

    this.myProfileService.deleteUserProfilePicture(this.myProfileService.fetchedUserInfo.id).subscribe((response : any)=>{
      this.notificationsService.toggleLoading(false);

      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }


      this.myProfileService.fetchedUserInfo = response.data;
      this.updateForm(response);
      // this.categoryName = "";
    },
    error =>{
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }

  deleteAccount():void{
    if(!this.isCreate && !this.myProfileService.fetchedUserInfo.canEdit){
      this.notificationsService.toggleFailed(true,"You don't have permissions, contact admin");
      return;
    }

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before removing an account");
      return;
    }

    this.notificationsService.toggleLoading(true, "Deleting Account");

    this.myProfileService.deleteAccount(this.myProfileService.fetchedUserInfo.id).subscribe((response : any)=>{
      this.notificationsService.toggleLoading(false);

      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }


      this.router.navigate(['/user-accounts' ]);

      // this.categoryName = "";
    },
    error =>{
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }

}
