import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDateDiffPipe'
})
export class MomentDateDiffPipePipe implements PipeTransform {

  transform(value: Date | string | moment.Moment): any {
    // console.log(value);
    return moment(value,'YYYY-MM-DD HH:mm:ss').fromNow();
  }

}
