<main>
  <section class="responsiveContainer">
    <form id="bookingForm" [formGroup]="bookingForm" (ngSubmit)="onBooking()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <span (click)="toggleFormLayout()" data-form-id="a1" class="toggleFormLayout"> <i class="fa fa-arrows"
            aria-hidden="true"></i> CHANGE VIEW</span>
        <div class="display-edit">
          <a routerLink="/dashboard"> DASHBOARD </a>
        </div>
        <h2 class="gradientColor" *ngIf="!isCreate">
          <span>{{  bookingForm.value.bookingId  }}</span>
        </h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-select">
              <label for="projectId">Project</label>
              <select placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
                <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                  [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
                </option>
              </select>
            </div>




          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Booking Price</label>
              <input formControlName="price" type="number" step="any" name="price" placeholder="Total ₹">
              <ng-container class="errors" *ngIf="bookingForm.value.price > 0">
                <ul>
                  <li> <strong> {{ bookingForm.value.price | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  bookingForm.value.price | numberToWords | titlecase}} </strong> </li>

                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['price'].errors?.required"> *required</span>
                <span *ngIf="fetchedBooking && bookingForm.controls['price'].errors?.numeric"> *Enter valid numeric
                  price</span>
                <span *ngIf="fetchedBooking && bookingForm.controls['price'].errors?.invalidMsg"> *Enter valid price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Plots</label>
              <ul>
                <li (click)="verifyPlotsAvailabilty()"> <a class="textualLink">Check availability </a> check if below
                  plots are available for booking </li>
              </ul>
              <textarea formControlName="plots" type="text" name="plots" placeholder="Eg : 77"></textarea>
              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['plots'].errors?.required"> *required</span>
                <span *ngIf="fetchedBooking && bookingForm.controls['plots'].errors?.minlength"> *Minimum 1 plot </span>
              </p>

              <ul>

                <li>If you want to add multiple plots, for eg : Plot 6, plot 30 and plot 100</li>
                <li> Enter plots value as 6,30,100</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="form-middle">
          <div *ngIf="!isCreate" class="form-field">
            <div class="input-container with-label">
              <label>Manage Booking</label>
              <div class="inline-links">
                <a fragment="addTransaction" [routerLink]="['./']">Add Transaction</a>
                <a fragment="transactions" [routerLink]="['./']"> View Transactions </a>
                <a fragment="addRemainder" [routerLink]="['./']">Add Remainder</a>
                <a fragment="remainders" [routerLink]="['./']"> View Remainders </a>
                <a fragment="addAttachment" [routerLink]="['./']">Upload Attachment</a>
                <a fragment="attachments" [routerLink]="['./']"> View Attachments </a>
                <a
                  href="{{ dataHolderService.apiBaseUrl + '/generate-booking-invoice?bookingId=' + bookingForm.value.bookingId + '&projectId=' + bookingForm.value.projectId  }}">
                  Download Invoice </a>
                <a fragment="invoices" [routerLink]="['./']"> View Invoices </a>

              </div>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>User id (Buyer)</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts/create" class="textualLink">Add user</a>
                </li>
                <li (click)="verifyUserId()">
                  <a  class="textualLink"> Verify user </a>
                </li>
                <li>
                  <a  target="_blanc" routerLink="/user-accounts" class="textualLink">Search users </a>
                </li>
              </ul>

              <div class="autocomplete">
                <input (input)="onFilterAccounts($event,false);" (keydown)="moveBetweenFilteredUsers($event,false);" formControlName="userId" type="text" name="userId"
                  placeholder="Buyer Account id"
                  placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              </div>

              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['userId'].errors?.required"> *required</span>
              </p>
              <div class="auto-complete-list" *ngIf="filteredUsers">
                <section class="search-list">
                  <ul>
                    <li *ngFor="let userAccountId of Object.keys(filteredUsers)"
                      (click)="selectedUserNameFromFilteredList($event , false)"
                      attr.data-account-id="{{ userAccountId }}">
                      <div class="list-item">
                        <section>
                          <a class="main-link">
                            <span>
                              <img
                                src="{{ (filteredUsers[userAccountId].profile_picture_url.length > 5) ? dataHolderService.apiBaseUrl + '/profile-picture?accountId=' + userAccountId  : 'assets/images/dummy.png'  }}">
                            </span>
                          </a>
                        </section>
                        <section>
                          <div>
                            <p>
                              <span>{{ filteredUsers[userAccountId].first_name + " " + ((filteredUsers[userAccountId]?.middle_name) ? filteredUsers[userAccountId].middle_name + " " : "" ) + filteredUsers[userAccountId].last_name  }}</span>
                            </p>
                            <p><span>{{ userAccountId }}</span></p>
                          </div>
                        </section>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
              <div *ngIf="verifiedUserIdData">
                <div class="userProfile">
                  <section>
                    <a class="main-link" [routerLink]="[ '/user-accounts/edit' , verifiedUserIdData.id ]">
                      <span>
                        <img
                          src='{{ verifiedUserIdData?.profile_picture_url ? dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + verifiedUserIdData.id : "assets/images/dummy.png " }}'>
                      </span>
                    </a>
                  </section>
                  <section>
                    <div>
                      <p>
                        <a class="normalLink" [routerLink]="[ '/user-accounts/edit' , verifiedUserIdData.id ]">
                          <span>
                            {{ verifiedUserIdData.first_name + " " + (verifiedUserIdData.middle_name!=null ? verifiedUserIdData.middle_name : "")  + " " + verifiedUserIdData.last_name }}
                          </span>
                        </a>
                      </p>
                      <!-- <p>
                        <a class="normalLink" [routerLink]="[ '/user-accounts/edit' , verifiedUserIdData.id ]" >
                          {{   dataHolderService.projectMetaDeta.USER_ID_PREFIX + verifiedUserIdData.id }}
                        </a>
                      </p> -->
                      <p>
                        <a class="normalLink" href="tel:{{ verifiedUserIdData.mobile_number }}">
                          {{ verifiedUserIdData.mobile_number }}
                        </a>
                      </p>
                      <p>
                        <a class="normalLink" href="mailto:{{ verifiedUserIdData.email }}">
                          {{ verifiedUserIdData.email }}
                        </a>
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label for="brokeredDeal">Is Brokered Deal ?</label>
              <select (change)="bookingForm.value.brokerId = ''" placeholder="Agent.." formControlName="brokeredDeal"
                name="brokeredDeal" id="brokeredDeal">
                <option [value]="0">No</option>
                <option [value]="1">Yes</option>
              </select>
            </div>
          </div>
          <div [ngStyle]="{ 'display' : bookingForm.value.brokeredDeal == '1' ? 'block' : 'none' }" class="form-field">
            <div class="input-container with-label">
              <label>Agent id</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts/create" target="_blank" [queryParams]="{ broker : true }" class="textualLink">Add
                    agent</a>
                </li>
                <li (click)="verifyBrokerId()">
                  <a class="textualLink">Verify agent </a>
                </li>
                <li>
                  <a target="_blanc" routerLink="/user-accounts" target="_blank" [queryParams]="{ isBroker : '1' , isFormVisible : '1' }"
                    class="textualLink">Search agents </a>
                </li>
              </ul>

              <div class="autocomplete">
                <input (keydown)="moveBetweenFilteredUsers($event,true);"  (input)="onFilterAccounts($event,true);" formControlName="brokerId" type="text" name="brokerId"
                  placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              </div>


              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['brokerId'].errors?.required"> *Invalid id </span>
              </p>

              <div class="auto-complete-list" *ngIf="filteredBrokers">
                <section class="search-list">
                  <ul>
                    <li *ngFor="let userAccountId of Object.keys(filteredBrokers)"
                      (click)="selectedUserNameFromFilteredList($event , true)"
                      attr.data-account-id="{{ userAccountId }}">
                      <div class="list-item">
                        <section>
                          <a class="main-link">
                            <span>
                              <img
                                src="{{ (filteredBrokers[userAccountId].profile_picture_url.length > 5) ? dataHolderService.apiBaseUrl + '/profile-picture?accountId=' + userAccountId  : 'assets/images/dummy.png'  }}">
                            </span>
                          </a>
                        </section>
                        <section>
                          <div>
                            <p>
                              <span>{{ filteredBrokers[userAccountId].first_name + " " + ((filteredBrokers[userAccountId]?.middle_name) ? filteredBrokers[userAccountId].middle_name + " " : "" ) + filteredBrokers[userAccountId].last_name  }}</span>
                            </p>
                            <p><span>{{ userAccountId }}</span></p>
                          </div>
                        </section>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>

              <div *ngIf="verifiedBrokerIdData">
                <div class="userProfile">
                  <section>
                    <a class="main-link" [routerLink]="[ '/user-accounts/edit' , verifiedBrokerIdData.id ]">
                      <span>
                        <img
                          src='{{ verifiedBrokerIdData?.profile_picture_url ? dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + verifiedBrokerIdData.id : "assets/images/dummy.png " }}'>
                      </span>
                    </a>
                  </section>
                  <section>
                    <div>
                      <p>
                        <a class="normalLink" [routerLink]="[ '/user-accounts/edit' , verifiedBrokerIdData.id ]">
                          <span>
                            {{ verifiedBrokerIdData.first_name + " " + ( verifiedBrokerIdData.middle_name!=null ? verifiedBrokerIdData.middle_name : "" ) + " " + verifiedBrokerIdData.last_name }}
                          </span>
                        </a>
                      </p>
                      <!-- <p>
                        <a class="normalLink" [routerLink]="[ '/user-accounts/edit' , verifiedBrokerIdData.id ]" >
                          {{   dataHolderService.projectMetaDeta.USER_ID_PREFIX + verifiedBrokerIdData.id }}
                        </a>
                      </p> -->
                      <p>
                        <a class="normalLink" href="tel:{{ verifiedBrokerIdData.mobile_number }}">
                          {{ verifiedBrokerIdData.mobile_number }}
                        </a>
                      </p>
                      <p>
                        <a class="normalLink" href="mailto:{{ verifiedBrokerIdData.email }}">
                          {{ verifiedBrokerIdData.email }}
                        </a>
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div [ngStyle]="{ 'display' : bookingForm.value.brokeredDeal == '1' ? 'block' : 'none' }" class="form-field">
            <div class="input-container with-label">
              <label> Brokerage Amount</label>
              <input formControlName="brokerage" type="number" step="any" name="brokerage" placeholder="Total ₹">
              <ng-container class="errors" *ngIf="bookingForm.value.brokerage > 0">
                <ul>
                  <li> <strong> {{ bookingForm.value.brokerage | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  bookingForm.value.brokerage | numberToWords |titlecase }} </strong> </li>

                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['brokerage'].errors?.required"> *required</span>
                <span *ngIf="fetchedBooking && bookingForm.controls['brokerage'].errors?.numeric"> *Enter valid numeric
                  brokerage</span>
                <span *ngIf="fetchedBooking && bookingForm.controls['brokerage'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>

        </div>
        <div class="form-right">
          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <label>BOOKING ID</label>
                <input readonly name="bookingId" formControlName="bookingId" type="text" readonly [value]="bookingId">
              </div>
            </div>
            <div class="form-field">
              <div class="input-container with-label">
                <label>BOOKED BY</label>
                <div class="userProfile">
                  <section>
                    <a class="main-link" [routerLink]="[ '/user-accounts/edit' , bookedBy.id ]">
                      <span>
                        <img
                          src='{{ bookedBy?.profile_picture_url ? dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + bookedBy.id : "assets/images/dummy.png " }}'>
                      </span>
                    </a>
                  </section>
                  <section>
                    <div>
                      <p>
                        <a class="normalLink" [routerLink]="[ '/user-accounts/edit' , bookedBy.id ]">
                          <span>
                            {{ bookedBy.first_name + " " + ( bookedBy.middle_name!=null ? bookedBy.middle_name : "" ) + " " + bookedBy.last_name }}
                          </span>
                        </a>
                      </p>

                      <p>
                        <a class="normalLink" href="tel:{{ bookedBy.mobile_number }}">
                          {{ bookedBy.mobile_number }}
                        </a>
                      </p>
                      <p>
                        <a class="normalLink" href="mailto:{{ bookedBy.email }}">
                          {{ bookedBy.email }}
                        </a>
                      </p>
                    </div>
                  </section>
                </div>
                <ul>
                  <li>Booking last updated :
                  <li>
                  <li>
                    {{ bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.updated_on | momentDateDiffPipe }}
                    (
                    {{ bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.updated_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }}
                    )
                  </li>
                </ul>
              </div>
            </div>

          </ng-container>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Booking Date (optional)</label>
              <input formControlName="bookingDate" type="date" name="bookingDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['bookingDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes"
                placeholder="Anything you want to add to the booking?"></textarea>

              <p class="errors">
                <span *ngIf="fetchedBooking && bookingForm.controls['additionalNotes'].errors?.minlength"> *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedBooking && bookingForm.controls['additionalNotes'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE </button>
        <button *ngIf="!isCreate" (click)="deleteBooking();" type="button">DELETE</button>

        <div *ngIf="!isCreate">
          <a class="submit"
            href="{{ dataHolderService.apiBaseUrl + '/generate-booking-invoice?bookingId=' + bookingForm.value.bookingId + '&projectId=' + bookingForm.value.projectId  }}">
            Download Invoice </a>

        </div>
      </div>
    </form>
  </section>
</main>

<ng-container *ngIf="!isCreate">

  <!-- Transactions-->
  <section id="transactions" class="responsiveContainer">
    <form class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <!-- <div class="display-edit">
              <a fragment="addTransaction" [routerLink]="['./']">New Transaction</a>
            </div> -->
        <h2 class="gradientColor"><span>TRANSACTIONS</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>BOOKING STATUS</label>
              <input readonly type="text" readonly
                [value]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.price == bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.total_payment_done ? 'FULL PAYMENT DONE' : 'PENDING PAYMENT'">
              <ul>
                <li>
                  <strong>{{ ((bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.total_payment_done / bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.price) * 100).toFixed(2) }}%
                    payment done </strong>
                </li>
              </ul>
              <ul class="inline">
                <li>
                  <a class="textualLink" fragment="addTransaction" [routerLink]="['./']">Add Transaction</a>
                </li>
                <li>
                  <a class="textualLink" fragment="transactions" [routerLink]="['./']"> View Transactions </a>
                </li>
              </ul>
              <div class="inline-links">
                <a *ngFor="let trn of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].transactions)"
                  fragment="{{ trn }}" [routerLink]="['./']">{{ trn }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-middle">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Payment Done</label>
              <input readonly type="text"
                [value]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.total_payment_done | properCurrencyFormat">
              <ul>
                <li> <strong>
                    {{  bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.total_payment_done | numberToWords }}
                  </strong> </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-right">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Pending Payment</label>
              <input readonly type="text"
                [value]="(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.price - bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.total_payment_done) | properCurrencyFormat">
              <ul>
                <li> <strong>
                    {{  (bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.price - bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData.total_payment_done) | numberToWords | titlecase }}
                  </strong> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">

      </div>
    </form>
  </section>


  <app-add-or-delete-transaction [isCreate]="true" [bookingId]="bookingForm.value.bookingId"
    [bookingData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData"
    (latestFetchedBookingInfo)="updateBookingPage($event)">
  </app-add-or-delete-transaction>

  <section *ngIf="totalTransactions > 0">
    <app-add-or-delete-transaction
      *ngFor="let trn of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].transactions)"
      [isCreate]="false" [bookingId]="bookingForm.value.bookingId"
      [bookingData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData"
      [transactionId]="trn"
      [transactionData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].transactions[trn]"
      (latestFetchedBookingInfo)="updateBookingPage($event)">
    </app-add-or-delete-transaction>
  </section>


  <!-- Remainders-->

  <section id="remainders" class="responsiveContainer">
    <form class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>REMAINDERS</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <ul class="inline">
                <li>
                  <a class="textualLink" fragment="addRemainder" [routerLink]="['./']">Add Remainder</a>
                </li>
                <li>
                  <a class="textualLink" fragment="remainders" [routerLink]="['./']"> View Remainders </a>
                </li>
              </ul>
              <div class="inline-links">
                <a *ngFor="let rmd of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].remainders)"
                  fragment="{{ rmd }}" [routerLink]="['./']">{{ rmd }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-middle">

        </div>
        <div class="form-right">

        </div>
      </div>
      <div class="form-button">

      </div>
    </form>
  </section>

  <app-add-or-delete-remainder [isCreate]="true" [bookingId]="bookingForm.value.bookingId"
    [bookingData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData"
    (latestFetchedBookingInfo)="updateBookingPage($event)">
  </app-add-or-delete-remainder>

  <section *ngIf="totalRemainders > 0">
    <app-add-or-delete-remainder
      *ngFor="let rmd of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].remainders)"
      [isCreate]="false" [bookingId]="bookingForm.value.bookingId"
      [bookingData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData"
      [remainderId]="rmd"
      [remainderData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].remainders[rmd]"
      (latestFetchedBookingInfo)="updateBookingPage($event)">
    </app-add-or-delete-remainder>
  </section>

  <!-- Attachments -->

  <section id="attachments" class="responsiveContainer">
    <form class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>ATTACHMENTS</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <ul class="inline">
                <li>
                  <a class="textualLink" fragment="addAttachment" [routerLink]="['./']">Upload Attachment</a>
                </li>
                <li>
                  <a class="textualLink" fragment="attachments" [routerLink]="['./']"> View Attachments </a>
                </li>
              </ul>
              <div class="inline-links">
                <a *ngFor="let aht of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].attachments)"
                  fragment="{{ aht }}" [routerLink]="['./']">{{ aht }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-middle">

        </div>
        <div class="form-right">

        </div>
      </div>
      <div class="form-button">

      </div>
    </form>
  </section>


  <app-add-or-delete-attachment [isCreate]="true" [bookingId]="bookingForm.value.bookingId"
    [bookingData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData"
    (latestFetchedBookingInfo)="updateBookingPage($event)">
  </app-add-or-delete-attachment>


  <section *ngIf="totalAttachments > 0">
    <app-add-or-delete-attachment
      *ngFor="let aht of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].attachments)"
      [isCreate]="false" [bookingId]="bookingForm.value.bookingId"
      [bookingData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].bookingData"
      [attachmentId]="aht"
      [attachmentData]="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].attachments[aht]"
      (latestFetchedBookingInfo)="updateBookingPage($event)">
    </app-add-or-delete-attachment>
  </section>

  <!-- Invoices -->

  <section id="invoices" class="responsiveContainer">
    <form class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>INVOICES</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <div class="inline-links"
                *ngIf="bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].invoices">
                <a *ngFor="let inv of Object.keys(bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].invoices)"
                  href="{{ dataHolderService.apiBaseUrl + '/download-booking-invoice?bookingId=' + bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].invoices[inv].booking_id + '&invoiceId=' + bookingsServiceService.fetchedBookingInfo[bookingForm.value.bookingId].invoices[inv].invoiceId  }}">{{ inv }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-middle">

        </div>
        <div class="form-right">

        </div>
      </div>
      <div class="form-button">
        <p>All generated invoices will appear here</p>
        <p> Reload page to load latest invoices</p>
      </div>
    </form>
  </section>

</ng-container>
