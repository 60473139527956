<section class="custom-page-form responsiveContainer">
  <form [formGroup]="customPageForm" (ngSubmit)="onSubmit($event)" #form="ngForm" class="application-forms">
    <div class="form-wrapper">
      <div class="form-description">
        <input type="hidden" formControlName="attachmentId" name="attachmentId">
        <h1>{{ isCreate ? 'Add Custom Page' : 'Edit Custom Page' }}</h1>
      </div>
      <div class="form-input">
        <label for="type_of_content">Type of Content </label>
        <select (change)="updateView($event);" name="type_of_content" formControlName="type_of_content">
          <option value="1">Blog item</option>
          <option value="2">Internal Page / Seperate new page </option>
          <option value="3">Project content </option>
        </select>
      </div>

      <div>
        <div class="form-input">
          <label for="title">Title of content / post you are adding </label>
          <input formControlName="title" type="title" name="title"
            placeholder="Title of content / post you are adding...." />
          <p>
            <span *ngIf="fetchedAttachment && customPageForm.controls['title'].errors?.required"> *required</span>
            <span *ngIf="fetchedAttachment && customPageForm.controls['title'].errors?.maxlength"> *Maximum 150
              characters
            </span>
          </p>
        </div>
        <div class="form-input">
          <label for="description">Description </label>
          <textarea formControlName="description" type="description" name="description"
            placeholder="description of content / post you are adding...."></textarea>
          <p>
            <span *ngIf="fetchedAttachment && customPageForm.controls['description'].errors?.required"> *required</span>
            <span *ngIf="fetchedAttachment && customPageForm.controls['description'].errors?.maxlength"> *Maximum 150
              characters
            </span>
          </p>
        </div>
      </div>

      <div [ngStyle]="{ 'display' : customPageForm.value.type_of_content == '2' ? 'block' : 'none' }">
        <div class="form-input">
          <label for="is_visible_on_page_tabs"> Is this a seperate page on your website? </label>
          <select name="is_visible_on_page_tabs" formControlName="is_visible_on_page_tabs">
            <option value="1">Yes </option>
            <option value="0"> No (Some internal page) </option>
          </select>
        </div>

        <div class="form-input">
          <label for="url_key">Page - Url / Content - Key </label>
          <p>This input will be used to fetch the page content. For eg: Home , will be used to fetch content on home page</p>
          <input formControlName="url_key" type="url_key" name="url_key" placeholder="For eg:our-clients" />
          <p>
            <span *ngIf="fetchedAttachment && customPageForm.controls['url_key'].errors?.maxlength"> *Maximum 150
              characters
            </span>
          </p>
        </div>

      </div>


      <div [ngStyle]="{ 'display' : customPageForm.value.type_of_content == '3' ? 'block' : 'none' }" class="form-input">
        <label for="project_id">Project</label>
        <select placeholder="Select Project.." formControlName="project_id" name="project_id" id="project_id">
          <option value="-1">Select project</option>
          <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
            [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
          </option>
        </select>
      </div>

      <div class="form-input">
        <label for="rich_text_content">Rich Text Content : </label>
        <div style="margin : 2em auto 2em auto; width: 90%;">
          <quill-editor [sanitize]="true" #editor formControlName="rich_text_content" name="rich_text_content">
          </quill-editor>
        </div>
      </div>
      <div class="form-input">
        <label for="custom_html">Custom HTML Content </label>
        <textarea formControlName="custom_html" type="custom_html" name="custom_html"
          placeholder="Any html content you want to add...."></textarea>

      </div>

      <div class="form-input">
        <label for="is_active"> Content Status </label>
        <select name="is_active" formControlName="is_active">
          <option value="1">Live (Visible to public)</option>
          <option value="2">Draft (Not visible to public)</option>
        </select>
      </div>

      <div class="form-input">
        <label for="content_order"> Content Visibility </label>
        <select name="content_order" formControlName="content_order">
          <option value="1"> Only Rich Text Content </option>
          <option value="2"> Only Custom Html </option>
          <option value="3"> First Rich Text then Custom Html </option>
          <option value="4"> First Custom Html then Rich Text </option>

        </select>
      </div>

      <div [ngStyle]="{ 'display' : customPageForm.value.type_of_content == '1' ? 'block' : 'none' }"
        class="form-input">
        <label for="sliderImage">Upload Image</label>
        <div class="form-layout" style="border : none !important;">
          <div>
            <div class="form-field">

              <div class="input-container with-file-upload">
                <div>
                  <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                    (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label" for="sliderImage">
                    <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                      here</span>
                    <input accept="image/*" type="file" (change)="onFilesChanged($event)" formControlName="image"
                      name="image[]" type="file" id="sliderImage">
                  </label>
                </div>

                <p class="errors">
                  <span *ngIf="fetchedAttachment && customPageForm.controls['image'].errors?.extension"> *Only
                    jpeg,bmp,png,jpg,gif,svg,gif files are accepted</span>
                  <span *ngIf="fetchedAttachment && customPageForm.controls['image'].errors?.file"> *Only 1 file can
                    be
                    uploaded</span>
                </p>

                <div *ngIf="!isCreate && attachmentData.image_url" class="existingProfile twoColumnsGrid">
                  <div>
                    <a download target="_blank" href="{{ attachmentData.image_url + '?id=' + attachmentData.id   }}">
                      <img src="{{  attachmentData.image_url + '?id=' + attachmentData.id   }}">
                    </a>
                    <button type="button" (click)="removeCustomPageImage()"> <i class="fa fa-times" aria-hidden="true"></i> REMOVE
                      IMAGE</button>
                  </div>
                  <div class="flexCenter">

                  </div>

                </div>

                <div *ngIf="localFileName">
                  <ul class="inline">
                    <li> <a class="textualLink">{{ localFileName }} </a> </li>
                  </ul>
                  <button type="button" class="submit" (click)="cancelfile($event)"> <i class="fa fa-times"
                      aria-hidden="true"></i> REMOVE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div  [ngStyle]="{ 'display' : customPageForm.value.type_of_content != '1' ? 'block' : 'none' }" class="form-input">
        <label for="sort_order">Sort Order  </label>
        <input formControlName="sort_order" type="number" name="sort_order"
          placeholder="Order of this content when there are multiple items" />
        <p>
          <span *ngIf="fetchedAttachment && customPageForm.controls['sort_order'].errors?.required"> *required</span>

        </p>
      </div>

      <div class="form-input">
        <button type="submit" class="noselect">SAVE</button>
      </div>

      <div *ngIf="!isCreate" class="form-input">
        <button  (click)="deleteCustomPageImage();" type="button" class="noselect submit-btn">DELETE</button>
      </div>


    </div>
  </form>
</section>

<!-- <form [formGroup]="form">
  <quill-editor [sanitize]="true" #editor formControlName="editor">
  </quill-editor>
</form>


<br>

<div [innerHTML]="data | sanitizeHtml">

</div> -->
