import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DataHolderService } from './data-holder.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  filteredProjects : any;

  fetchedProjectInfo;

  constructor(
    private httpClient: HttpClient,
    private dataHolderService: DataHolderService
  ) { }

  filterProjects(filterType, filter) : any{
    let body = new HttpParams();
    body = body.set('filter', filter.split("-").pop());
    body = body.set('filterType', filterType);
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/project-types', { params: body });
  }

  newProject(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/new-project' , body);

  }


  updateProject(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-project' , body);

  }


  searchProjects(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/search-projects' , body);

  }
}
