import { Component, OnInit , Input } from '@angular/core';
import { ProjectsService } from './../projects.service';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-city-projects',
  templateUrl: './city-projects.component.html',
  styleUrls: ['./city-projects.component.css']
})
export class CityProjectsComponent implements OnInit {

  filteredProjects;

  @Input() pageIsEmbedded: boolean = false;
  @Input() cityId = null;

  constructor(
    public projectsService: ProjectsService,
    public dataHolderService: DataHolderService,
    public notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (!this.pageIsEmbedded) {
        this.dataHolderService.updatePageMeta(`${this.projectsService.filteredProjects.heading} | Cities / Towns / Villages - Areas where we have our properties | ${this.dataHolderService.projectMetaDeta.default_title}`);
        this.filteredProjects = this.projectsService.filteredProjects;
      }
    });

    if(this.cityId){
      this.filter();
    }
  }

  filter(): void {
    this.projectsService.filterProjects('projectCity', this.cityId).subscribe((response: any) => {
      if (response.status) {
        this.filteredProjects = response.data;
      }

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

}
