import { DataHolderService } from './../data-holder.service';
import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent implements OnInit {

  constructor(public dataHolder : DataHolderService) { }
  @Input() pageIsEmbedded = false;

  ngOnInit(): void {

    if(!this.pageIsEmbedded){
      this.dataHolder.updatePageMeta('Projects - ' + this.dataHolder.projectMetaDeta.default_title);
    }
  }

}
