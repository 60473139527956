<div class="fullWidthSection trns" style="margin-top: 0em;">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin: 0; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">TRANSACTIONS
  </h3>

  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="radio-toggler noselect">
      <div class="radio-container">
        <input (change)="setViewVisibility('listView')" name="viewType" [checked]="viewType == 'listView'" type="radio"
          value="listView" id="listView">
        <label for="listView">
          <i class="fa fa-table" aria-hidden="true"></i> LIST
        </label>
        <input (change)="setViewVisibility('reportView')" name="viewType" [checked]="viewType == 'reportView'"
          type="radio" value="reportView" id="reportView">
        <label for="reportView">
          <i class="fa fa-th-large" aria-hidden="true"></i> SUMMARY
        </label>
      </div>
    </div>

    <pre></pre>

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS & REPORTS</span>
    </div>

  </div>

</section>

<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchTransactions" (ngSubmit)="onFilterTransactions()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-select">
              <label for="projectId">Project</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterTransactions();"
                placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
                <option selected value="">Select project</option>
                <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                  [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Booking ID</label>
              <input name="bookingId" formControlName="bookingId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['bookingId'].errors?.minlength"> *Minimum 1
                  character
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Transaction ID</label>
              <input name="transactionId" formControlName="transactionId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.TRANSACTION_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['transactionId'].errors?.minlength"> *Minimum 1
                  character
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Partner ID</label>
              <ul class="inline">
                <li>ID of the partner who registered the booking</li>
              </ul>
              <input formControlName="addedBy" type="text" name="addedBy"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['addedBy'].errors?.minLength"> *Minimum 1
                  character
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Customer ID</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts" class="textualLink">Search users </a>
                </li>
                <li>
                  ID of the buyer
                </li>
              </ul>
              <input formControlName="userId" type="text" name="userId" placeholder="Buyer's  id" />
              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['userId'].errors?.minlength"> *Minimum 1
                  character</span>
              </p>
            </div>
          </div>
        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Transaction Date </label>
              <input formControlName="minimumDate" type="date" name="minimumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['minimumDate'].errors?.invalidMsg"> *Select a
                  valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Transaction Date </label>
              <input formControlName="maximumDate" type="date" name="maximumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['maximumDate'].errors?.invalidMsg"> *Select a
                  valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Transaction Amount</label>
              <input formControlName="minimumPrice" type="number" step="any" name="minimumPrice"
                placeholder="Minimum Total ₹">
              <ng-container *ngIf="searchTransactions.value.minimumPrice > 0">
                <ul>
                  <li> <strong> {{ searchTransactions.value.minimumPrice | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  searchTransactions.value.minimumPrice | numberToWords }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['minimumPrice'].errors?.numeric"> *Enter valid
                  numeric
                  price</span>
                <span *ngIf="submitted && searchTransactions.controls['minimumPrice'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Transaction Amount</label>
              <input formControlName="maximumPrice" type="number" step="any" name="maximumPrice"
                placeholder="Maximum Total ₹">
              <ng-container *ngIf="searchTransactions.value.maximumPrice > 0">
                <ul>
                  <li> <strong> {{ searchTransactions.value.maximumPrice | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  searchTransactions.value.maximumPrice | numberToWords }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['maximumPrice'].errors?.numeric"> *Enter valid
                  numeric
                  price</span>
                <span *ngIf="submitted && searchTransactions.controls['maximumPrice'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes"
                placeholder="Any notes you had added previously"></textarea>

              <p class="errors">
                <span *ngIf="submitted && searchTransactions.controls['additionalNotes'].errors?.minlength"> *Minimum 1
                  character
                </span>
                <span *ngIf="submitted && searchTransactions.controls['additionalNotes'].errors?.maxlength"> *Maximum
                  512
                  characters
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort by</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterTransactions();"
                placeholder="Select option.." formControlName="sortBy" name="sortBy">
                <option value="added_on">Transaction Date</option>
                <option value="credit">Transaction Amount</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort order</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterTransactions();"
                placeholder="Select option.." formControlName="sortOrder" name="sortOrder">
                <option value="ASC">Low to high</option>
                <option value="DESC">High to low</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterTransactions();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>
      <div>
        <a class="submit" target="_blanc" [routerLink]="dataHolderService.apiBaseUrl + '/transactions-report'" [routerLink]=""
        [queryParams]="{
          projectId :  searchTransactions.value.projectId,
          bookingId :  searchTransactions.value.bookingId,
          transactionId :  searchTransactions.value.transactionId,
          userId : searchTransactions.value.userId,
          addedBy :  searchTransactions.value.addedBy,
          additionalNotes :  searchTransactions.value.additionalNotes,
          minimumDate :  searchTransactions.value.minimumDate,
          maximumDate :  searchTransactions.value.maximumDate,
          minimumPrice :  searchTransactions.value.minimumPrice,
          maximumPrice :  searchTransactions.value.maximumPrice,
          sortBy :  searchTransactions.value.sortBy,
          sortOrder :  searchTransactions.value.sortOrder,
          viewType : viewType
        }">Download Report</a>
      </div>
    </div>

    </form>
  </section>
</main>

<section class="responsiveContainer single-booking-cards" *ngIf="totalResults > 0 && viewType == 'reportView'">
  <div class="horizontal-card" *ngFor="let trn of Object.keys(transactions)">
    <div class="twoColumnsGrid">
      <div>
        <h1><a [routerLink]="['/bookings', transactions[trn].booking_id ]" class="grad1"> {{ trn }}</a></h1>
        <p>Transaction Date : </p>
        <p>
          <span> {{ transactions[trn].added_on | momentDateTransformPipe : ('MMM Do, YYYY ')  }} (
            {{ transactions[trn].added_on | momentDateDiffPipe  }} ) </span>
        </p>

        <p>Transaction Notes</p>
        <p>{{ transactions[trn].additional_notes ?  transactions[trn].additional_notes : '-' }}</p>

        <p>Added by -
          {{ transactions[trn].added_by.first_name + " " + (transactions[trn].added_by.middle_name!=null ? transactions[trn].added_by.middle_name : "")  + " " + transactions[trn].added_by.last_name }}
        </p>
        <p>
          <span [routerLink]="[ '/user-accounts/edit' , transactions[trn].added_by.id ]"> <i class="fa fa-users"
              aria-hidden="true"></i> {{ transactions[trn].added_by.id }}</span>
          <span> <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ transactions[trn].added_by.email }}</span>
          <span> <i class="fa fa-phone" aria-hidden="true"></i> {{ transactions[trn].added_by.mobile_number }}</span>
        </p>

      </div>
      <div>


        <p class="blurred-title"> Booking ID </p>
        <h1><a [routerLink]="['/bookings', transactions[trn].booking_id ]" class="grad1">
            {{ transactions[trn].booking_id }}</a></h1>

        <p> Transaction Amount </p>
        <p> <span>{{ transactions[trn].credit | properCurrencyFormat  }}</span> </p>
        <p> ({{ transactions[trn].credit | numberToWords | titlecase  }}) </p>

        <p>Buyer -
          {{ transactions[trn].buyer_name }}
        </p>
        <p>
          <span [routerLink]="[ '/user-accounts/edit' , transactions[trn].buyer_id ]"> <i class="fa fa-users"
              aria-hidden="true"></i> {{ transactions[trn].buyer_id }}</span>
          <span> <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ transactions[trn].buyer_email }}</span>
          <span> <i class="fa fa-phone" aria-hidden="true"></i> {{ transactions[trn].buyer_mobile }}</span>
        </p>


      </div>
    </div>
  </div>
</section>

<section class="fullWidthTable" *ngIf="totalResults > 0 && viewType == 'listView'">
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>Transaction ID</th>
          <th>Transaction Date</th>
          <th>Weekday</th>
          <th>Buyer ID</th>
          <th>Buyer</th>
          <th>Buyer - Email</th>
          <th>Buyer - Mobile</th>
          <th>Added By</th>
          <th>Booking ID</th>
          <th>Transaction Amount</th>
          <th>Transaction Notes</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let trn of Object.keys(transactions)">
          <td>
            <a [routerLink]="['/bookings', transactions[trn].booking_id ]">
              <span> <i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>
          <td>
            {{ trn }}
          </td>
          <td>{{ transactions[trn].added_on | momentDateTransformPipe : (' DD-MM-YYYY ')  }}</td>
          <td>{{ transactions[trn].added_on | date : ('EEEE') }} ({{ transactions[trn].added_on | momentDateDiffPipe  }})</td>

          <td>{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX + transactions[trn].buyer_id }}</td>
          <td>{{ transactions[trn].buyer_name }}</td>

          <td><p *ngIf="!transactions[trn].buyer_email"> - </p>
            <p>{{ transactions[trn].buyer_email }}</p></td>

          <td><p *ngIf="!transactions[trn].buyer_mobile"> - </p>
            <p>{{ transactions[trn].buyer_mobile }}</p></td>

          <td>{{ transactions[trn].added_by.first_name + " " + (transactions[trn].added_by.middle_name!=null ? transactions[trn].added_by.middle_name : "")  + " " + transactions[trn].added_by.last_name }}</td>

          <td>{{ transactions[trn].booking_id }}</td>
          <td>{{ transactions[trn].credit | properCurrencyFormat }}</td>
          <td>{{ transactions[trn].additional_notes ?  transactions[trn].additional_notes : '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>


<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found </p>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchTransactions.value.page }" [routerLink]="['./']"
        [queryParams]="{
        projectId :  searchTransactions.value.projectId,
        bookingId :  searchTransactions.value.bookingId,
        transactionId :  searchTransactions.value.transactionId,
        userId : searchTransactions.value.userId,
        additionalNotes :  searchTransactions.value.additionalNotes,
        addedBy :  searchTransactions.value.addedBy,
        minimumDate :  searchTransactions.value.minimumDate,
        maximumDate :  searchTransactions.value.maximumDate,
        minimumPrice :  searchTransactions.value.minimumPrice,
        maximumPrice :  searchTransactions.value.maximumPrice,
        sortBy :  searchTransactions.value.sortBy,
        sortOrder :  searchTransactions.value.sortOrder,
        pagination : searchTransactions.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink,
        viewType : viewType
      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
