<section class="fullScreenFlex loginFormContainer">
  <section class="responsive600">
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()" #form="ngForm" id="loginForm" class="application-forms">
      <div class="form-wrapper">
        <div class="form-description">
          <h1>LOGIN | {{ dataHolder.projectMetaDeta.default_title }}</h1>
          <p> Manage Plots, Bookings, Transactions, Users </p>
        </div>

        <div class="form-input no-box-shadow">
          <div class="icon-container">
            <div>
              <i style="color: blue;" class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <div>
              <input formControlName="userId" type="userId" name="userId"  placeholder="{{ dataHolder.projectMetaDeta.USER_ID_PREFIX }}XXXX">
            </div>
          </div>
          <p>
            <span *ngIf="submitted && loginForm.controls['userId'].errors?.required"> *Account ID required</span>
          </p>
        </div>

        <div class="form-input no-box-shadow">
          <div class="icon-container togglePasswordVisibility">
            <div (click)="passwordFieldHidden = !passwordFieldHidden">
              <i style="color: blue;"
                [ngClass]=" { 'fa-eye' : passwordFieldHidden , 'fa-eye-slash' : !passwordFieldHidden }" class="fa"
                aria-hidden="true"></i>
            </div>
            <div>
              <input formControlName="password" [type]="passwordFieldHidden ? 'password' : 'text'" name="password"
                placeholder="PASSWORD">
            </div>
          </div>
          <p>
            <span *ngIf="submitted && loginForm.controls['password'].errors?.required"> *required</span>
            <span *ngIf="submitted && loginForm.controls['password'].errors?.minlength"> *Minimum 8 characters </span>
            <span *ngIf="submitted && loginForm.controls['password'].errors?.maxlength"> *Maximum 128 characters </span>
          </p>
        </div>

        <div class="form-input">

          <button type="submit" class="noselect">LOGIN</button>
        </div>
        <div class="form-description">
          <p>Note : Contact Software Team to reset password </p>
        </div>
      </div>
    </form>


  </section>
</section>
