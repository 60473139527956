import { ProfilesService } from './../profiles.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  moment: any = moment;

  Object = Object;

  constructor(public dataHolderService : DataHolderService ,  public profilesService : ProfilesService ) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Dashboard - ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

  }

}
