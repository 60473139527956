import { Component, OnInit, Input } from '@angular/core';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(public dataHolderService: DataHolderService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`About us | ${this.dataHolderService.projectMetaDeta.default_title}`);

  }

}
