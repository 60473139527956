import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { TransactionsService } from '../transactions.service';


@Component({
  selector: 'app-search-transactions',
  templateUrl: './search-transactions.component.html',
  styleUrls: ['./search-transactions.component.css']
})
export class SearchTransactionsComponent implements OnInit {


  Object = Object;

  searchTransactions: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  transactions: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  @Input() pageIsEmbedded: boolean = false;

  isFormVisible : boolean = false;

  viewType : string = 'listView';

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private transactionsService : TransactionsService
  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Transactions | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    let formBuilderConfig = {
      projectId: [""],
      bookingId: ["", [ Validators.minLength(1) ]],
      transactionId: ["", [ Validators.minLength(1) ]],
      userId: ["", [ Validators.minLength(1) ]],
      addedBy: ["", [ Validators.minLength(1) ]],
      additionalNotes: ["", [Validators.minLength(1), Validators.maxLength(512)]],
      minimumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      maximumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      minimumPrice: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      maximumPrice: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      page: ["1", []],
      pagination: [ 10 , [Validators.required]],
      sortBy: [ "added_on", [Validators.required]],
      sortOrder: [ "DESC", [Validators.required]],
    };

    this.searchTransactions = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();
    this.onFilterTransactions();


  }

  checkForQueryParams() : void{
    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("projectId" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          projectId: queryParams.projectId
        });
      }

      if ("bookingId" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          bookingId: queryParams.bookingId
        });
      }

      if ("transactionId" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          transactionId: queryParams.transactionId
        });
      }

      if ("userId" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          userId: queryParams.userId
        });
      }

      if ("additionalNotes" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          additionalNotes: queryParams.additionalNotes
        });
      }


      if ("minimumDate" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          minimumDate: queryParams.minimumDate
        });
      }

      if ("maximumDate" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          maximumDate: queryParams.maximumDate
        });
      }

      if ("minimumPrice" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          minimumPrice: queryParams.minimumPrice
        });
      }

      if ("maximumPrice" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          maximumPrice: queryParams.maximumPrice
        });
      }

      if ("addedBy" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          addedBy: queryParams.addedBy
        });
      }

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          page: queryParams.page
        });
      }

      if ("pagination" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          pagination: queryParams.pagination
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          sortBy: queryParams.sortBy
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchTransactions.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }

      if ("viewType" in queryParams && ["listView","weeklyView","reportView"].includes(queryParams.viewType) ) {
        hasQueryParams = true;
        this.viewType = queryParams.viewType;
      }

      if(hasQueryParams){
        this.onFilterTransactions();
      }
    });

  }

  onFilterTransactions() : any{
    this.submitted = true;
    if (this.searchTransactions.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Filtering transactions...");
    this.transactionsService.getTransactions(this.searchTransactions.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.transactions = response.data;
      this.paginationLinks = response.pagination_data;
      setTimeout(() => {
        if( document.querySelector("section.fullWidthTable")){
          document.querySelector("section.fullWidthTable").scrollIntoView();
        }
      }, 1000 );


      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    })
  }

  setViewVisibility(type) : void{
    this.viewType = type;
  }

  updatePageNumber(e) : void{
    // console.log(e.target);
    this.searchTransactions.patchValue({
      page : e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }


}
