import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'properCurrencyFormat'
})
export class ProperCurrencyFormatPipe implements PipeTransform {

  transform(value: number ): any {
    return new Intl.NumberFormat('en-IN', { style: "currency", currency: "INR" }).format(value);
  }

}
