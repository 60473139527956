import { ContactUsService } from './../contact-us.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit , Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NotificationsService } from './../notifications.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contactUsForm: FormGroup;

  submitted: boolean = false;

  @Input() pageIsEmbedded : boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private contactUsService: ContactUsService
  ) { }


  ngOnInit(): void {

    if(!this.pageIsEmbedded){
      this.dataHolderService.updatePageMeta(`Contact us | ${this.dataHolderService.projectMetaDeta.default_title}`);
    }

    this.contactUsForm = this.formBuilder.group({
      fullName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      email: ["", [Validators.email]],
      mobile: ["", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      message: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(2000)]],

    });

    this.route.queryParams.subscribe(queryParams => {
      // console.log(queryParams);
      if ("plots" in queryParams && "project" in queryParams) {

        let projectId = 'id' + queryParams.project;
        if (Object.keys(this.dataHolderService.projectMetaDeta.PROJECT_PAGE_CONTENTS).includes(projectId) && queryParams.plots.trim().length > 0) {
          let msg = `Hello, I want to know more details of the following plot(s) - ` + queryParams.plots + " of this project -  " + this.dataHolderService.projectMetaDeta.PROJECT_PAGE_CONTENTS[projectId].name + ',' + this.dataHolderService.projectMetaDeta.PROJECT_PAGE_CONTENTS[projectId].main_town;
          this.contactUsForm.patchValue({
            message: msg
          });
        }
      }
    });

  }

  onContact(): void {
    this.submitted = true;

    if (this.contactUsForm.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Sending your query...");
    this.contactUsService.contactUs(this.contactUsForm.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.submitted = false;
      this.contactUsForm.reset();
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

}
