import { CareersComponent } from './careers/careers.component';
import { BlogArticlesComponent } from './blog-articles/blog-articles.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { SeperatePageComponent } from './seperate-page/seperate-page.component';
import { SearchPropertyTypesComponent } from './search-property-types/search-property-types.component';
import { CityProjectsComponent } from './city-projects/city-projects.component';
import { CitiesComponent } from './cities/cities.component';
import { EditCustomPageComponent } from './edit-custom-page/edit-custom-page.component';
import { AddCustomPageComponent } from './add-custom-page/add-custom-page.component';
import { AddEditCustomPagesComponent } from './add-edit-custom-pages/add-edit-custom-pages.component';
import { SearchHomePageSlidesComponent } from './search-home-page-slides/search-home-page-slides.component';
import { HomePageSlideShowComponent } from './home-page-slide-show/home-page-slide-show.component';
import { AddPlotComponent } from './add-plot/add-plot.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { EditPlotComponent } from './edit-plot/edit-plot.component';
import { SearchContactUsComponent } from './search-contact-us/search-contact-us.component';
import { PropertyTypeProjectsComponent } from './property-type-projects/property-type-projects.component';
import { PropertyTypesComponent } from './property-types/property-types.component';
import { LicenseComponent } from './license/license.component';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SearchTransactionsComponent } from './search-transactions/search-transactions.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SearchRemaindersComponent } from './search-remainders/search-remainders.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SearchBookingsComponent } from './search-bookings/search-bookings.component';
import { SearchPlotsComponent } from './search-plots/search-plots.component';
// import { NewTransactionComponent } from './new-transaction/new-transaction.component';
import { NewBookingComponent } from './new-booking/new-booking.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { AuthGuardService } from './auth-guard.service';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { LoginComponent } from './login/login.component';
// import { HighlightsComponent } from './highlights/highlights.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { IndexPageComponent } from './index-page/index-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginActivityComponent } from './login-activity/login-activity.component';
import { EditBookingComponent } from './edit-booking/edit-booking.component';
import { SearchUsersComponent } from './search-users/search-users.component';
import { ProjectPageComponent } from './project-page/project-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProjectStatusProjectsComponent } from './project-status-projects/project-status-projects.component';
import { MarkPlotFacingComponent } from './mark-plot-facing/mark-plot-facing.component';
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { SearchProjectsComponent } from './search-projects/search-projects.component';
import { SearchCustomPagesComponent } from './search-custom-pages/search-custom-pages.component';
import { SearchProjectCitiesComponent } from './search-project-cities/search-project-cities.component';

const routes: Routes = [

  { path: '', component: IndexPageComponent  },
  { path: 'locations', component: ProjectsListComponent },
  // { path: 'highlights', component: HighlightsComponent },
  { path: 'partner-login', component: LoginComponent },
  { path: 'contact-us', component: ContactUsComponent  },
  { path: 'disclaimer', component: DisclaimerComponent  },

  { path: 'contact-us-messages-received', component: SearchContactUsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' }  },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'login-activity/:token', component: LoginActivityComponent  },

  { path: 'project/:projectId', component: ProjectPageComponent },
  { path: 'properties', component: PropertyTypesComponent },
  { path: 'properties/:propertyId', component: PropertyTypeProjectsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchPropertyTypeProjects'  } },
  { path: 'projects/status/:statusId', component: ProjectStatusProjectsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchStatusTypeProjects'  } },

  { path: 'projects/new-project', component: AddProjectComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' } },
  { path: 'projects/edit-project/:projectId', component: EditProjectComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchProjectData' } },
  { path: 'search-projects', component: SearchProjectsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' } },

  { path: 'my-profile', component: MyProfileComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' , customAuthApi: 'myProfile'  } },
  { path: 'user-accounts/create', component: CreateUserComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'user-accounts/edit/:userId', component: EditUserComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchUserProfile' } },
  { path: 'user-accounts', component: SearchUsersComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },

  { path: 'bookings/:projectId/new-booking', component: NewBookingComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'bookings/:bookingId', component: EditBookingComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchBookingData' } },
  { path: 'bookings', component: SearchBookingsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'plots', component: SearchPlotsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },

  { path: 'add-plot/:projectId', component: AddPlotComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'edit-plot/:projectId/:plotNo', component: EditPlotComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' ,  customAuthApi: 'editPlot' } },

  { path: 'transactions', component: SearchTransactionsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'remainders', component: SearchRemaindersComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'dashboard', component: DashboardComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'dashboard' } },
  { path: 'change-password', component: ChangePasswordComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' } },
  { path: 'license', component: LicenseComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' }  },
  { path: 'bulk-plots-marking', component: MarkPlotFacingComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'authenticated' }  },
  { path: 'useful-links', component: UsefulLinksComponent  },

  { path: 'manage-home-page-slides', component: SearchHomePageSlidesComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' } },
  { path: 'manage-cities', component: SearchProjectCitiesComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' } },
  { path: 'manage-property-types', component: SearchPropertyTypesComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' } },

  { path: 'manage-custom-pages', component: SearchCustomPagesComponent ,  canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' }  },
  { path: 'add-custom-page', component: AddCustomPageComponent ,  canActivate: [ AuthGuardService], data: { expectedResponse: 'is_super_admin' }  },
  { path: 'edit-custom-page/:customPageId', component: EditCustomPageComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchCustomPageEdit'  } },

  { path: 'cities', component: CitiesComponent },
  { path: 'cities/:cityId', component: CityProjectsComponent , canActivate: [ AuthGuardService], data: { expectedResponse: 'status' , customAuthApi: 'fetchCityProjects'  } },

  { path: 'p/:urlKey', component: SeperatePageComponent  },
  { path: 'news', component: BlogArticlesComponent  },
  { path: 'news/:articleId', component: BlogPageComponent  },
  { path: 'careers', component: CareersComponent  },


  { path: '**', component: NotFoundComponentComponent  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
