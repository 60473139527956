import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomPagesService {

  fetchedCustomPage;

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  getSlides() : any{
    return this.httpClient.get( this.dataHolderService.apiBaseUrl + '/home-page-slides' );
  }

  addCustomPage(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/add-custom-page' , body);
  }

  updateCustomPage(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-custom-page' , body);
  }

  deleteCustomPage( attachmentId  ) : any{
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-custom-page', {
      params: body
    });
  }

  fetchCustomPageEdit(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/fetch-custom-page-edit' , body);

  }

  deleteCustomPageImage(attachmentId): any {
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/delete-custom-page-image', {
      params: body
    });
  }


  searchPages(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/get-pages', body );
  }

  getBlogArticles(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/blog-posts', body );
  }

}
