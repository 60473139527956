import { Pipe, PipeTransform } from '@angular/core';

const regulars = [
  {
    1: 'one', 2: 'two', 3: 'three', 4: 'four', 5: 'five', 6: 'six', 7: 'seven', 8: 'eight', 9: 'nine'
  },
  {
    2: 'twenty', 3: 'thirty', 4: 'forty', 5: 'fifty', 6: 'sixty', 7: 'seventy', 8: 'eighty', 9: 'ninety'
  }
]

const exceptions = {
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
  17: 'seventeen',
  18: 'eighteen',
  19: 'nineteen'
}

const partInWords = (part) => {
  if (parseInt(part) === 0) return
  part = part + '';
  const digits = part.split('')
  const words = []
  if (digits.length === 3) {
    words.push([regulars[0][digits.shift()], 'hundred'].join(' '))
  }
  if (exceptions[digits.join('')]) {
    words.push(exceptions[digits.join('')])
  } else {
    words.push(digits.reverse().reduce((memo, el, i) => {
      memo.unshift(regulars[i][el])
      return memo
    }, []).filter(w => w).join(' '))
  }
  return words.filter(w => w.trim().length).join(' and ')
}

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {




  transform(value: any): any {
    return this.number2text(value);
  }

  number2text(value) {
    var fraction = Math.round(this.frac(value) * 100);
    var f_text = "";

    if (fraction > 0) {
      f_text = "AND " + this.convert_number(fraction) + " PAISE";
    }

    return this.convert_number(value) + " RUPEE " + f_text + " ONLY";
  }

  frac(f) {
    return f % 1;
  }

  convert_number(number) {
    if ((number < 0) || (number > 999999999)) {
      return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000);  /* Crore */
    number -= Gn * 10000000;
    var kn = Math.floor(number / 100000);     /* lakhs */
    number -= kn * 100000;
    var Hn = Math.floor(number / 1000);      /* thousand */
    number -= Hn * 1000;
    var Dn = Math.floor(number / 100);       /* Tens (deca) */
    number = number % 100;               /* Ones */
    var tn = Math.floor(number / 10);
    var one = Math.floor(number % 10);
    var res = "";

    if (Gn > 0) {
      res += (this.convert_number(Gn) + " CRORE");
    }
    if (kn > 0) {
      res += (((res == "") ? "" : " ") +
        this.convert_number(kn) + " LAKH");
    }
    if (Hn > 0) {
      res += (((res == "") ? "" : " ") +
        this.convert_number(Hn) + " THOUSAND");
    }

    if (Dn) {
      res += (((res == "") ? "" : " ") +
        this.convert_number(Dn) + " HUNDRED");
    }


    var ones = Array("", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "THIRTEEN", "FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN", "NINETEEN");
    var tens = Array("", "", "TWENTY", "THIRTY", "FOURTY", "FIFTY", "SIXTY", "SEVENTY", "EIGHTY", "NINETY");

    if (tn > 0 || one > 0) {
      if (!(res == "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      }
      else {

        res += tens[tn];
        if (one > 0) {
          res += ("-" + ones[one]);
        }
      }
    }

    if (res == "") {
      res = "zero";
    }
    return res;
  }



}
