<div class="fullWidthSection plts" style="margin-top: 0em;">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin: 0; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">PLOTS
  </h3>
  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS & REPORTS</span>
    </div>
  </div>

</section>

<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchPlots" (ngSubmit)="onFilterPlots()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">

      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-select">
              <label for="projectId">Project</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
                <option value="">Select project(*required)</option>
                <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                  [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
                </option>
              </select>
              <p class="errors">
                <span *ngIf="submitted &&  searchPlots.controls['projectId'].errors?.required"> *required</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Plots</label>
              <textarea formControlName="plots" type="text" name="plots" placeholder="Eg : 77"></textarea>
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['plots'].errors?.minlength"> *Minimum 1 plot </span>
              </p>
              <ul>
                <li>Tip : You can search status of multiple plots. if you want to know status of Plot 6, plot 30 , plot
                  100, plot 110</li>
                <li> Enter plots value as 6,30,100,110</li>
              </ul>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Booking Status</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                placeholder="Select option.." formControlName="bookingStatus" name="bookingStatus">
                <option [value]="0">Available
                </option>
                <option [value]="1">Booked
                </option>
                <option [value]="-1">Available & Booked
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Booking - Payment Status</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                placeholder="Select option.." formControlName="paymentStatus" name="paymentStatus">
                <option [value]="0">Pending Payment
                </option>
                <option [value]="1">Full Payment received
                </option>
                <option [value]="-1">Pending & Full Payment received
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>BOOKING ID</label>
              <input name="bookingId" formControlName="bookingId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['bookingId'].errors?.minlength"> *Minimum 1 charcter
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Booking Date </label>
              <input formControlName="minimumDate" type="date" name="minimumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['minimumDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Booking Date </label>
              <input formControlName="maximumDate" type="date" name="maximumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['maximumDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Customer ID</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts" class="textualLink">Search users </a>
                </li>
                <li>
                  ID of the buyer
                </li>
              </ul>
              <input formControlName="userId" type="text" name="userId" placeholder="Buyer's  id"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['userId'].errors?.minlength"> *Minimum 1
                  character</span>
              </p>

            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Customer Name</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts" class="textualLink">Search users </a>
                </li>
                <li>
                  Name of the buyer
                </li>
              </ul>
              <input formControlName="userName" type="text" name="userName" placeholder="Customer name"
                 />

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['userName'].errors?.minlength"> *Minimum 1
                  character</span>
              </p>

            </div>
          </div>
        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-select">
              <div>
                <label for="is_north_facing">North facing</label>
                <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                  formControlName="is_north_facing" name="is_north_facing" id="is_north_facing">
                  <option [value]="0">No
                  </option>
                  <option [value]="1">Yes
                  </option>
                  <option [value]="-1">Select Option
                  </option>
                </select>
              </div>
              <div>
                <label for="is_south_facing">South facing</label>
                <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                  formControlName="is_south_facing" name="is_south_facing" id="is_south_facing">
                  <option [value]="0">No
                  </option>
                  <option [value]="1">Yes
                  </option>
                  <option [value]="-1">Select Option
                  </option>
                </select>
              </div>
              <div>
                <label for="is_west_facing">West facing</label>
                <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                  formControlName="is_west_facing" name="is_west_facing" id="is_west_facing">
                  <option [value]="0">No
                  </option>
                  <option [value]="1">Yes
                  </option>
                  <option [value]="-1">Select Option
                  </option>
                </select>
              </div>
              <div>
                <label for="is_east_facing">East facing</label>
                <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();" placeholder=".."
                  formControlName="is_east_facing" name="is_east_facing" id="is_east_facing">
                  <option [value]="0">No
                  </option>
                  <option [value]="1">Yes
                  </option>
                  <option [value]="-1">Select Option
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label for="brokeredDeals">Only Agent deals?</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                formControlName="brokeredDeals" name="brokeredDeals" id="brokeredDeals">
                <option [value]="-1">No</option>
                <option [value]="1">Yes</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Agent ID</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts" [queryParams]="{ isBroker : '1' , isFormVisible : '1' }"
                    class="textualLink">Search Agents </a>
                </li>
                <li>
                  ID of the Agent
                </li>
              </ul>

              <input formControlName="brokerId" type="text" name="brokerId"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['brokerId'].errors?.minLength"> *Minimum 1 character
                </span>
              </p>

            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Agent Name</label>
              <ul class="inline">
                <li>
                    <a target="_blanc" routerLink="/user-accounts" [queryParams]="{ isBroker : '1' , isFormVisible : '1' }"
                      class="textualLink">Search Agents </a>
                  </li>
                  <li>
                    Name of the Agent
                  </li>
              </ul>
              <input formControlName="brokerName" type="text" name="brokerName" placeholder="Agent name"
                 />

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['brokerName'].errors?.minlength"> *Minimum 1
                  character</span>
              </p>

            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Brokerage</label>
              <input formControlName="minimumBrokerage" type="number" step="any" name="minimumBrokerage"
                placeholder="Minimum Brokerage ₹">
              <ng-container *ngIf="searchPlots.value.minimumBrokerage > 0">
                <ul>
                  <li> <strong> {{ searchPlots.value.minimumBrokerage | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  searchPlots.value.minimumBrokerage | numberToWords | titlecase }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['minimumBrokerage'].errors?.numeric"> *Enter valid
                  numeric
                  price</span>
                <span *ngIf="submitted && searchPlots.controls['minimumBrokerage'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Brokerage</label>
              <input formControlName="maximumBrokerage" type="number" step="any" name="maximumBrokerage"
                placeholder="Maximum Brokerage ₹">
              <ng-container *ngIf="searchPlots.value.maximumBrokerage > 0">
                <ul>
                  <li> <strong> {{ searchPlots.value.maximumBrokerage | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  searchPlots.value.maximumBrokerage | numberToWords }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['maximumBrokerage'].errors?.numeric"> *Enter valid
                  numeric
                  price</span>
                <span *ngIf="submitted && searchPlots.controls['maximumBrokerage'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>

          <!-- <div class="form-field">
            <div class="input-container with-label">
              <label>Min North Dimensions</label>
              <input formControlName="min_north_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['min_north_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Max North Dimensions</label>
              <input formControlName="max_north_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['max_north_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Min South Dimensions</label>
              <input formControlName="min_south_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['min_south_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Max South Dimensions</label>
              <input formControlName="max_south_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['max_south_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Min East Dimensions</label>
              <input formControlName="min_east_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['min_east_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Max East Dimensions</label>
              <input formControlName="max_east_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['max_east_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div> -->
        </div>
        <div class="form-right">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Partner ID</label>
              <ul class="inline">
                <li>ID of the partner who has registered the booking</li>
              </ul>
              <input formControlName="bookedBy" type="text" name="bookedBy"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}XXXX" />
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['bookedBy'].errors?.minLength"> *Minimum 1 character
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Partner Name</label>

              <input formControlName="bookedByName" type="text" name="bookedByName"
                placeholder="Partner Name" />
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['bookedByName'].errors?.minLength"> *Minimum 1 character
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Price</label>
              <input formControlName="minimumPrice" type="number" step="any" name="minimumPrice"
                placeholder="Minimum Booking Price">
              <ng-container *ngIf="searchPlots.value.minimumPrice > 0">
                <ul>
                  <li> <strong> {{ searchPlots.value.minimumPrice | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  searchPlots.value.minimumPrice | numberToWords | titlecase }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['minimumPrice'].errors?.numeric"> *Enter valid numeric
                  price</span>
                <span *ngIf="submitted && searchPlots.controls['minimumPrice'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Price</label>
              <input formControlName="maximumPrice" type="number" step="any" name="maximumPrice"
                placeholder="Maximum Booking Price">
              <ng-container *ngIf="searchPlots.value.maximumPrice > 0">
                <ul>
                  <li> <strong> {{ searchPlots.value.maximumPrice | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  searchPlots.value.maximumPrice | numberToWords }} </strong> </li>
                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['maximumPrice'].errors?.numeric"> *Enter valid numeric
                  price</span>
                <span *ngIf="submitted && searchPlots.controls['maximumPrice'].errors?.invalidMsg"> *Enter valid
                  price:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>

          <!-- <div class="form-field">
            <div class="input-container with-label">
              <label>Min West Dimensions</label>
              <input formControlName="min_west_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['min_west_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Max West Dimensions</label>
              <input formControlName="max_west_dimensions" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['max_west_dimensions'].errors?.numeric"> *Enter valid
                  numeric value</span>
              </p>
            </div>
          </div> -->
          <div class="form-field">
            <div class="input-container with-label">
              <label>Min Area</label>
              <input formControlName="min_area" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['min_area'].errors?.numeric"> *Enter valid numeric
                  value</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Max Area</label>
              <input formControlName="max_area" type="number" step="any" name="price" placeholder="">
              <p class="errors">
                <span *ngIf="submitted && searchPlots.controls['max_area'].errors?.numeric"> *Enter valid numeric
                  value</span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort by</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                placeholder="Select option.." formControlName="sortBy" name="sortBy">
                <option value="plot_no">Plot number</option>
                <option value="area">Plot area</option>
                <option value="booking_date">Booking Date</option>
                <option value="booking_price">Booking price</option>
                <option value="payment_status">Payment Received</option>
                <option value="brokerage">Brokerage</option>

              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort order</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterPlots();"
                placeholder="Select option.." formControlName="sortOrder" name="sortOrder">
                <option value="ASC">Ascending</option>
                <option value="DESC">Descending</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <div>
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>
        </div>

        <a class="submit" target="_blanc" [routerLink]="dataHolderService.apiBaseUrl + '/plots-report'"  [queryParams]="{
          projectId :  searchPlots.value.projectId,
          plots :  searchPlots.value.plots,
          is_north_facing :  searchPlots.value.is_north_facing,
          is_south_facing :  searchPlots.value.is_south_facing,
          is_west_facing :  searchPlots.value.is_west_facing,
          is_east_facing :  searchPlots.value.is_east_facing,
          min_north_dimensions :  searchPlots.value.min_north_dimensions,
          max_north_dimensions :  searchPlots.value.max_north_dimensions,
          min_south_dimensions :  searchPlots.value.min_south_dimensions,
          max_south_dimensions :  searchPlots.value.max_south_dimensions,
          min_east_dimensions :  searchPlots.value.min_east_dimensions,
          max_east_dimensions :  searchPlots.value.max_east_dimensions,
          min_west_dimensions :  searchPlots.value.min_west_dimensions,
          max_west_dimensions :  searchPlots.value.max_west_dimensions,
          min_area :  searchPlots.value.min_area,
          max_area :  searchPlots.value.max_area,
          bookingStatus :  searchPlots.value.bookingStatus,
          sortBy :  searchPlots.value.sortBy,
          sortOrder :  searchPlots.value.sortOrder,

          bookingId :  searchPlots.value.bookingId,
          minimumPrice :  searchPlots.value.minimumPrice,
          maximumPrice :  searchPlots.value.maximumPrice,
          minimumBrokerage :  searchPlots.value.minimumBrokerage,
          maximumBrokerage :  searchPlots.value.maximumBrokerage,

          userId : searchPlots.value.userId,
          userName : searchPlots.value.userName,

          bookedBy :  searchPlots.value.bookedBy,
          bookedByName :  searchPlots.value.bookedByName,

          brokerId :  searchPlots.value.brokerId,
          brokeredDeals :  searchPlots.value.brokeredDeals,
          brokerName :  searchPlots.value.brokerName,

          paymentStatus :  searchPlots.value.paymentStatus,

          minimumDate : searchPlots.value.minimumDate,
          maximumDate : searchPlots.value.maximumDate

        }">Download Report</a>
      </div>
    </form>
  </section>
</main>

<section class="single-plot-cards" *ngIf="totalResults > 0" style="
  margin: 0 auto 0 auto;
  width: 98%;
">

  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>Plot No.</th>
          <th>Facing</th>
          <th>Measurments</th>
          <th>Area</th>
          <th>Additional Notes</th>

          <th>Status</th>
          <th>Customer</th>
          <th>Booking Price</th>
          <th>Amount Received</th>
          <th>Booking Date</th>
          <th>Booking Payment Status</th>

          <th>Agent</th>
          <th>Brokerage</th>

          <th>Booked by</th>



        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let plotIndex of Object.keys(plots)">
          <td>
            <a [routerLink]="['/edit-plot', plots[plotIndex].project_id , plots[plotIndex].plot_no ]">
              <span><i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>
          <td>{{  plots[plotIndex]['plot_no'] }}</td>

          <td>
            <p class="horizontal-card-cont">
              <span> <i
                  [ngClass]=" { 'fa-check' : plots[plotIndex]['is_north_facing'] == '1' , 'fa-times' : plots[plotIndex]['is_north_facing'] != '1' }"
                  class="fa" aria-hidden="true"></i> North </span>
              <span> <i
                  [ngClass]=" { 'fa-check' : plots[plotIndex]['is_east_facing'] == '1' , 'fa-times' : plots[plotIndex]['is_east_facing'] != '1' }"
                  class="fa" aria-hidden="true"></i> East </span>
              <span><i
                  [ngClass]=" { 'fa-check' : plots[plotIndex]['is_south_facing'] == '1' , 'fa-times' : plots[plotIndex]['is_south_facing'] != '1' }"
                  class="fa" aria-hidden="true"></i> South</span>
              <span><i
                  [ngClass]=" { 'fa-check' : plots[plotIndex]['is_west_facing'] == '1' , 'fa-times' : plots[plotIndex]['is_west_facing'] != '1' }"
                  class="fa" aria-hidden="true"></i> West</span>
            </p>
          </td>
          <td>

            <p class="horizontal-card-cont">
              <span>{{ plots[plotIndex].north_dimensions ? plots[plotIndex].north_dimensions :  '-'   }} North </span>
              <span>{{ plots[plotIndex].east_dimensions ? plots[plotIndex].east_dimensions :  '-'   }} East </span>
              <span>{{ plots[plotIndex].south_dimensions ? plots[plotIndex].south_dimensions :  '-'   }} South</span>
              <span>{{ plots[plotIndex].west_dimensions ? plots[plotIndex].west_dimensions :  '-'   }} West</span>
            </p>

          </td>
          <td>
            <p class="blurred-title"> {{ plots[plotIndex].area ? plots[plotIndex].area :  '-'   }} </p>

          </td>
          <td>
            <p class="blurred-title">
              {{ plots[plotIndex].additional_notes ? plots[plotIndex].additional_notes :  '-'   }} </p>

          </td>
          <td>


            <a *ngIf="plots[plotIndex].booking_id!=null" class="grad2"
              [routerLink]="['/bookings', plots[plotIndex].booking_id]">
              {{ plots[plotIndex].booking_id }} </a>

            <a *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''" class="grad2"
              [routerLink]="['/bookings', plots[plotIndex]['project_id'], 'new-booking']"
              [queryParams]="{ plots : plots[plotIndex]['plot_no'] }"> BOOK NOW</a>

          </td>

          <td>


            <a target="_blanc" *ngIf="plots[plotIndex].booking_id!=null"
            [routerLink]="['/user-accounts/edit', plots[plotIndex].buyer_id]">
            {{ plots[plotIndex].buyer_name }} </a>

            <a *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''" > - </a>

          </td>


          <td>


            <p *ngIf="plots[plotIndex].booking_id!=null" >
            {{ plots[plotIndex].booking_price | properCurrencyFormat }} </p>

            <p *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''"> -  </p>

          </td>

          <td>


            <p *ngIf="plots[plotIndex].booking_id!=null" >
            {{ plots[plotIndex].total_payment_done | properCurrencyFormat }} </p>

            <p *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''"> -  </p>

          </td>

          <td>


            <p *ngIf="plots[plotIndex].booking_id!=null" >
            {{ plots[plotIndex].booking_date  | momentDateTransformPipe : ('MMM Do, YYYY ')}} </p>

            <p *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''"> -  </p>

          </td>

          <td>


            <p *ngIf="plots[plotIndex].booking_id!=null" >
            {{ plots[plotIndex].booking_price ==  plots[plotIndex].total_payment_done ? 'Full Payment Received' : 'Pending Payment' }} </p>

            <p *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''"> -  </p>

          </td>

          <td>


            <a target="_blanc" *ngIf="plots[plotIndex].booking_id!=null && plots[plotIndex].broker_id!=null"
            [routerLink]="['/user-accounts/edit', plots[plotIndex].broker_id]">
            {{ plots[plotIndex].broker_name }} </a>

            <a *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id=='' || plots[plotIndex].broker_id == null" > - </a>

          </td>

          <td>


            <p *ngIf="plots[plotIndex].booking_id!=null && plots[plotIndex].broker_id!=null" >
            {{ plots[plotIndex].brokerage | properCurrencyFormat}} </p>

            <a *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id=='' || plots[plotIndex].broker_id == null" > - </a>

          </td>

          <td>


            <a target="_blanc" *ngIf="plots[plotIndex].booking_id!=null"
            [routerLink]="['/user-accounts/edit', plots[plotIndex].booked_by_id ]">
            {{ plots[plotIndex].booked_by }} </a>

            <a *ngIf="plots[plotIndex].booking_id == null || plots[plotIndex].booking_id==''" > - </a>

          </td>

        </tr>
      </tbody>
    </table>
  </div>

  <!-- </div> -->

</section>

<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found </p>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchPlots.value.page }" [routerLink]="['./']" [queryParams]="{
        projectId :  searchPlots.value.projectId,
        plots :  searchPlots.value.plots,
        is_north_facing :  searchPlots.value.is_north_facing,
        is_south_facing :  searchPlots.value.is_south_facing,
        is_west_facing :  searchPlots.value.is_west_facing,
        is_east_facing :  searchPlots.value.is_east_facing,
        min_north_dimensions :  searchPlots.value.min_north_dimensions,
        max_north_dimensions :  searchPlots.value.max_north_dimensions,
        min_south_dimensions :  searchPlots.value.min_south_dimensions,
        max_south_dimensions :  searchPlots.value.max_south_dimensions,
        min_east_dimensions :  searchPlots.value.min_east_dimensions,
        max_east_dimensions :  searchPlots.value.max_east_dimensions,
        min_west_dimensions :  searchPlots.value.min_west_dimensions,
        max_west_dimensions :  searchPlots.value.max_west_dimensions,
        min_area :  searchPlots.value.min_area,
        max_area :  searchPlots.value.max_area,
        bookingStatus :  searchPlots.value.bookingStatus,
        sortBy :  searchPlots.value.sortBy,
        sortOrder :  searchPlots.value.sortOrder,
        pagination : searchPlots.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink,

        bookingId :  searchPlots.value.bookingId,
        minimumPrice :  searchPlots.value.minimumPrice,
        maximumPrice :  searchPlots.value.maximumPrice,
        minimumBrokerage :  searchPlots.value.minimumBrokerage,
        maximumBrokerage :  searchPlots.value.maximumBrokerage,

        userId : searchPlots.value.userId,
        userName : searchPlots.value.userName,

        bookedBy :  searchPlots.value.bookedBy,
        bookedByName :  searchPlots.value.bookedByName,

        brokerId :  searchPlots.value.brokerId,
        brokeredDeals :  searchPlots.value.brokeredDeals,
        brokerName :  searchPlots.value.brokerName,

        paymentStatus :  searchPlots.value.paymentStatus,

        minimumDate : searchPlots.value.minimumDate,
        maximumDate : searchPlots.value.maximumDate

      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
