import { Component, OnInit } from '@angular/core';
import { DataHolderService } from './../data-holder.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  constructor(
    public dataHolderService: DataHolderService,

  ) { }


  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(` Disclaimer | Terms & Conditions | ${this.dataHolderService.projectMetaDeta.default_title}`);

  }

}
