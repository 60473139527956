<section class="responsiveContainer">
  <h3 style="text-align: center; font-size: 2em; margin-top: 1em; padding: 0;">BULK PLOTS FACING EDITOR</h3>
  <form [formGroup]="markPlots" (ngSubmit)="onMarkPlots()" #form="ngForm" class="form-layout"
    [ngClass]=" { 'onecolumn' : onecolumn }">
    <div class="form-heading">

    </div>
    <div class="form-body">
      <div class="form-left">
        <div class="form-field">
          <div class="input-container with-select">
            <label for="projectId">Project</label>
            <select placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
              <option value="">Select project(*required)</option>
              <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
              </option>
            </select>
            <p class="errors">
              <span *ngIf="submitted &&  markPlots.controls['projectId'].errors?.required"> *required</span>
            </p>
          </div>
        </div>
        <div class="form-field">
          <div class="input-container with-label">
            <label>Plots</label>
            <textarea formControlName="plots" type="text" name="plots" placeholder="Eg : 77"></textarea>
            <p class="errors">
              <span *ngIf="submitted && markPlots.controls['plots'].errors?.minlength"> *Minimum 1 plot </span>
            </p>
            <ul>
              <li>Tip : You can enter multiple plots. if you want to know update facing of Plot 6, plot 30 , plot
                100, plot 110</li>
              <li> Enter plots value as 6,30,100,110</li>
            </ul>
          </div>
        </div>




      </div>

      <div class="form-middle">

        <div class="form-field">
          <div class="input-container with-select">
            <label>Facing Direction</label>
            <select placeholder="Select option.." formControlName="facingDirection" name="facingDirection">
              <option value="is_north_facing">North</option>
              <option value="is_south_facing">South</option>
              <option value="is_east_facing">East</option>
              <option value="is_west_facing">West</option>

            </select>
          </div>
        </div>

      </div>
      <div class="form-right">

        <div class="form-field">
          <div class="input-container with-select">
            <label>Facing Value</label>
            <select placeholder="Select option.." formControlName="facingValue" name="facingValue">
              <option [value]="0">No
              </option>
              <option [value]="1">Yes
              </option>
            </select>
          </div>
        </div>


      </div>
    </div>
    <div class="form-button">
      <button>UPDATE</button>
    </div>
  </form>
</section>
