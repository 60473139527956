import { Component, OnInit , Input } from '@angular/core';
import { DataHolderService } from './../data-holder.service';
// import panzoom from "panzoom";
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.css']
})
export class HighlightsComponent implements OnInit {

  config: SwiperOptions = {
    slidesPerView: 1,
    zoom: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: true
    },
    speed: 500,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  constructor(public dataHolder : DataHolderService) { }
  @Input() pageIsEmbedded = false;

  ngOnInit(): void {

    if(!this.pageIsEmbedded){
      this.dataHolder.updatePageMeta('Highlights - ' + this.dataHolder.projectMetaDeta.default_title);
    }

    // document.querySelectorAll<HTMLElement>(".zoomImgs").forEach((ele)=>{

    //   panzoom(ele, {
    //     maxZoom: 3,
    //     minZoom: 0.5,
    //     onTouch: function (e) {
    //       // `e` - is current touch event.

    //       return false; // tells the library to not preventDefault.
    //     }
    //   });
    // })
  }

}
