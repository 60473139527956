import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  passwordFieldHidden : boolean = true;

  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder, public notificationsService: NotificationsService,
    private httpClient: HttpClient, private router: Router,
    public dataHolder: DataHolderService
  ) {

  }

  ngOnInit(): void {
    this.dataHolder.updatePageMeta('Login - ' + this.dataHolder.projectMetaDeta.default_title);

    this.loginForm = this.formBuilder.group({
      userId: ["", [Validators.required ]],
      password: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
    });
  }

  onLogin(): void {
    this.submitted = true;
    if(this.loginForm.invalid){
      this.notificationsService.toggleFailed(true,"Invalid data");
      return;
    }
    let body = new HttpParams();
    body = body.set('userId', this.loginForm.get('userId').value);
    body = body.set('password', this.loginForm.get('password').value);

    this.notificationsService.toggleLoading(true,'Processing your login request..');
    this.httpClient.post( this.dataHolder.apiBaseUrl + '/partner-login', body ).subscribe((response : any)=>{

      this.notificationsService.toggleLoading(false);
      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      // this.loginForm.reset();
      this.submitted = false;


      // this.authService.checkAuthentication().subscribe((res)=>{
      //   // console.log(res);
      // });

      setTimeout(()=>{
        this.router.navigate(['/dashboard']);
      },500);

    });
  }

}
