<main>
  <section class="responsiveContainer">
    <form [id]="isCreate ? 'addTransaction' : transactionForm.value.transactionId " [formGroup]="transactionForm"
      (ngSubmit)="onTransaction()" #form="ngForm" class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>{{ isCreate ? 'NEW TRANSACTION' : transactionForm.value.transactionId  }}</span>
        </h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>BOOKING ID</label>
              <input readonly name="bookingId" formControlName="bookingId" type="text" readonly [value]="bookingId">
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>₹ Credit</label>
              <input formControlName="credit" type="number" step="any" name="credit" placeholder="₹ Received">
              <ng-container class="errors" *ngIf="transactionForm.value.credit > 0">
                <ul>
                  <li> <strong> {{ transactionForm.value.credit | properCurrencyFormat }} </strong> </li>
                  <li> <strong> {{  transactionForm.value.credit | numberToWords | titlecase }} </strong> </li>

                </ul>
              </ng-container>

              <p class="errors">
                <span *ngIf="fetchedTrasaction && transactionForm.controls['credit'].errors?.required"> *required</span>
                <span *ngIf="fetchedTrasaction && transactionForm.controls['credit'].errors?.numeric"> *Enter valid
                  numeric
                  credit</span>
                <span *ngIf="fetchedTrasaction && transactionForm.controls['credit'].errors?.invalidMsg"> *Enter valid
                  credit: For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-middle">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Transaction Date (optional)</label>
              <input formControlName="transactionDate" type="date" name="transactionDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="fetchedTrasaction && transactionForm.controls['transactionDate'].errors?.invalidMsg">
                  *Select a valid
                  transaction date</span>
              </p>
            </div>
          </div>
          <ng-container *ngIf="!isCreate">
          <div class="form-field">
            <div class="input-container with-label">
              <ul>
                <li>Transaction last updated :
                </li>
                <li>
                  {{ transactionData.updated_on | momentDateDiffPipe }}
                </li>
                <li>( {{ transactionData.updated_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>
              </ul>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Added By</label>
              <input readonly type="text" readonly [value]="transactionData.added_by" />
              <ul class="inline">
                <li (click)="getUserDetails()">
                  <a class="textualLink">User details</a>
                </li>
              </ul>
              <div *ngIf="addedBy" class="userProfile">
                <section>
                  <a class="main-link" [routerLink]="[ '/user-accounts/edit' , addedBy.id ]">
                    <span>
                      <img
                        src='{{ addedBy?.profile_picture_url ? dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + addedBy.id : "assets/images/dummy.png " }}'>
                    </span>
                  </a>
                </section>
                <section>
                  <div>
                    <p>
                      <a class="normalLink" [routerLink]="[ '/user-accounts/edit' , addedBy.id ]">
                        <span>
                          {{ addedBy.first_name + " " + ( addedBy.middle_name!=null ? addedBy.middle_name : "" ) + " " + addedBy.last_name }}
                        </span>
                      </a>
                    </p>

                    <p>
                      <a class="normalLink" href="tel:{{ addedBy.mobile_number }}">
                        {{ addedBy.mobile_number }}
                      </a>
                    </p>
                    <p>
                      <a class="normalLink" href="mailto:{{ addedBy.email }}">
                        {{ addedBy.email }}
                      </a>
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          </ng-container>
        </div>
        <div class="form-right">
          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <label>Transaction ID</label>
                <input readonly name="transactionId" formControlName="transactionId" type="text" readonly>
              </div>
            </div>

          </ng-container>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes"
                placeholder="Anything you want to add to the transaction?"></textarea>
              <p class="errors">
                <span *ngIf="fetchedTrasaction && transactionForm.controls['additionalNotes'].errors?.minlength">
                  *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedTrasaction && transactionForm.controls['additionalNotes'].errors?.maxlength">
                  *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <button (click)="deleteTransaction();" *ngIf="!isCreate" type="button">DELETE</button>
        <p  style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> Booking data will be refreshed, if you make changes to transactions </p>

      </div>
    </form>
  </section>
</main>
