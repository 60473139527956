import { DataHolderService } from './../data-holder.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.css']
})
export class IndexPageComponent implements OnInit {


  constructor(private dataHolder : DataHolderService) { }

  ngOnInit(): void {
    this.dataHolder.updatePageMeta();
  }

}
