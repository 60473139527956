import { CustomPagesService } from './../custom-pages.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';


@Component({
  selector: 'app-add-edit-custom-pages',
  templateUrl: './add-edit-custom-pages.component.html',
  styleUrls: ['./add-edit-custom-pages.component.css']
})
export class AddEditCustomPagesComponent implements OnInit {

  submitted: boolean = false;

  customPageForm: FormGroup;

  @Input() isCreate: boolean | string;

  attachmentData: any = null;

  fetchedAttachment: boolean = false;

  attachmentUrl: any;

  localFileName: any;


  @ViewChild('editor', {
    static: true
  })

  editor: QuillEditorComponent;

  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private customPagesService: CustomPagesService,
    private router: Router,
    private route: ActivatedRoute

  ) {
  }

  ngOnInit(): void {

    this.attachmentData = this.isCreate ? [] : this.customPagesService.fetchedCustomPage;

    this.dataHolderService.updatePageMeta(`${this.isCreate ? 'Add ' : 'Edit '} Custom Page  | ${this.dataHolderService.projectMetaDeta.default_title}`);


    this.customPageForm = this.formBuilder.group({
      attachmentId: [this.isCreate ? '1' : this.attachmentData['id'], []],
      title: [this.isCreate ? '' : this.attachmentData['title'], [Validators.maxLength(150)]],
      description: [this.isCreate ? '' : this.attachmentData['description'], [Validators.maxLength(150)]],

      rich_text_content: [this.isCreate ? '' : this.attachmentData['rich_text_content'] ? this.attachmentData['rich_text_content'] : ''],
      custom_html: [this.isCreate ? '' : this.attachmentData['custom_html'] ? this.attachmentData['custom_html'] : ''],

      type_of_content: [this.isCreate ? '1' : this.attachmentData['type_of_content'], [Validators.required]],
      is_active: [this.isCreate ? '1' : this.attachmentData['is_active'], [Validators.required]],
      content_order: [this.isCreate ? '1' : this.attachmentData['content_order'], [Validators.required]],
      is_visible_on_page_tabs: [this.isCreate ? '0' : this.attachmentData['is_visible_on_page_tabs'], [Validators.required]],
      url_key: [this.isCreate ? '' : this.attachmentData['url_key'], [Validators.maxLength(150)]],
      sort_order: [this.isCreate ? '' : this.attachmentData['sort_order'], []],
      project_id: [this.isCreate ? '-1' : this.attachmentData['project_id'], []],
      image: ["", []],

    });

    this.checkForQueryParams();
  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {

      if ("type_of_content" in queryParams && [1, 2, 3].includes(parseInt(queryParams.type_of_content))) {

        this.customPageForm.patchValue({
          type_of_content: queryParams.type_of_content
        });

        if(queryParams.type_of_content == '3' && "project_id" in queryParams){
          this.customPageForm.patchValue({
            project_id: queryParams.project_id
          });
        }
      }

    });
  }

  onSubmit(e): void {


    const formData = new FormData(e.target.closest("form"));
    formData.append("rich_text_content", this.customPageForm.value.rich_text_content);
    let attachment = e.target.closest("form").querySelector(`input[name="image[]"]`);


    if (this.customPageForm.invalid) {
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true, "Invalid data, make sure to follow guidelines before filling data");
      return;
    }

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before proceeding..");
      return;
    }

    if (this.customPageForm.value.type_of_content == '1' &&
      (this.customPageForm.value.title.trim().length < 1 ||
        this.customPageForm.value.description.trim().length < 1 || (this.isCreate && !this.validateFiles(attachment)))) {
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true, "Blog item must have title, description and an image..");
      return;
    }

    if (this.customPageForm.value.type_of_content == '3' && this.customPageForm.value.project_id == '-1') {
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true, "Project content must be mapped to a project");
      return;
    }

    if (this.customPageForm.value.type_of_content == '2' && this.customPageForm.value.url_key.trim().length < 1) {
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true, "Page content key / url can not be empty");
      return;
    }



    if (this.isCreate) {
      this.notificationsService.toggleLoading(true, "Adding Page...");
      this.newCustomPage(formData, attachment);
      return;
    }
    else {
      this.notificationsService.toggleLoading(true, "Updating page...");
      this.updateCustomPage(formData);
      return;
    }
  }

  newCustomPage(formData, attachment): void {

    this.customPagesService.addCustomPage(formData).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);

      this.fetchedAttachment = false;
      attachment.value = "";
      this.localFileName = null;

      setTimeout(() => {
        this.router.navigate(['/edit-custom-page', response.data['id']]);
      }, 500);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateCustomPage(formData): void {

    this.customPagesService.updateCustomPage(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.localFileName = null;
      this.attachmentData = response.data;
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  removeCustomPageImage(): void {

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before proceeding..");
      return;
    }


    this.notificationsService.toggleLoading(true, "Deleting image");
    this.customPagesService.deleteCustomPageImage(this.customPageForm.value.attachmentId).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }

      this.notificationsService.toggleSucceeded(true, response.message);
      this.attachmentData = response.data;

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }

  deleteCustomPageImage(): void {

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before proceeding..");
      return;
    }


    this.notificationsService.toggleLoading(true, "Deleting Page");
    this.customPagesService.deleteCustomPage(this.customPageForm.value.attachmentId).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }

      this.notificationsService.toggleSucceeded(true, response.message);
      setTimeout(() => {
        this.router.navigate(['/manage-custom-pages']);
      }, 500);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }

  updateView(e): void {

  }

  // constructor(private formBuilder: FormBuilder) {
  //   this.form = formBuilder.group({
  //     editor: [`<section class="responsiveContainer">
  //     <h1>Disclaimer for Sri Saraswathi Homes</h1>

  //     <p>If you require any more information or have any questions about our site's disclaimer, please feel free to contact
  //       us.</p>

  //     <h2>Disclaimers for Sri Saraswathi Homes</h2>

  //     <p>All the information on this website - https://www.srisaraswathihomes.com/ - is published in good faith and for general
  //       information purpose only. Sri Saraswathi Homes does not make any warranties about the completeness, reliability and
  //       accuracy of this information. Any action you take upon the information you find on this website , is strictly at your own risk. Sri Saraswathi Homes will not be liable for any losses and/or damages in
  //       connection with the use of our website.</p>

  //     <p>Plot Measurements, facing, booking status, brochures & images etc may not be accurate. contact head office
  //       for accurate information </p>

  //     <p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to
  //       provide only quality links to useful and ethical websites, we have no control over the content and nature of these
  //       sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site
  //       owners and content may change without notice and may occur before we have the opportunity to remove a link which may
  //       have gone 'bad'.</p>

  //     <h2>Consent</h2>

  //     <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>

  //     <h2>Update</h2>

  //     <p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>
  //   </section>
  //   `],
  //   });
  // }


  // ngOnInit(): void {
  //   this.form
  //     .controls
  //     .editor
  //     .valueChanges.pipe(
  //       debounceTime(400),
  //       distinctUntilChanged()
  //     )
  //     .subscribe((data) => {
  //       // tslint:disable-next-line:no-console
  //      this.data = data;
  //       console.log('native fromControl value changes with debounce', data)
  //     });

  //   this.editor
  //     .onContentChanged
  //     .pipe(
  //       debounceTime(400),
  //       distinctUntilChanged()
  //     )
  //     .subscribe((data: ContentChange) => {
  //       // tslint:disable-next-line:no-console
  //       console.log('view child + directly subscription', data)
  //     })
  // }


  preventDefaultOnDrag(e): void {
    e.preventDefault();
  }

  onFilesDrop(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="image[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFiles(attachment)) {
      this.localFileName = null;
      attachment.value = "";
    }
  }


  onFilesChanged(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="image[]"]`);
    if (!this.validateFiles(attachment)) {
      this.localFileName = null;
      attachment.value = "";
    }
  }

  cancelfile(e): void {
    this.localFileName = null;
    let attachment = e.target.closest("form").querySelector(`input[name="image[]"]`);
    attachment.value = "";
  }

  validateFiles(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "project image file is required and you can upload only 1 one file");
      return false;
    }
    let extensions = ["jpeg", "bmp", "png", "jpg", "gif", "svg", "gif"];
    let sizes = { minSize: 10, maxSize: 1e+7 };
    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name).toLowerCase())) {
        this.notificationsService.toggleFailed(true, "Only jpeg,bmp,png,jpg,gif,svg,gif files are accepted");
        return false;
      }
      if (files[i].size < sizes.minSize || files[i].size > sizes.maxSize) {
        this.notificationsService.toggleFailed(true, "Maximum accepted file size is 10000KB");
        return false;
      }
      this.localFileName = files[i].name;
    }
    return true;
  }

  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

}
