<div class="fullWidthSection rmds" style="margin-top: 0em;">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin: 0; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">REMAINDERS
  </h3>

  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="radio-toggler noselect">
      <div class="radio-container">
        <input (change)="setViewVisibility('listView')" name="viewType" [checked]="viewType == 'listView'" type="radio"
          value="listView" id="listView">
        <label for="listView">
          <i class="fa fa-table" aria-hidden="true"></i> LIST
        </label>
        <input (change)="setViewVisibility('reportView')" name="viewType" [checked]="viewType == 'reportView'"
          type="radio" value="reportView" id="reportView">
        <label for="reportView">
          <i class="fa fa-th-large" aria-hidden="true"></i> SUMMARY
        </label>
      </div>
    </div>

    <pre></pre>

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS & REPORTS</span>
    </div>

  </div>

</section>

<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchRemainders" (ngSubmit)="onFilterRemainders()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-select">
              <label for="projectId">Project</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
                <option selected value="">Select project</option>
                <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                  [value]="project.id">{{ project['name'] + ',' + project['main_town'] }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Booking ID</label>
              <input name="bookingId" formControlName="bookingId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchRemainders.controls['bookingId'].errors?.minlength"> *Minimum 1
                  character
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Remainder ID</label>
              <input name="remainderId" formControlName="remainderId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.REMAINDER_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchRemainders.controls['remainderId'].errors?.minlength"> *Minimum 1
                  character
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Customer ID</label>
              <ul class="inline">
                <li>
                  <a target="_blanc" routerLink="/user-accounts" class="textualLink">Search users </a>
                </li>
                <li>
                  ID of the buyer
                </li>
              </ul>
              <input formControlName="userId" type="text" name="userId" placeholder="Buyer's  id" />
              <p class="errors">
                <span *ngIf="submitted && searchRemainders.controls['userId'].errors?.minlength"> *Minimum 1
                  character</span>
              </p>
            </div>
          </div>



        </div>

        <div class="form-middle">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes"
                placeholder="Any notes you had added previously"></textarea>

              <p class="errors">
                <span *ngIf="submitted && searchRemainders.controls['additionalNotes'].errors?.minlength"> *Minimum 1
                  character
                </span>
                <span *ngIf="submitted && searchRemainders.controls['additionalNotes'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Remainder Date </label>
              <input formControlName="minimumDate" type="date" name="minimumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchRemainders.controls['minimumDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Remainder Date </label>
              <input formControlName="maximumDate" type="date" name="maximumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchRemainders.controls['maximumDate'].errors?.invalidMsg"> *Select a valid
                  booking date</span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">


          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort by</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                placeholder="Select option.." formControlName="sortBy" name="sortBy">
                <option value="remainder_date">Remainder Date</option>
                <option value="payment_status">Payment received</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort order</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                placeholder="Select option.." formControlName="sortOrder" name="sortOrder">
                <option value="ASC">Low to high</option>
                <option value="DESC">High to low</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>
      <div>
        <a class="submit" target="_blanc" [routerLink]="dataHolderService.apiBaseUrl + '/remainders-report'" [queryParams]="{
          projectId :  searchRemainders.value.projectId,
          bookingId :  searchRemainders.value.bookingId,
          remainderId :  searchRemainders.value.transactionId,
          userId : searchRemainders.value.userId,
          additionalNotes :  searchRemainders.value.additionalNotes,
          minimumDate :  searchRemainders.value.minimumDate,
          maximumDate :  searchRemainders.value.maximumDate,
          sortBy :  searchRemainders.value.sortBy,
          sortOrder :  searchRemainders.value.sortOrder,
          viewType : viewType
        }">Download Report</a>
      </div>
    </div>


    </form>
  </section>
</main>


<section class="responsiveContainer single-booking-cards" *ngIf="totalResults > 0 && viewType == 'reportView'">
  <div class="horizontal-card" *ngFor="let rmd of Object.keys(remainders)">
    <div class="twoColumnsGrid">
      <div>
        <h1><a [routerLink]="['/bookings', remainders[rmd].booking_id ]" class="grad1"> {{ rmd }}</a></h1>
        <p>Remainder Date : </p>
        <p>
          <span>  {{ remainders[rmd].remainder_date | momentDateTransformPipe : ('MMM Do, YYYY ')  }} ( {{ remainders[rmd].remainder_date | momentDateDiffPipe  }} ) </span>
         </p>

        <p>Remainder Notes</p>
        <p>{{ remainders[rmd].additional_notes ?  remainders[rmd].additional_notes : '-' }}</p>

        <p class="blurred-title"> Booking ID </p>
        <h1><a [routerLink]="['/bookings', remainders[rmd].booking_id ]" class="grad1"> {{ remainders[rmd].booking_id }}</a></h1>
      </div>
      <div>


        <p class="blurred-title"> Payment status </p>
        <h1 *ngIf="remainders[rmd].price == remainders[rmd].total_payment_done">
          <a [routerLink]="['/bookings', remainders[rmd].booking_id ]" class="grad2"> <i class="fa fa-check" aria-hidden="true"></i>FULL
            PAYMENT DONE </a>
        </h1>
        <h1 *ngIf="remainders[rmd].price != remainders[rmd].total_payment_done">
          <a [routerLink]="['/bookings', remainders[rmd].booking_id ]" class="grad2"> <i class="fa fa-hourglass-half" aria-hidden="true"></i>
            PENDING PAYMENT </a>
        </h1>


        <p><span>{{ ((remainders[rmd].total_payment_done / remainders[rmd].price) * 100).toFixed(2)  }} % payment received </span> </p>
        <p> Price : {{ remainders[rmd].price | properCurrencyFormat   }} </p>
        <p> ({{ remainders[rmd].price | numberToWords | titlecase }})</p>

        <p> Received : {{ remainders[rmd].total_payment_done | properCurrencyFormat  }} </p>
        <p> ({{ remainders[rmd].total_payment_done | numberToWords | titlecase  }}) </p>


      </div>
    </div>
  </div>
</section>

<section class="fullWidthTable" *ngIf="totalResults > 0 && viewType == 'listView'">
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>Remainder ID</th>
          <th>Remainder Date</th>
          <th>Weekday</th>
          <th>Buyer ID</th>
          <th>Buyer</th>
          <th>Buyer - Email</th>
          <th>Buyer - Mobile</th>
          <th>Booking ID</th>
          <th>Price</th>
          <th>Received Amount</th>
          <th>Pending Amount</th>
          <th>Payment Status</th>
          <th>Remainder Notes</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rmd of Object.keys(remainders)">
          <td>
            <a [routerLink]="['/bookings', remainders[rmd].booking_id ]">
              <span> <i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>
          <td>
            {{ rmd }}
          </td>
          <td>{{ remainders[rmd].remainder_date | momentDateTransformPipe : ('DD-MM-YYYY ')  }}</td>
          <td>{{ remainders[rmd].remainder_date | date : ('EEEE') }} ({{ remainders[rmd].remainder_date | momentDateDiffPipe  }})</td>
          <td>{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX + remainders[rmd].buyer_id }}</td>
          <td>{{ remainders[rmd].buyer_name }}</td>

          <td><p *ngIf="!remainders[rmd].buyer_email"> - </p>
            <p>{{ remainders[rmd].buyer_email }}</p></td>

          <td><p *ngIf="!remainders[rmd].buyer_mobile"> - </p>
            <p>{{ remainders[rmd].buyer_mobile }}</p></td>

          <td>{{ remainders[rmd].booking_id }}</td>
          <td>{{ remainders[rmd].price | properCurrencyFormat }}</td>
          <td>{{ remainders[rmd].total_payment_done | properCurrencyFormat  }}</td>
          <td>{{ remainders[rmd].price - remainders[rmd].total_payment_done | properCurrencyFormat}}</td>
          <td>
            <p *ngIf="remainders[rmd].price == remainders[rmd].total_payment_done">FULL PAYMENT DONE</p>
            <p *ngIf="remainders[rmd].price != remainders[rmd].total_payment_done">PAYMENT PENDING</p>
          </td>
          <td>{{ remainders[rmd].additional_notes ?  remainders[rmd].additional_notes : '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found </p>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchRemainders.value.page }" [routerLink]="['./']" [queryParams]="{
        projectId :  searchRemainders.value.projectId,
        bookingId :  searchRemainders.value.bookingId,
        remainderId :  searchRemainders.value.remainderId,
        userId : searchRemainders.value.userId,
        additionalNotes :  searchRemainders.value.additionalNotes,
        minimumDate :  searchRemainders.value.minimumDate,
        maximumDate :  searchRemainders.value.maximumDate,
        sortBy :  searchRemainders.value.sortBy,
        sortOrder :  searchRemainders.value.sortOrder,
        pagination : searchRemainders.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink,
        viewType : viewType

      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
