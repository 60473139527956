import { DataHolderService } from './../data-holder.service';
import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home-page-slide-show',
  templateUrl: './home-page-slide-show.component.html',
  styleUrls: ['./home-page-slide-show.component.css']
})
export class HomePageSlideShowComponent implements OnInit {

  config: SwiperOptions = {
    slidesPerView: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
    },
    speed: 3000,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  constructor(public dataHolder : DataHolderService) { }

  ngOnInit(): void {
  }


}
