<div class="buyNowOverlay" [ngClass]=" { 'active' : contactThewebbl }">
  <div class="shareContainer responsive600">
    <div class="closeBuyNow" (click)="contactThewebbl = !contactThewebbl;">
      <svg class="svg-icon" viewBox="0 0 20 20">
        <path fill="none"
          d="M12.71,7.291c-0.15-0.15-0.393-0.15-0.542,0L10,9.458L7.833,7.291c-0.15-0.15-0.392-0.15-0.542,0c-0.149,0.149-0.149,0.392,0,0.541L9.458,10l-2.168,2.167c-0.149,0.15-0.149,0.393,0,0.542c0.15,0.149,0.392,0.149,0.542,0L10,10.542l2.168,2.167c0.149,0.149,0.392,0.149,0.542,0c0.148-0.149,0.148-0.392,0-0.542L10.542,10l2.168-2.168C12.858,7.683,12.858,7.44,12.71,7.291z M10,1.188c-4.867,0-8.812,3.946-8.812,8.812c0,4.867,3.945,8.812,8.812,8.812s8.812-3.945,8.812-8.812C18.812,5.133,14.867,1.188,10,1.188z M10,18.046c-4.444,0-8.046-3.603-8.046-8.046c0-4.444,3.603-8.046,8.046-8.046c4.443,0,8.046,3.602,8.046,8.046C18.046,14.443,14.443,18.046,10,18.046z">
        </path>
      </svg>
    </div>
    <h2 style="letter-spacing: 0.1em;">THE WEB BLINDERS</h2>
    <!-- <p style="font-size: 0.9em;"><q>Passion meets Perfection</q></p> -->

    <!-- <p>Manage projects, bookings, plots, transactions, customers, agents, payment remainders etc</p> -->

    <!-- <p>Contact us, if you are looking for a Software solution for your business </p> -->
    <p><i class="fa fa-envelope" aria-hidden="true"></i>
      <a class="margin-right" *ngFor="let m of dataHolderService.projectMetaDeta.DEV_EMAILS.split(',')" target="_blanc"  href="mailto:{{ m }}">
         {{ m }}</a>
    </p>
    <p ><i class="fa fa-phone" aria-hidden="true"></i>
      <a class="margin-right" *ngFor="let m of dataHolderService.projectMetaDeta.DEV_MOBILES.split(',')" target="_blanc" href="tel:{{ m }}">  {{ m }}</a>
    </p>
    <p>
      <a target="_blanc" href="https://www.thewebblinders.in">
        <i class="fa fa-globe"></i> www.thewebblinders.in</a>
    </p>

    <p>
      <a target="_blanc" href="https://g.page/the-web-blinders?share">
        <i class="fa fa-map" aria-hidden="true"></i> The Web Blinders, Shop No : 64, 4th Floor, Skanda Mall, Near Congress Office, Khadak Pura, Kurnool, Andhra Pradesh 518001</a>
    </p>
  </div>
</div>

<footer>
  <a href="" (click)="scrollToTop(); false" class="scrollToTop smoothScroll">
    <span> <i class="fa fa-arrow-up" aria-hidden="true"></i> GO TO TOP </span>
  </a>
  <div class="footerSectionsContainer responsiveContainer">
    <div class="footerSection">
      <div>
        <h2>{{ dataHolderService.projectMetaDeta.COMPANY_NAME }}</h2>
        <p>
         <a target="_blanc"
         href="https://goo.gl/maps/UVpgA8xAqN7Lwbys7"> {{ dataHolderService.projectMetaDeta.COMPANY_ADDRESS }}  </a>
        </p>
        <p><a [routerLink]="['/about-us']">About</a></p>
        <p><a [routerLink]="['/contact-us']">Contact us</a></p>

      </div>

    </div>
    <div class="footerSection">
      <div>
        <h2> BROWSE </h2>
        <a [routerLink]="['/locations']">Projects</a>
        <a [routerLink]="['/properties']">Properties</a>
        <a [routerLink]="['/']">Home</a>
        <a *ngIf="dataHolderService.userInfo" [routerLink]="['/license']">License</a>
        <a [routerLink]="['/disclaimer']">Disclaimer</a>

      </div>

    </div>
    <div class="footerSection">
      <div>
        <h2>Join us</h2>

        <!-- <p> <a href="tel:{{ dataHolderService.projectMetaDeta.primary_mobile_number }}">
            {{ dataHolderService.projectMetaDeta.primary_mobile_number }}</a></p> -->
        <p> <a href="mailto:{{ dataHolderService.projectMetaDeta.COMPANY_EMAIL }}">
            {{ dataHolderService.projectMetaDeta.COMPANY_EMAIL }}</a></p>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolderService.projectMetaDeta.COMPANY_FACEBOOK.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_FACEBOOK : ''}}"
          class="storeLink">
          <i style="color: blue;" class="fa fa-facebook-square" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolderService.projectMetaDeta.COMPANY_FACEBOOK.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_TWITTER : ''}}"
          class="storeLink">
          <i style="color: #00ACEE;" class="fa fa-twitter-square" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="mailto:{{ dataHolderService.projectMetaDeta.COMPANY_EMAIL.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_EMAIL : ''}}"
          class="storeLink">
          <i style="color: blueviolet;" class="fa fa-envelope" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolderService.projectMetaDeta.COMPANY_LINKEDIN.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_LINKEDIN : ''}}"
          class="storeLink">
          <i style="color: #0E76A8;" class="fa fa-linkedin-square" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ 'https://wa.me/' + (dataHolderService.projectMetaDeta.COMPANY_WHATSAPP.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_WHATSAPP : '' ) + '/?text=Hello!'  }}"
          class="storeLink">
          <i style="color: #25D366;" class="fa fa fa-whatsapp" aria-hidden="true"></i>
        </a>

        <a rel=" nofollow noopener noreferrer" target="_blank"
        href="{{ dataHolderService.projectMetaDeta.COMPANY_INSTAGRAM.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_INSTAGRAM : ''}}"
        class="storeLink">
        <i style="color: #E1306C;" class="fa fa-instagram" aria-hidden="true"></i>
        </a>



      </div>
    </div>
  </div>

  <div class="footerNote">
    <p>&copy; {{ moment().format('YYYY') }} - Real Estate Management System by</p>
    <p (click)="contactThewebbl = !contactThewebbl;"> <strong><a class="" style="font-size: 1.1em; cursor: pointer;">THE WEB BLINDERS</a></strong></p>
  </div>
</footer>
