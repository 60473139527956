<div class="fullWidthSection" style="margin-top: 0em;">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin-top: 1em; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">Custom Pages
  </h3>
  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS </span>
    </div>
  </div>

</section>

<main class="responsiveContainer" [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <form [formGroup]="searchPages" (ngSubmit)="onFilterPages()" #form="ngForm" class="application-forms"
    [ngClass]=" { 'onecolumn' : onecolumn }">

    <div class="form-wrapper">
      <div class="form-description">
        <input type="hidden" formControlName="page" name="page">
      </div>
    </div>

    <div class="form-input">
      <label for="type_of_content">Type of Content </label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" name="type_of_content"
        formControlName="type_of_content">
        <option value="-1">All</option>
        <option value="1">Blog item</option>
        <option value="2">Internal Page / Seperate new page </option>
        <option value="3">Project content </option>
      </select>
    </div>

    <div [ngStyle]="{ 'display' : searchPages.value.type_of_content == '1' ? 'block' : 'none' }">
      <div class="form-input">
        <label for="title">Title of content </label>
        <input formControlName="title" type="title" name="title"
          placeholder="Title of content / post you are adding...." />
        <p>
          <span *ngIf="submitted && searchPages.controls['title'].errors?.minlength"> *Minimum 1 character
          </span>
        </p>
      </div>
      <div class="form-input">
        <label for="description">Description </label>
        <textarea formControlName="description" type="description" name="description"
          placeholder="description of content...."></textarea>
        <p>
          <span *ngIf="submitted && searchPages.controls['description'].errors?.minlength"> *Minimum 1 character
          </span>
        </p>
      </div>
    </div>

    <div [ngStyle]="{ 'display' : searchPages.value.type_of_content == '2' ? 'block' : 'none' }">
      <div class="form-input">
        <label for="is_visible_on_page_tabs"> Seperate Page or not</label>
        <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();"
          name="is_visible_on_page_tabs" formControlName="is_visible_on_page_tabs">
          <option value="1">Yes </option>
          <option value="0"> No (Some internal page) </option>
        </select>
      </div>

      <div class="form-input">
        <label for="url_key">Page - Url / Content - Key </label>
        <input formControlName="url_key" type="url_key" name="url_key" placeholder="For eg:our-clients" />
        <p>
          <span *ngIf="submitted && searchPages.controls['url_key'].errors?.minlength"> *Minimum 1 character
          </span>
        </p>
      </div>

    </div>

    <div [ngStyle]="{ 'display' : searchPages.value.type_of_content == '3' ? 'block' : 'none' }" class="form-input">
      <label for="project_id">Project</label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" placeholder="Select Project.."
        formControlName="project_id" name="project_id" id="project_id">
        <option value="-1">Select project</option>
        <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;" [value]="project.id">
          {{ project['name'] + ',' + project['main_town'] }}
        </option>
      </select>
    </div>

    <div class="form-input">
      <label for="is_active"> Content Status </label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" name="is_active"
        formControlName="is_active">
        <option value="-1">Live & Draft</option>
        <option value="1">Live (Visible to public)</option>
        <option value="2">Draft (Not visible to public)</option>
      </select>
    </div>

    <div class="form-input">
      <label for="content_order"> Content Visibility </label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" name="content_order"
        formControlName="content_order">
        <option value="-1"> All </option>
        <option value="1"> Only Rich Text Content </option>
        <option value="2"> Only Custom Html </option>
        <option value="3"> First Rich Text then Custom Html </option>
        <option value="4"> First Custom Html then Rich Text </option>
      </select>
    </div>

    <div class="form-input">
      <label for="sortBy"> Sort By </label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" name="sortBy"
        formControlName="sortBy">
        <option value="added_on">Added On</option>
        <option value="updated_on">Updated On</option>
        <option value="title">Title</option>
        <option value="description">Description</option>
        <option value="sort_order">Sort Order</option>
        <option value="url_key">Url / Key</option>
      </select>
    </div>

    <div class="form-input">
      <label for="sortOrder"> Sort Order </label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" name="sortOrder"
        formControlName="sortOrder">
        <option value="ASC">Ascending</option>
        <option value="DESC">Descending</option>
      </select>
    </div>

    <div class="form-input">
      <label>Results per page</label>
      <select data-page-number="1" (change)="updatePageNumber($event); onFilterPages();" placeholder="Select option.."
        formControlName="pagination" name="pagination">
        <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
          {{ l }}
        </option>
      </select>
    </div>

    <div class="form-input">
      <div>
        <button type="submit" (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>
      </div>
    </div>

  </form>
</main>

<section *ngIf="totalResults > 0" class="search-custom-pages" style="
  margin: 0 auto 0 auto;
  width: 98%;
">

  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr>
          <th style="text-align: right !important;" colspan="8">
            <a class="grad1" [routerLink]="['/add-custom-page']" [queryParams]="{ type_of_content : '1' }" style="color: white;"><span><i  aria-hidden="true" class="fa fa-plus"></i>Add Blog Item </span></a>
            <a class="grad1" [routerLink]="['/add-custom-page']" [queryParams]="{ type_of_content : '2' }" style="color: white;"><span><i  aria-hidden="true" class="fa fa-plus"></i>Add New Page / Internal Page </span></a>
            <a class="grad1" [routerLink]="['/add-custom-page']" [queryParams]="{ type_of_content : '3' }" style="color: white;"><span><i  aria-hidden="true" class="fa fa-plus"></i>Add Project Content </span></a>
          </th>
        </tr>
        <tr>
          <th></th>
          <th>Content type</th>
          <th>Title</th>
          <th>Status</th>
          <th>Page - Url / Content - Key</th>
          <th>Project</th>
          <th>Added on</th>
          <th>Last Updated </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let page of pages">
          <td>
            <a [routerLink]="['/edit-custom-page', page['id'] ]">
              <span> <i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>
          <td>{{ page['type_of_content_name'] }}</td>
          <td>{{ page['title'] ? page['title'] : '' }}</td>
          <td>{{ page['is_active'] == '1' ? 'Live' : 'Draft' }}</td>
          <td>{{ page['url_key'] ? page['url_key'] : '' }}</td>
          <td>{{ page['project']['id'] ?  page['project']['name'] : '' }}</td>
          <td>{{ page['added_on'] | momentDateTransformPipe : (' DD-MM-YYYY ')  }} </td>
          <td>{{ page['updated_on'] | momentDateDiffPipe  }} </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination">
    <div *ngIf="totalResults < 1">
      <p> No results found </p>
    </div>
    <div *ngIf="totalResults > 0">
      <p>Total results : {{ totalResults }} </p>
      <div>
        <a *ngFor="let paginationLink of paginationLinks "
          [ngClass]=" { 'current' : paginationLink == searchPages.value.page }" [routerLink]="['./']" [queryParams]="{
          is_active :  searchPages.value.is_active,
          type_of_content :  searchPages.value.type_of_content,
          title :  searchPages.value.title,
          description :  searchPages.value.description,
          content_order :  searchPages.value.content_order,
          is_visible_on_page_tabs :  searchPages.value.is_visible_on_page_tabs,
          url_key :  searchPages.value.url_key,
          project_id :  searchPages.value.project_id,
          sortBy :  searchPages.value.sortBy,
          sortOrder :  searchPages.value.sortOrder,
          pagination : searchPages.value.pagination,
          page : paginationLink == '...' ? '1' : paginationLink
      }">
          {{ paginationLink }} </a>
      </div>
    </div>
  </div>

</section>
