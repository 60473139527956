import { RemaindersService } from './../remainders.service';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';

@Component({
  selector: 'app-search-remainders',
  templateUrl: './search-remainders.component.html',
  styleUrls: ['./search-remainders.component.css']
})
export class SearchRemaindersComponent implements OnInit {

  Object = Object;

  searchRemainders: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  remainders: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  @Input() pageIsEmbedded: boolean = false;

  isFormVisible : boolean = false;

  viewType : string = 'listView';

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private remaindersService : RemaindersService
  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Remainders | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    let formBuilderConfig = {
      projectId: [""],
      bookingId: ["", [ Validators.minLength(1) ]],
      remainderId: ["", [ Validators.minLength(1) ]],
      userId: ["", [ Validators.minLength(1) ]],
      additionalNotes: ["", [Validators.minLength(1), Validators.maxLength(512)]],
      minimumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      maximumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      page: ["1", []],
      pagination: [ 10 , [Validators.required]],
      sortBy: [ "remainder_date", [Validators.required]],
      sortOrder: [ "DESC", [Validators.required]],
    };

    this.searchRemainders = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();
    this.onFilterRemainders();


  }

  checkForQueryParams() : void{
    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("projectId" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          projectId: queryParams.projectId
        });
      }

      if ("bookingId" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          bookingId: queryParams.bookingId
        });
      }

      if ("remainderId" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          remainderId: queryParams.remainderId
        });
      }

      if ("userId" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          userId: queryParams.userId
        });
      }

      if ("additionalNotes" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          additionalNotes: queryParams.additionalNotes
        });
      }


      if ("minimumDate" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          minimumDate: queryParams.minimumDate
        });
      }

      if ("maximumDate" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          maximumDate: queryParams.maximumDate
        });
      }

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          page: queryParams.page
        });
      }

      if ("pagination" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          pagination: queryParams.pagination
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          sortBy: queryParams.sortBy
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchRemainders.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }

      if ("viewType" in queryParams && ["listView","weeklyView","reportView"].includes(queryParams.viewType) ) {
        hasQueryParams = true;
        this.viewType = queryParams.viewType;
      }

      if(hasQueryParams){
        this.onFilterRemainders();
      }
    });

  }

  onFilterRemainders() : any{
    this.submitted = true;
    if (this.searchRemainders.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Filtering remainders...");
    this.remaindersService.getRemainders(this.searchRemainders.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.remainders = response.data;
      this.paginationLinks = response.pagination_data;
      setTimeout(() => {
        if( document.querySelector("section.fullWidthTable")){
          document.querySelector("section.fullWidthTable").scrollIntoView();
        }
      }, 1000 );


      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    })
  }

  setViewVisibility(type) : void{
    this.viewType = type;
  }

  updatePageNumber(e) : void{
    // console.log(e.target);
    this.searchRemainders.patchValue({
      page : e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

}
