

import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectImagesService {


  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }


  addProjectImage(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/add-project-image' , body);
  }

  updateProjectImage(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-project-image' , body);
  }

  deleteProjectImage( attachmentId, projectId ) : any{
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId );
    body = body.set('projectId', projectId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-project-image', {
      params: body
    });
  }

}
