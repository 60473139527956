import { PropertyTypesService } from './../property-types.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-property-types',
  templateUrl: './search-property-types.component.html',
  styleUrls: ['./search-property-types.component.css']
})
export class SearchPropertyTypesComponent implements OnInit {

  slides: Observable<any>;

  Object = Object;

  totalResults: number = 0;

  projectImageFormVisible = false;
  projectImageFormData: null;
  projectImageFormIsCreate: boolean = false;

  constructor(
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    public homePageSlidesService: PropertyTypesService,
  ) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Manage Property types | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);
    this.onFilterSlides();
  }


  onFilterSlides(): void {
    this.notificationsService.toggleLoading(true, "Fetching property types...");
    this.homePageSlidesService.getPropertTypes().subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        return;
      }

      this.totalResults = response.total_results;
      this.slides = response.data;
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

  updateProjectPage(latestFetchedProjectInfo): void {
    this.slides = latestFetchedProjectInfo;
    this.projectImageFormVisible = false;
  }

  onAddEditProjectImage(e): void {


    let a = e.target.closest("a");
    this.projectImageFormIsCreate = a.getAttribute("data-attachment-is-create") == 'true' ? true : false;

    // console.log(a.getAttribute("data-attachment-is-create"));

    this.projectImageFormData = this.projectImageFormIsCreate ? {} : JSON.parse(a.getAttribute("data-attachment-data"));
    this.projectImageFormVisible = true;

  }

}
