import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-seperate-page',
  templateUrl: './seperate-page.component.html',
  styleUrls: ['./seperate-page.component.css']
})
export class SeperatePageComponent implements OnInit {

  urlKey: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,

  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.urlKey = params["urlKey"];
    });
  }

}
