<main>
  <section class="responsiveContainer">
    <form id="myProfileForm" [formGroup]="myProfileForm" (ngSubmit)="onProfileEdit()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <span (click)="toggleFormLayout()" data-form-id="a1" class="toggleFormLayout"> <i class="fa fa-arrows"
            aria-hidden="true"></i> CHANGE VIEW</span>
        <div class="display-edit">
          <a routerLink="/user-accounts"> ALL USERS </a>
        </div>
        <h2 *ngIf="!isCreate" class="gradientColor"><span>{{ 'EDIT USER ACCOUNT' }}</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Name</label>
              <input formControlName="firstName" type="text" name="firstName" placeholder="name">
              <p class="errors">

                <span *ngIf="fetchedProfile && myProfileForm.controls['firstName'].errors?.required"> *required</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['firstName'].errors?.minlength"> *Minimum 2
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['firstName'].errors?.maxlength"> *Maximum 200
                  characters </span>

              </p>
            </div>
          </div>
          <!-- <div class="form-field">
            <div class="input-container with-label">
              <label>Middle name</label>
              <input formControlName="middleName" type="text" name="middleName" placeholder="Middle name">
              <p class="errors">

                <span *ngIf="fetchedProfile && myProfileForm.controls['middleName'].errors?.minlength"> *Minimum 2
                  characters </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['middleName'].errors?.maxlength"> *Maximum 30
                  characters </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['middleName'].errors?.invalidMsg"> *Only Alphabets
                  with no spaces are allowed </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Last name</label>
              <input formControlName="lastName" type="text" name="lastName" placeholder="Last name">

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.required"> *required</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.minlength"> *Minimum 2
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.maxlength"> *Maximum 30
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.invalidMsg"> *Only Alphabets
                  with
                  no spaces are allowed </span>
              </p>
            </div>
          </div> -->
          <div class="form-field">
            <div class="input-container with-select">
              <label for="gender">Gender</label>

              <select placeholder="Gender.." formControlName="gender" name="gender" id="gender">
                <option [value]="0">Female
                </option>
                <option [value]="1">Male
                </option>
                <option [value]="-1">Prefer not to answer
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label for="broker">Is Agent ?</label>

              <select placeholder="Agent.." formControlName="broker" name="broker" id="broker">
                <option [value]="0">No
                </option>
                <option [value]="1">Yes
                </option>
                <option [value]="-1">Select Option
                </option>
              </select>
            </div>
          </div>

          <ng-container *ngIf="dataHolderService.userInfo.is_super_admin == 'true'">
            <div class="form-field">
              <div class="input-container with-select">
                <label for="partner">Is Partner ?</label>

                <select placeholder="partner.." formControlName="partner" name="partner" id="partner">
                  <option [value]="0">No
                  </option>
                  <option [value]="1">Yes
                  </option>
                  <option [value]="-1">Select Option
                  </option>
                </select>

                <ul>
                  <li>Partner accounts will have login access to the application</li>
                </ul>
              </div>
            </div>

            <div class="form-field">
              <div class="input-container with-icon">
                <label for="password">Account Password</label>
                <ul class="inline">
                  <li>If you want to add/update password of this account, fill this field. Otherwise leave it blank</li>
                </ul>
                <div class="icon-container">
                  <div (click)="passwordFieldHidden = !passwordFieldHidden">
                    <i style="color: black;" class="fa"
                      [ngClass]=" { 'fa-eye' : passwordFieldHidden , 'fa-eye-slash' : !passwordFieldHidden }"
                      aria-hidden="true"></i>
                  </div>
                  <div>
                    <input formControlName="password" id="password" [type]="passwordFieldHidden ? 'password' : 'text'"
                      name="password" name="password" placeholder="Account password...">

                  </div>
                </div>
                <p class="errors">
                  <span *ngIf="fetchedProfile && myProfileForm.controls['password'].errors?.minlength"> *Minimum 8
                    characters</span>
                  <span *ngIf="fetchedProfile && myProfileForm.controls['password'].errors?.maxlength"> *Minimum 128
                    characters</span>

                </p>

              </div>
            </div>

          </ng-container>



        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Email</label>
              <input type="email" formControlName="email" name="email">

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['email'].errors?.email"> *Invalid email </span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>WhatsApp</label>
              <input formControlName="whatsAppNumber" type="text" name="whatsAppNumber" placeholder="+91xxxxxxxxxx">

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['whatsAppNumber'].errors?.minlength"> *Minimum 10
                  digits </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['whatsAppNumber'].errors?.maxlength"> *Maximum 20
                  digits </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['whatsAppNumber'].errors?.invalidMsg"> *Mobile
                  number
                  can contain only digits and an optional + prefix </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Mobile No.</label>
              <input formControlName="mobileNumber" type="text" name="mobileNumber" placeholder="+91xxxxxxxxxx">

              <p class="errors">

                <span *ngIf="fetchedProfile && myProfileForm.controls['mobileNumber'].errors?.minlength"> *Minimum 10
                  digits
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['mobileNumber'].errors?.maxlength"> *Maximum 20
                  digits
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['mobileNumber'].errors?.invalidMsg"> *Mobile
                  number
                  can contain only digits and an optional + prefix </span>
              </p>
            </div>
          </div>
          <div *ngIf="!isCreate" class="form-field">
            <div class="input-container with-label">
              <label>USER ID</label>
              <input readonly style="display: none !important;" formControlName="userId" type="text" name="userId"
                placeholder="">
              <input readonly type="text" placeholder=""
                [value]="dataHolderService.projectMetaDeta.USER_ID_PREFIX + myProfileService.fetchedUserInfo.id">

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['userId'].errors?.required"> *required</span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">
          <div class="form-field">
            <div class="input-container with-label">
              <label>About</label>
              <textarea formControlName="about" type="text" name="about"
                placeholder="Anything you want to add.."></textarea>

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['about'].errors?.minlength"> *Minimum 2 characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['about'].errors?.maxlength"> *Maximum 256
                  characters
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Address</label>
              <textarea formControlName="address" type="text" name="address"
                placeholder="House number, town , city etc"></textarea>
              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['address'].errors?.minlength"> *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['address'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-file-upload">
              <label>Profile picture</label>
              <div class="existingProfile twoColumnsGrid">
                <div>
                  <div *ngIf="hasProfilePicture">
                    <a target="_blank" href="{{ profilePictureUrl }}">
                      <img src="{{ profilePictureUrl }}">
                    </a>
                    <button (click)="deletePicture()" type="button"> <i class="fa fa-times" aria-hidden="true"></i>
                      DELETE
                      PICTURE</button>
                  </div>
                </div>
                <div class="flexCenter">

                </div>
              </div>

              <div>
                <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                  (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label" for="profilePicture">
                  <span>Select new profile picture or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your
                    picture here</span>
                  <input accept="image/*" (change)="onFilesChanged($event)" formControlName="profilePicture"
                    name="profilePicture[]" type="file" id="profilePicture">
                </label>
              </div>
              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.extension"> *Only png,
                  jpg/jpeg, bmp, gif files are accepted</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.fileSize"> *Minimum file
                  size is 10KB and maximum file size is 10000KB</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.file"> *Only 1 file can
                  be
                  uploaded</span>
                <!-- <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.image?.message"> *Upload a square shaped image(preferably) with at least 150px width and 150px height</span> -->
              </p>
              <div *ngIf="hasPreview" class="existingProfile twoColumnsGrid">
                <div>
                  <div *ngIf="hasPreview">
                    <a download target="_blank" href="{{ imageUri }}">
                      <img src="{{ imageUri }}">
                    </a>
                    <button (click)="cancelProfilePicture()"> <i class="fa fa-times" aria-hidden="true"></i> REMOVE
                      PICTURE</button>
                  </div>
                </div>
                <div class="flexCenter">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <ng-container *ngIf="isCreate">
          <button [disabled]="disabled" class="" type="submit">SAVE PROFILE</button>
        </ng-container>

        <ng-container *ngIf="!isCreate && myProfileService.fetchedUserInfo.is_visible == '1'">
          <button *ngIf="myProfileService.fetchedUserInfo.canEdit" [disabled]="disabled" class="" type="submit">SAVE
            PROFILE</button>

            <button (click)="deleteAccount();" *ngIf="myProfileService.fetchedUserInfo.canEdit" [disabled]="disabled" class="" type="button">DELETE ACCOUNT</button>

          <p *ngIf="!myProfileService.fetchedUserInfo.canEdit">You don't have permissions to edit this account</p>


        </ng-container>

        <p *ngIf="!isCreate && myProfileService.fetchedUserInfo.is_visible != '1'">This account has been removed and it is in archive mode
        </p>

      </div>
    </form>
  </section>
</main>
