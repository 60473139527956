import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private http: HttpClient,
    private dataHolderService: DataHolderService
  ) {

  }

  load(): Promise<any> {
    return this.http.get(this.dataHolderService.serverBaseUrl + 'assets/site-assets/meta-data/projectMetaData.json')
      .toPromise()
      .then(data => this.dataHolderService.projectMetaDeta = data);
  }
}
