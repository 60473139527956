import { ProfilesService } from './../profiles.service';

import { Component, OnInit } from '@angular/core';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';

@Component({
  selector: 'app-header-side-nav',
  templateUrl: './header-side-nav.component.html',
  styleUrls: ['./header-side-nav.component.css']
})
export class HeaderSideNavComponent implements OnInit {

  projectListExpanded : boolean = true;

  constructor(
    public notificationsService: NotificationsService,
    public dataHolder: DataHolderService,
    public profilesService: ProfilesService
  ) { }

  ngOnInit(): void {


  }

}
