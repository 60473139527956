<main>
  <section class="responsiveContainer">

    <form
    id="plotForm"  [formGroup]="plotForm" (ngSubmit)="onPlot()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <span (click)="toggleFormLayout()" data-form-id="a1" class="toggleFormLayout"> <i class="fa fa-arrows"
            aria-hidden="true"></i> CHANGE VIEW</span>

        <h2  class="gradientColor"><span>{{ isCreate ?  'ADD PLOT' : 'EDIT PLOT' }}</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-select">
              <label for="projectId">Project</label>
              <input type="hidden" name="projectId" formControlName="projectId">
              <select readonly data-page-number="1"
                placeholder="Select Project.." formControlName="projectId" name="projectId" id="projectId">
                <option value="-1">Select project(*required)</option>
                <option *ngFor="let project of dataHolderService.projectMetaDeta.PROJECTS; let i=index;"
                  [value]="project.id" >{{ project['name'] + ',' + project['main_town'] }}
                </option>
              </select>

              <p class="errors">
                <span *ngIf="submitted &&  plotForm.controls['projectId'].errors?.required"> *required</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Plot No.</label>
              <input [readonly]="!isCreate" formControlName="plotNo" type="text" name="plotNo" placeholder="Plot Number">
              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['plotNo'].errors?.required"> *required </span>
                <span *ngIf="submitted && plotForm.controls['plotNo'].errors?.minlength"> *Minimum 1
                  character </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Area</label>
              <input step="any" formControlName="area" type="number" name="area" placeholder="Area">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['area'].errors?.numeric"> *Enter valid numeric value</span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Additional Notes</label>
              <textarea formControlName="additionalNotes" type="text" name="additionalNotes" placeholder="Additional Notes"></textarea>

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['additionalNotes'].errors?.minLength"> *Minimum 1 character</span>
                <span *ngIf="submitted && plotForm.controls['additionalNotes'].errors?.maxLength"> *Minimum 500 characters</span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Gmaps Iframe</label>
              <textarea formControlName="gmaps_iframe" type="text" name="gmaps_iframe" placeholder="Google Maps Iframe"></textarea>

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['gmaps_iframe'].errors?.minLength"> *Minimum 1 character</span>
                <span *ngIf="submitted && plotForm.controls['gmaps_iframe'].errors?.maxLength"> *Minimum 2000 characters</span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Gmaps Link</label>
              <textarea formControlName="gmaps_link" type="text" name="gmaps_link" placeholder="Google Maps link"></textarea>

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['gmaps_link'].errors?.minLength"> *Minimum 1 character</span>
                <span *ngIf="submitted && plotForm.controls['gmaps_link'].errors?.maxLength"> *Minimum 2000 characters</span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Geo fence</label>
              <textarea formControlName="geo_fence" type="text" name="geo_fence" placeholder="Geo Fence Text"></textarea>

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['geo_fence'].errors?.minLength"> *Minimum 1 character</span>
                <span *ngIf="submitted && plotForm.controls['geo_fence'].errors?.maxLength"> *Minimum 2000 characters</span>
              </p>
            </div>
          </div>


        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-select">
              <label for="isNorth">North Facing</label>

              <select  formControlName="isNorth" name="isNorth"
              id="isNorth">
              <option value="0">No
              </option>
              <option  value="1">Yes
              </option>

            </select>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label for="isSouth">South Facing</label>

              <select  formControlName="isSouth" name="isSouth"
              id="isSouth">
              <option value="0">No
              </option>
              <option  value="1">Yes
              </option>

            </select>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label for="isWest">West Facing</label>

              <select  formControlName="isWest" name="isWest"
              id="isWest">
              <option value="0">No
              </option>
              <option  value="1">Yes
              </option>

            </select>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label for="isEast">East Facing</label>

              <select  formControlName="isEast" name="isEast"
              id="isEast">
              <option value="0">No
              </option>
              <option  value="1">Yes
              </option>

            </select>
            </div>
          </div>


          <div class="form-field">
            <div class="input-container with-label">
              <label>Latitude </label>
              <input step="any" formControlName="lattitude" type="number" name="lattitude" placeholder="Latitude">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['lattitude'].errors?.numeric"> *Enter valid numeric value</span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Longitude </label>
              <input step="any" formControlName="longitude" type="number" name="longitude" placeholder="Longitude">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['longitude'].errors?.numeric"> *Enter valid numeric value</span>

              </p>
            </div>
          </div>

        </div>
        <div class="form-right">

          <div class="form-field">
            <div class="input-container with-label">
              <label>North Dimensions</label>
              <input formControlName="northDimensions" type="text" name="northDimensions" placeholder="North Dimensions">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['northDimensions'].errors?.minlength"> *Minimum 1 character
                </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>South Dimensions</label>
              <input formControlName="southDimensions" type="text" name="southDimensions" placeholder="South Dimensions">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['southDimensions'].errors?.minlength"> *Minimum 1 character
                </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>West Dimensions</label>
              <input formControlName="westDimensions" type="text" name="westDimensions" placeholder="West Dimensions">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['westDimensions'].errors?.minlength"> *Minimum 1 character
                </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>East Dimensions</label>
              <input formControlName="eastDimensions" type="text" name="eastDimensions" placeholder="East Dimensions">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['eastDimensions'].errors?.minlength"> *Minimum 1 character
                </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Price </label>
              <input step="any" formControlName="min_price" type="number" name="min_price" placeholder="Minimum Price..">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['min_price'].errors?.numeric"> *Enter valid numeric value</span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Price </label>
              <input step="any" formControlName="max_price" type="number" name="max_price" placeholder="Maximum Price..">

              <p class="errors">
                <span *ngIf="submitted && plotForm.controls['max_price'].errors?.numeric"> *Enter valid numeric value</span>

              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="form-button">
          <button [disabled]="disabled"  class="" type="submit">SUBMIT</button>

          <button *ngIf="!isCreate" (click)="deletePlot();" type="button">DELETE</button>
          <p *ngIf="!isCreate"  style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> If it is a booked plot, plot will be removed from the booking </p>

      </div>
    </form>
  </section>
</main>
