<main>
  <section class="responsiveContainer">
    <form [id]="isCreate ? 'addAttachment' : attachmentForm.value.attachmentId " [formGroup]="attachmentForm"
      (ngSubmit)="onAttachment($event)" #form="ngForm" class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>{{ isCreate ? 'ADD ATTACHMENT' : attachmentForm.value.attachmentId  }}</span>
        </h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>BOOKING ID</label>
              <input readonly name="bookingId" formControlName="bookingId" type="text" readonly [value]="bookingId">
            </div>
          </div>
        </div>
        <div class="form-middle">
          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <label>Attachment ID</label>
                <input readonly name="attachmentId" formControlName="attachmentId" type="text" readonly>
              </div>
            </div>

          </ng-container>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Attachment Name</label>
              <textarea formControlName="attachmentName" type="text" name="attachmentName"
                placeholder="For eg: Aaadhar"></textarea>
              <p class="errors">
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachmentName'].errors?.minlength">
                  *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachmentName'].errors?.maxlength">
                  *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Attachment Description</label>
              <textarea formControlName="attachmentDescription" type="text" name="attachmentDescription"
                placeholder="For eg: 886974121234"></textarea>
              <p class="errors">
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachmentDescription'].errors?.minlength">
                  *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachmentDescription'].errors?.maxlength">
                  *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">
          <div class="form-field">

            <div class="input-container with-file-upload">
              <label>Upload Attachment</label>
              <div>
                <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                  (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label" for="{{ 'X' +  ( isCreate ? dataHolderService.projectMetaDeta.ATTACHMENT_ID_PREFIX : attachmentData.attachmentId ) +  'Y' }}">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept="image/*,.pdf" type="file" (change)="onFilesChanged($event)"
                    formControlName="attachment" name="attachment[]" type="file" id="{{ 'X' +  ( isCreate ? dataHolderService.projectMetaDeta.ATTACHMENT_ID_PREFIX : attachmentData.attachmentId  ) +  'Y' }}">
                </label>
              </div>
              <p class="errors">
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachment'].errors?.extension"> *Only
                  jpeg,bmp,png,jpg,gif,svg,webm,pdf files are accepted</span>
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachment'].errors?.fileSize"> *Minimum file
                  size is 10KB
                  and maximum file size is 10000KB</span>
                <span *ngIf="fetchedAttachment && attachmentForm.controls['attachment'].errors?.file"> *Only 1 file can
                  be
                  uploaded</span>
                <!-- <span *ngIf="fetchedAttachment && attachmentForm.controls['banner'].errors?.image?.message"> *Upload a square shaped image(preferably) with at least 150px width and 150px height</span> -->
              </p>
              <div *ngIf="localFileName">
                <ul class="inline">
                 <li> <a class="textualLink">{{  localFileName }} </a> </li>
                 </ul>
                <button type="button" class="submit" (click)="cancelfile($event)"> <i class="fa fa-times" aria-hidden="true"></i> REMOVE</button>
              </div>

            </div>
          </div>
          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <ul>
                  <li>Attachment last updated : </li>
                  <li>
                    {{ attachmentData.updated_on | momentDateDiffPipe }}
                  </li>
                  <li>( {{ attachmentData.updated_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>
                  <br>
                  <li>Download </li>
                  <li>
                    <a class="submit" href="{{ dataHolderService.apiBaseUrl + '/download-attachment?bookingId=' + attachmentData.booking_id + '&attachmentId=' + attachmentData.attachmentId  }}">  {{ attachmentData.client_side_attachment_name }} </a>

                  </li>

                </ul>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <button (click)="deleteAttachment();" *ngIf="!isCreate" type="button">DELETE</button>
        <p style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> Booking data will be
          refreshed, if you make changes to attachments </p>

      </div>
    </form>
  </section>
</main>
