<section *ngIf="totalResults > 0" class="custom-page-container">

  <ng-container  *ngIf="this.fetchConfig.type_of_content == '1'">
    <section class="blog-item">
      <h1>{{ pages[0].title  }}</h1>

      <div>
        <img src="{{ pages[0].image_url }}" alt="{{ pages[0].title + ',' + dataHolderService.projectMetaDeta.default_title }}" />
      </div>
      <div>
        <p>
          {{ pages[0].description  }}
        </p>
      </div>
    </section>
  </ng-container>
  <ng-container *ngFor="let page of pages">
    <div class="custom-page-content" [innerHTML]="getContent(page) | sanitizeHtml">

    </div>
  </ng-container>
</section>

<ng-container *ngIf="!loading && seperatePage && totalResults < 1">
  <app-not-found-component></app-not-found-component>
</ng-container>
