import { CustomPagesService } from './../custom-pages.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';

@Component({
  selector: 'app-render-custom-page',
  templateUrl: './render-custom-page.component.html',
  styleUrls: ['./render-custom-page.component.css']
})
export class RenderCustomPageComponent implements OnInit {

  TYPE_OF_CONTENTS = {
    BLOG: '1',
    PAGE_CONTENT: '2',
    PROJECT_CONTENT: '3'
  };

  content_order = {
    'Only Rich Text Content': 1,
    'Only Custom Html': 2,
    'First Rich Text then Custom Html': 3,
    'First Custom Html then Rich Text ': 4
  };

  Object = Object;

  pages: Observable<any>;

  totalResults: number = 0;



  @Input() fetchConfig: any;

  @Input() seperatePage: boolean | string = false;

  loading = true;


  // @Input() type_of_content : string = '';

  // @Input() url_key : string = '';

  // @Input() id : string = '';

  // @Input() project_id : string = '';


  constructor(
    private customPagesService: CustomPagesService,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,

  ) { }

  ngOnInit(): void {
    this.onFilterPages();
  }

  onFilterPages(): void {
    this.customPagesService.searchPages(this.fetchConfig).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);
      this.loading = false;

      if (!response.status) {
        // this.notificationsService.toggleFailed(true, response.message);
        return;
      }

      this.totalResults = response.total_results;
      this.pages = response.data;

      if (this.seperatePage && this.pages[0].title && this.pages[0].description) {
        this.dataHolderService.updatePageMeta(`${this.pages[0].title} - ${this.dataHolderService.projectMetaDeta.default_title}`, `${this.pages[0].description}`);
      }
      // this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

  getContent(page): any {

    // console.log(page);
    let html = page.custom_html && page.custom_html != 'null' &&
      page.custom_html != 'NULL' && page.custom_html.trim().length > 0 ? page.custom_html : '';
    let rich_text =
      page.rich_text_content && page.rich_text_content != 'null' &&
        page.rich_text_content != 'NULL' && page.rich_text_content.trim().length > 0 ? `<div class="rich-text-data-view">${page.rich_text_content}</div>` : '';

    let content = '';
    switch (parseInt(page.content_order)) {
      case 1: {
        content = rich_text;
        break;
      }
      case 2: {
        content = html;
        break;
      }
      case 3: {
        content = rich_text + html;
        break;
      }
      case 4: {
        content = html + rich_text;
        break;
      }
    }
    return content;
  }

}
