<div class="topfixed loader grad2"  [ngClass]="{ 'active' : notificationsService.loading }">
  <p> <i class="fa fa-cog fa-spin fa-3x fa-fw"></i> {{ notificationsService.loadingMessage }}</p>
</div>
<div (click)="notificationsService.toggleSucceeded(false)" class="topfixed succeeded grad3" [ngClass]="{ 'active' : notificationsService.succeeded }">
  <p> <i class="fa fa-check" aria-hidden="true"></i> {{ notificationsService.succeededMessage }} </p>
</div>
<div (click)="notificationsService.toggleFailed(false)" class="topfixed failed grad4" [ngClass]="{ 'active' : notificationsService.failed }">
  <p> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ notificationsService.failedMessage }} </p>
</div>
<div (click)="notificationsService.toggleInfo(false)" class="topfixed info grad1" [ngClass]="{ 'active' : notificationsService.infoDisplayed }">
<p><i class="fa fa-info-circle" aria-hidden="true"></i> {{ notificationsService.infoMessage }}  </p>
</div>

