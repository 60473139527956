<div class="fullWidthSection messages">

</div>
<h3 style="text-align: center; font-size: 2em;margin:0" class="gradientColor">MESSAGES RECEIVED </h3>
<section class="responsiveContainer">
  <p class="noselect" (click)="isFormVisible = !isFormVisible"
    style="cursor: pointer; color: black; text-align: right; font-weight: bolder;">
    <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS</span>
  </p>
</section>

<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchMessages" (ngSubmit)="onFilterMessages()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Name</label>
              <input formControlName="name" type="text" name="name" placeholder="Name">
              <p class="errors">

                <span *ngIf="submitted && searchMessages.controls['name'].errors?.minlength"> *Minimum 1 characters
                </span>
                <span *ngIf="submitted && searchMessages.controls['name'].errors?.maxlength"> *Maximum 100
                  characters </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Email</label>
              <input type="email" formControlName="email" name="email" placeholder="user@yours.com">
              <p class="errors">
                <span *ngIf="submitted && searchMessages.controls['email'].errors?.email"> *Invalid email </span>
              </p>
            </div>
          </div>

        </div>

        <div class="form-middle">



          <div class="form-field">
            <div class="input-container with-label">
              <label>Mobile No.</label>
              <input formControlName="mobile" type="text" name="mobile" placeholder="709xxxx9100">

              <p class="errors">
                <span *ngIf="submitted && searchMessages.controls['mobile'].errors?.minlength"> *Minimum 1
                  digit
                </span>
                <span *ngIf="submitted && searchMessages.controls['mobile'].errors?.maxlength"> *Maximum 20
                  digits
                </span>
                <span *ngIf="submitted && searchMessages.controls['mobile'].errors?.invalidMsg"> *Mobile
                  number
                  can contain only digits and an optional + prefix </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterMessages();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort order</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterMessages();"
                placeholder="Select option.." formControlName="sortOrder" name="sortOrder">
                <option value="ASC">Low to high</option>
                <option value="DESC">High to low</option>
              </select>
            </div>
          </div>


        </div>
        <div class="form-right">



          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Date </label>
              <input formControlName="minimumDate" type="date" name="minimumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchMessages.controls['minimumDate'].errors?.invalidMsg"> *Select a valid
                  date</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Date </label>
              <input formControlName="maximumDate" type="date" name="maximumDate" placeholder="Date">
              <p class="errors">
                <span *ngIf="submitted && searchMessages.controls['maximumDate'].errors?.invalidMsg"> *Select a valid
                  date</span>
              </p>
            </div>
          </div>


          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort by</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterMessages();"
                placeholder="Select option.." formControlName="sortBy" name="sortBy">
                <option value="contacted_on">Date</option>
                <option value="name">Name</option>
                <option value="email">Email</option>

              </select>
            </div>
          </div>

        </div>
      </div>
      <div class="form-button">
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>
      </div>
    </form>
  </section>
</main>

<section *ngIf="totalResults > 0" class="responsiveContainer">
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>DATE</th>
          <th>NAME</th>
          <th>EMAIL</th>
          <th>MOBILE</th>
          <th>MESSAGE</th>
        </tr>
        <tr *ngFor="let key of Object.keys(messages)">
          <td></td>
          <td>
            {{ messages[key].contacted_on | momentDateTransformPipe : ('DD-MM-YYYY') }}
            <br>
            (
            {{ messages[key].contacted_on | momentDateDiffPipe  }}

            )
          </td>
          <td>
            {{ messages[key].name  }}

          </td>
          <td>
            {{ messages[key]?.email ? messages[key]?.email : '-'  }}

          </td>
          <td>
            {{ messages[key]?.phone ? messages[key]?.phone : '-'  }}

          </td>
          <td>
            {{ messages[key]?.message ? messages[key]?.message : '-'  }}

          </td>
        </tr>

      </thead>
      <tbody>
      </tbody>
    </table>
  </div>

</section>
<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found, click on below link to view all users </p>
    <a [routerLink]="['/contact-us-messages-received']" [queryParams]="{ page : '1' }">All </a>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchMessages.value.page }"
        [routerLink]="['/contact-us-messages-received']" [queryParams]="{
        name : searchMessages.value.name,
        email : searchMessages.value.email,
        mobile : searchMessages.value.mobile,
        minimumDate : searchMessages.value.minimumDate,
        maximumDate : searchMessages.value.maximumDate,
        sortBy : searchMessages.value.sortBy,
        sortOrder : searchMessages.value.sortOrder,
        pagination : searchMessages.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink
      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
