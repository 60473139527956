<section class="home-page-slideshow-wrapper">
    <section class=" slideshow">
       <swiper [config]="config">
        <!-- <div class="swiper-container"> -->
            <!-- <div class="swiper-wrapper"> -->

                <div class="swiper-slide" *ngFor="let slide of dataHolder.projectMetaDeta.HOME_PAGE_SLIDESHOW">
                    <!-- <img alt="{{ slide['heading'] }}" src="{{  slide['image_url']  }}"> -->

                    <div class="bg-cont flexCenter"   [ngStyle]="{'background-image': 'url('+ slide['image_url'] +')'}"  >
                      <div class="slider-content">
                        <h3>{{ slide['heading'] }}</h3>
                        <div class="bar"></div>
                        <p>{{ slide['description'] }}</p>
                        <div class="links">
                          <a  [routerLink]="[ slide['links'][0] ]">{{ slide['linkText'][0] }}</a>
                          <a  [routerLink]="[ slide['links'][1] ]">{{ slide['linkText'][1] }}</a>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="twoColumnsGrid ">
                        <div class="flexCenter">
                            <div> -->

                            <!-- </div>
                        </div> -->
                    <!-- </div> -->
                </div>
            <!-- </div> -->
            <!-- Add Pagination -->
            <!-- <div class="swiper-pagination"></div> -->
        <!-- </div> -->
        </swiper>
    </section>
  </section>
