import { Component, OnInit } from '@angular/core';
import {ActivatedRoute , Params , Router } from '@angular/router';
import { HttpClient , HttpParams } from '@angular/common/http';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';

@Component({
  selector: 'app-login-activity',
  templateUrl: './login-activity.component.html',
  styleUrls: ['./login-activity.component.css']
})
export class LoginActivityComponent implements OnInit {

  verified : boolean = false;
  loading : boolean = true;
  message : string = 'Securing your account, Deleting logged in sessions... ';

  constructor(private route:ActivatedRoute ,  private httpClient: HttpClient , private router: Router , private dataHolderService : DataHolderService , public notificationsService: NotificationsService) { }


  ngOnInit(): void {


    this.dataHolderService.updatePageMeta('Login alert - ' + this.dataHolderService.projectMetaDeta.default_title);

    this.route.params.subscribe((params : Params)=>{

      let body = new HttpParams();
      body = body.set('token',  params["token"] );

      this.httpClient.post( this.dataHolderService.apiBaseUrl + '/login-alert', body ).subscribe((response : any)=>{
        this.loading = false;
        if(!response.status){
          this.message = response.message;
          return;
        }

        this.verified = true;
        this.message = response.message;

        setTimeout(()=>{
          this.router.navigate(['/change-password']);
        },5000);
      },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

    });

  }

}
