import { PlotsService } from './../plots.service';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './../notifications.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DataHolderService } from './../data-holder.service';
@Component({
  selector: 'app-mark-plot-facing',
  templateUrl: './mark-plot-facing.component.html',
  styleUrls: ['./mark-plot-facing.component.css']
})
export class MarkPlotFacingComponent implements OnInit {

  Object = Object;

  markPlots: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;


  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private plotsService : PlotsService
  ) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Bulk Plots Facing Editor | ${this.dataHolderService.projectMetaDeta.default_title}`);

    let formBuilderConfig = {
      projectId: ["-1", Validators.required],
      plots: ["", Validators.minLength(1)],
      facingDirection: ["is_north_facing", Validators.required],
      facingValue: ["1", Validators.required],
    };

    this.markPlots = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();

  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;


      if ("projectId" in queryParams) {
        hasQueryParams = true;
        this.markPlots.patchValue({
          projectId: queryParams.projectId
        });
      }

      if ("plots" in queryParams) {
        hasQueryParams = true;
        this.markPlots.patchValue({
          plots: queryParams.plots
        });
      }

      if ("facingDirection" in queryParams) {
        hasQueryParams = true;
        this.markPlots.patchValue({
          facingDirection: queryParams.facingDirection
        });
      }

      if ("facingValue" in queryParams) {
        hasQueryParams = true;
        this.markPlots.patchValue({
          facingValue: queryParams.facingValue
        });
      }


    });
  }

  onMarkPlots() : void{
    this.submitted = true;

    if (this.markPlots.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }

    if (this.markPlots.value.projectId == '-1') {
      this.notificationsService.toggleFailed(true, "Select Project");
      return;
    }
    this.notificationsService.toggleLoading(true, "Updating plots & generating layout...");
    this.plotsService.markPlots(this.markPlots.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    })
  }

}
