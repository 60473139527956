import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.css' , './../projects-list/projects-list.component.css']
})
export class ProjectStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
