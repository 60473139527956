import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from './../notifications.service';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.css']
})
export class CitiesComponent implements OnInit {

  @Input() pageIsEmbedded: boolean = false;

  constructor(
    public dataHolderService: DataHolderService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {

    if (!this.pageIsEmbedded) {
      this.dataHolderService.updatePageMeta(`Cities / Towns / Villages - Areas where we have our properties | ${this.dataHolderService.projectMetaDeta.default_title}`);

    }
  }

}
