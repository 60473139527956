<main>
  <section class="responsiveContainer">
    <form [id]="isCreate ? 'addProjectAttachment' : projectAttachmentsForm.value.attachmentId "
      [formGroup]="projectAttachmentsForm" (ngSubmit)="onAttachment($event)" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">

        <h2 class="gradientColor"><span>{{ isCreate ? 'ADD PROJECT ATTACHMENT' : attachmentData.attachment_id  }}</span>
        </h2>

        <input type="hidden" readonly name="projectId" formControlName="projectId" />

        <input type="hidden" readonly name="attachmentId" formControlName="attachmentId" />

      </div>
      <div class="form-body">
        <div class="form-left">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Sort order</label>
              <input name="sortOrder" formControlName="sortOrder" type="number" placeholder="Enter sort order">
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectAttachmentsForm.controls['sortOrder'].errors?.required">
                  *required</span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Description </label>
              <textarea formControlName="longDescription" type="text" name="longDescription"
                placeholder="Long Description"></textarea>

            </div>
          </div>

        </div>
        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Button Text</label>
              <input formControlName="shortDescription" type="text" name="shortDescription" placeholder="Download.." />
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectAttachmentsForm.controls['shortDescription'].errors?.required">
                  *required</span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-file-upload">
              <label> Thumbnail</label>
              <div>
                <label (drop)="onFilesDropImage($event)" (dragover)="preventDefaultOnDragImage($event)"
                  (dragenter)="preventDefaultOnDragImage($event)" class="drag-drop-container-label"
                  for="{{ 'XX' +  ( isCreate ? dataHolderService.projectMetaDeta.PROJECT_ATTACHMENT_ID_PREFIX : attachmentData.attachment_id ) +  'Y' }}">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept="image/*" type="file" (change)="onFilesChangedImage($event)"
                    formControlName="thumbnail_image" name="thumbnail_image[]" type="file"
                    id="{{ 'XX' +  ( isCreate ? dataHolderService.projectMetaDeta.PROJECT_ATTACHMENT_ID_PREFIX : attachmentData.attachment_id ) +  'Y' }}">
                </label>
              </div>
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectAttachmentsForm.controls['thumbnail_image'].errors?.extension">
                  *Only
                  jpeg,bmp,png,jpg,gif,svg files are accepted</span>
                <span *ngIf="fetchedAttachment && projectAttachmentsForm.controls['thumbnail_image'].errors?.file">
                  *Only 1 file can
                  be
                  uploaded</span>
              </p>

              <div *ngIf="!isCreate" class="existingProfile twoColumnsGrid">
                <div>
                  <a download target="_blank" href="{{ attachmentData.thumbnail_url }}">
                    <img src="{{ attachmentData.thumbnail_url }}">
                  </a>
                </div>
              </div>

              <div *ngIf="localFileNameImage">
                <ul class="inline">
                  <li> <a class="textualLink">{{  localFileNameImage }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfileImage($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>
            </div>
          </div>



        </div>

        <div class="form-right">



          <div class="form-field">
            <div class="input-container with-file-upload">
              <label> Attachment</label>
              <div>
                <label (drop)="onFilesDropBrochure($event)" (dragover)="preventDefaultOnDragBrochure($event)"
                  (dragenter)="preventDefaultOnDragBrochure($event)" class="drag-drop-container-label"
                  for="{{ 'X' +  ( isCreate ? dataHolderService.projectMetaDeta.PROJECT_ATTACHMENT_ID_PREFIX : attachmentData.attachment_id ) +  'YY' }}">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input type="file" (change)="onFilesChangedBrochure($event)" formControlName="attachment"
                    name="attachment[]" type="file"
                    id="{{ 'X' +  ( isCreate ? dataHolderService.projectMetaDeta.PROJECT_ATTACHMENT_ID_PREFIX : attachmentData.attachment_id ) +  'YY' }}">
                </label>
              </div>



              <div *ngIf="localFileNameBrochure">
                <ul class="inline">
                  <li> <a class="textualLink">{{ localFileNameBrochure }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfileBrochure($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>
            </div>
          </div>

          <div *ngIf="!isCreate" class="existingProfile twoColumnsGrid form-button">
            <div>
              <a download target="_blank" href="{{ attachmentData.url }}">
                <button>
                  Download Attachment
                </button>
              </a>
            </div>
          </div>

          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <ul>
                  <li>Attachment last updated : </li>
                  <li>
                    {{ attachmentData.updated_on | momentDateDiffPipe }}
                  </li>
                  <li>( {{ attachmentData.updated_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>

                  <li>Attachment added on : </li>

                  <li>( {{ attachmentData.added_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>

                </ul>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <button (click)="deleteProjectAttachment();" *ngIf="!isCreate" type="button">DELETE</button>
        <p style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> Project data will be
          refreshed, if you make changes to project attachments</p>

      </div>
    </form>
  </section>
</main>
