import { ProfilesService } from './../profiles.service';
import { BookingsServiceService } from './../bookings-service.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';



@Component({
  selector: 'app-add-or-edit-booking',
  templateUrl: './add-or-edit-booking.component.html',
  styleUrls: ['./add-or-edit-booking.component.css']
})
export class AddOrEditBookingComponent implements OnInit {

  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedBooking: boolean = false;

  bookingForm: FormGroup;

  disabled: boolean = false;

  verifiedUserIdData: any;

  verifiedBrokerIdData: any;

  bookedBy: any;

  bookingId: any = null;

  totalTransactions: number = 0;
  totalRemainders: number = 0;
  totalAttachments: number = 0;

  filteredUsers: any = null;
  filteredBrokers: any = null;

  filteredUsersActiveIndex: number = -1;
  filteredBrokersActiveIndex: number = -1;

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    public bookingsServiceService: BookingsServiceService,
    private router: Router,
    private profilesService: ProfilesService
  ) {

    document.addEventListener("click", (e) => {
      this.filteredUsers = null;
      this.filteredBrokers = null;
    });

  }

  ngOnInit(): void {

    this.bookingForm = this.formBuilder.group({
      price: ["", [
        Validators.required,
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      userId: ["", [Validators.required]],
      brokeredDeal: ["0"],
      brokerId: ["", []],
      brokerage: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      bookingId: ["1", Validators.required],
      projectId: ["", Validators.required],
      bookingDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      plots: ["", [Validators.required, Validators.minLength(1)]],
      additionalNotes: ["", [Validators.minLength(3), Validators.maxLength(512)]]
    });

    if (this.isCreate) {
      this.dataHolderService.updatePageMeta(`Plots Booking | ${this.dataHolderService.projectMetaDeta.default_title}`);

      this.route.params.subscribe((params: Params) => {
        this.bookingForm.controls.projectId.setValue(params["projectId"].split("-").pop());
      });

      this.route.queryParams.subscribe(queryParams => {
        if ("plots" in queryParams) {
          this.bookingForm.patchValue({
            plots: queryParams.plots
          });
        }
      });
    }
    else {
      this.route.params.subscribe((params: Params) => {
        this.bookingForm.controls.bookingId.setValue(params["bookingId"]);
      });
      this.dataHolderService.updatePageMeta(`${this.bookingForm.value.bookingId} | Manage Booking, Plots, Transactions, Remainders, Attachments  | ${this.dataHolderService.projectMetaDeta.default_title}`);
      this.updateForm();

    }

  }
  scroll(id) {
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }



  onBooking(): void {
    // this.fetchedBooking = true;
    // const formData = new FormData(<HTMLFormElement>document.getElementById('bookingForm'));

    const formData = this.bookingForm.value;

    if (this.bookingForm.value.brokeredDeal == "1" && this.bookingForm.value.brokerId.trim().length < 1) {
      this.fetchedBooking = true;
      this.notificationsService.toggleFailed(true, "Agent id can not be empty");
      return;
    }
    if (this.bookingForm.invalid) {
      this.fetchedBooking = true;
      this.notificationsService.toggleFailed(true, "Invalid data, make sure to follow guidelines before filling data");

      return;
    }
    // this.disabled = true;

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before booking");
      return;
    }

    if (this.isCreate) {
      this.notificationsService.toggleLoading(true, "Booking plots, please wait...");
      this.newBooking(formData);
      return;

    }
    else {
      this.notificationsService.toggleLoading(true, "Updating booking...");
      this.updateBooking(formData);
      return;
    }

  }

  newBooking(formData): void {
    this.bookingsServiceService.addBooking(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);

        if ("plot_availability_errors" in response) {
          this.notificationsService.toggleFailed(true, response.plot_availability_errors.message.join(','));

        }
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);

      // this.dataHolderService.userInfo = response["user"];


      setTimeout(() => {
        this.router.navigate(['/bookings', response.data['url']]);
      }, 500);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateBooking(formData): void {
    this.bookingsServiceService.updateBooking(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);

        if ("plot_availability_errors" in response) {
          this.notificationsService.toggleFailed(true, response.plot_availability_errors.message.join(','));

        }
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);


      this.onBookingDataChange(response["data"], response);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  deleteBooking(): void {
    if (!window.confirm("Plots, Transactions, Remainders, Attachments associated with this booking will be deleted. Do you want to proceed ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before deleting the booking");
      return;
    }
    this.notificationsService.toggleLoading(true, "Deleting Booking " + this.bookingForm.value.bookingId);

    this.bookingsServiceService.deleteBooking(this.bookingForm.value.projectId, this.bookingForm.value.bookingId).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      setTimeout(() => {
        this.router.navigate(['/bookings'], {
          queryParams: { projectId: this.bookingForm.value.projectId }
        });
      }, 500);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  verifyUserId(): any {
    this.verifiedUserIdData = null;

    let userId = this.bookingForm.value.userId.split(this.dataHolderService.projectMetaDeta.USER_ID_PREFIX).pop();
    if (userId.length < 1) {
      this.notificationsService.toggleFailed(true, "Enter valid user id");
      return;
    }

    this.profilesService.fetchUserProfile(userId).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.verifiedUserIdData = response["user"];
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }

  verifyBrokerId(): any {
    this.verifiedUserIdData = null;

    let userId = this.bookingForm.value.brokerId.split(this.dataHolderService.projectMetaDeta.USER_ID_PREFIX).pop();
    if (userId.length < 1) {
      this.notificationsService.toggleFailed(true, "Enter valid agent id");
      return;
    }

    this.profilesService.fetchUserProfile(userId, 1).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.verifiedBrokerIdData = response["user"];
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }

  verifyPlotsAvailabilty(): any {

    if (this.bookingForm.value.plots.trim().length < 1) {
      this.notificationsService.toggleFailed(true, "Plots value can not be empty");
      return;
    }

    this.bookingsServiceService.checkPlotsForAvailability(
      this.bookingForm.value.projectId,
      this.bookingForm.value.plots,
      this.bookingForm.value.bookingId
    ).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.plot_availability_status.message.join(','));
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.plot_availability_status.message.join(','));
      // this.verifiedBrokerIdData = response["user"];
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }


  updateForm(response = null): void {

    this.fetchedBooking = true;
    if (response != null) {
      this.notificationsService.toggleSucceeded(true, response.message);
    }

    this.totalTransactions = Object.keys(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].transactions).length;

    this.totalRemainders = Object.keys(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].remainders).length;

    this.totalAttachments = Object.keys(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].attachments).length;

    this.bookingForm.controls.price.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookingData.price);

    this.bookingForm.controls.userId.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].user.id);

    this.verifiedUserIdData = this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].user;

    this.bookingForm.controls.projectId.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId]['bookingData'].project_id);

    if (this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].broker?.id) {
      this.verifiedBrokerIdData = this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].broker;
      this.bookingForm.controls.brokeredDeal.setValue(1);
      this.bookingForm.controls.brokerId.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].broker.id);
      this.bookingForm.controls.brokerage.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookingData.brokerage);

    }
    else {
      this.bookingForm.controls.brokeredDeal.setValue(0);
    }

    this.bookingForm.controls.plots.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookingData.plots);
    if (this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookingData["additional_notes"]) {
      this.bookingForm.controls.additionalNotes.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookingData["additional_notes"]);
    }

    this.bookingForm.controls.bookingDate.setValue(this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookingData["booked_on"].split(" ")[0]);

    this.bookedBy = this.bookingsServiceService.fetchedBookingInfo[this.bookingForm.value.bookingId].bookedBy;

  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

  customTimeValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, moment.HTML5_FMT.TIME).isValid()) {
        console.log(moment(control.value, moment.HTML5_FMT.TIME).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  onBookingDataChange(data, response = null): void {
    this.totalTransactions = 0;
    this.totalRemainders = 0;
    this.totalAttachments = 0;
    this.bookingsServiceService.fetchedBookingInfo = data;
    this.updateForm(response);

  }

  updateBookingPage(latestFetchedBookingInfo): void {
    this.onBookingDataChange(latestFetchedBookingInfo);
  }

  checkForQueryParams(): void {

  }




  onFilterAccounts(e, isBrokers = false): void {

    if (isBrokers) {

      if (this.bookingForm.value.brokerId.trim().length < 1) {
        this.notificationsService.toggleFailed(true, 'Enter at least 1 character to load search suggestions..');
        return;
      }

      this.filteredBrokers = null;
      this.filteredBrokersActiveIndex = -1;
    }

    else {

      if (this.bookingForm.value.userId.trim().length < 1) {
        this.notificationsService.toggleFailed(true, 'Enter at least 1 character to load search suggestions....');
        return;
      }

      this.filteredUsers = null;
      this.filteredUsersActiveIndex = -1;

    }


    let body = new HttpParams();

    if (isBrokers) {


      body = body.set("name", this.bookingForm.value.brokerId);
      body = body.set("email", this.bookingForm.value.brokerId);
      body = body.set("userId", this.bookingForm.value.brokerId);
      body = body.set("mobile_number", this.bookingForm.value.brokerId);



    }
    else {
      body = body.set("name", this.bookingForm.value.userId);
      body = body.set("email", this.bookingForm.value.userId);
      body = body.set("userId", this.bookingForm.value.userId);
      body = body.set("mobile_number", this.bookingForm.value.userId);


    }


    this.profilesService.accountSearchSuggestions(body).subscribe((response: any) => {


      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }

      if (isBrokers) {
        this.filteredBrokers = response.data;
      }
      else {
        this.filteredUsers = response.data;
      }



    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }

  moveBetweenFilteredUsers(e, isBrokers = true): void {



    if (isBrokers) {

      if (!this.filteredBrokers) {
        return;
      }
      if (e.keyCode == 40) {
        this.filteredBrokersActiveIndex++;
        this.addActiveToFilteredUsersListItem(e.target.closest("div.form-field"), isBrokers);

      }
      else if (e.keyCode == 38) {
        this.filteredBrokersActiveIndex--;
        this.addActiveToFilteredUsersListItem(e.target.closest("div.form-field"), isBrokers);


      }
      else if (e.keyCode == 13) {
        let suggestions = e.target.closest("div.form-field").querySelectorAll("section.search-list li");

        if (suggestions && suggestions[this.filteredBrokersActiveIndex]) {
          suggestions[this.filteredBrokersActiveIndex].click();
          e.preventDefault();
        }



      }


    }
    else {

      if (!this.filteredUsers) {
        return;
      }

      if (e.keyCode == 40) {
        this.filteredUsersActiveIndex++;
        this.addActiveToFilteredUsersListItem(e.target.closest("div.form-field"), isBrokers);
      }
      else if (e.keyCode == 38) {
        this.filteredUsersActiveIndex--;

        this.addActiveToFilteredUsersListItem(e.target.closest("div.form-field"), isBrokers);

      }
      else if (e.keyCode == 13) {
        let suggestions = e.target.closest("div.form-field").querySelectorAll("section.search-list li");

        if (suggestions && suggestions[this.filteredUsersActiveIndex]) {
          suggestions[this.filteredUsersActiveIndex].click();
          e.preventDefault();
        }


      }
    }

  }

  addActiveToFilteredUsersListItem(parentEle, isBrokers = true): void {

    this.removeActiveFromFilteredUsersListItem(parentEle);
    let suggestions = parentEle.querySelectorAll("section.search-list li");

    if (isBrokers) {

      if (this.filteredBrokersActiveIndex >= Object.keys(this.filteredBrokers).length) {
        this.filteredBrokersActiveIndex = 0;
      }

      if (this.filteredBrokersActiveIndex < 0) {
        this.filteredBrokersActiveIndex = Object.keys(this.filteredBrokers).length - 1;
      }

      suggestions[this.filteredBrokersActiveIndex].classList.add("active");
      suggestions[this.filteredBrokersActiveIndex].scrollIntoView();

    }

    else {

      if (this.filteredUsersActiveIndex >= Object.keys(this.filteredUsers).length) {
        this.filteredUsersActiveIndex = 0;
      }

      if (this.filteredUsersActiveIndex < 0) {
        this.filteredUsersActiveIndex = Object.keys(this.filteredUsers).length - 1;
      }

      suggestions[this.filteredUsersActiveIndex].classList.add("active");
      suggestions[this.filteredUsersActiveIndex].scrollIntoView();

    }




  }

  removeActiveFromFilteredUsersListItem(parentEle): void {
    parentEle.querySelectorAll("section.search-list li.active").forEach((ele) => {
      ele.classList.remove("active");
    });
  }


  selectedUserNameFromFilteredList(e, isBroker = false) {

    if (isBroker) {
      this.bookingForm.patchValue({
        brokerId: e.target.closest("li").getAttribute("data-account-id"),
      });
      this.verifyBrokerId();
      this.filteredBrokers = null;
      this.filteredBrokersActiveIndex = -1;
    }
    else {

      this.bookingForm.patchValue({
        userId: e.target.closest("li").getAttribute("data-account-id"),
      });
      this.verifyUserId();
      this.filteredUsers = null;

      this.filteredUsersActiveIndex = -1;


    }



  }

}
