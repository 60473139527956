
<section class="fullScreenFlex contactUsFromContainer" style="min-height: 100vh !important;">
  <section class="responsiveContainer twoColumnsGrid">
    <section class="flexCenter">
      <div style="margin: 0 auto 0 auto; width: 90%;">
        <h2>{{ dataHolderService.projectMetaDeta.COMPANY_NAME }}</h2>
        <p>
          <a class="block-link" target="_blanc" href="https://goo.gl/maps/z3oZe2o3J4eKAmnv6">
            {{ dataHolderService.projectMetaDeta.COMPANY_ADDRESS }}
          </a>

        </p>
        <section class="iframe-container">

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15355.250094616977!2d78.053376!3d15.8138364!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb5dda975c852d7%3A0x8e55afa976787c5c!2sSri%20Saraswathi%20Homes!5e0!3m2!1sen!2sin!4v1621172049164!5m2!1sen!2sin"
            style="border:0;" allowfullscreen="" loading="lazy"></iframe>

        </section>
        <p class="inline-links">

          <a style="border: none; color: black; font-size: 1em;" *ngFor="let m of dataHolderService.projectMetaDeta.COMPANY_MOBILE_NUMBERS.split(',')"
            href="tel:{{ m }}">
            {{ m }}
          </a>

        </p>
        <p class="inline-links"> <a  style="border: none; color: black; font-size: 1em;"  href="mailto:{{ dataHolderService.projectMetaDeta.COMPANY_EMAIL }}">
            {{ dataHolderService.projectMetaDeta.COMPANY_EMAIL }}</a></p>
        <div>
          <a rel=" nofollow noopener noreferrer" target="_blank"
            href="{{ dataHolderService.projectMetaDeta.COMPANY_FACEBOOK.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_FACEBOOK : ''}}"
            class="storeLink">
            <i style="color: blue;" class="fa fa-facebook-square" aria-hidden="true"></i>
          </a>
          <a rel=" nofollow noopener noreferrer" target="_blank"
            href="{{ dataHolderService.projectMetaDeta.COMPANY_FACEBOOK.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_TWITTER : ''}}"
            class="storeLink">
            <i style="color: #00ACEE;" class="fa fa-twitter-square" aria-hidden="true"></i>
          </a>
          <a rel=" nofollow noopener noreferrer" target="_blank"
            href="mailto:{{ dataHolderService.projectMetaDeta.COMPANY_EMAIL.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_EMAIL : ''}}"
            class="storeLink">
            <i style="color: blueviolet;" class="fa fa-envelope" aria-hidden="true"></i>
          </a>
          <a rel=" nofollow noopener noreferrer" target="_blank"
            href="{{ dataHolderService.projectMetaDeta.COMPANY_LINKEDIN.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_LINKEDIN : ''}}"
            class="storeLink">
            <i style="color: #0E76A8;" class="fa fa-linkedin-square" aria-hidden="true"></i>
          </a>
          <a rel=" nofollow noopener noreferrer" target="_blank"
            href="{{ 'https://wa.me/' + (dataHolderService.projectMetaDeta.COMPANY_WHATSAPP.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_WHATSAPP : '' ) + '/?text=Hello!'  }}"
            class="storeLink">
            <i style="color: #25D366;" class="fa fa fa-whatsapp" aria-hidden="true"></i>
          </a>
          <a rel=" nofollow noopener noreferrer" target="_blank"
            href="{{ dataHolderService.projectMetaDeta.COMPANY_INSTAGRAM.length > 0 ? dataHolderService.projectMetaDeta.COMPANY_INSTAGRAM : ''}}"
            class="storeLink">
            <i style="color: #E1306C;" class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </section>
    <section class="contactUsForm flexCenter">
      <form [formGroup]="contactUsForm" (ngSubmit)="onContact()" #form="ngForm" class="application-forms">
        <div class="form-wrapper">
          <div class="form-description">
            <h1>CONTACT US</h1>
            <p>Send us a message, We will get back to you as soon as possible </p>
          </div>
          <div class="form-input no-box-shadow">
            <div class="icon-container">
              <div>
                <i style="color: blue;" class="fa fa-user-circle-o" aria-hidden="true"></i>
              </div>
              <div>
                <input formControlName="fullName" type="text" name="fullName" placeholder="Name">

              </div>
            </div>
            <p>
              <span *ngIf="submitted && contactUsForm.controls['fullName'].errors?.required"> *required</span>
              <span *ngIf="submitted && contactUsForm.controls['fullName'].errors?.minlength"> *Minimum 3 characters
              </span>
              <span *ngIf="submitted && contactUsForm.controls['fullName'].errors?.maxlength"> *Maximum 60 characters
              </span>
            </p>
          </div>
          <div class="form-input no-box-shadow">
            <div class="icon-container">
              <div>
                <i style="color: blue;" class="fa fa-phone" aria-hidden="true"></i>
              </div>
              <div>
                <input formControlName="mobile" type="tel" name="mobile" placeholder="Mobile number">
              </div>

            </div>
            <p>
              <span *ngIf="submitted && contactUsForm.controls['mobile'].errors?.required"> *required</span>
              <span *ngIf="submitted && contactUsForm.controls['mobile'].errors?.invalidMsg"> *Enter valid mobile number
              </span>
              <span *ngIf="submitted && contactUsForm.controls['mobile'].errors?.minlength"> *10 digits only
              </span>
              <span *ngIf="submitted && contactUsForm.controls['mobile'].errors?.maxlength"> *10 digits only
              </span>
            </p>
          </div>
          <div class="form-input no-box-shadow">
            <div class="icon-container">
              <div>
                <i style="color: blue;" class="fa fa-envelope" aria-hidden="true"></i>
              </div>
              <div>
                <input formControlName="email" type="email" name="email" placeholder="Email">

              </div>

            </div>
            <p>
              <span *ngIf="submitted && contactUsForm.controls['email'].errors?.email"> *Invalid email </span>
            </p>
          </div>

          <div class="form-input">
            <textarea formControlName="message" type="message" name="message"
              placeholder="Hey, I want to know about your...."></textarea>
            <p>
              <span *ngIf="submitted && contactUsForm.controls['message'].errors?.required"> *required</span>
              <span *ngIf="submitted && contactUsForm.controls['message'].errors?.minlength"> *Minimum 3
                characters</span>
              <span *ngIf="submitted && contactUsForm.controls['message'].errors?.maxlength"> *Maximum 2000 characters
              </span>
            </p>
          </div>
          <div class="form-input">
            <button type="submit" class="noselect">SUBMIT</button>

          </div>
        </div>
      </form>
    </section>
  </section>
</section>
