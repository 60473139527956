import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RemaindersService {
  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  addRemainder(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/add-remainder', body);
  }

  updateRemainder(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/update-remainder', body);
  }

  deleteRemainder(remainderId, bookingId): any {
    let body = new HttpParams();
    body = body.set('remainderId', remainderId);
    body = body.set('bookingId', bookingId);

    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/delete-remainder', {
      params: body
    });
  }

  getRemainders(body) {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/remainders', {
      params: body
    });
  }

}
