<main>
  <section class="responsiveContainer">
    <form [formGroup]="projectImageForm" (ngSubmit)="onAttachment($event)" #form="ngForm" class="form-layout">
      <div class="form-heading">

        <h2 class="gradientColor"><span>{{ isCreate ? 'ADD SLIDE' : 'UPDATE SLIDE' }}</span>
        </h2>

        <input type="hidden" name="attachmentId" formControlName="attachmentId">

      </div>
      <div class="form-body">
        <div class="form-left">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Heading </label>
              <textarea formControlName="heading" type="text" name="heading" placeholder="Heading"></textarea>
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['heading'].errors?.required">
                  *required</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['heading'].errors?.maxlength">
                  *Maximum 150 chars</span>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Description </label>
              <textarea formControlName="description" type="text" name="description"
                placeholder="Description"></textarea>
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['description'].errors?.required">
                  *required</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['description'].errors?.maxlength">
                  *Maximum 150 chars</span>
            </div>
          </div>

        </div>
        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Button1 - Text</label>
              <input formControlName="link1Text" type="text" name="link1Text" placeholder="">
              <p class="errors">

                <span *ngIf="fetchedAttachment && projectImageForm.controls['link1Text'].errors?.required">
                  *required</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['link1Text'].errors?.maxlength"> *Maximum 45
                  characters </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Button2- Text</label>
              <input formControlName="link2Text" type="text" name="link2Text" placeholder="">
              <p class="errors">

                <span *ngIf="fetchedAttachment && projectImageForm.controls['link2Text'].errors?.required">
                  *required</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['link2Text'].errors?.maxlength"> *Maximum 45
                  characters </span>

              </p>
            </div>
          </div>


          <div class="form-field">
            <div class="input-container with-label">
              <label>Button1 - Link </label>
              <textarea formControlName="link1" type="text" name="link1"></textarea>
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['link1'].errors?.required">
                  *required</span>

            </div>
          </div>


          <div class="form-field">
            <div class="input-container with-label">
              <label>Button2 - Link </label>
              <textarea formControlName="link2" type="text" name="link2"></textarea>
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['link2'].errors?.required">
                  *required</span>

            </div>
          </div>

        </div>

        <div class="form-right">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Sort order</label>
              <input name="sortOrder" formControlName="sortOrder" type="number" placeholder="Enter sort order">

              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['sortOrder'].errors?.required">
                  *required</span>
              </p>
            </div>
          </div>

          <div class="form-field">

            <div class="input-container with-file-upload">
              <label>Upload Image</label>

              <div>
                <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                  (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label" for="sliderImage">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept="image/*" type="file" (change)="onFilesChanged($event)" formControlName="image"
                    name="image[]" type="file" id="sliderImage">
                </label>
              </div>

              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['image'].errors?.extension"> *Only
                  jpeg,bmp,png,jpg,gif,svg,gif files are accepted</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['image'].errors?.file"> *Only 1 file can
                  be
                  uploaded</span>
              </p>

              <div *ngIf="!isCreate" class="existingProfile twoColumnsGrid">
                <div>
                  <a download target="_blank" href="{{ attachmentData.image_url + '?id=' + attachmentData.id   }}">
                    <img src="{{  attachmentData.image_url + '?id=' + attachmentData.id   }}">
                  </a>
                </div>
              </div>

              <div *ngIf="localFileName">
                <ul class="inline">
                  <li> <a class="textualLink">{{ localFileName }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfile($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <button (click)="deleteProjectImage();" *ngIf="!isCreate" type="button">DELETE</button>

      </div>

    </form>
  </section>
</main>
