<section class="fullScreenFlex responsiveContainer loginActivity">
  <div>
      <section *ngIf="!verified">
          <h1>LOGIN ALERT</h1>
          <h1>
              <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
              <!-- <i *ngIf="!loading" class="fa fa-times" aria-hidden="true"></i> -->
          </h1>
      </section>
      <section *ngIf="verified">
          <h1> <i class="fa fa-check-circle" aria-hidden="true"></i> </h1>
          <!-- <p> <a [routerLink]="['/change-password']">Change Password</a> </p> -->
      </section>
      <h3> {{ message }}</h3>

  </div>
</section>
