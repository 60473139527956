import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderSideNavComponent } from './header-side-nav/header-side-nav.component';
import { IndexPageComponent } from './index-page/index-page.component';
import { HomePageSlideShowComponent } from './home-page-slide-show/home-page-slide-show.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginActivityComponent } from './login-activity/login-activity.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AddOrEditUserComponent } from './add-or-edit-user/add-or-edit-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AddOrEditBookingComponent } from './add-or-edit-booking/add-or-edit-booking.component';
import { NewBookingComponent } from './new-booking/new-booking.component';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { ProperCurrencyFormatPipe } from './proper-currency-format.pipe';
import { AddOrDeleteTransactionComponent } from './add-or-delete-transaction/add-or-delete-transaction.component';
import { NewTransactionComponent } from './new-transaction/new-transaction.component';
import { EditBookingComponent } from './edit-booking/edit-booking.component';
import { MomentDateDiffPipePipe } from './moment-date-diff-pipe.pipe';
import { MomentDateTransformPipePipe } from './moment-date-transform-pipe.pipe';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { AddOrDeleteRemainderComponent } from './add-or-delete-remainder/add-or-delete-remainder.component';
import { AddOrDeleteAttachmentComponent } from './add-or-delete-attachment/add-or-delete-attachment.component';
import { SearchUsersComponent } from './search-users/search-users.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SearchPlotsComponent } from './search-plots/search-plots.component';
import { SearchBookingsComponent } from './search-bookings/search-bookings.component';
import { FooterComponent } from './footer/footer.component';
import { SearchRemaindersComponent } from './search-remainders/search-remainders.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SearchTransactionsComponent } from './search-transactions/search-transactions.component';
import { ProjectPageComponent } from './project-page/project-page.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';
import { LicenseComponent } from './license/license.component';

import { APP_INITIALIZER } from '@angular/core';
import { ConfigService } from './config.service';
import { LocationImageComponent } from './location-image/location-image.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { PropertyTypesComponent } from './property-types/property-types.component';
import { PropertyTypeProjectsComponent } from './property-type-projects/property-type-projects.component';
import { CommonModule } from '@angular/common';
import { ProjectStatusComponent } from './project-status/project-status.component';
import { ProjectStatusProjectsComponent } from './project-status-projects/project-status-projects.component';
import { SearchContactUsComponent } from './search-contact-us/search-contact-us.component';
import { MarkPlotFacingComponent } from './mark-plot-facing/mark-plot-facing.component';
import { EditPlotComponent } from './edit-plot/edit-plot.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { AddEditProjectComponent } from './add-edit-project/add-edit-project.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { SearchProjectsComponent } from './search-projects/search-projects.component';
import { AddOrDeleteProjectImagesComponent } from './add-or-delete-project-images/add-or-delete-project-images.component';
import { AddOrDeleteProjectAttachmentsComponent } from './add-or-delete-project-attachments/add-or-delete-project-attachments.component';
import { AddEditPlotComponent } from './add-edit-plot/add-edit-plot.component';
import { AddPlotComponent } from './add-plot/add-plot.component';
import { SearchHomePageSlidesComponent } from './search-home-page-slides/search-home-page-slides.component';
import { AddEditHomePageSlideComponent } from './add-edit-home-page-slide/add-edit-home-page-slide.component';
import { AddEditCustomPagesComponent } from './add-edit-custom-pages/add-edit-custom-pages.component';
import { QuillModule } from 'ngx-quill';
import { AddCustomPageComponent } from './add-custom-page/add-custom-page.component';
import { EditCustomPageComponent } from './edit-custom-page/edit-custom-page.component';
import { SearchCustomPagesComponent } from './search-custom-pages/search-custom-pages.component';
import { AddEditProjectCitiesComponent } from './add-edit-project-cities/add-edit-project-cities.component';
import { SearchProjectCitiesComponent } from './search-project-cities/search-project-cities.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { CitiesComponent } from './cities/cities.component';
import { CityProjectsComponent } from './city-projects/city-projects.component';
import { AddEditPropertyTypeComponent } from './add-edit-property-type/add-edit-property-type.component';
import { SearchPropertyTypesComponent } from './search-property-types/search-property-types.component';
import { RenderCustomPageComponent } from './render-custom-page/render-custom-page.component';
import { SeperatePageComponent } from './seperate-page/seperate-page.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { BlogArticlesComponent } from './blog-articles/blog-articles.component';
import { CareersComponent } from './careers/careers.component';

export function configService(configService: ConfigService) {
  return () => configService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderSideNavComponent,
    IndexPageComponent,
    HomePageSlideShowComponent,
    NotificationsComponent,
    ProjectsListComponent,
    HighlightsComponent,
    LoginComponent,
    LoginActivityComponent,
    DashboardComponent,
    MyProfileComponent,
    AddOrEditUserComponent,
    CreateUserComponent,
    EditUserComponent,
    AddOrEditBookingComponent,
    NewBookingComponent,
    NumberToWordsPipe,
    ProperCurrencyFormatPipe,
    AddOrDeleteTransactionComponent,
    NewTransactionComponent,
    EditBookingComponent,
    MomentDateDiffPipePipe,
    MomentDateTransformPipePipe,
    AddTransactionComponent,
    AddOrDeleteRemainderComponent,
    AddOrDeleteAttachmentComponent,
    SearchUsersComponent,
    SearchPlotsComponent,
    SearchBookingsComponent,
    FooterComponent,
    SearchRemaindersComponent,
    ChangePasswordComponent,
    SearchTransactionsComponent,
    ProjectPageComponent,
    ContactUsComponent,
    AboutUsComponent,
    NotFoundComponentComponent,
    LicenseComponent,
    LocationImageComponent,
    SanitizeHtmlPipe,
    PropertyTypesComponent,
    PropertyTypeProjectsComponent,
    ProjectStatusComponent,
    ProjectStatusProjectsComponent,
    SearchContactUsComponent,
    MarkPlotFacingComponent,
    EditPlotComponent,
    DisclaimerComponent,
    UsefulLinksComponent,
    AddEditProjectComponent,
    AddProjectComponent,
    EditProjectComponent,
    SearchProjectsComponent,
    AddOrDeleteProjectImagesComponent,
    AddOrDeleteProjectAttachmentsComponent,
    AddEditPlotComponent,
    AddPlotComponent,
    SearchHomePageSlidesComponent,
    AddEditHomePageSlideComponent,
    AddEditCustomPagesComponent,
    AddCustomPageComponent,
    EditCustomPageComponent,
    SearchCustomPagesComponent,
    AddEditProjectCitiesComponent,
    SearchProjectCitiesComponent,
    ProjectCardComponent,
    CitiesComponent,
    CityProjectsComponent,
    AddEditPropertyTypeComponent,
    SearchPropertyTypesComponent,
    RenderCustomPageComponent,
    SeperatePageComponent,
    BlogPageComponent,
    BlogArticlesComponent,
    CareersComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RxReactiveFormsModule,
    LazyLoadImageModule,
    InlineSVGModule.forRoot(),
    NgxPanZoomModule,
    QuillModule.forRoot({
      suppressGlobalRegisterWarning : true
    })

  ],
  providers: [ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configService,
      // useFactory: (configService: ConfigService) => function () { return configService.load(); },
      // useFactory: (configService: ConfigService) => configService.loader,
      deps: [ConfigService],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
