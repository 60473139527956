import { TransactionsService } from './../transactions.service';
import { ProfilesService } from './../profiles.service';
import { Component, OnInit, Input , Output, EventEmitter  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-add-or-delete-transaction',
  templateUrl: './add-or-delete-transaction.component.html',
  styleUrls: ['./add-or-delete-transaction.component.css']
})
export class AddOrDeleteTransactionComponent implements OnInit {

  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedTrasaction : boolean = false;

  transactionForm : FormGroup;

  disabled: boolean = false;

  addedBy : any;

  @Input() bookingData : any = null;

  @Input() bookingId : any = null;

  @Input() transactionId : any = null;

  @Input() transactionData : any = null;

  @Output() latestFetchedBookingInfo = new EventEmitter<any>();

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private router: Router,
    private profilesService: ProfilesService,
    private transactionsService : TransactionsService
  ) { }

  ngOnInit(): void {

    this.transactionForm = this.formBuilder.group({
      credit: ["", [
        Validators.required,
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      transactionId: ["1", Validators.required],
      bookingId: ["", Validators.required],
      transactionDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      additionalNotes: ["", [Validators.minLength(3), Validators.maxLength(512)]]
    });

    this.transactionForm.controls.bookingId.setValue(this.bookingId);

    if(!this.isCreate){
      this.updateForm();
    }
  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  onTransaction() : any{
    const formData = this.transactionForm.value;

    if(this.transactionForm.invalid){
      this.fetchedTrasaction = true;
      this.notificationsService.toggleFailed(true,"Invalid data, make sure to follow guidelines before filling data");
      return;
    }
    // this.disabled = true;

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before making the transaction");
      return;
    }

    if (this.isCreate) {
      this.notificationsService.toggleLoading(true, "Adding Transaction...");
      this.newTransaction(formData);
      return;

    }
    else{
      this.notificationsService.toggleLoading(true, "Updating Transaction...");
      this.updateTransaction(formData);
      return;
    }
  }

  newTransaction(formData) : void{

    this.transactionsService.addTransaction(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
      this.transactionForm.patchValue({
        credit: "",
        transactionId: "1",
        transactionDate: "",
        additionalNotes: ""
      });
      this.fetchedTrasaction = false;
      setTimeout(()=>{
        if(document.getElementById(response.trn)){
          document.getElementById(response.trn).scrollIntoView();
        }
      },1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateTransaction(formData) : void{

    this.transactionsService.updateTransaction(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );

      setTimeout(()=>{
        if(document.getElementById(response.trn)){
          document.getElementById(response.trn).scrollIntoView();
        }
      },1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  deleteTransaction() : void{
    if (!window.confirm("Do you want to delete the transaction ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before deleting the transaction");
      return;
    }
    this.notificationsService.toggleLoading(true, "Deleting Transaction "+ this.transactionData.transactionId );

    this.transactionsService.deleteTransaction(this.transactionData.transactionId , this.transactionData.booking_id).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  getUserDetails() : void{
    let userId = this.transactionData.added_by.split(this.dataHolderService.projectMetaDeta.USER_ID_PREFIX).pop();
    if (userId.length < 1) {
      this.notificationsService.toggleFailed(true, "Enter valid user id");
      return;
    }
    this.profilesService.fetchUserProfile(userId).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.addedBy = response["user"];
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateForm(response = null): void {
    this.fetchedTrasaction = true;
    if (response != null) {
      this.notificationsService.toggleSucceeded(true, response.message);
    }

    this.transactionForm.controls.credit.setValue(this.transactionData.credit);
    this.transactionForm.controls.transactionId.setValue(this.transactionData.transactionId);
    this.transactionForm.controls.bookingId.setValue(this.transactionData.booking_id);

    if(this.transactionData.additional_notes){
      this.transactionForm.controls.additionalNotes.setValue(this.transactionData.additional_notes);
    }

    this.transactionForm.controls.transactionDate.setValue(this.transactionData.added_on.split(" ")[0]);

  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }
}
