import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { ProfilesService } from './../profiles.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  submitted: boolean = false;

  oldPasswordHidden: boolean = true;

  newPasswordHidden: boolean = true;

  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder, public notificationsService: NotificationsService,
    private router: Router, private dataHolderService: DataHolderService,
    private profilesService: ProfilesService) {


  }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Change Password - ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);
    this.forgotPasswordForm = this.formBuilder.group({
      oldPassword: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
      newPassword: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
    });
  }

  onSetNewPassword(): void {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid data");
      return;
    }
    if (this.forgotPasswordForm.get("oldPassword").value.trim() == this.forgotPasswordForm.get("newPassword").value.trim()) {
      this.notificationsService.toggleFailed(true, "Both passwords can not be same");
      return;
    }
    this.notificationsService.toggleLoading(true, 'Updating your password...');
    this.profilesService.changePassword(this.forgotPasswordForm.value).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);

      // this.dataHolderService.userInfo = response["user"];
      this.submitted = false;

      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 500);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });

  }

}
