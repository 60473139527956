<header class="menu-social-links-wrapper">
  <section class="responsiveContainer">
    <div class="menu-social-links">



      <div class="s-media-links">
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolder.projectMetaDeta.COMPANY_FACEBOOK.length > 0 ? dataHolder.projectMetaDeta.COMPANY_FACEBOOK : ''}}"
          class="storeLink">
          <i style="color: blue;" class="fa fa-facebook-square" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolder.projectMetaDeta.COMPANY_FACEBOOK.length > 0 ? dataHolder.projectMetaDeta.COMPANY_TWITTER : ''}}"
          class="storeLink">
          <i style="color: #00ACEE;" class="fa fa-twitter-square" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="mailto:{{ dataHolder.projectMetaDeta.COMPANY_EMAIL.length > 0 ? dataHolder.projectMetaDeta.COMPANY_EMAIL : ''}}"
          class="storeLink">
          <i style="color: blueviolet;" class="fa fa-envelope" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolder.projectMetaDeta.COMPANY_LINKEDIN.length > 0 ? dataHolder.projectMetaDeta.COMPANY_LINKEDIN : ''}}"
          class="storeLink">
          <i style="color: #0E76A8;" class="fa fa-linkedin-square" aria-hidden="true"></i>
        </a>
        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ 'https://wa.me/' + (dataHolder.projectMetaDeta.COMPANY_WHATSAPP.length > 0 ? dataHolder.projectMetaDeta.COMPANY_WHATSAPP : '' ) + '/?text=Hello!'  }}"
          class="storeLink">
          <i style="color: #25D366;" class="fa fa fa-whatsapp" aria-hidden="true"></i>
        </a>

        <a rel=" nofollow noopener noreferrer" target="_blank"
          href="{{ dataHolder.projectMetaDeta.COMPANY_INSTAGRAM.length > 0 ? dataHolder.projectMetaDeta.COMPANY_INSTAGRAM : ''}}"
          class="storeLink">
          <i style="color: #E1306C;" class="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </div>
      <div class="contact-number-cont">

        <a class="grad6" href="tel:{{ dataHolder.projectMetaDeta.primary_mobile_number }}">
          <span> <i class="fa fa-phone" aria-hidden="true"></i>
            {{ dataHolder.projectMetaDeta.primary_mobile_number }}</span>
        </a>
      </div>
    </div>
  </section>
</header>
<!-- Desktop Menu Header -->
<header class="desktop-menu-wrapper">
  <div class="responsiveContainer">

    <div class="desktop-menu">

      <div class="first-col">
        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " id="logo" [routerLink]="['/']">
              <img src="assets/site-assets/logo.png">
            </a>
          </div>
        </div>
      </div>
      <div class="second-col">
        <div *ngIf="dataHolder.userInfo" class="desktop-menu-flex-container">
          <div>
            <a class="main-link" [routerLink]="['/my-profile']">
              <span>
                <img
                  src="{{ dataHolder.userInfo?.profile_picture_url ?  dataHolder.apiBaseUrl +  '/profile-picture?accountId=' + dataHolder.userInfo.id  : 'assets/images/dummy.png' }}">
              </span>
            </a>
            <div class="hiddenMenu">
              <table>
                <tbody>
                  <tr>
                    <td>Hi, {{ dataHolder.userInfo['first_name'] }} </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/dashboard']" class="main-link">
                        <span> <i class="fa fa-columns" aria-hidden="true"></i>
                          Dashboard</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/my-profile']" class="main-link">
                        <span> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                          Profile</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/change-password']" class="main-link">
                        <span> <i class="fa fa-key-modern" aria-hidden="true"></i> Change
                          Password</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a (click)="profilesService.logoutUser()" class="main-link">
                        <span> <i class="fa fa-sign-out" aria-hidden="true"></i> Logout</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td> Accounts </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/user-accounts']" class="main-link">
                        <span> <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                          Users</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/user-accounts']" [queryParams]="{ isBroker : '1' }" class="main-link">
                        <span> <i class="fa fa fa-link" aria-hidden="true"></i>
                          Agents</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/user-accounts/create']" class="main-link">
                        <span> <i class="fa fa-user-plus" aria-hidden="true"></i>
                          Add User</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/user-accounts/create']" [queryParams]="{ broker : 'true' }" class="main-link">
                        <span> <i class="fa fa-user-plus" aria-hidden="true"></i>
                          Add Agent</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td> Manage </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/remainders']" class="main-link">
                        <span> <i class="fa fa-calendar" aria-hidden="true"></i>
                          Remainders</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/transactions']" class="main-link">
                        <span> <i class="fa fa-money" aria-hidden="true"></i>
                          Transactions</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/contact-us-messages-received']" class="main-link">
                        <span> <i class="fa fa-envelope-o" aria-hidden="true"></i>
                          Messages Received</span></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a [routerLink]="['/bulk-plots-marking']" class="main-link">
                        <span> <i class="fa fa-th" aria-hidden="true"></i> Bulk Plots
                          Marking</span></a>
                    </td>
                  </tr>
                  <ng-container *ngIf="dataHolder.userInfo.is_super_admin == 'true'">
                    <tr>
                      <td>
                        <a [routerLink]="['/manage-home-page-slides']" class="main-link">
                          <span> <i class="fa fa-picture-o" aria-hidden="true"></i> Home Page Slides</span></a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a [routerLink]="['/manage-custom-pages']" class="main-link">
                          <span> <i class="fa fa-book" aria-hidden="true"></i> Manage Custom Pages</span></a>
                      </td>
                    </tr>

                  </ng-container>


                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <div class="desktop-menu-flex-container ">
        <div>
          <a class="main-link " [routerLink]="['/locations']">
            <span> LOCATIONS </span>
          </a>
        </div>
      </div> -->


        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " [routerLink]="['/locations']">
              <span> PROJECTS </span>
            </a>
            <div class="hiddenMenu" [ngStyle]="{ 'left' : dataHolder.userInfo ? '-200%' : '-100%' }">
              <table>
                <tbody>

                  <tr *ngIf="dataHolder.userInfo && dataHolder.userInfo.is_super_admin == 'true'">

                    <td>
                      <a class="main-link " [routerLink]="['/search-projects' ]">
                        <span> Manage Projects </span>
                      </a>

                      <div style="margin: 1em;">
                        <a class="main-link " [routerLink]="['/search-projects' ]">
                          <span>All Projects </span>
                        </a>
                        <a class="main-link " [routerLink]="['/projects/new-project' ]">
                          <span>Add Project </span>
                        </a>

                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td>Projects by Status</td>
                  </tr>

                  <tr>
                    <td>
                      <a class="main-link " [routerLink]="['/projects/status/2' ]">
                        <span> Completed Projects </span>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <a class="main-link " [routerLink]="['/projects/status/1' ]">
                        <span> On Going Projects </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a class="main-link " [routerLink]="['/projects/status/0' ]">
                        <span> Upcoming Projects </span>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>All Projects</td>
                  </tr>

                  <tr *ngFor="let project of dataHolder.projectMetaDeta.PROJECTS">
                    <td>
                      <a class="main-link " [routerLink]="['/project', project.url ]">
                        <span> {{ project['name'] }} </span>
                      </a>


                      <div style="margin: 1em;" *ngIf="dataHolder.userInfo">

                        <a class="main-link " [routerLink]="['/bookings', project.url , 'new-booking' ]">
                          <span> New Booking </span>
                        </a>
                        <a class="main-link " [routerLink]="['/bookings']" [queryParams]="{
                        projectId : project.id , minimumPrice : '' , maximumPrice : '' , minimumBrokerage : '' , maximumBrokerage : '' , bookingId : '' , userId : '' , bookedBy : '' , brokerId : '' , brokeredDeals : '-1' , additionalNotes : '' , minimumDate : '' , maximumDate : '' , bookingStatus : '-1' , sortBy : 'booked_on' , sortOrder : 'DESC' , pagination : '5' , page : '1'
                      }">
                          <span> Bookings </span>
                        </a>

                        <a *ngIf="dataHolder.userInfo.is_super_admin == 'true'" class="main-link "
                          [routerLink]="['/add-plot', project.id ]">
                          <span> Add Plot </span>
                        </a>

                        <a class="main-link " [routerLink]="['/plots']" [queryParams]="{
                        projectId : project.id , plots : '' , is_north_facing : '-1' , is_south_facing : '-1' , is_west_facing : '-1' , is_east_facing : '-1' , min_north_dimensions : '' , max_north_dimensions : '' , min_south_dimensions : '' , max_south_dimensions : '' , min_east_dimensions : '' , max_east_dimensions : '' , min_west_dimensions : '' , max_west_dimensions : '' , min_area : '' , max_area : '' , bookingStatus : '-1' , sortBy : 'plot_no' , sortOrder : 'ASC' , pagination : '25' , page : '1'
                     }">
                          <span> Plots </span>
                        </a>

                        <a class="main-link " [routerLink]="['/remainders']"
                          [queryParams]="{ projectId : project.id  }">
                          <span> Remainders </span>
                        </a>
                        <a class="main-link " [routerLink]="['/transactions']"
                          [queryParams]="{ projectId : project.id  }">
                          <span> Transactions </span>
                        </a>

                      </div>
                    </td>
                  </tr>


                </tbody>


              </table>
            </div>
          </div>
        </div>
        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " [routerLink]="['/properties']">
              <span> PROPERTIES </span>
            </a>
            <div class="hiddenMenu">
              <table>
                <tbody>

                  <tr *ngIf="dataHolder.userInfo && dataHolder.userInfo.is_super_admin == 'true'">
                    <td>
                      <a class="main-link" [routerLink]="['/manage-property-types']"> <span> <i class="fa fa-building"
                            aria-hidden="true"></i> Manage Property Types </span> </a>
                    </td>
                  </tr>

                  <tr *ngFor="let property of dataHolder.projectMetaDeta.PROPERTY_TYPES">
                    <td>
                      <a class="main-link " [routerLink]="['/properties', property.url ]">
                        <span> {{ property['name'] }} </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " [routerLink]="['/cities']">
              <span> CITIES </span>
            </a>
            <div class="hiddenMenu">
              <table>
                <tbody>

                  <tr *ngIf="dataHolder.userInfo && dataHolder.userInfo.is_super_admin == 'true'">
                    <td>
                      <a class="main-link" [routerLink]="['/manage-cities']"> <span> <i class="fa fa-map"
                            aria-hidden="true"></i> Manage Towns / Cities / Villages </span> </a>
                    </td>
                  </tr>

                  <tr *ngFor="let property of dataHolder.projectMetaDeta.CITIES">
                    <td>
                      <a class="main-link "
                        [routerLink]="['/cities', property.name.split(' ').join('-') + '-' + property['id']   ]">
                        <span> {{ property['name'] }} </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " [routerLink]="['/news']">
              <span> NEWS </span>
            </a>
          </div>
        </div>
        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " [routerLink]="['/careers']">
              <span> CAREERS </span>
            </a>
          </div>
        </div>

        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link " [routerLink]="['/contact-us']">
              <span> CONTACT </span>
            </a>
            <div class="hiddenMenu">
              <table>
                <tbody>
                  <tr>
                    <a class="main-link " [routerLink]="['/about-us']">
                      <span> ABOUT </span>
                    </a>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="desktop-menu-flex-container ">
          <div>
            <a class="main-link">
              <span> MORE </span>
            </a>
            <div class="hiddenMenu" style="left: -220% !important;">
              <table>
                <tbody>
                  <tr *ngFor="let page of dataHolder.projectMetaDeta.PAGES">
                    <td>
                      <a class="main-link" [routerLink]="['/p', page.url_key ]">
                        <span> {{ page.title }} </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="third-col">
      <div class="desktop-menu-flex-container ">
        <div>
          <a class="twoRowGrid main-link" href="tel:{{ dataHolder.projectMetaDeta.primary_mobile_number }}">
            <div class="flexCenter">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </div>
            <div class="flexCenter">
              <span>{{ dataHolder.projectMetaDeta.primary_mobile_number }}</span>
            </div>
          </a>
        </div>
      </div>
    </div> -->
    </div>



  </div>
</header>


<div id="mobile-menu-wrapper">
  <!-- Mobile Menu Header -->
  <header class="mobile-menu">

    <div></div>
    <div class="logo-container">
      <a [routerLink]="['/']">
        <img src="assets/site-assets/logo.png">
      </a>
    </div>
    <div class="openSideNav" (click)="notificationsService.openSideNav()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <div></div>


  </header>

  <!-- Mobile Side Navigation -->
  <nav class="side-nav" [ngClass]=" { 'active' : notificationsService.sideNavActiveClass }">
    <div class="close-nav closeSideNav " (click)="notificationsService.closeSideNav()">
      <svg class="svg-icon " viewBox="0 0 20 20 ">
        <path fill="none "
          d="M12.71,7.291c-0.15-0.15-0.393-0.15-0.542,0L10,9.458L7.833,7.291c-0.15-0.15-0.392-0.15-0.542,0c-0.151,0.151-0.151,0.392,0,0.541L9.458,10l-2.168,2.167c-0.151,0.15-0.151,0.393,0,0.542c0.15,0.151,0.392,0.151,0.542,0L10,10.542l2.168,2.167c0.151,0.151,0.392,0.151,0.542,0c0.148-0.151,0.148-0.392,0-0.542L10.542,10l2.168-2.168C12.858,7.683,12.858,7.44,12.71,7.291z
             M10,1.188c-4.867,0-8.812,3.946-8.812,8.812c0,4.867,3.945,8.812,8.812,8.812s8.812-3.945,8.812-8.812C18.812,5.133,14.867,1.188,10,1.188z M10,18.046c-4.444,0-8.046-3.603-8.046-8.046c0-4.444,3.603-8.046,8.046-8.046c4.443,0,8.046,3.602,8.046,8.046C18.046,14.443,14.443,18.046,10,18.046z ">
        </path>
      </svg>
    </div>
    <div *ngIf="dataHolder.userInfo">
      <div class="menu-profile-pic">
        <img
          src="{{ dataHolder.userInfo?.profile_picture_url ?  dataHolder.apiBaseUrl +  '/profile-picture?accountId=' + dataHolder.userInfo.id  : 'assets/images/dummy.png' }}">
      </div>
      <div class="nav-links">
        <div><a [routerLink]="['/my-profile']" class="main-link"> Hi, {{ dataHolder.userInfo['first_name'] }} </a></div>
        <div class="nav-links">
          <div>
            <a [routerLink]="['/dashboard']" class="main-link">
              <span> <i class="fa fa-columns" aria-hidden="true"></i>
                Dashboard</span></a>
          </div>
          <div>
            <a [routerLink]="['/my-profile']" class="main-link">
              <span> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                Profile</span></a>
          </div>
          <div>
            <a [routerLink]="['/change-password']" class="main-link">
              <span> <i class="fa fa-key-modern" aria-hidden="true"></i> Change
                Password</span></a>
          </div>
          <div>
            <a (click)="profilesService.logoutUser()" class="main-link">
              <span> <i class="fa fa-sign-out" aria-hidden="true"></i> Logout</span></a>
          </div>
        </div>
        <div><a class="main-link"> Accounts</a></div>
        <div class="nav-links">
          <div>
            <a [routerLink]="['/user-accounts']" class="main-link">
              <span> <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                Users</span></a>
          </div>
          <div>
            <a [routerLink]="['/user-accounts']" [queryParams]="{ isBroker : '1' }" class="main-link">
              <span> <i class="fa fa fa-link" aria-hidden="true"></i>
                Agents</span></a>
          </div>
          <div>
            <a [routerLink]="['/user-accounts/create']" class="main-link">
              <span> <i class="fa fa-user-plus" aria-hidden="true"></i>
                Add User</span></a>
          </div>
          <div>
            <a [routerLink]="['/user-accounts/create']" [queryParams]="{ broker : 'true' }" class="main-link">
              <span> <i class="fa fa-user-plus" aria-hidden="true"></i>
                Add Agent</span></a>
          </div>
        </div>
        <div><a class="main-link"> Manage</a></div>
        <div class="nav-links">
          <div>
            <a [routerLink]="['/remainders']" class="main-link">
              <span> <i class="fa fa-calendar" aria-hidden="true"></i>
                Remainders</span></a>
          </div>
          <div>
            <a [routerLink]="['/transactions']" class="main-link">
              <span> <i class="fa fa-money" aria-hidden="true"></i>
                Transactions</span></a>
          </div>
          <div>
            <a [routerLink]="['/contact-us-messages-received']" class="main-link">
              <span> <i class="fa fa-envelope-o" aria-hidden="true"></i>
                Messages Received</span></a>
          </div>

          <div>
            <a [routerLink]="['/bulk-plots-marking']" class="main-link">
              <span> <i class="fa fa-th" aria-hidden="true"></i> Bulk Plots Marking</span></a>
          </div>
          <ng-container *ngIf="dataHolder.userInfo.is_super_admin == 'true'">
            <div>
              <a [routerLink]="['/manage-home-page-slides']" class="main-link">
                <span> <i class="fa fa-picture-o" aria-hidden="true"></i> Home Page Slides</span></a>
            </div>

            <div>
              <a [routerLink]="['/manage-custom-pages']" class="main-link">
                <span> <i class="fa fa-book" aria-hidden="true"></i> Manage Custom Pages</span></a>
            </div>
          </ng-container>

        </div>
      </div>
    </div>


    <div class="nav-links">
      <a class="main-link " [routerLink]="['/']">
        <span>HOME</span>
      </a>
    </div>

    <div class="nav-links">

      <a class="main-link " (click)="projectListExpanded = !projectListExpanded;">
        <span> PROJECTS <i class="fa"
            [ngClass]=" { 'fa-minus' : projectListExpanded , 'fa-plus' : !projectListExpanded }" aria-hidden="true"></i>
        </span>
      </a>

      <div [ngStyle]="{ 'display' : projectListExpanded ? 'block' : 'none' }">

        <div class="nav-links">
          <a class="main-link " [routerLink]="['/locations' ]">
            <span> Projects by Status </span>
          </a>

          <div class="nav-links">
            <a class="main-link " [routerLink]="['/projects/status/2' ]">
              <span> Completed Projects </span>
            </a>
            <a class="main-link " [routerLink]="['/projects/status/1' ]">
              <span> On Going Projects </span>
            </a>
            <a class="main-link " [routerLink]="['/projects/status/0' ]">
              <span> Upcoming Projects </span>
            </a>
          </div>
        </div>


        <div class="nav-links" *ngIf="dataHolder.userInfo && dataHolder.userInfo.is_super_admin == 'true'">
          <a class="main-link " [routerLink]="['/search-projects' ]">
            <span> Manage Projects </span>
          </a>

          <div class="nav-links">

            <div class="nav-links">
              <a class="main-link " [routerLink]="['/search-projects' ]">
                <span>All Projects </span>
              </a>
            </div>

            <div class="nav-links">
              <a class="main-link " [routerLink]="['/projects/new-project' ]">
                <span>Add Project </span>
              </a>
            </div>

          </div>
        </div>


        <div class="nav-links">

          <a class="main-link " [routerLink]="['/locations' ]">
            <span> All Projects </span>
          </a>

          <div class="nav-links" *ngFor="let project of dataHolder.projectMetaDeta.PROJECTS">


            <a class="main-link " [routerLink]="['/project', project.url ]">
              <span> {{ project['name'] }} </span>
            </a>

            <div class="nav-links" *ngIf="dataHolder.userInfo">
              <div class="nav-links">
                <a class="main-link " [routerLink]="['/bookings', project.url , 'new-booking' ]">
                  <span> New Booking </span>
                </a>
              </div>
              <div class="nav-links">
                <a class="main-link " [routerLink]="['/bookings']" [queryParams]="{
                projectId : project.id , minimumPrice : '' , maximumPrice : '' , minimumBrokerage : '' , maximumBrokerage : '' , bookingId : '' , userId : '' , bookedBy : '' , brokerId : '' , brokeredDeals : '-1' , additionalNotes : '' , minimumDate : '' , maximumDate : '' , bookingStatus : '-1' , sortBy : 'booked_on' , sortOrder : 'DESC' , pagination : '5' , page : '1'
              }">
                  <span> Bookings </span>
                </a>
              </div>
              <div class="nav-links">

                <a class="main-link " [routerLink]="['/plots']" [queryParams]="{
                projectId : project.id , plots : '' , is_north_facing : '-1' , is_south_facing : '-1' , is_west_facing : '-1' , is_east_facing : '-1' , min_north_dimensions : '' , max_north_dimensions : '' , min_south_dimensions : '' , max_south_dimensions : '' , min_east_dimensions : '' , max_east_dimensions : '' , min_west_dimensions : '' , max_west_dimensions : '' , min_area : '' , max_area : '' , bookingStatus : '-1' , sortBy : 'plot_no' , sortOrder : 'ASC' , pagination : '25' , page : '1'
             }">
                  <span> Plots </span>
                </a>
              </div>

              <div class="nav-links">
                <a *ngIf="dataHolder.userInfo.is_super_admin == 'true'" class="main-link "
                  [routerLink]="['/add-plot', project.id ]">
                  <span> Add Plot </span>
                </a>
              </div>
              <div class="nav-links">
                <a class="main-link " [routerLink]="['/remainders']" [queryParams]="{ projectId : project.id  }">
                  <span> Remainders </span>
                </a>
              </div>
              <div class="nav-links">
                <a class="main-link " [routerLink]="['/transactions']" [queryParams]="{ projectId : project.id  }">
                  <span> Transactions </span>
                </a>
              </div>
            </div>
          </div>
        </div>



      </div>




    </div>




    <div class="nav-links">
      <a class="main-link " [routerLink]="['/properties']">
        <span> PROPERTIES </span>
      </a>
      <div class="nav-links" *ngIf="dataHolder.userInfo && dataHolder.userInfo.is_super_admin == 'true'">
        <a class="main-link" [routerLink]="['/manage-property-types']"> <span> <i class="fa fa-building"
              aria-hidden="true"></i> Manage Property Types </span> </a>
      </div>
      <div class="nav-links" *ngFor="let property of dataHolder.projectMetaDeta.PROPERTY_TYPES">
        <a class="main-link " [routerLink]="['/properties', property.url ]">
          <span> {{ property['name'] }} </span>
        </a>
      </div>
    </div>


    <div class="nav-links">
      <a class="main-link " [routerLink]="['/cities']">
        <span> CITIES </span>
      </a>
      <div class="nav-links" *ngIf="dataHolder.userInfo && dataHolder.userInfo.is_super_admin == 'true'">
        <a class="main-link" [routerLink]="['/manage-cities']"> <span> <i class="fa fa-map" aria-hidden="true"></i>
            Manage Towns / Cities / Villages </span> </a>
      </div>
      <div class="nav-links" *ngFor="let property of dataHolder.projectMetaDeta.CITIES">
        <a class="main-link " [routerLink]="['/cities', property.name.split(' ').join('-') + '-' + property['id'] ]">
          <span> {{ property['name'] }} </span>
        </a>
      </div>
    </div>


    <div class="nav-links">
      <a class="main-link " [routerLink]="['/news']">
        <span> NEWS</span>
      </a>
    </div>
    <div class="nav-links">
      <a class="main-link " [routerLink]="['/careers']">
        <span> CAREERS</span>
      </a>
    </div>
    <div class="nav-links">
      <a class="main-link " [routerLink]="['/about-us']">
        <span> ABOUT US</span>
      </a>
    </div>

    <div class="nav-links">
      <a class="main-link " [routerLink]="['/contact-us']">
        <span> CONTACT US</span>
      </a>
    </div>

    <div class="nav-links">
      <a class="main-link ">
        <span> MORE </span>
      </a>
      <div class="nav-links">
        <a class="main-link" *ngFor="let page of dataHolder.projectMetaDeta.PAGES" [routerLink]="['/p', page.url_key ]">
          <span> {{ page.title }} </span>
        </a>

      </div>
    </div>

  </nav>

</div>
