
<main>
  <section class="responsiveContainer">
    <form [id]="isCreate ? 'addProjectImage' : attachmentData.attachment_id" [formGroup]="projectImageForm"
      (ngSubmit)="onAttachment($event)" #form="ngForm" class="form-layout onecolumn">
      <div class="form-heading">

        <h2 class="gradientColor"><span>{{ isCreate ? 'ADD PROJECT IMAGE' : attachmentData.attachment_id }}</span>
        </h2>

        <input type="hidden" name="projectId" formControlName="projectId">
        <input type="hidden" name="attachmentId" formControlName="attachmentId">

      </div>
      <div class="form-body">
        <div class="form-left">

        </div>
        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Sort order</label>
              <input  name="sortOrder" formControlName="sortOrder" type="number" placeholder="Enter sort order"  >

                <p class="errors">
                  <span *ngIf="fetchedAttachment && projectImageForm.controls['sortOrder'].errors?.required"> *required</span>
                </p>
            </div>
          </div>
        </div>

        <div class="form-right">
          <div class="form-field">

            <div class="input-container with-file-upload">
              <label>Upload Project Image</label>

              <div>
                <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                  (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label"
                  for="{{ 'X' +  ( isCreate ? dataHolderService.projectMetaDeta.PROJECT_ATTACHMENT_ID_PREFIX : attachmentData.attachment_id ) +  'Y' }}">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept="image/*" type="file" (change)="onFilesChanged($event)" formControlName="image"
                    name="image[]" type="file"
                    id="{{ 'X' +  ( isCreate ? dataHolderService.projectMetaDeta.PROJECT_ATTACHMENT_ID_PREFIX : attachmentData.attachment_id  ) +  'Y' }}">
                </label>
              </div>

              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['image'].errors?.extension"> *Only
                  jpeg,bmp,png,jpg,gif,svg files are accepted</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['image'].errors?.file"> *Only 1 file can
                  be
                  uploaded</span>
              </p>

              <div *ngIf="!isCreate" class="existingProfile twoColumnsGrid">
                <div>
                  <a download target="_blank" href="{{ attachmentData.url + '?id=' + attachmentData.attachment_id   }}">
                    <img src="{{  attachmentData.url + '?id=' + attachmentData.attachment_id   }}">
                  </a>
                </div>
              </div>

              <div *ngIf="localFileName">
                <ul class="inline">
                  <li> <a class="textualLink">{{ localFileName }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfile($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!isCreate">
            <div class="form-field">
              <div class="input-container with-label">
                <ul>
                  <li>Attachment last updated : </li>
                  <li>
                    {{ attachmentData.updated_on | momentDateDiffPipe }}
                  </li>
                  <li>( {{ attachmentData.updated_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>

                  <li>Attachment added on : </li>

                  <li>( {{ attachmentData.added_on | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ') }} ) </li>

                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <button (click)="deleteProjectImage();" *ngIf="!isCreate" type="button">DELETE</button>
        <p style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> Project data will be
          refreshed, if you make changes to project images </p>
      </div>

    </form>
  </section>
</main>
