<div class="project-card-container" [ngStyle]="{'background-image':' url(' + project?.image_url + ')'}">

  <div class="project-card-wrapper">
    <p class="city-indicator">
      <a class="grad3" [routerLink]="['/cities' , project.main_town + '-' + project.main_town_id  ]">
        <span><i class="fa fa-map-o" aria-hidden="true"></i> {{ project['main_town'] }}</span>
      </a>
      <a class="dark-bg-transparent status-indicator" [routerLink]="['/projects/status' , project.status ]">
        <span><i class="fa fa-circle" aria-hidden="true"></i> {{ project['status_text'] }}</span>
      </a>
    </p>
    <!-- <p class="status-indicator">
      <a class="dark-bg-transparent" [routerLink]="['/projects/status' , project.status ]">
        <span><i class="fa fa-circle" aria-hidden="true"></i> {{ project['status_text'] }}</span>
      </a>
    </p> -->

    <div class="dark-bg-transparent">
      <h1>

        <a [routerLink]="['/project', project.url ]">
          {{ project['name'] }}
        </a>
      </h1>
      <div class="bar"></div>
      <p>
        <a *ngFor="let projectType of project.project_types"
          [routerLink]="['/properties' , projectType.property_type_id]">
          <span><i class="fa fa-building-o" aria-hidden="true"></i> {{ projectType['project_type_name'] }}</span>
        </a>
      </p>
    </div>




  </div>



</div>
