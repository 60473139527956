import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  addTransaction(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/new-transaction' , body);
  }

  updateTransaction(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-transaction' , body);
  }

  deleteTransaction( transactionId , bookingId ) : any{
    let body = new HttpParams();
    body = body.set('transactionId', transactionId );
    body = body.set('bookingId', bookingId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-transaction', {
      params: body
    });
  }

  getTransactions(body) {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/transactions', {
      params: body
    });
  }

}
