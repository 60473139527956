import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  loading: boolean = false;
  succeeded: boolean = false;
  failed: boolean = false;
  infoDisplayed = false;

  loadingMessage: string = "";
  succeededMessage: string = "";
  failedMessage: string = "";
  infoMessage : string = "";

  sideNavActiveClass: boolean = false;

  searchBarActive : boolean = false;

  messageInterval : number = 8000;

  constructor() { }

  toggleLoading(isAdd: boolean = false, message: string = ""): void {
    this.resetNotifications();
    this.loading = isAdd;
    this.loadingMessage = message;

  }

  toggleSucceeded(isAdd: boolean = false, message: string = ""): void {
    this.resetNotifications();

    this.succeeded = isAdd;
    this.succeededMessage = message;

    if (isAdd) {
      setTimeout(() => { this.toggleSucceeded(false); }, this.messageInterval);
    }

  }
  toggleFailed(isAdd: boolean = false, message: string = ""): void {
    this.resetNotifications();

    this.failed = isAdd;
    this.failedMessage = message;

    if (isAdd) {
      setTimeout(() => { this.toggleFailed(false); }, this.messageInterval);
    }

  }

  toggleInfo(isAdd: boolean = false, message: string = ""): void {
    this.resetNotifications();

    this.infoDisplayed = isAdd;
    this.infoMessage = message;


  }

  resetNotifications(): void {

    this.loading = false;
    this.succeeded = false;
    this.failed = false;
    this.infoDisplayed = false;

    this.loadingMessage = "";
    this.succeededMessage = "";
    this.failedMessage = "";
    this.infoMessage = "";
    // this.sideNavActiveClass = this.sideNavActiveClass ? false : this.sideNavActiveClass;
  }

  openSideNav(): void {
    this.sideNavActiveClass = true;
  }

  closeSideNav():void{
    this.sideNavActiveClass = false;

  }

  openSearchBar() : void{
    this.searchBarActive = true;
    var inp = document.querySelector("input.searchInput");
    (inp as HTMLInputElement).focus();

  }

  closeSearchBar() : void{
    this.searchBarActive = false;

  }
}
