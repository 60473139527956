import { RemaindersService } from './../remainders.service';
import { Component, OnInit, Input , Output, EventEmitter  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-add-or-delete-remainder',
  templateUrl: './add-or-delete-remainder.component.html',
  styleUrls: ['./add-or-delete-remainder.component.css']
})
export class AddOrDeleteRemainderComponent implements OnInit {


  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedRemainder : boolean = false;

  remainderForm : FormGroup;

  disabled: boolean = false;

  @Input() bookingData : any = null;

  @Input() bookingId : any = null;

  @Input() remainderId : any = null;

  @Input() remainderData : any = null;

  @Output() latestFetchedBookingInfo = new EventEmitter<any>();

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private router: Router,
    private remaindersService : RemaindersService
  ) { }

  ngOnInit(): void {

    this.remainderForm = this.formBuilder.group({
      remainderId: ["1", Validators.required],
      bookingId: ["", Validators.required],
      remainderDate: ["", [ Validators.required , this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      additionalNotes: ["", [Validators.minLength(3), Validators.maxLength(512)]]
    });

    this.remainderForm.controls.bookingId.setValue(this.bookingId);

    if(!this.isCreate){
      this.updateForm();
    }
  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  onRemainder() : any{
    const formData = this.remainderForm.value;

    if(this.remainderForm.invalid){
      this.fetchedRemainder = true;
      this.notificationsService.toggleFailed(true,"Invalid data, make sure to follow guidelines before filling data");
      return;
    }
    // this.disabled = true;

    // if (!window.confirm("Are you sure ?")) {
    //   this.notificationsService.toggleFailed(true, "You have to confirm before adding the remainder");
    //   return;
    // }

    if (this.isCreate) {
      this.notificationsService.toggleLoading(true, "Creating remainder...");
      this.newRemainder(formData);
      return;

    }
    else{
      this.notificationsService.toggleLoading(true, "Updating Transaction...");
      this.updateRemainder(formData);
      return;
    }
  }

  newRemainder(formData) : void{

    this.remaindersService.addRemainder(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
      this.remainderForm.patchValue({
        remainderId: "1",
        remainderDate: "",
        additionalNotes: ""
      });
      this.fetchedRemainder = false;
      setTimeout(()=>{
        if(document.getElementById(response.rmd)){
          document.getElementById(response.rmd).scrollIntoView();
        }
      },1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateRemainder(formData) : void{

    this.remaindersService.updateRemainder(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
      setTimeout(()=>{
        if(document.getElementById(response.rmd)){
          document.getElementById(response.rmd).scrollIntoView();
        }
      },1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  deleteRemainder() : void{

    this.notificationsService.toggleLoading(true, "Deleting Transaction "+ this.remainderData.remainderId );

    this.remaindersService.deleteRemainder(this.remainderData.remainderId , this.remainderData.booking_id).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateForm(response = null): void {
    this.fetchedRemainder = true;
    if (response != null) {
      this.notificationsService.toggleSucceeded(true, response.message);
    }

    this.remainderForm.controls.remainderId.setValue(this.remainderData.remainderId);
    this.remainderForm.controls.bookingId.setValue(this.remainderData.booking_id);

    if(this.remainderData.additional_notes){
      this.remainderForm.controls.additionalNotes.setValue(this.remainderData.additional_notes);
    }

    this.remainderForm.controls.remainderDate.setValue(this.remainderData.remainder_date);

  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

}
