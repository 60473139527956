
import { ProjectAttachmentsService } from './../project-attachments.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-add-or-delete-project-attachments',
  templateUrl: './add-or-delete-project-attachments.component.html',
  styleUrls: ['./add-or-delete-project-attachments.component.css']
})
export class AddOrDeleteProjectAttachmentsComponent implements OnInit {

  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedAttachment: boolean = false;

  projectAttachmentsForm: FormGroup;

  disabled: boolean = false;

  localFileNameImage: any;

  localFileNameBrochure: any;

  // projectImageUrl = null;

  // projectSvgImageUrl = null;

  @Input() projectId: any = null;

  @Input() attachmentData: any = null;

  @Output() latestFetchedProjectInfo = new EventEmitter<any>();

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private router: Router,
    private projectAttachmentsService: ProjectAttachmentsService,
  ) { }

  ngOnInit(): void {

    this.projectAttachmentsForm = this.formBuilder.group({
      attachmentId: [this.isCreate ? '1' : this.attachmentData['attachment_id'], [Validators.required]],
      projectId: [this.projectId, [Validators.required]],
      sortOrder: [this.isCreate ? '0' : this.attachmentData['sort_order'], [Validators.required]],
      shortDescription: [this.isCreate ? "" : (this.attachmentData['short_description'] ? this.attachmentData['short_description'] : ''), [Validators.required]],
      longDescription: [this.isCreate ? "" : (this.attachmentData['long_description'] ? this.attachmentData['long_description'] : ''), []],
      thumbnail_image: ["", []],
      attachment: ["", [ ]],
    });


    // console.log(this.attachmentData);


    this.projectAttachmentsForm.controls.projectId.setValue(this.projectId);


  }



  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  onAttachment(e): any {
    // const formData = this.projectAttachmentsForm.value;

    const formData = new FormData(e.target.closest("form"));

    if (this.projectAttachmentsForm.invalid) {
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true, "Invalid data, make sure to follow guidelines before filling data");
      return;
    }

    if (this.isCreate) {

      let attachment_image = e.target.closest("form").querySelector(`input[name="thumbnail_image[]"]`);
      if (!this.validateFilesImage(attachment_image)) {
        return;
      }

      let attachment_brochure = e.target.closest("form").querySelector(`input[name="attachment[]"]`);
      if (!this.validateFilesBrochure(attachment_brochure)) {
        return;
      }

      this.notificationsService.toggleLoading(true, "Uploading project attachment...");
      this.newProjectAttachment(formData , attachment_image , attachment_brochure );
      return;

    }
    else {




      this.notificationsService.toggleLoading(true, "Updating project attachment...");
      this.updateProjectAttachment(formData );
      return;
    }
  }

  newProjectAttachment(formData , attachment_image , attachment_brochure ): void {

    this.projectAttachmentsService.addProjectAttachment(formData).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedProjectInfo.emit(response.data);
      this.projectAttachmentsForm.patchValue({
        attachmentId: "1",
        sortOrder: "0"

      });
      this.fetchedAttachment = false;
      attachment_image.value= "";
      attachment_brochure.value = "";
      this.localFileNameImage = null;
      this.localFileNameBrochure = null;
      // setTimeout(() => {
      //   if (document.getElementById(response.aht)) {
      //     document.getElementById(response.aht).scrollIntoView();
      //   }
      // }, 1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateProjectAttachment(formData): void {

    this.projectAttachmentsService.updateProjectAttachment(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedProjectInfo.emit(response.data);

      this.fetchedAttachment = false;

      this.localFileNameImage = null;
      this.localFileNameBrochure = null;


    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  deleteProjectAttachment(): void {

    this.notificationsService.toggleLoading(true, "Deleting project attachment " + this.attachmentData.attachment_id);

    this.projectAttachmentsService.deleteProjectAttachment(this.attachmentData.attachment_id, this.attachmentData.project_id).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedProjectInfo.emit(response.data);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }



  preventDefaultOnDragImage(e): void {
    e.preventDefault();
  }

  onFilesDropImage(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="thumbnail_image[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFilesImage(attachment)) {
      this.localFileNameImage = null;
      attachment.value = "";
    }
  }


  onFilesChangedImage(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="thumbnail_image[]"]`);
    // let files = e.dataTransfer.files;
    // attachment.files = files;
    if (!this.validateFilesImage(attachment)) {
      this.localFileNameImage = null;
      attachment.value = "";
    }
  }

  cancelfileImage(e): void {
    this.localFileNameImage = null;
    let attachment = e.target.closest("form").querySelector(`input[name="thumbnail_image[]"]`);
    attachment.value = "";
  }

  validateFilesImage(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Thumbnail is required and you can upload only 1 one file");
      return false;
    }
    let extensions = ["jpeg", "bmp", "png", "jpg", "gif", "svg"];
    let sizes = { minSize: 10, maxSize: 1e+7 };
    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name).toLowerCase())) {
        this.notificationsService.toggleFailed(true, "Only jpeg,bmp,png,jpg,gif,svg files are accepted");
        return false;
      }
      if (files[i].size < sizes.minSize || files[i].size > sizes.maxSize) {
        this.notificationsService.toggleFailed(true, "Maximum accepted file size is 10000KB");
        return false;
      }
      this.localFileNameImage = files[i].name;
    }
    return true;
  }

  preventDefaultOnDragBrochure(e): void {
    e.preventDefault();
  }

  onFilesDropBrochure(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="attachment[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFilesBrochure(attachment)) {
      this.localFileNameBrochure = null;
      attachment.value = "";
    }
  }


  onFilesChangedBrochure(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="attachment[]"]`);
    // let files = e.dataTransfer.files;
    // attachment.files = files;
    if (!this.validateFilesBrochure(attachment)) {
      this.localFileNameBrochure = null;
      attachment.value = "";
    }
  }

  cancelfileBrochure(e): void {
    this.localFileNameBrochure = null;
    let attachment = e.target.closest("form").querySelector(`input[name="attachment[]"]`);
    attachment.value = "";
  }

  validateFilesBrochure(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Attachment file is required and you can upload only 1 one file");
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      this.localFileNameBrochure = files[i].name;
    }
    return true;
  }

  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

}
