import { Component, OnInit } from '@angular/core';
import { DataHolderService } from './../data-holder.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

  constructor(public dataHolderService: DataHolderService) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`License | ${this.dataHolderService.projectMetaDeta.default_title}`);

  }

}
