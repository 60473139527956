import { Component, OnInit } from '@angular/core';
import { DataHolderService } from './../data-holder.service';

@Component({
  selector: 'app-useful-links',
  templateUrl: './useful-links.component.html',
  styleUrls: ['./useful-links.component.css']
})
export class UsefulLinksComponent implements OnInit {

  constructor(
    public dataHolderService: DataHolderService
  ) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Useful Links | ${this.dataHolderService.projectMetaDeta.default_title}`);

  }

}
