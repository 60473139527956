import { BookingsServiceService } from './../bookings-service.service';
import { NotificationsService } from './../notifications.service';
import { ProfilesService } from './../profiles.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';

@Component({
  selector: 'app-search-bookings',
  templateUrl: './search-bookings.component.html',
  styleUrls: ['./search-bookings.component.css']
})
export class SearchBookingsComponent implements OnInit {

  Object = Object;

  searchBookings: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  bookings: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  @Input() pageIsEmbedded: boolean = false;

  isFormVisible : boolean = false;

  projectUrl : string;

  verifiedUserIdData: any;

  verifiedBrokerIdData: any;

  bookedBy: any;

  firstTime : boolean = true;

  viewType : string = 'listView';

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private profilesService: ProfilesService,
    private bookingsServiceService: BookingsServiceService
  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Bookings | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

     let formBuilderConfig = {
      projectId: ["", Validators.required],
      minimumPrice: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      maximumPrice: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      minimumBrokerage: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      maximumBrokerage: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      bookingId: ["", [ Validators.minLength(1) ]],
      userId: ["", [ Validators.minLength(1) ]],
      bookedBy: ["", [ Validators.minLength(1) ]],
      brokerId: ["", [ Validators.minLength(1) ]],
      brokeredDeals: ["-1", [ Validators.required ]],
      additionalNotes: ["", [Validators.minLength(1), Validators.maxLength(512)]],
      minimumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      maximumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      bookingStatus: ["-1", [ Validators.required ]],
      page: ["1", []],
      pagination: [ 5 , [Validators.required]],
      sortBy: [ "booked_on", [Validators.required]],
      sortOrder: [ "DESC", [Validators.required]],
    };

    this.searchBookings = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();
    this.onFilterBookings();
  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("projectId" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          projectId: queryParams.projectId
        });
      }

      if ("minimumPrice" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          minimumPrice: queryParams.minimumPrice
        });
      }

      if ("maximumPrice" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          maximumPrice: queryParams.maximumPrice
        });
      }

      if ("minimumBrokerage" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          minimumBrokerage: queryParams.minimumBrokerage
        });
      }

      if ("maximumBrokerage" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          maximumBrokerage: queryParams.maximumBrokerage
        });
      }

      if ("bookingId" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          bookingId: queryParams.bookingId
        });
      }

      if ("userId" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          userId: queryParams.userId
        });
      }

      if ("bookedBy" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          bookedBy: queryParams.bookedBy
        });
      }

      if ("brokerId" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          brokerId: queryParams.brokerId
        });
      }

      if ("brokeredDeals" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          brokeredDeals: queryParams.brokeredDeals
        });
      }

      if ("additionalNotes" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          additionalNotes: queryParams.additionalNotes
        });
      }

      if ("minimumDate" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          minimumDate: queryParams.minimumDate
        });
      }

      if ("maximumDate" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          maximumDate: queryParams.maximumDate
        });
      }

      if ("bookingStatus" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          bookingStatus: queryParams.bookingStatus
        });
      }

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          page: queryParams.page
        });
      }

      if ("pagination" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          pagination: queryParams.pagination
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          sortBy: queryParams.sortBy
        });
      }

      if ("sortOrder" in queryParams) {
        hasQueryParams = true;
        this.searchBookings.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }

      if ("viewType" in queryParams && ["listView","weeklyView","reportView"].includes(queryParams.viewType) ) {
        hasQueryParams = true;
        this.viewType = queryParams.viewType;
      }

      if(hasQueryParams){
        this.onFilterBookings();
      }

    });
  }

  onFilterBookings() : void{
    this.firstTime = false;
    this.submitted = true;
    if (this.searchBookings.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Filtering bookings...");
    this.bookingsServiceService.searchBookings(this.searchBookings.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.bookings = response.data;
      this.paginationLinks = response.pagination_data;
      setTimeout(() => {
        if( document.querySelector("section.fullWidthTable")){
          document.querySelector("section.fullWidthTable").scrollIntoView();
        }
      }, 1000 );


      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    })

  }

  setViewVisibility(type) : void{
    this.viewType = type;
  }

  updatePageNumber(e) : void{
    // console.log(e.target);
    this.searchBookings.patchValue({
      page : e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

}
