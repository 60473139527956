import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-edit-plot',
  templateUrl: './edit-plot.component.html',
  styleUrls: ['./edit-plot.component.css']
})
export class EditPlotComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
