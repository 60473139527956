<section style="margin: 0 auto 0 auto; width: 98%;">

  <div class="project-page-container">

    <h3 class="border-heading">{{ project.name }}</h3>
    <p>{{ project.description  }}</p>

    <p>
      <a target="_blanc" href="{{ project.gmap_link ? project.gmap_link : ''  }}" class="inline">
        <i class="fa fa-map-o" aria-hidden="true"></i>
        {{project.main_town }}
      </a>
      <a *ngIf="project.acres" class="inline">
        <i class="fa fa-sitemap" aria-hidden="true"></i>
        {{ project.acres }} acres
      </a>
      <a *ngIf="project.has_plots=='1'" class="inline">
        <i class="fa fa-building" aria-hidden="true"></i>
        {{ project.num_of_plots }} plots
      </a>
      <a style="text-decoration: underline;" target="_blanc" *ngIf="project.gmap_link" href="{{ project.gmap_link   }}"
        class="inline">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        {{ project.address }}
      </a>
    </p>


    <ng-container *ngIf="project && project.GALLERY && Object.keys(project.GALLERY).length > 0">

      <!-- <h3 class="border-heading">Gallery</h3> -->

      <section *ngIf="project && project.GALLERY" class="slideshow">

        <swiper [config]="config">
          <div class="swiper-slide" *ngFor="let imageId of Object.keys(project.GALLERY)">
            <img
              alt="{{  project.name + ', ' +project.main_town + ', ' + this.dataHolderService.projectMetaDeta.default_title }}"
              [src]="project.GALLERY[imageId].url">
            <!-- <div class="bg" [style.background-image]="'url('+project.GALLERY[imageId].url+')'">
              </div> -->
          </div>

        </swiper>
      </section>
    </ng-container>

  </div>

</section>


<section style="margin: 0 auto 0 auto; width: 98%;">
  <div class="project-page-container">
    <ng-container *ngIf="project.has_plots=='1'">
      <h3 class="border-heading">LIVE DIGITAL LAYOUT</h3>
      <p>Use digital layout to get availability status, measurements etc other details of a plot. Click on a plot, to get
        its details </p>
      <!-- <p><strong>Digital Layout : </strong> Click on a plot to check for its availability. You can filter the layout by
            applying below filters</p> -->

    </ng-container>

    <p class="indicator">

      <ng-container *ngIf="project.has_plots=='1' && plotsAggregateData">

        <span style="background-color: green;">{{ plotsAggregateData.total_plots }} Total Plots </span>
        <span style="background-color: green;">{{ plotsAggregateData.available_plots }} Available Plots </span>
        <span style="background-color: red;">{{ plotsAggregateData.booked_plots }} Booked Plots </span>
        <span style="background-color: orange;">Plots Matching your query </span>

      </ng-container>



    </p>

    <ng-container *ngIf="project.has_plots=='1'">
      <form [formGroup]="plotFilter" (ngSubmit)="onFilterLayout()" #form="ngForm" class="filter-plots-layout">
        <div class="inline-form-field">
          <label>Plot number</label>
          <input formControlName="plots" name="plots" type="text" placeholder="Plot numbers (Eg : 1)">
        </div>
        <div class="inline-form-field">
          <label>Plots Facing</label>
          <select placeholder="Select option.." formControlName="plotFacing" name="plotFacing">
            <option value="north">North</option>
            <option value="south">South</option>
            <option value="west">West</option>
            <option value="east">East</option>
            <option [value]="-1">North,East,West,South</option>
          </select>
        </div>
        <div class="inline-form-field">
          <label>Plots Status</label>
          <select placeholder="Select option.." formControlName="bookingStatus" name="bookingStatus">
            <option value="Available">Available
            </option>
            <option value="Booked">Booked
            </option>
            <option [value]="-1">Available & Booked
            </option>
          </select>
        </div>
        <div class="inline-form-field">
          <button type="submit">FILTER</button>
          <button (click)="onResetLayout();" type="button">RESET</button>

        </div>

      </form>
    </ng-container>
  </div>
</section>


<ng-container *ngIf="project.has_plots=='1'">
  <div class="layout-wrapper">
    <section class="plots-layout-container" [ngClass]=" { 'full-screen' : fullScreenMode  }">
      <div style="text-align: left !important;">

        <button (click)="fullScreenMode = !fullScreenMode;" class="toggleFullScreen dark-bg-transparent"><i
            class="fa fa-television" aria-hidden="true"></i> <span>{{ fullScreenMode ? 'Exit ': 'Enter ' }} Full
            Screen</span></button>

      </div>
      <section class="flexCenter">
        <div id="scene">
          <div (onSVGInserted)="loadjs($event); initPanZoom();"
            [inlineSVG]="'/assets/site-assets/svg_layouts/'+ project.id + '_svg_layout.svg'">
          </div>
        </div>
      </section>
    </section>
  </div>
</ng-container>





<section style="margin: 0 auto 0 auto; width: 98%;">

  <div class="project-page-container">

    <ng-container *ngIf="project && project.BROCHURES && Object.keys(project.BROCHURES).length > 0">
      <h3 class="border-heading">Media & Downloads</h3>
      <section class="twoColumnsGrid">

        <div class="project-media-card" *ngFor="let imageId of Object.keys(project.BROCHURES)">
          <div class="video-or-thumbnail-container">
            <ng-container
              *ngIf="supportedVideoExtensions.includes(project.BROCHURES[imageId].url.split('.').pop()); else displayImage">
              <video controls [src]="project.BROCHURES[imageId].url"
                [poster]="project.BROCHURES[imageId].thumbnail_url"></video>
            </ng-container>
            <ng-template #displayImage>
              <div class="bg_thumb"
                [ngStyle]="{'background-image':' url(' + project.BROCHURES[imageId].thumbnail_url + ')'}">

              </div>
            </ng-template>

          </div>
          <div class="video-or-thumbnail-description">
            <p> <a class="grad3" download href="{{ project.BROCHURES[imageId].url }}">
                {{ project.BROCHURES[imageId].short_description }} </a>
            </p>
            <p> {{ project.BROCHURES[imageId].long_description ? project.BROCHURES[imageId].long_description : '' }}
            </p>
          </div>
        </div>

        <!-- <div class="plot-card-container" *ngFor="let imageId of Object.keys(project.BROCHURES)">
            <a download href="{{ project.BROCHURES[imageId].url }}" class="plot-card-wrapper flexCenter"
              [ngStyle]="{'background-image':' url(' + project.BROCHURES[imageId].thumbnail_url + ')'}">
              <h1>
                {{   project.BROCHURES[imageId].short_description }}
              </h1>
            </a>
          </div> -->

      </section>
    </ng-container>

    <ng-container *ngIf="project.gmap_iframe">
      <h3 class="border-heading">Location</h3>
      <section class="iframe-container">
        <div class="iframe-content" [innerHTML]="project.gmap_iframe | sanitizeHtml"></div>
      </section>
    </ng-container>

  </div>

</section>

<app-render-custom-page *ngIf="project"
  [fetchConfig]="{ project_id : project.id , sortBy : 'sort_order' , sortOrder : 'ASC' , pagination : '500' }">
</app-render-custom-page>


<app-contact-us [pageIsEmbedded]="true"></app-contact-us>

<div *ngIf="plotDetails?.plot_no" class="buyNowOverlay plotDetails" [ngClass]=" { 'active' : plotDetailsActive }">
  <div class="shareContainer responsive600">
    <div class="closeBuyNow" (click)="plotDetailsActive = !plotDetailsActive;">
      <svg class="svg-icon" viewBox="0 0 20 20">
        <path fill="none"
          d="M12.71,7.291c-0.15-0.15-0.393-0.15-0.542,0L10,9.458L7.833,7.291c-0.15-0.15-0.392-0.15-0.542,0c-0.149,0.149-0.149,0.392,0,0.541L9.458,10l-2.168,2.167c-0.149,0.15-0.149,0.393,0,0.542c0.15,0.149,0.392,0.149,0.542,0L10,10.542l2.168,2.167c0.149,0.149,0.392,0.149,0.542,0c0.148-0.149,0.148-0.392,0-0.542L10.542,10l2.168-2.168C12.858,7.683,12.858,7.44,12.71,7.291z M10,1.188c-4.867,0-8.812,3.946-8.812,8.812c0,4.867,3.945,8.812,8.812,8.812s8.812-3.945,8.812-8.812C18.812,5.133,14.867,1.188,10,1.188z M10,18.046c-4.444,0-8.046-3.603-8.046-8.046c0-4.444,3.603-8.046,8.046-8.046c4.443,0,8.046,3.602,8.046,8.046C18.046,14.443,14.443,18.046,10,18.046z">
        </path>
      </svg>
    </div>
    <h2 style="letter-spacing: 0.1em;">PLOT {{ plotDetails.plot_no }} </h2>
    <table>
      <tbody>
        <tr>
          <td>STATUS</td>
          <td>{{ plotDetails.booked }}</td>
        </tr>
        <tr>
          <td>Facing</td>
          <td>{{ plotDetails.plot_facing.join(' , ') }}</td>
        </tr>
        <tr>
          <td>Measurements (ft)</td>
          <td>{{ plotDetails.plot_measurements.join(', ') }}</td>
        </tr>
        <tr>
          <td>Area(sq.ft)</td>
          <td>{{ plotDetails.area }} </td>
        </tr>
        <tr>
          <td>Pricing</td>
          <td>₹ {{ plotDetails.pricing }} </td>
        </tr>
        <tr *ngIf="authenticated && plotDetails.booking_id && plotDetails.booking_id!='null'">
          <td>Booking ID</td>
          <td><a [routerLink]="['/bookings', plotDetails.booking_id ]"> {{ plotDetails.booking_id }} </a></td>
        </tr>
        <tr>
          <td colspan="2">
            <a [routerLink]="['/contact-us']" [queryParams]="{ plots : plotDetails.plot_no , project : project.id }">
              <span style="text-decoration: underline;">Contact us </span> to know more details or to book this plot
            </a>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <a>Pricing, Measurements, facing & booking status may not be accurate, contact head office
              for accurate information </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
