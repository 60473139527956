<div class="dashboardCWrapper">
  <div class="dashboardC">

    <div class="fullWidthSection dashboard">

    </div>
    <h3 style="text-align: center;font-size:2em;margin-top: 0.3em;" class="">DASHBOARD</h3>

    <section class="responsiveContainer">
      <section class="dashboardContainer">

        <div class="twoColumnsGrid">
          <div>
            <h1 class="">PROJECTS</h1>
            <div>
              <a class="dasboardLink ">
                <span>Number of Projects</span>
                <span>{{ Object.keys(profilesService.dashboardData.projects_data).length }}</span>
              </a>
            </div>
          </div>
          <div>
            <h1 class="">PROJECTS LIST</h1>
            <div>
              <a *ngFor="let id of Object.keys(profilesService.dashboardData.projects_data)" routerLink="./"
                fragment="{{ id }}" class="dasboardLink ">
                <span>{{ profilesService.dashboardData.projects_data[id].name }} ,
                  {{ profilesService.dashboardData.projects_data[id].main_town }}</span>
              </a>
            </div>
          </div>
        </div>

        <section *ngFor="let id of Object.keys(profilesService.dashboardData.projects_data)">

          <h1 id="{{ id }}" style="text-align: center;" class="">
            {{ profilesService.dashboardData.projects_data[id].name | uppercase }}
            {{ profilesService.dashboardData.projects_data[id].main_town | uppercase }} </h1>

          <!-- <div class="twoColumnsGrid"> -->
          <div>
            <h1 class=""> PLOTS </h1>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id }">
                <span>Number of Plots</span>
                <span>{{ profilesService.dashboardData.projects_data[id].total_plots }} </span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , bookingStatus : '1' }">
                <span>Booked Plots</span>
                <span>{{ profilesService.dashboardData.projects_data[id].booked_plots }}</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , bookingStatus : '0' }">
                <span>Available Plots</span>
                <span>{{ profilesService.dashboardData.projects_data[id].available_plots }}</span>
              </a>
            </div>
            <div>

              <div>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_north_facing : '1' }">
                  <span>Total North Facing Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].north_facing_plots }}</span>
                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_north_facing : '1' , bookingStatus : '1' }">
                  <span> North Facing Booked Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].north_facing_booked_plots }}</span>
                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_north_facing : '1' , bookingStatus : '0' }">
                  <span> North Facing Available Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].north_facing_available_plots }}</span>
                </a>
              </div>

              <div>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_south_facing : '1' }">
                  <span>Total South Facing Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].south_facing_plots }}</span>

                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_south_facing : '1' , bookingStatus : '1'  }">
                  <span> South Facing Booked Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].south_facing_booked_plots }}</span>

                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_south_facing : '1' , bookingStatus : '0' }">
                  <span> South Facing Available Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].south_facing_available_plots }}</span>

                </a>
              </div>

              <div>

                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_east_facing : '1' }">
                  <span>Total East Facing Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].east_facing_plots }}</span>

                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_east_facing : '1' , bookingStatus : '1' }">
                  <span> East Facing Booked Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].east_facing_booked_plots }}</span>

                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_east_facing : '1' , bookingStatus : '0'  }">
                  <span> East Facing Available Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].east_facing_available_plots }}</span>

                </a>

              </div>

              <div>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_west_facing : '1' }">
                  <span>Total West Facing Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].west_facing_plots }}</span>
                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_west_facing : '1' , bookingStatus : '1' }">
                  <span> West Facing Booked Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].west_facing_booked_plots }}</span>
                </a>
                <a class="dasboardLink " [routerLink]="[ '/plots']"
                  [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , is_west_facing : '1'  , bookingStatus : '0'}">
                  <span> West Facing Available Plots</span>
                  <span>{{ profilesService.dashboardData.projects_data[id].west_facing_available_plots }}</span>
                </a>

              </div>

            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'area' , sortOrder : 'DESC' }">
                <span>Plot Area ( Max to Min )</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'area' , sortOrder : 'ASC' }">
                <span>Plot Area ( Min to Max )</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'plot_no' , sortOrder : 'DESC' }">
                <span>Plot number ( Max to Min )</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/plots']"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'plot_no' , sortOrder : 'ASC' }">
                <span>Plot number ( Min to Max )</span>
              </a>

            </div>
          </div>


          <div>
            <h1 class=""> BOOKINGS </h1>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id  }">
                <span>Number of Bookings</span>
                <span>{{ profilesService.dashboardData.projects_data[id].total_bookings }}</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , bookingStatus : '0'  }">
                <span>Pending Payment</span>
                <span>{{ profilesService.dashboardData.projects_data[id].total_bookings_with_pending_payment }}</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , bookingStatus : '1'  }">
                <span>Full Payment</span>
                <span>{{ profilesService.dashboardData.projects_data[id].total_bookings_with_full_payment }}</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , brokeredDeals : '1'  }">
                <span>Brokered Bookings</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , bookedBy : dataHolderService.userInfo.id  }">
                <span>Your Bookings</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'price' , sortOrder : 'DESC'  }">
                <span>Max to Min Price</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'price' , sortOrder : 'ASC'  }">
                <span>Min to Max Price</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'payment_status' , sortOrder : 'DESC'  }">
                <span>Max to Min Payment Received</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'payment_status' , sortOrder : 'ASC'  }">
                <span>Min to Max Payment Received</span>
              </a>

            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'booked_on' , sortOrder : 'DESC'  }">
                <span>Recent Bookings</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/bookings' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'booked_on' , sortOrder : 'ASC'  }">
                <span>Oldest Bookings</span>
              </a>
              <a class="dasboardLink "
                [routerLink]="[ '/bookings', profilesService.dashboardData.projects_data[id].url,'new-booking' ]">
                <span>New Booking</span>
              </a>
            </div>
          </div>
          <div>
            <h1 class=""> REMAINDERS </h1>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().format('YYYY-MM-DD')  }">
                <span>Upcoming Remainders</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().format('YYYY-MM-DD') , maximumDate : moment().format('YYYY-MM-DD') }">
                <span> Today </span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().format('YYYY-MM-DD') , maximumDate : moment().add( 7 ,'days').format('YYYY-MM-DD')  }">
                <span>This Week</span>
              </a>

            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().add(1,'days').format('YYYY-MM-DD') , maximumDate : moment().add(1,'days').format('YYYY-MM-DD')   }">
                <span>Tomorrow</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().format('YYYY-MM-DD') , maximumDate : moment().add(3,'days').format('YYYY-MM-DD')   }">
                <span>Next 3 days</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().format('YYYY-MM-DD') , maximumDate : moment().add(30,'days').format('YYYY-MM-DD')   }">
                <span>Next 30 days</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , maximumDate :  moment().subtract(1,'days').format('YYYY-MM-DD') , minimumDate : moment().subtract(1,'days').format('YYYY-MM-DD')   }">
                <span>Yesterday</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , maximumDate : moment().format('YYYY-MM-DD') , minimumDate : moment().subtract(3,'days').format('YYYY-MM-DD')   }">
                <span>Past 3 days</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , maximumDate : moment().format('YYYY-MM-DD') , minimumDate : moment().subtract(7,'days').format('YYYY-MM-DD')   }">
                <span>Last week</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , maximumDate : moment().format('YYYY-MM-DD') , minimumDate : moment().subtract(30,'days').format('YYYY-MM-DD')   }">
                <span>Last 30 Days</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'remainder_date' , sortOrder : 'DESC'   }">
                <span>Newest Remainders</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'remainder_date' , sortOrder : 'ASC'   }">
                <span>Oldest Remainders</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'payment_status' , sortOrder : 'DESC'   }">
                <span>Max to Min Payment Received</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'payment_status' , sortOrder : 'ASC'   }">
                <span>Min to Max Payment Received</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/remainders' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id    }">
                <span>All Remainders</span>
              </a>
            </div>
          </div>
          <div>
            <h1 class=""> TRANSACTIONS </h1>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , minimumDate : moment().format('YYYY-MM-DD') , maximumDate : moment().format('YYYY-MM-DD')  }">
                <span>Today </span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , addedBy : dataHolderService.userInfo.id    }">
                <span>Your Transactions</span>
              </a>

            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , maximumDate : moment().format('YYYY-MM-DD') , minimumDate : moment().subtract(7,'days').format('YYYY-MM-DD')   }">
                <span>Last week</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , maximumDate : moment().format('YYYY-MM-DD') , minimumDate : moment().subtract(30,'days').format('YYYY-MM-DD')   }">
                <span>Last 30 Days</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'added_on' , sortOrder : 'DESC'   }">
                <span>Newest Transactions</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'added_on' , sortOrder : 'ASC'   }">
                <span>Oldest Transactions</span>
              </a>
            </div>
            <div>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'credit' , sortOrder : 'DESC'   }">
                <span>Max to Min Transaction amount</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id , sortBy : 'credit' , sortOrder : 'ASC'   }">
                <span>Min to Max Transaction amount</span>
              </a>
              <a class="dasboardLink " [routerLink]="[ '/transactions' ]"
                [queryParams]="{ projectId : profilesService.dashboardData.projects_data[id].id    }">
                <span>All Transactions</span>
              </a>
            </div>
          </div>
          <!-- </div> -->
        </section>

        <h1 style="text-align: center;" class=""> ACCOUNTS </h1>
        <!-- <div class="twoColumnsGrid"> -->
        <div>
          <div>
            <a class="dasboardLink " [routerLink]="[ '/user-accounts' ]">
              <span>Active Accounts</span>
              <span>{{ profilesService.dashboardData.users.total_accounts }} </span>
            </a>
            <a class="dasboardLink " [routerLink]="[ '/user-accounts' ]" [queryParams]="{ isBroker : '1' }">
              <span>Agents</span>
              <span>{{ profilesService.dashboardData.users.total_brokers }} </span>
            </a>
            <a class="dasboardLink " [routerLink]="[ './' ]" fragment="partners">
              <span>Partner Accounts</span>
              <span>{{ profilesService.dashboardData.users.total_partners }} </span>
            </a>
          </div>
          <div>
            <a [routerLink]="['/user-accounts/create']" class="dasboardLink ">
              <span> Add User</span></a>
            <a [routerLink]="['/user-accounts/create']" [queryParams]="{ broker : 'true' }" class="dasboardLink ">
              <span> Add Agent</span></a>
          </div>
        </div>
        <h1 style="text-align: center;" id="partners" class="">PARTNERS</h1>
        <div>
          <div>
            <a *ngFor="let userId of Object.keys(profilesService.dashboardData.partners_data)" class="dasboardLink "
              [routerLink]="[ '/user-accounts/edit',userId ]">
              <span>{{ profilesService.dashboardData.partners_data[userId].full_name }}</span>
              <span> Last Active :
                <ng-container *ngIf="profilesService.dashboardData.partners_data[userId].last_seen">
                  {{ profilesService.dashboardData.partners_data[userId].last_seen | momentDateDiffPipe }}
                </ng-container>
              </span>
            </a>
          </div>
        </div>
        <!-- </div> -->


        <p>CONTACT SOFTWARE TEAM</p>
        <p>For any issues or assistance or feedback, contact us </p>
        <div>
          <a class="dasboardLink " *ngFor="let m of dataHolderService.projectMetaDeta.DEV_MOBILES.split(',')"
            href="tel:{{ m }}">
            <span> <i class="fa fa-phone" aria-hidden="true"></i> {{ m }}</span>
          </a>
        </div>
        <div>
          <a class="dasboardLink " *ngFor="let m of dataHolderService.projectMetaDeta.DEV_EMAILS.split(',')"
            href="mailto:{{ m }}">
            <span> <i class="fa fa-envelope" aria-hidden="true"></i> {{ m }}</span>
          </a>
        </div>

      </section>
    </section>

  </div>
</div>
