<section class="property-types">
  <h3 class="border-heading">Areas where we have our properties</h3>
  <p>Cities / Towns / Villages where the company has properties.</p>
  <section class="twoColumnsGrid">
    <div *ngFor="let property of dataHolderService.projectMetaDeta.CITIES" [ngStyle]="{'background-image':' url(' + property?.image_url + ')'}">
      <p>
        <a class=" " [routerLink]="['/cities',  property.name.split(' ').join('-') + '-' + property['id'] ]">
          <span> {{ property['name'] }} </span>
        </a>
      </p>
    </div>
  </section>
</section>

<section *ngIf="!pageIsEmbedded">
  <app-city-projects *ngFor="let property of dataHolderService.projectMetaDeta.CITIES"
    [pageIsEmbedded]="true" [cityId]="property.name.split(' ').join('-') + '-' + property['id']">
  </app-city-projects>
</section>
