import { NotificationsService } from './../notifications.service';
import { ProfilesService } from './../profiles.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';


@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.css']
})
export class SearchUsersComponent implements OnInit {

  Object = Object;

  searchUsers: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  users: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  @Input() pageIsEmbedded: boolean = false;

  isFormVisible : boolean = false;

  viewType : string = 'listView';

  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private profilesService: ProfilesService
  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Users | ${this.dataHolderService.projectMetaDeta.default_title}`);


    let formBuilderConfig = {
      name: ["", [Validators.minLength(1), Validators.maxLength(100)]],
      email: ["", [Validators.email]],
      mobile: ["", [Validators.minLength(1), Validators.maxLength(20), this.customPatternValid({ pattern: /^[0-9+]+$/, msg: 'Mobile number can contain only digits and an optional + prefix' })]],
      userId: ["", [Validators.minLength(1)]],
      isBroker: ["-1", []],
      page: ["1", []],
      pagination: [this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.default, [Validators.required]],
    };

    this.searchUsers = this.formBuilder.group(formBuilderConfig);

    this.checkForQueryParams();
    this.onFilterUsers();

  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("name" in queryParams) {
        hasQueryParams = true;
        this.searchUsers.patchValue({
          name: queryParams.name
        });
      }

      if ("email" in queryParams) {
        hasQueryParams = true;
        this.searchUsers.patchValue({
          email: queryParams.email
        });
      }

      if ("mobile" in queryParams) {
        hasQueryParams = true;
        this.searchUsers.patchValue({
          mobile: queryParams.mobile
        });
      }

      if ("userId" in queryParams) {
        hasQueryParams = true;
        this.searchUsers.patchValue({
          userId: queryParams.userId
        });
      }

      if ("isBroker" in queryParams && [-1, 0, 1].includes(parseInt(queryParams.isBroker))) {
        hasQueryParams = true;
        this.searchUsers.patchValue({
          isBroker: queryParams.isBroker
        });
      }

      if ("page" in queryParams) {
        this.searchUsers.patchValue({
          page: queryParams.page
        });
        hasQueryParams = true;
      }

      if ("pagination" in queryParams) {
        this.searchUsers.patchValue({
          pagination: queryParams.pagination
        });
        hasQueryParams = true;
      }

      if ("viewType" in queryParams && ["listView","weeklyView","reportView"].includes(queryParams.viewType) ) {
        hasQueryParams = true;
        this.viewType = queryParams.viewType;
      }

      if(hasQueryParams){
        this.onFilterUsers();
      }

    });

  }

  onFilterUsers(): void {

    this.submitted = true;
    if (this.searchUsers.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }

    this.dataHolderService.updatePageMeta(`Users | Page ${this.searchUsers.value.page} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    this.notificationsService.toggleLoading(true, "Searching users...");
    this.profilesService.searchUsers(this.searchUsers.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.users = response.data;
      this.paginationLinks = response.pagination_data;
      setTimeout(() => {
        if( document.querySelector("section.fullWidthTable")){
          document.querySelector("section.fullWidthTable").scrollIntoView();
        }
      }, 1000 );
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }

  setViewVisibility(type) : void{
    this.viewType = type;
  }

  updatePageNumber(e) : void{
    // console.log(e.target);
    this.searchUsers.patchValue({
      page : e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      if (control.value && !control.value.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

}
