<section class="responsiveContainer">
  <h1>Disclaimer for Sri Saraswathi Homes</h1>

  <p>If you require any more information or have any questions about our site's disclaimer, please feel free to contact
    us.</p>

  <h2>Disclaimers for Sri Saraswathi Homes</h2>

  <p>All the information on this website - https://www.srisaraswathihomes.com/ - is published in good faith and for general
    information purpose only. Sri Saraswathi Homes does not make any warranties about the completeness, reliability and
    accuracy of this information. Any action you take upon the information you find on this website , is strictly at your own risk. Sri Saraswathi Homes will not be liable for any losses and/or damages in
    connection with the use of our website.</p>

  <p>Plot Measurements, facing, booking status, brochures & images etc may not be accurate. contact head office
    for accurate information </p>

  <p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to
    provide only quality links to useful and ethical websites, we have no control over the content and nature of these
    sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site
    owners and content may change without notice and may occur before we have the opportunity to remove a link which may
    have gone 'bad'.</p>

  <h2>Consent</h2>

  <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>

  <h2>Update</h2>

  <p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>
</section>
