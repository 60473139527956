<main>
  <section class="responsiveContainer">
    <form id="myProfileForm" [formGroup]="myProfileForm" (ngSubmit)="onProfileEdit()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <span (click)="toggleFormLayout()" data-form-id="a1" class="toggleFormLayout grad7"> <i class="fa fa-arrows"
            aria-hidden="true"></i> CHANGE VIEW</span>
        <div class="display-edit">
          <a routerLink="/dashboard"> DASHBOARD </a>
        </div>
        <h2 class="gradientColor"><span>EDIT PROFILE</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Name</label>
              <input formControlName="firstName" type="text" name="firstName" placeholder="Name">
              <p class="errors">

                <span *ngIf="fetchedProfile && myProfileForm.controls['firstName'].errors?.required"> *required</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['firstName'].errors?.minlength"> *Minimum 2
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['firstName'].errors?.maxlength"> *Maximum 200
                  characters </span>

              </p>
            </div>
          </div>
          <!-- <div class="form-field">
            <div class="input-container with-label">
              <label>Middle name</label>
              <input formControlName="middleName" type="text" name="middleName" placeholder="Middle name">
              <p class="errors">

                <span *ngIf="fetchedProfile && myProfileForm.controls['middleName'].errors?.minlength"> *Minimum 2
                  characters </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['middleName'].errors?.maxlength"> *Maximum 30
                  characters </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['middleName'].errors?.invalidMsg"> *Only Alphabets
                  with no spaces are allowed </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Last name</label>
              <input formControlName="lastName" type="text" name="lastName" placeholder="Last name">

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.required"> *required</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.minlength"> *Minimum 2
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.maxlength"> *Maximum 30
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['lastName'].errors?.invalidMsg"> *Only Alphabets
                  with
                  no spaces are allowed </span>
              </p>
            </div>
          </div> -->
          <div class="form-field">
            <div class="input-container with-select">
              <label for="gender">Gender</label>

              <select placeholder="Gender.." formControlName="gender" name="gender"
              id="gender">
              <option [value]="0">Female
              </option>
              <option  [value]="1">Male
              </option>
              <option  [value]="-1">Prefer not to answer
              </option>
            </select>
            </div>
          </div>



        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Email</label>
              <input  type="email" formControlName="email" name="email">
              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['email'].errors?.email"> *Invalid email </span>

              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>WhatsApp</label>
              <input formControlName="whatsAppNumber" type="text" name="whatsAppNumber" placeholder="+91xxxxxxxxxx">

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['whatsAppNumber'].errors?.minlength"> *Minimum 10
                  digits </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['whatsAppNumber'].errors?.maxlength"> *Maximum 20
                  digits </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['whatsAppNumber'].errors?.invalidMsg"> *Mobile
                  number
                  can contain only digits and an optional + prefix </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Mobile No.</label>
              <input formControlName="mobileNumber" type="text"   name="mobileNumber" placeholder="+91xxxxxxxxxx">

              <p class="errors">

                <span *ngIf="fetchedProfile && myProfileForm.controls['mobileNumber'].errors?.minlength"> *Minimum 10
                  digits
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['mobileNumber'].errors?.maxlength"> *Maximum 20
                  digits
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['mobileNumber'].errors?.invalidMsg"> *Mobile
                  number
                  can contain only digits and an optional + prefix </span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">
          <div class="form-field">
            <div class="input-container with-label">
              <label>About</label>
              <textarea formControlName="about" type="text" name="about"
                placeholder="Write a few words about you"></textarea>

              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['about'].errors?.minlength"> *Minimum 2 characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['about'].errors?.maxlength"> *Maximum 256
                  characters
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Address</label>
              <textarea formControlName="address" type="text" name="address"
                placeholder="Your location(optional)"></textarea>
              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['address'].errors?.minlength"> *Minimum 3
                  characters
                </span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['address'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-file-upload">
              <label>Profile picture</label>
              <div class="existingProfile twoColumnsGrid">
                <div>
                  <div *ngIf="hasProfilePicture">
                    <a target="_blank" href="{{ profilePictureUrl }}">
                      <img src="{{ profilePictureUrl }}">
                    </a>
                    <button (click)="deletePicture()" type="button"> <i class="fa fa-times" aria-hidden="true"></i>
                      DELETE
                      PICTURE</button>
                  </div>
                </div>
                <div class="flexCenter">

                </div>
              </div>

              <div>
                <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                  (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label" for="profilePicture">
                  <span>Select new profile picture or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your
                    picture here</span>
                  <input accept="image/*" (change)="onFilesChanged($event)" formControlName="profilePicture"
                    name="profilePicture[]" type="file" id="profilePicture">
                </label>
              </div>
              <p class="errors">
                <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.extension"> *Only png,
                  jpg/jpeg, bmp, gif files are accepted</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.fileSize"> *Minimum file
                  size is 10KB and maximum file size is 10000KB</span>
                <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.file"> *Only 1 file can
                  be
                  uploaded</span>
                <!-- <span *ngIf="fetchedProfile && myProfileForm.controls['profilePicture'].errors?.image?.message"> *Upload a square shaped image(preferably) with at least 150px width and 150px height</span> -->
              </p>
              <div *ngIf="hasPreview" class="existingProfile twoColumnsGrid">
                <div>
                  <div *ngIf="hasPreview">
                    <a download target="_blank" href="{{ imageUri }}">
                      <img src="{{ imageUri }}">
                    </a>
                    <button (click)="cancelProfilePicture()"> <i class="fa fa-times" aria-hidden="true"></i> REMOVE
                      PICTURE</button>
                  </div>
                </div>
                <div class="flexCenter">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button class="" type="submit">SAVE PROFILE</button>
        <p style="font-size: 0.8em;"><i aria-hidden="true" class="fa fa-info-circle"></i> Contact software team for updating email or mobile number</p>
      </div>
    </form>
  </section>
</main>
