import { Component, OnInit } from '@angular/core';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  constructor(public dataHolderService: DataHolderService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Careers | ${this.dataHolderService.projectMetaDeta.default_title}`);

  }

}
