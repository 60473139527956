<section class="responsiveContainer">
  <h1 class="border-heading">News - {{ this.dataHolderService.projectMetaDeta.default_title }}</h1>
  <section class="twoColumnsGrid" *ngIf="totalResults > 0">
    <section *ngFor="let page of pages" class="articleLinkWrapper">
      <article class="articleLinkContainer">
        <h2  >
          <a  [routerLink]="['/news', (page['title'] ? page['title'].split(' ').join('-') : '') + '-' + page['id'] ]" class="headingLink">{{ page['title'] ? page['title'] : '' }}</a>
        </h2>
        <p style="margin: 1em;" class="time">
          <time>{{ page['added_on'] | momentDateTransformPipe : ('MMM Do, YYYY ')   }}</time>
        </p>


        <img *ngIf="page['image_url']" class="articleImage" [src]="page['image_url']"
          [alt]=" page['title'] ? page['title'] : '' + ',' + dataHolderService.projectMetaDeta.default_title ">

        <p class="description">{{ page['description'] ? page['description'] : '' }}</p>
        <a class="viewLink" [routerLink]="['/news',  (page['title'] ? page['title'].split(' ').join('-') : '') + '-' + page['id'] ]">READ</a>
      </article>
    </section>
  </section>
</section>

<div class="pagination">
  <div *ngIf="totalResults < 1">
    <p> No results found </p>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchPages.value.page }" [routerLink]="['./']" [queryParams]="{
        is_active :  searchPages.value.is_active,
        page : paginationLink == '...' ? '1' : paginationLink
    }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
