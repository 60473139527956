import { CustomPagesService } from './../custom-pages.service';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-blog-articles',
  templateUrl: './blog-articles.component.html',
  styleUrls: ['./blog-articles.component.css']
})
export class BlogArticlesComponent implements OnInit {

  Object = Object;

  pages: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  searchPages: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private customPagesService: CustomPagesService
  ) { }

  ngOnInit(): void {
    let formBuilderConfig = {
      page: ["1", []],
    };

    this.searchPages = this.formBuilder.group(formBuilderConfig);
    this.dataHolderService.updatePageMeta(`Blog & News - Page : ${this.searchPages.value.page} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    this.checkForQueryParams();
    this.onFilterPages();

  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {

      let hasQueryParams = false;

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          page: queryParams.page
        });
      }

      if (hasQueryParams) {
        this.onFilterPages();
      }

    });
  }

  onFilterPages(): void {
    this.notificationsService.toggleLoading(true, "Loading Articles...");
    this.customPagesService.getBlogArticles(this.searchPages.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        this.totalResults = 0;
        return;
      }

      this.dataHolderService.updatePageMeta(`Blog & News - Page : ${this.searchPages.value.page} | ${this.dataHolderService.projectMetaDeta.default_title}`);


      this.totalResults = response.total_results;
      this.pages = response.data;
      this.paginationLinks = response.pagination_data;
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

  updatePageNumber(e): void {
    // console.log(e.target);
    this.searchPages.patchValue({
      page: e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }



}
