import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.css']
})
export class EditBookingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
