<section class="responsiveContainer">
  <h2>Property Types </h2>
  <p>You can bind property types etc to Projects</p>
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr>
          <th style="text-align: right;" colspan="3">
            <a data-attachment-is-create="true" (click)="onAddEditProjectImage($event)" class="grad1"
              style="color:white;"><span><i aria-hidden="true" class="fa fa-plus"></i>Add Property Type </span></a>
          </th>
        </tr>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Image</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let slide of slides;index as img">
          <td>
            <a data-attachment-is-create="false" attr.data-attachment-data="{{ slides[img] | json }}"
              (click)="onAddEditProjectImage($event)"><span><i aria-hidden="true" class="fa fa fa-pencil"></i> Edit
              </span></a>

          </td>
          <td>{{ slides[img]['name'] }}</td>
          <td>
            <a target="_blank" href="{{ slides[img]['image_url']  }}"><img class="thum" src="{{ slides[img]['image_url']  }}" />
            </a>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</section>

<section class="fullScreenPopup" *ngIf="projectImageFormVisible">

  <div class="container">

    <div (click)="projectImageFormVisible = !projectImageFormVisible;" class="closeFullScreenPopup">
      <button> <i class="fa fa-times" aria-hidden="true"></i> CANCEL </button>
    </div>

    <app-add-edit-property-type [isCreate]="projectImageFormIsCreate" [attachmentData]="projectImageFormData"
      (latestFetchedProjectInfo)="updateProjectPage($event)">
    </app-add-edit-property-type>


  </div>



</section>
