<section class="responsiveContainer property-types">
  <h3 class="border-heading">PROPERTY TYPES</h3>
  <p>The Company has experience in different property types and understands your needs, your taste & standards.</p>
  <section class="twoColumnsGrid">
    <div *ngFor="let property of dataHolderService.projectMetaDeta.PROPERTY_TYPES" [ngStyle]="{'background-image':' url(' + property?.image_url + ')'}">
      <p>
        <a class=" " [routerLink]="['/properties', property.url ]">
          <span> {{ property['name'] }} </span>
        </a>
      </p>
    </div>
  </section>
</section>

<section *ngIf="!pageIsEmbedded">
  <app-property-type-projects *ngFor="let property of dataHolderService.projectMetaDeta.PROPERTY_TYPES"
    [pageIsEmbedded]="true" [propertyTypeId]="property.url">

  </app-property-type-projects>
</section>
