import { DataHolderService } from './../data-holder.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contactThewebbl : boolean = false;

  constructor(public dataHolderService : DataHolderService) { }
  moment: any = moment;


  ngOnInit(): void {
  }

  scrollToTop() : void{
    window.scrollTo(0, 0);
  }

}
