<section class="fullScreenFlex forgotPasswordFormContainer" style="min-height: 100vh !important;">
  <section class="responsiveContainer twoColumnsGrid">
    <section class="flexCenter">
      <div>
        <h2> Update Password </h2>
        <p> Your account will be logged out of all existing sessions after password change</p>
        <p> If you don't remember your old password, contact software team for further assistance</p>

      </div>
    </section>
    <section class="forgotPasswordForm flexCenter">
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSetNewPassword()" #form="ngForm" class="application-forms">
        <div class="form-wrapper">
          <div class="form-description">
            <h1>CHANGE PASSWORD</h1>
            <p>Set new password to your account</p>

          </div>
          <div class="form-input no-box-shadow">
            <div class="icon-container">
              <div (click)="oldPasswordHidden = !oldPasswordHidden">
                <i style="color: grey;" class="fa" [ngClass]=" { 'fa-eye' : oldPasswordHidden , 'fa-eye-slash' : !oldPasswordHidden }" aria-hidden="true"></i>
              </div>
              <div>
                <input [type]="oldPasswordHidden ? 'password' : 'text'" type="password" formControlName="oldPassword"  name="oldPassword" placeholder="OLD PASSWORD">
              </div>
            </div>
            <p>
              <span *ngIf="submitted && forgotPasswordForm.controls['oldPassword'].errors?.required"> *required</span>
              <span *ngIf="submitted && forgotPasswordForm.controls['oldPassword'].errors?.minlength"> *Minimum 8
                characters </span>
              <span *ngIf="submitted && forgotPasswordForm.controls['oldPassword'].errors?.maxlength"> *Maximum 128
                characters </span>
            </p>
          </div>
          <br>
          <div class="form-input no-box-shadow">
            <div class="icon-container">
              <div (click)="newPasswordHidden = !newPasswordHidden">
                <i style="color: grey;" class="fa" [ngClass]=" { 'fa-eye' : newPasswordHidden , 'fa-eye-slash' : !newPasswordHidden }" aria-hidden="true"></i>
              </div>
              <div>
                <input [type]="newPasswordHidden ? 'password' : 'text'"  formControlName="newPassword"  name="newPassword" placeholder="NEW PASSWORD">
              </div>
            </div>
            <p>
              <span *ngIf="submitted && forgotPasswordForm.controls['newPassword'].errors?.required"> *required</span>
              <span *ngIf="submitted && forgotPasswordForm.controls['newPassword'].errors?.minlength"> *Minimum 8
                characters </span>
              <span *ngIf="submitted && forgotPasswordForm.controls['newPassword'].errors?.maxlength"> *Maximum 128
                characters </span>
            </p>
          </div>
          <div class="form-input">
            <button type="submit" class="noselect">DONE</button>
          </div>
        </div>
      </form>
    </section>
  </section>
</section>
