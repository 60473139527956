import { ContactUsService } from './../contact-us.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NotificationsService } from './../notifications.service';
import * as moment from 'moment';

@Component({
  selector: 'app-search-contact-us',
  templateUrl: './search-contact-us.component.html',
  styleUrls: ['./search-contact-us.component.css']
})
export class SearchContactUsComponent implements OnInit {

  Object = Object;

  searchMessages: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  messages: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  @Input() pageIsEmbedded: boolean = false;

  isFormVisible: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private contactUsService: ContactUsService
  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Messages Received | Contact us | ${this.dataHolderService.projectMetaDeta.default_title}`);


    let formBuilderConfig = {
      name: ["", [Validators.minLength(1), Validators.maxLength(100)]],
      email: ["", [Validators.email]],
      mobile: ["", [Validators.minLength(1), Validators.maxLength(20), this.customPatternValid({ pattern: /^[0-9+]+$/, msg: 'Mobile number can contain only digits and an optional + prefix' })]],
      page: ["1", []],
      minimumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      maximumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      pagination: [this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.default, [Validators.required]],
      sortBy: [ "contacted_on", [Validators.required]],
      sortOrder: [ "DESC", [Validators.required]],
    };

    this.searchMessages = this.formBuilder.group(formBuilderConfig);

    this.checkForQueryParams();
    this.onFilterMessages();

  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("name" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          name: queryParams.name
        });
      }

      if ("email" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          email: queryParams.email
        });
      }

      if ("mobile" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          mobile: queryParams.mobile
        });
      }


      if ("minimumDate" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          minimumDate: queryParams.minimumDate
        });
      }

      if ("maximumDate" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          maximumDate: queryParams.maximumDate
        });
      }

      if ("sortOrder" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }
      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchMessages.patchValue({
          sortBy: queryParams.sortBy
        });
      }


      if ("page" in queryParams) {
        this.searchMessages.patchValue({
          page: queryParams.page
        });
        hasQueryParams = true;
      }

      if ("pagination" in queryParams) {
        this.searchMessages.patchValue({
          pagination: queryParams.pagination
        });
        hasQueryParams = true;
      }

      if (hasQueryParams) {
        this.onFilterMessages();
      }

    });

  }

  onFilterMessages(): void {

    this.submitted = true;
    if (this.searchMessages.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }

    this.dataHolderService.updatePageMeta(`Messages Received | Contact us | Page ${this.searchMessages.value.page} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    this.notificationsService.toggleLoading(true, "Getting messages...");
    this.contactUsService.searchContactUs(this.searchMessages.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.messages = response.data;
      this.paginationLinks = response.pagination_data;
      if (document.querySelector("section.user-gallery")) {
        document.querySelector("section.user-gallery").scrollIntoView();

      }
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updatePageNumber(e): void {
    // console.log(e.target);
    this.searchMessages.patchValue({
      page: e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      if (control.value && !control.value.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

}
