import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomePageSlidesService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  getSlides() : any{
    return this.httpClient.get( this.dataHolderService.apiBaseUrl + '/home-page-slides' );
  }

  addSlide(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/add-home-page-slide' , body);
  }

  updateSlide(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-home-page-slide' , body);
  }

  deleteSlide( attachmentId  ) : any{
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-home-page-slide', {
      params: body
    });
  }

}
