import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDateTransformPipe'
})
export class MomentDateTransformPipePipe implements PipeTransform {

  transform(value: Date | string | moment.Moment, dateFormat: string): any {
    // console.log(value);
    return moment(value).format(dateFormat);
  }

}
