import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ProjectsService } from './../projects.service';
import { ProfilesService } from './../profiles.service';

@Component({
  selector: 'app-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.css']
})
export class AddEditProjectComponent implements OnInit {


  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedProject: boolean = false;

  projectForm: FormGroup;

  disabled: boolean = false;

  projectId: any = null;

  localFileNameSVG: any;

  localFileNameIMG: any;



  projectImageUrl = null;

  projectSvgImageUrl = null;

  projectImageFormVisible = false;
  projectImageFormData : null;
  projectImageFormIsCreate : boolean = false;

  projectAttachmentFormVisible = false;
  projectAttachmentFormData : null;
  projectAttachmentIsCreate : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    public projectsService: ProjectsService,
    private router: Router,
    private profilesService: ProfilesService
  ) {


  }

  ngOnInit(): void {

    this.projectForm = this.formBuilder.group({
      projectId: ["", []],
      name: ["", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200)
      ]],
      description: ["", [
        Validators.required,
        Validators.minLength(1)
      ]],
      address: ["", [Validators.minLength(1)]],
      gmap_iframe: ["", [Validators.minLength(1)]],
      gmap_link: ["", [Validators.minLength(1)]],
      acres: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })
      ]],
      main_town: ["", [
        Validators.required,
        Validators.minLength(1)
      ]],
      status: ["0", [
        Validators.required
      ]],
      has_plots: ["0", [
        Validators.required
      ]],
      image_url: ["", [
        RxwebValidators.extension({ extensions: ["jpeg", "bmp", "png", "jpg", "gif", "svg"] }),
        RxwebValidators.file({ minFiles: 1, maxFiles: 1 })
      ]],
      svg_layout: ["", [
        RxwebValidators.extension({ extensions: ["svg"] }),
        RxwebValidators.file({ minFiles: 1, maxFiles: 1 }),
      ]],
      project_type_ids: [[], []]
    });


    if (this.isCreate) {
      this.dataHolderService.updatePageMeta(`Add Project | ${this.dataHolderService.projectMetaDeta.default_title}`);

      this.route.params.subscribe((params: Params) => {
      });

    }
    else {

      this.route.params.subscribe((params: Params) => {
        this.projectForm.controls.projectId.setValue(params["projectId"]);
      });
      this.dataHolderService.updatePageMeta(`${this.projectForm.value.projectId} | Edit Project | ${this.dataHolderService.projectMetaDeta.default_title}`);
      this.updateForm();

    }

  }
  updateForm(response = null): void {

    this.fetchedProject = true;
    if (response != null) {
      this.notificationsService.toggleSucceeded(true, response.message);
    }



    this.projectForm.controls.name.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.name);
    this.projectForm.controls.description.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.description);

    // if( this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.address ){
    this.projectForm.controls.address.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.address);

    // }

    // if(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.gmap_iframe){
    this.projectForm.controls.gmap_iframe.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.gmap_iframe);

    // }

    // if(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.gmap_link){
    this.projectForm.controls.gmap_link.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.gmap_link);

    // }

    // if(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.acres){
    this.projectForm.controls.acres.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.acres);

    // }

    // if(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.main_town){
    this.projectForm.controls.main_town.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.main_town_id);

    // }
    // if(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.status){
    this.projectForm.controls.status.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.status);

    // }

    this.projectForm.controls.has_plots.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.has_plots == '1' ? '1' : '0');

    // if(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.project_type_ids){
    this.projectForm.controls.project_type_ids.setValue(this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.project_type_ids);

    // }

    this.projectImageUrl = this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.image_url + "?&timestamp=" + new Date().getTime();
    this.projectSvgImageUrl = this.projectsService.fetchedProjectInfo[this.projectForm.value.projectId].projectData.svg_layout_link + "?&timestamp=" + new Date().getTime();

  }


  onProject(e): void {

    const formData = new FormData(e.target.closest("form"))

    if (this.projectForm.invalid) {
      this.fetchedProject = true;
      this.notificationsService.toggleFailed(true, "Invalid data, make sure to follow guidelines before filling data");
      return;
    }

    if (!window.confirm("Are you sure?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm before proceeding to the next step");
      return;
    }

    if (this.isCreate) {
      let svg_file = e.target.closest("form").querySelector(`input[name="svg_layout[]"]`);
      if (this.projectForm.value.has_plots == '1' && !this.validateFilesSVG(svg_file)) {
        return;
      }

      let img_url = e.target.closest("form").querySelector(`input[name="image_url[]"]`);

      if (!this.validateFilesIMG(img_url)) {
        return;
      }

      this.notificationsService.toggleLoading(true, "Adding Project...");
      this.newProject(formData);
      return;

    }
    else {
      this.notificationsService.toggleLoading(true, "Updating project...");
      this.updateProject(formData);
      return;
    }

  }

  newProject(formData): void {
    this.projectsService.newProject(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;

      }

      this.notificationsService.toggleSucceeded(true, response.message);

      // this.dataHolderService.userInfo = response["user"];


      setTimeout(() => {
        this.router.navigate(['/projects/edit-project', response.data['url']]);
      }, 500);



    });
  }

  updateProject(formData): void {

    this.projectsService.updateProject(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);


      this.onProjectDataChange(response["data"], response);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }


  onProjectDataChange(data, response = null): void {

    this.projectImageFormVisible = false;
    this.projectAttachmentFormVisible = false;

    this.localFileNameIMG = null;
    this.localFileNameSVG = null;

    this.projectsService.fetchedProjectInfo = data;
    this.updateForm(response);

  }

  updateProjectPage(latestFetchedProjectInfo): void {
    this.onProjectDataChange(latestFetchedProjectInfo);
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

  preventDefaultOnDragSVG(e): void {
    e.preventDefault();
  }

  onFilesDropSVG(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="svg_layout[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFilesSVG(attachment)) {
      this.localFileNameSVG = null;
      attachment.value = "";
    }
  }

  onFilesChangedSVG(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="svg_layout[]"]`);
    // let files = e.dataTransfer.files;
    // attachment.files = files;
    if (!this.validateFilesSVG(attachment)) {
      this.localFileNameSVG = null;
      attachment.value = "";
    }
  }


  cancelfileSVG(e): void {
    // console.log(e);
    this.localFileNameSVG = null;
    let attachment = e.target.closest("form").querySelector(`input[name="svg_layout[]"]`);
    attachment.value = "";
  }

  validateFilesSVG(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Digital Layout is required");
      return false;
    }
    let extensions = ["svg"];
    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name).toLowerCase())) {
        this.notificationsService.toggleFailed(true, "Only svg files are accepted");
        return false;
      }

      this.localFileNameSVG = files[i].name;
    }
    return true;
  }


  preventDefaultOnDragIMG(e): void {
    e.preventDefault();
  }

  onFilesDropIMG(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="image_url[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFilesIMG(attachment)) {
      this.localFileNameIMG = null;
      attachment.value = "";
    }
  }

  onFilesChangedIMG(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="image_url[]"]`);
    // let files = e.dataTransfer.files;
    // attachment.files = files;
    if (!this.validateFilesIMG(attachment)) {
      this.localFileNameIMG = null;
      attachment.value = "";
    }
  }


  cancelfileIMG(e): void {
    // console.log(e);
    this.localFileNameIMG = null;
    let attachment = e.target.closest("form").querySelector(`input[name="image_url[]"]`);
    attachment.value = "";
  }

  validateFilesIMG(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Image is required and you can upload only one image");
      return false;
    }
    let extensions = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name).toLowerCase())) {
        this.notificationsService.toggleFailed(true, "Only png, jpg/jpeg, bmp, gif files are accepted");
        return false;
      }

      this.localFileNameIMG = files[i].name;
    }
    return true;
  }


  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

  onAddEditProjectImage(e) : void{


    let a = e.target.closest("a");
    this.projectImageFormIsCreate = a.getAttribute("data-attachment-is-create") == 'true' ? true : false;

    // console.log(a.getAttribute("data-attachment-is-create"));

    this.projectImageFormData  = this.projectImageFormIsCreate ? {} : JSON.parse(a.getAttribute("data-attachment-data"));
    this.projectImageFormVisible = true;

  }

  onAddEditProjectAttachment(e) : void{


    let a = e.target.closest("a");
    this.projectAttachmentIsCreate = a.getAttribute("data-attachment-is-create") == 'true' ? true : false;

    // console.log(a.getAttribute("data-attachment-is-create"));

    this.projectAttachmentFormData  = this.projectAttachmentIsCreate ? {} : JSON.parse(a.getAttribute("data-attachment-data"));
    this.projectAttachmentFormVisible = true;

  }


}
