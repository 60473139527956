import { ProfilesService } from './../profiles.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, FormGroupExtension, RxFormBuilder } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  onecolumn: boolean = false;

  fetchedProfile: boolean = false;

  hasProfilePicture: boolean = false;

  profilePictureUrl: string = "";

  myProfileForm: FormGroup;

  hasPreview: boolean = false;

  imageUri: any;

  disabled : boolean = false;

  constructor(
    private formBuilder: RxFormBuilder, public notificationsService: NotificationsService,
    public myProfileService: ProfilesService,
    private dataHolderService: DataHolderService
  ) {

  }


  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Edit Profile - ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    this.myProfileForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      // lastName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30), this.customPatternValid({ pattern: /^[a-zA-Z]+$/, msg: 'Only alphabets with no spaces are allowed' })]],
      // middleName: ["", [ Validators.minLength(2), Validators.maxLength(30), this.customPatternValid({ pattern: /^[a-zA-Z]+$/, msg: 'Only alphabets with no spaces are allowed' })]],
      gender : ["-1"],
      about: ["", [Validators.minLength(2), Validators.maxLength(250)]],
      address: ["", [Validators.minLength(3), Validators.maxLength(512)]],
      whatsAppNumber: ["", [Validators.minLength(10), Validators.maxLength(20), this.customPatternValid({ pattern: /^[0-9+]+$/, msg: 'Mobile number can contain only digits and an optional + prefix' })]],
      mobileNumber: ["", [ Validators.minLength(10), Validators.maxLength(20), this.customPatternValid({ pattern: /^[0-9+]+$/, msg: 'Mobile number can contain only digits and an optional + prefix' })]],
      email: ["", [ Validators.email]],
      profilePicture: ["", [
        RxwebValidators.extension({ extensions: ['png', 'jpg', 'jpeg', 'bmp', 'gif'] }),
        RxwebValidators.file({ minFiles: 1, maxFiles: 1 }),
        RxwebValidators.fileSize({ minSize: 10000, maxSize: 1e+7 }),
      ]]
    });

    this.updateForm();
  }



  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      if (control.value && !control.value.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  updateForm(response = null): void {

    this.fetchedProfile = true;
    if(response!=null){
        this.notificationsService.toggleSucceeded(true, response.message );
    }

    this.imageUri = null;
    this.hasPreview = false;
    this.hasProfilePicture = false;

    this.myProfileForm.controls.firstName.setValue(this.dataHolderService.userInfo.first_name);
    // this.myProfileForm.controls.middleName.setValue(this.dataHolderService.userInfo.middle_name);
    // this.myProfileForm.controls.lastName.setValue(this.dataHolderService.userInfo.last_name);
    this.myProfileForm.controls.gender.setValue(this.dataHolderService.userInfo.gender);
    this.myProfileForm.controls.about.setValue(this.dataHolderService.userInfo.about);
    this.myProfileForm.controls.address.setValue(this.dataHolderService.userInfo.address);
    this.myProfileForm.controls.email.setValue(this.dataHolderService.userInfo.email);
    this.myProfileForm.controls.whatsAppNumber.setValue(this.dataHolderService.userInfo.whatsapp_number);
    this.myProfileForm.controls.mobileNumber.setValue(this.dataHolderService.userInfo.mobile_number);

    if (this.dataHolderService.userInfo.profile_picture_url.length > 5) {
      this.hasProfilePicture = true;
      this.profilePictureUrl = this.dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + this.dataHolderService.userInfo.id + "&timestamp=" + new Date().getTime();
    }
  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  preventDefaultOnDrag(e): void {
    e.preventDefault();
  }

  onFilesDrop(e): void {
    e.preventDefault();
    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    let files = e.dataTransfer.files;
    profilePictureInput.files = files;
    if (!this.validateFiles()) {
      this.hasPreview = false;

      profilePictureInput.value = "";

    }
  }
  onFilesChanged(e): void {
    e.preventDefault();
    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    if (!this.validateFiles()) {
      this.hasPreview = false;
      profilePictureInput.value = "";
    }
  }

  validateFiles(): boolean {

    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    let files = profilePictureInput.files;

    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Only 1 file can be uploaded");
      return false;
    }

    let extensions = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
    let sizes = { minSize: 10000, maxSize: 1e+7 };

    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name))) {
        this.notificationsService.toggleFailed(true, "Only png, jpg/jpeg, bmp, gif files are accepted");
        return false;
      }

      if (files[i].size < sizes.minSize || files[i].size > sizes.maxSize) {
        this.notificationsService.toggleFailed(true, "Minimum file size is 10KB and maximum file size is 10000KB");
        return false;
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (_event) => {
        this.imageUri = reader.result;
        this.onProfileEdit();
      }
    }

    this.hasPreview = true;

    return true;

  }

  cancelProfilePicture() : void{
    this.hasPreview = false;
    this.imageUri = null;
    let profilePictureInput = <HTMLInputElement>document.querySelector(`input[name="profilePicture[]"]`);
    profilePictureInput.value = "";
  }

  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

  onProfileEdit(): void {
    const formData = new FormData(<HTMLFormElement>document.getElementById('myProfileForm'));
    if(this.myProfileForm.invalid){
      this.notificationsService.toggleFailed(true,"Invalid data, make sure to follow guidelines before filling data");
      return;
    }

    this.notificationsService.toggleLoading(true, "Updating profile..");

    this.myProfileService.updateMyProfile(formData).subscribe((response : any)=>{
      this.notificationsService.toggleLoading(false);

      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }

      this.dataHolderService.userInfo = response["user"];

      this.updateForm(response);
      // this.categoryName = "";
    },
    error =>{
      this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }

  deletePicture():void{

    this.notificationsService.toggleLoading(true, "Deleting profile picture");

    this.myProfileService.deleteMyProfilePicture().subscribe((response : any)=>{
      this.notificationsService.toggleLoading(false);

      if(!response.status){
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }


      this.dataHolderService.userInfo = response["user"];
      this.updateForm(response);
      // this.categoryName = "";
    },
    error =>{
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
    });
  }


}

