import { PropertyTypesService } from './../property-types.service';
import { Component, OnInit , Input, Output, EventEmitter  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-add-edit-property-type',
  templateUrl: './add-edit-property-type.component.html',
  styleUrls: ['./add-edit-property-type.component.css']
})
export class AddEditPropertyTypeComponent implements OnInit {



  moment: any = moment;

  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedAttachment: boolean = false;

  projectImageForm: FormGroup;

  disabled: boolean = false;

  localFileName: any;


  @Input() attachmentData: any = null;

  @Output() latestFetchedProjectInfo = new EventEmitter<any>();


  attachmentUrl: any;

  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private router: Router,
    private projectCitiesService: PropertyTypesService,
  ) { }

  ngOnInit(): void {

    this.projectImageForm = this.formBuilder.group({
      attachmentId: [ this.isCreate ? '1' : this.attachmentData['id'] , [ ]],
      name: [ this.isCreate ? '' : this.attachmentData['name'] , [ Validators.required , Validators.maxLength(150)  ]],
      image: ["", []],
    });


    // if (!this.isCreate) {
    //   this.updateForm();
    // }
  }



  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  onAttachment(e): any {

    const formData = new FormData(e.target.closest("form"));

    if (this.projectImageForm.invalid) {
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true, "Invalid data, make sure to follow guidelines before filling data");
      return;
    }

    if (this.isCreate) {

      let attachment = e.target.closest("form").querySelector(`input[name="image[]"]`);
      if (!this.validateFiles(attachment)) {
        return;
      }

      this.notificationsService.toggleLoading(true, "Adding Property Type...");
      this.newProjectImage(formData, attachment);
      return;

    }
    else {
      this.notificationsService.toggleLoading(true, "Updating Property Type...");
      this.updateProjectImage(formData);
      return;
    }
  }

  newProjectImage(formData, attachment): void {

    this.projectCitiesService.addPropertyType(formData).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedProjectInfo.emit(response.data);
      this.projectImageForm.patchValue({
        attachmentId: "1",
      });
      this.fetchedAttachment = false;
      attachment.value = "";
      this.localFileName = null;
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateProjectImage(formData): void {

    this.projectCitiesService.updatePropertyType(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedProjectInfo.emit(response.data);
      this.localFileName = null;

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  deleteProjectImage(): void {

    this.notificationsService.toggleLoading(true, "Deleting Property Type..");

    this.projectCitiesService.deletePropertyType(this.attachmentData.id).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedProjectInfo.emit(response.data);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }


  preventDefaultOnDrag(e): void {
    e.preventDefault();
  }

  onFilesDrop(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="image[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFiles(attachment)) {
      this.localFileName = null;
      attachment.value = "";
    }
  }


  onFilesChanged(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="image[]"]`);
    if (!this.validateFiles(attachment)) {
      this.localFileName = null;
      attachment.value = "";
    }
  }

  cancelfile(e): void {
    this.localFileName = null;
    let attachment = e.target.closest("form").querySelector(`input[name="image[]"]`);
    attachment.value = "";
  }

  validateFiles(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Image file is required and you can upload only 1 one file");
      return false;
    }
    let extensions = ["jpeg", "bmp", "png", "jpg", "gif", "svg", "gif"];
    let sizes = { minSize: 10, maxSize: 1e+7 };
    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name).toLowerCase())) {
        this.notificationsService.toggleFailed(true, "Only jpeg,bmp,png,jpg,gif,svg,gif files are accepted");
        return false;
      }
      if (files[i].size < sizes.minSize || files[i].size > sizes.maxSize) {
        this.notificationsService.toggleFailed(true, "Maximum accepted file size is 10000KB");
        return false;
      }
      this.localFileName = files[i].name;
    }
    return true;
  }

  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }


}
