import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  addAttachment(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/new-attachment' , body);
  }

  updateAttachment(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-attachment' , body);
  }

  deleteAttachment( attachmentId , bookingId ) : any{
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId );
    body = body.set('bookingId', bookingId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-attachment', {
      params: body
    });
  }

}
