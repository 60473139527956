import { ProjectsService } from './../projects.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DataHolderService } from './../data-holder.service';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationsService } from '../notifications.service';


@Component({
  selector: 'app-search-projects',
  templateUrl: './search-projects.component.html',
  styleUrls: ['./search-projects.component.css']
})
export class SearchProjectsComponent implements OnInit {

  Object = Object;

  onecolumn: boolean = false;

  submitted: boolean = false;

  firstTime: boolean = true;

  @Input() pageIsEmbedded: boolean = false;

  projects: Observable<any>;

  paginationLinks: any;

  totalResults: number = 0;

  searchProjects: FormGroup;

  isFormVisible: boolean = false;

  viewType: string = 'listView';

  constructor(
    private formBuilder: RxFormBuilder,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    public notificationsService: NotificationsService,
    private projectsServiceService: ProjectsService

  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Projects | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    let formBuilderConfig = {
      projectId: [""],
      name: ["", [Validators.minLength(1)]],
      description: ["", [Validators.minLength(1)]],
      address: ["", [Validators.minLength(1)]],
      minimumAcres: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid minimum value' })
      ]],
      maximumAcres: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid maximum value' })
      ]],
      main_town: ["-1", []],
      status: ["-1", [Validators.required]],
      has_plots: ["-1", [Validators.required]],
      page: ["1", []],
      pagination: [25, [Validators.required]],
      sortBy: ["name", [Validators.required]],
      sortOrder: ["ASC", [Validators.required]],
    }

    this.searchProjects = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();
    this.onFilterProjects();
  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("projectId" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          projectId: queryParams.projectId
        });
      }

      if ("minimumAcres" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          minimumAcres: queryParams.minimumAcres
        });
      }

      if ("maximumAcres" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          maximumAcres: queryParams.maximumAcres
        });
      }

      if ("name" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          name: queryParams.name
        });
      }

      if ("description" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          description: queryParams.description
        });
      }

      if ("address" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          address: queryParams.address
        });
      }

      if ("main_town" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          main_town: queryParams.main_town
        });
      }

      if ("status" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          status: queryParams.status
        });
      }

      if ("status" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          status: queryParams.status
        });
      }

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          page: queryParams.page
        });
      }

      if ("pagination" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          pagination: queryParams.pagination
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          sortBy: queryParams.sortBy
        });
      }

      if ("sortOrder" in queryParams) {
        hasQueryParams = true;
        this.searchProjects.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }

      if ("viewType" in queryParams && ["listView", "weeklyView", "reportView"].includes(queryParams.viewType)) {
        hasQueryParams = true;
        this.viewType = queryParams.viewType;
      }

      if (hasQueryParams) {
        this.onFilterProjects();
      }

    });
  }

  onFilterProjects(): void {
    this.firstTime = false;
    this.submitted = true;
    if (this.searchProjects.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Filtering projects...");
    this.projectsServiceService.searchProjects(this.searchProjects.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.projects = response.data;
      this.paginationLinks = response.pagination_data;
      setTimeout(() => {
        if (document.querySelector("section.fullWidthTable")) {
          document.querySelector("section.fullWidthTable").scrollIntoView();
        }
      }, 1000);

      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })

  }

  setViewVisibility(type): void {
    this.viewType = type;
  }

  updatePageNumber(e): void {
    // console.log(e.target);
    this.searchProjects.patchValue({
      page: e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }
}
