import { CustomPagesService } from './custom-pages.service';
import { PlotsService } from './plots.service';
import { ProjectsService } from './projects.service';
import { BookingsServiceService } from './bookings-service.service';
import { ProfilesService } from './profiles.service';
import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public router: Router,
    private dataHolder: DataHolderService,
    private profilesService: ProfilesService,
    private bookingsServiceService: BookingsServiceService,
    private projectsService : ProjectsService,
    private plotsService : PlotsService,
    private customPagesService : CustomPagesService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const expectedResponse = route.data.expectedResponse;

    if ("customAuthApi" in route.data) {

      if (route.data.customAuthApi == 'myProfile') {
        return this.profilesService.fetchMyProfile().pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/partner-login']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          return true;
        }));
      }

      if (route.data.customAuthApi == 'fetchUserProfile') {
        return this.profilesService.fetchUserProfile(route.params.userId).pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/partner-login']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          this.profilesService.fetchedUserInfo = response["user"];
          return true;
        }));
      }

      if (route.data.customAuthApi == 'dashboard') {
        return this.profilesService.getDashboardData().pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/partner-login']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          this.profilesService.dashboardData = response["data"];
          return true;
        }));
      }

      if (route.data.customAuthApi == 'fetchBookingData') {

        let body = new HttpParams();
        body = body.set('bookingId', route.params.bookingId);

        return this.bookingsServiceService.searchBookings(body).pipe(map((response) => {
          this.setMeta(response);


          if (!response[expectedResponse]) {
            this.router.navigate(['/dashboard']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          this.bookingsServiceService.fetchedBookingInfo = response["data"];
          return true;
        }));


      }

      if (route.data.customAuthApi == 'fetchPropertyTypeProjects') {

        return this.projectsService.filterProjects('projectType',route.params.propertyId).pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/']);
            return false;
          }
          // this.dataHolder.userInfo = response["auth_user"];
          this.projectsService.filteredProjects = response["data"];
          return true;
        }));

      }

      if (route.data.customAuthApi == 'fetchStatusTypeProjects') {

        return this.projectsService.filterProjects('projectStatus',route.params.statusId.split("-").pop()).pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/']);
            return false;
          }
          // this.dataHolder.userInfo = response["auth_user"];
          this.projectsService.filteredProjects = response["data"];
          return true;
        }));

      }

      if (route.data.customAuthApi == 'fetchCityProjects') {

        return this.projectsService.filterProjects('projectCity',route.params.cityId.split("-").pop()).pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/']);
            return false;
          }
          // this.dataHolder.userInfo = response["auth_user"];
          this.projectsService.filteredProjects = response["data"];
          return true;
        }));

      }




      if (route.data.customAuthApi == 'editPlot') {

        let body = new HttpParams();
        body = body.set('projectId', route.params.projectId);
        body = body.set('plots', route.params.plotNo);


        return this.bookingsServiceService.searchPlots(body).pipe(map((response) => {
          this.setMeta(response);


          if (!response[expectedResponse]) {
            this.router.navigate(['/dashboard']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          this.plotsService.fetchedPlotData = response["data"];
          return true;
        }));


      }

      if (route.data.customAuthApi == 'fetchProjectData') {

        let body = new HttpParams();
        body = body.set('projectId', route.params.projectId);

        return this.projectsService.searchProjects(body).pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/dashboard']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          this.projectsService.fetchedProjectInfo = response["data"];
          return true;
        }));


      }


      if (route.data.customAuthApi == 'fetchCustomPageEdit') {

        let body = new HttpParams();
        body = body.set('attachmentId', route.params.customPageId);

        return this.customPagesService.fetchCustomPageEdit(body).pipe(map((response) => {
          this.setMeta(response);

          if (!response[expectedResponse]) {
            this.router.navigate(['/dashboard']);
            return false;
          }
          this.dataHolder.userInfo = response["auth_user"];
          this.customPagesService.fetchedCustomPage = response["data"];
          return true;
        }));


      }

    }

    return this.profilesService.checkAuthentication().pipe(map((response) => {
      this.setMeta(response);
      if (!response[expectedResponse]) {
        this.router.navigate(['/partner-login']);
        return false;
      }
      this.dataHolder.userInfo = response["auth_user"];
      return true;
    }));

  }

  setMeta(response): void {

    // if(response['updateMeta'] && response['updateMeta'] == 'true'){
    //   this.dataHolder.projectMetaDeta = response['projectMetaData'];

    // }

    // if(response['projectMetaData'])

  }
}
