import { BookingsServiceService } from './../bookings-service.service';
import { NotificationsService } from './../notifications.service';
import { ProfilesService } from './../profiles.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';


@Component({
  selector: 'app-search-plots',
  templateUrl: './search-plots.component.html',
  styleUrls: ['./search-plots.component.css']
})
export class SearchPlotsComponent implements OnInit {

  Object = Object;

  searchPlots: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  plots: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  @Input() pageIsEmbedded: boolean = false;

  isFormVisible: boolean = false;

  projectUrl: string;


  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private profilesService: ProfilesService,
    private bookingsServiceService: BookingsServiceService
  ) { }

  ngOnInit(): void {

    this.dataHolderService.updatePageMeta(`Plots | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    let formBuilderConfig = {

      projectId: ["", Validators.required],
      plots: ["", Validators.minLength(1)],
      is_north_facing: ["-1", [Validators.required]],
      is_south_facing: ["-1", [Validators.required]],
      is_west_facing: ["-1", [Validators.required]],
      is_east_facing: ["-1", [Validators.required]],
      min_north_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      max_north_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      min_south_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      max_south_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      min_east_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      max_east_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      min_west_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      max_west_dimensions: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      min_area: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      max_area: ["", [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })]],
      bookingStatus: ["-1", [Validators.required]],
      page: ["1", []],
      pagination: [25, [Validators.required]],
      sortBy: ["plot_no", [Validators.required]],
      sortOrder: ["ASC", [Validators.required]],

      bookingId: ["", [ Validators.minLength(1) ]],
      minimumPrice: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      maximumPrice: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      minimumBrokerage: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],
      maximumBrokerage: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
        this.customPatternValid({ pattern: /^\d*(\.\d{1,2})?$/, msg: 'Enter a valid indian currency' })
      ]],

      userId: ["", [ Validators.minLength(1) ]],
      userName: ["", [ Validators.minLength(1) ]],

      bookedBy: ["", [ Validators.minLength(1) ]],
      bookedByName: ["", [ Validators.minLength(1) ]],

      brokeredDeals: ["-1", [ Validators.required ]],
      brokerId: ["", [ Validators.minLength(1) ]],
      brokerName: ["", [ Validators.minLength(1) ]],

      paymentStatus: ["-1", [Validators.required]],

      minimumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],
      maximumDate: ["", [this.customDateValidator({ datePattern: 'YYYY-MM-DD', msg: 'Select a valid Date' })]],


    };

    this.searchPlots = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();
    this.onFilterPlots();

  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("isFormVisible" in queryParams) {
        this.isFormVisible = true;
      }

      if ("projectId" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          projectId: queryParams.projectId
        });
      }

      if ("plots" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          plots: queryParams.plots
        });
      }

      if ("is_north_facing" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          is_north_facing: queryParams.is_north_facing
        });
      }

      if ("is_south_facing" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          is_south_facing: queryParams.is_south_facing
        });
      }

      if ("is_west_facing" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          is_west_facing: queryParams.is_west_facing
        });
      }

      if ("is_east_facing" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          is_east_facing: queryParams.is_east_facing
        });
      }

      if ("min_north_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          min_north_dimensions: queryParams.min_north_dimensions
        });
      }

      if ("max_north_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          max_north_dimensions: queryParams.max_north_dimensions
        });
      }

      if ("min_south_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          min_south_dimensions: queryParams.min_south_dimensions
        });
      }

      if ("max_south_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          max_south_dimensions: queryParams.max_south_dimensions
        });
      }

      if ("min_east_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          min_east_dimensions: queryParams.min_east_dimensions
        });
      }

      if ("max_east_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          max_east_dimensions: queryParams.max_east_dimensions
        });
      }

      if ("min_west_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          min_west_dimensions: queryParams.min_west_dimensions
        });
      }

      if ("max_west_dimensions" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          max_west_dimensions: queryParams.max_west_dimensions
        });
      }

      if ("min_area" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          min_area: queryParams.min_area
        });
      }

      if ("max_area" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          max_area: queryParams.max_area
        });
      }

      if ("bookingStatus" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          bookingStatus: queryParams.bookingStatus
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          sortBy: queryParams.sortBy
        });
      }

      if ("sortOrder" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          page: queryParams.page
        });
      }

      if ("pagination" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          pagination: queryParams.pagination
        });
      }


      if ("minimumPrice" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          minimumPrice: queryParams.minimumPrice
        });
      }

      if ("maximumPrice" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          maximumPrice: queryParams.maximumPrice
        });
      }

      if ("minimumBrokerage" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          minimumBrokerage: queryParams.minimumBrokerage
        });
      }

      if ("maximumBrokerage" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          maximumBrokerage: queryParams.maximumBrokerage
        });
      }

      if ("userId" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          userId: queryParams.userId
        });
      }

      if ("userName" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          userName: queryParams.userName
        });
      }

      if ("bookedBy" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          bookedBy: queryParams.bookedBy
        });
      }

      if ("bookedByName" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          bookedByName: queryParams.bookedByName
        });
      }

      if ("brokeredDeals" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          brokeredDeals: queryParams.brokeredDeals
        });
      }

      if ("brokerId" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          brokerId: queryParams.brokerId
        });
      }

      if ("brokerName" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          brokerName: queryParams.brokerName
        });
      }

      if ("paymentStatus" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          paymentStatus: queryParams.paymentStatus
        });
      }

      if ("minimumDate" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          minimumDate: queryParams.minimumDate
        });
      }

      if ("maximumDate" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          maximumDate: queryParams.maximumDate
        });
      }

      if ("bookingId" in queryParams) {
        hasQueryParams = true;
        this.searchPlots.patchValue({
          bookingId: queryParams.bookingId
        });
      }


      if (hasQueryParams) {
        this.onFilterPlots();
      }


    });
  }

  onFilterPlots(): void {
    this.submitted = true;
    if (this.searchPlots.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Filtering plots...");
    this.bookingsServiceService.searchPlots(this.searchPlots.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        if (!this.pageIsEmbedded) {
          this.notificationsService.toggleFailed(true, response.message);

        }
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.plots = response.data;
      this.paginationLinks = response.pagination_data;
      if (document.querySelector("section.single-plot-cards")) {
        document.querySelector("section.single-plot-cards").scrollIntoView();

      }
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })

  }

  updatePageNumber(e): void {
    // console.log(e.target);
    this.searchPlots.patchValue({
      page: e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

}
