<div class="fullWidthSection pjts" style="margin-top: 0em;">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin: 0; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">PROJECTS
  </h3>

  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="radio-toggler noselect">
      <div class="radio-container">
        <input (change)="setViewVisibility('listView')" name="viewType" [checked]="viewType == 'listView'" type="radio"
          value="listView" id="listView">
        <label for="listView">
          <i class="fa fa-table" aria-hidden="true"></i> LIST
        </label>
        <input (change)="setViewVisibility('reportView')" name="viewType" [checked]="viewType == 'reportView'"
          type="radio" value="reportView" id="reportView">
        <label for="reportView">
          <i class="fa fa-th-large" aria-hidden="true"></i> SUMMARY
        </label>
      </div>
    </div>

    <pre></pre>

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS & REPORTS</span>
    </div>
  </div>
</section>

<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchProjects" (ngSubmit)="onFilterProjects()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">
      </div>
      <div class="form-body">
        <div class="form-left">


          <div class="form-field">
            <div class="input-container with-label">
              <label>Project ID</label>
              <input name="projectId" formControlName="projectId" type="text"
                placeholder="{{ dataHolderService.projectMetaDeta.PROJECT_ID_PREFIX }}XXXX">
              <p class="errors">
                <span *ngIf="submitted && searchProjects.controls['projectId'].errors?.minlength"> *Minimum 1 charcter
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Project Name</label>
              <input formControlName="name" type="text" name="name" placeholder="Enter Project Name" />

              <p class="errors">
                <span *ngIf="submitted && searchProjects.controls['name'].errors?.minlength"> *Minimum 1
                  character
                </span>
                <span *ngIf="submitted && searchProjects.controls['name'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Project Description</label>
              <textarea formControlName="description" type="text" name="description"
                placeholder="Enter Project Description"></textarea>

              <p class="errors">
                <span *ngIf="submitted && searchProjects.controls['description'].errors?.minlength"> *Minimum 1
                  character
                </span>
                <span *ngIf="submitted && searchProjects.controls['description'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Project Address</label>
              <input formControlName="address" type="text" name="address" placeholder="Enter Project Address" />

              <p class="errors">
                <span *ngIf="submitted && searchProjects.controls['address'].errors?.minlength"> *Minimum 1
                  character
                </span>
                <span *ngIf="submitted && searchProjects.controls['address'].errors?.maxlength"> *Maximum 512
                  characters
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label>Project Main Town</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterProjects();" placeholder="Select City.." formControlName="main_town" name="main_town" id="main_town">
                <option value="-1">Select Town / City / Village</option>
                <option *ngFor="let city of dataHolderService.projectMetaDeta.CITIES; let i=index;"
                  [value]="city.id">{{ city['name']  }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Minimum Acres</label>
              <input formControlName="minimumAcres" type="number" step="any" name="minimumAcres"
                placeholder="Minimum Total Acres">


              <p class="errors">
                <span *ngIf="submitted && searchProjects.controls['minimumAcres'].errors?.numeric"> *Enter valid numeric
                  acres</span>
                <span *ngIf="submitted && searchProjects.controls['minimumAcres'].errors?.invalidMsg"> *Enter valid
                  acres:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-label">
              <label>Maximum Acres</label>
              <input formControlName="maximumAcres" type="number" step="any" name="maximumAcres"
                placeholder="Maximum Total Acres">


              <p class="errors">
                <span *ngIf="submitted && searchProjects.controls['maximumAcres'].errors?.numeric"> *Enter valid numeric
                  acres</span>
                <span *ngIf="submitted && searchProjects.controls['maximumAcres'].errors?.invalidMsg"> *Enter valid
                  acres:
                  For eg: 100 or 100.34</span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label>Project Status</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterProjects();"
                placeholder="Select option.." formControlName="status" name="status">
                <option [value]="-1">All
                </option>
                <option [value]="0">Upcoming
                </option>
                <option [value]="1">Ongoing
                </option>
                <option [value]="2">Completed
                </option>
              </select>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label>Digital Layout</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterProjects();"
                placeholder="Select option.." formControlName="has_plots" name="has_plots">
                <option [value]="-1">All
                </option>
                <option [value]="0">No
                </option>
                <option [value]="1">Yes
                </option>
              </select>
            </div>
          </div>

        </div>

        <div class="form-right">

          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort by</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterProjects();"
                placeholder="Select option.." formControlName="sortBy" name="sortBy">
                <option value="name">Name</option>
                <option value="added_on">Added On</option>
                <option value="updated_on">Updated On</option>
                <option value="acres">Acres</option>

              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Sort order</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterProjects();"
                placeholder="Select option.." formControlName="sortOrder" name="sortOrder">
                <option value="ASC">Ascending</option>
                <option value="DESC">Decending</option>
              </select>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterProjects();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="form-button">
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>

        <div>
          <a class="submit" target="_blanc" [routerLink]="dataHolderService.apiBaseUrl + '/projects-report'"
            [routerLink]="" [queryParams]="{
                projectId :  searchProjects.value.projectId,
                name :  searchProjects.value.name,
                description :  searchProjects.value.description,
                address :  searchProjects.value.address,
                minimumAcres :  searchProjects.value.minimumAcres,
                maximumAcres :  searchProjects.value.maximumAcres,
                main_town :  searchProjects.value.main_town,
                has_plots : searchProjects.value.has_plots,
                status :  searchProjects.value.status,
                sortBy :  searchProjects.value.sortBy,
                sortOrder :  searchProjects.value.sortOrder,
                viewType : viewType
              }">Download Report</a>
        </div>
      </div>

    </form>
  </section>
</main>

<section class="responsiveContainer single-booking-cards" *ngIf="totalResults > 0 && viewType == 'reportView'">
  <div class="horizontal-card" *ngFor="let pjt of Object.keys(projects)">
    <div class="twoColumnsGrid">
      <div>
        <h1><a [routerLink]="['/projects/edit-project', pjt ]" class="grad1"> {{ pjt }} </a></h1>
        <p class="blurred-title"> {{ projects[pjt].projectData.name }} </p>

        <p class="blurred-title"> Project Description : </p>
        <p> {{ projects[pjt].projectData.description }} </p>

        <p class="blurred-title">
          <span> Acres - {{ projects[pjt].projectData.acres }} </span>
        </p>

        <p class="blurred-title"> Digital Layout : </p>
        <h1 *ngIf="projects[pjt].projectData.has_plots == 0">
          <a class="grad2" style="cursor: unset;"> NO </a>
        </h1>
        <div class="layout-img-container" *ngIf="projects[pjt].projectData.has_plots == '1'">
          <a href="{{ projects[pjt].projectData.svg_layout_link  }}" download class=""> <img
              src="{{ projects[pjt].projectData.svg_layout_link }}"> </a>
        </div>

      </div>

      <div>

        <p>Address - </p>
        <p> {{ projects[pjt].projectData.address }} </p>

        <p> Main Town : {{ projects[pjt].projectData.main_town }} </p>

        <p class="blurred-title"> Project Status : </p>
        <h1 *ngIf="projects[pjt].projectData.status == 0">
          <a class="grad2" style="cursor: unset;"> UPCOMING</a>
        </h1>
        <h1 *ngIf="projects[pjt].projectData.status == 1">
          <a class="grad2" style="cursor: unset;"> ONGOING</a>
        </h1>
        <h1 *ngIf="projects[pjt].projectData.status == 2">
          <a class="grad2" style="cursor: unset;"> COMPLETED </a>
        </h1>


        <p><span> Added on {{ projects[pjt].projectData.added_on | momentDateTransformPipe : ('MMM Do, YYYY ') }}
          </span> </p>

        <p><span> Updated on {{ projects[pjt].projectData.updated_on | momentDateTransformPipe : ('MMM Do, YYYY ') }}
          </span> </p>

        <p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.455874392272!2d78.07405431528231!3d15.779898489060308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb5dd611d1bb7b5%3A0x774095f8c7a152ef!2sSRI%20SARASWATHI%20HOMES%20REALESTATE!5e0!3m2!1sen!2sin!4v1621171016198!5m2!1sen!2sin\"
            style="border:0; width: 60%;" allowfullscreen="\" loading="lazy\"></iframe>
        </p>
      </div>
    </div>

  </div>
</section>

<section class="fullWidthTable" *ngIf="totalResults > 0 && viewType == 'listView'">
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>Project ID</th>
          <th>Project Name</th>
          <th>Main Town</th>
          <th>Acres</th>
          <th>Project Status</th>
          <th>Digital Layout</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pjt of Object.keys(projects)">
          <td>
            <a [routerLink]="['/projects/edit-project', pjt ]">
              <span> <i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>

          <td>
            {{ pjt }}
          </td>

          <td>{{ projects[pjt].projectData.name }}</td>


          <td>{{ projects[pjt].projectData.main_town }}</td>

          <td>{{ projects[pjt].projectData.acres }}</td>

          <td>
            <p *ngIf="projects[pjt].projectData.status == '0'"> Upcoming </p>
            <p *ngIf="projects[pjt].projectData.status == '1'"> Ongoing </p>
            <p *ngIf="projects[pjt].projectData.status == '2'"> Completed </p>
          </td>

          <td>

            <p *ngIf="projects[pjt].projectData.has_plots == '1'">
              <a href="{{ projects[pjt].projectData['svg_layout_link'] }}">Download Layout</a>
            </p>
          </td>


        </tr>
      </tbody>
    </table>
  </div>
</section>

<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found </p>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchProjects.value.page }" [routerLink]="['./']" [queryParams]="{
        projectId :  searchProjects.value.projectId,
        name :  searchProjects.value.name,
        description :  searchProjects.value.description,
        address :  searchProjects.value.address,
        minimumAcres :  searchProjects.value.minimumAcres,
        maximumAcres :  searchProjects.value.maximumAcres,
        main_town :  searchProjects.value.main_town,
        has_plots : searchProjects.value.has_plots,
        status :  searchProjects.value.status,
        sortBy :  searchProjects.value.sortBy,
        sortOrder :  searchProjects.value.sortOrder,
        pagination : searchProjects.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink,
        viewType : viewType
      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
