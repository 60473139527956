<section class="notFoundContainer">
  <div>
      <div>
        <img src="assets/images/undraw_page_not_found_su7k.svg" alt="Not found">
      </div>
      <h2>You are requesting for something that doesn't exist</h2>
      <p> <q>Are you lost..? You are lost only to find better </q></p>
      <a class="grad3" routerLink="/">HOME</a>
  </div>
</section>
<app-contact-us [pageIsEmbedded]="true"></app-contact-us>
