import { AttachmentsService } from './../attachments.service';
import { Component, OnInit, Input , Output, EventEmitter  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, FormGroupExtension, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-add-or-delete-attachment',
  templateUrl: './add-or-delete-attachment.component.html',
  styleUrls: ['./add-or-delete-attachment.component.css']
})
export class AddOrDeleteAttachmentComponent implements OnInit {


  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  fetchedAttachment : boolean = false;

  attachmentForm : FormGroup;

  disabled: boolean = false;

  localFileName : any;

  @Input() bookingData : any = null;

  @Input() bookingId : any = null;

  @Input() attachmentId : any = null;

  @Input() attachmentData : any = null;

  @Output() latestFetchedBookingInfo = new EventEmitter<any>();

  constructor(
    private formBuilder: RxFormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private router: Router,
    private attachmentsService : AttachmentsService
  ) { }

  ngOnInit(): void {

    this.attachmentForm = this.formBuilder.group({
      attachmentId: ["1", Validators.required],
      bookingId: ["", Validators.required],
      attachmentName: ["", [Validators.minLength(3), Validators.maxLength(512)]],
      attachmentDescription: ["", [Validators.minLength(3), Validators.maxLength(512)]],
      attachment: ["", [
        RxwebValidators.extension({ extensions: ["jpeg", "bmp", "png", "jpg", "gif", "svg", "webm", "pdf"] }),
        RxwebValidators.file({ minFiles: 1, maxFiles: 1 }),
        RxwebValidators.fileSize({ minSize: 10, maxSize: 1e+7 }),
      ]],
    });

    this.attachmentForm.controls.bookingId.setValue(this.bookingId);

    if(!this.isCreate){
      this.updateForm();
    }
  }

  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

  onAttachment(e) : any{
    // const formData = this.attachmentForm.value;

    const formData = new FormData(e.target.closest("form"));

    if(this.attachmentForm.invalid){
      this.fetchedAttachment = true;
      this.notificationsService.toggleFailed(true,"Invalid data, make sure to follow guidelines before filling data");
      return;
    }


    if (this.isCreate) {

      let attachment = e.target.closest("form").querySelector(`input[name="attachment[]"]`);
      if(!this.validateFiles(attachment)){
        return;
      }

      this.notificationsService.toggleLoading(true, "Uploading attachment...");
      this.newAttachment(formData, attachment);
      return;

    }
    else{
      this.notificationsService.toggleLoading(true, "Updating attachment...");
      this.updateAttachment(formData);
      return;
    }
  }

  newAttachment(formData , attachment ) : void{

    this.attachmentsService.addAttachment(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
      this.attachmentForm.patchValue({
        attachmentId: "1",
        attachmentName: "",
        attachmentDescription: ""
      });
      this.fetchedAttachment = false;
      attachment.value= "";
      this.localFileName = null;
      setTimeout(()=>{
        if(document.getElementById(response.aht)){
          document.getElementById(response.aht).scrollIntoView();
        }
      },1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateAttachment(formData) : void{

    this.attachmentsService.updateAttachment(formData).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
      this.localFileName = null;

      setTimeout(()=>{
        if(document.getElementById(response.aht)){
          document.getElementById(response.aht).scrollIntoView();
        }
      },1000);
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  deleteAttachment() : void{

    this.notificationsService.toggleLoading(true, "Deleting Attachment "+ this.attachmentData.attachmentId );

    this.attachmentsService.deleteAttachment(this.attachmentData.attachmentId , this.attachmentData.booking_id).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
      this.latestFetchedBookingInfo.emit(response.data );
    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      });
  }

  updateForm(response = null): void {


    this.fetchedAttachment = true;
    if (response != null) {
      this.notificationsService.toggleSucceeded(true, response.message);
    }

    this.attachmentForm.controls.attachmentId.setValue(this.attachmentData.attachmentId);
    this.attachmentForm.controls.bookingId.setValue(this.attachmentData.booking_id);

    if(this.attachmentData.attachment_name){
      this.attachmentForm.controls.attachmentName.setValue(this.attachmentData.attachment_name);
    }
    if(this.attachmentData.attachment_description){
      this.attachmentForm.controls.attachmentDescription.setValue(this.attachmentData.attachment_description);
    }



  }

  preventDefaultOnDrag(e): void {
    e.preventDefault();
  }

  onFilesDrop(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="attachment[]"]`);
    let files = e.dataTransfer.files;
    attachment.files = files;
    if (!this.validateFiles(attachment)) {
      this.localFileName = null;
      attachment.value = "";
    }
  }

  onFilesChanged(e): void {
    e.preventDefault();
    let attachment = e.target.closest("div.form-field").querySelector(`input[name="attachment[]"]`);
    // let files = e.dataTransfer.files;
    // attachment.files = files;
    if (!this.validateFiles(attachment)) {
      this.localFileName = null;
      attachment.value = "";
    }
  }


  cancelfile(e) : void{
    // console.log(e);
    this.localFileName = null;
    let attachment = e.target.closest("form").querySelector(`input[name="attachment[]"]`);
    attachment.value="";
  }

  validateFiles(attachment): boolean {
    let files = attachment.files;
    if (files.length !== 1) {
      this.notificationsService.toggleFailed(true, "Attachment file is required and you can upload only 1 one file");
      return false;
    }
    let extensions = ["jpeg", "bmp", "png", "jpg", "gif", "svg", "webm", "pdf"];
    let sizes = { minSize: 10, maxSize: 1e+7 };
    for (let i = 0; i < files.length; i++) {
      if (!extensions.includes(this.getFileExtension(files[i].name).toLowerCase())) {
        this.notificationsService.toggleFailed(true, "Only jpeg,bmp,png,jpg,gif,svg,webm,pdf files are accepted");
        return false;
      }
      if (files[i].size < sizes.minSize || files[i].size > sizes.maxSize) {
        this.notificationsService.toggleFailed(true, "Maximum accepted file size is 10000KB");
        return false;
      }
      this.localFileName = files[i].name;
    }
    return true;
  }

  getFileExtension(fileName): string {
    return fileName.split('.').pop();
  }

  customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      let val = String(control.value);
      if (val && !val.match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  customDateValidator(config: any): ValidatorFn {
    return (control: FormControl) => {
      const datePattern: string = config.pattern;
      if (control.value && !moment(control.value, datePattern, true).isValid()) {
        // console.log(moment(control.value, datePattern , true).isValid());
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };

  }

}
