import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BookingsServiceService {

constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  fetchedBookingInfo : any;

  addBooking(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/new-booking' , body);
  }

  updateBooking(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-booking' , body);
  }

  deleteBooking( projectId , bookingId ) : any{
    let body = new HttpParams();
    body = body.set('projectId', projectId );
    body = body.set('bookingId', bookingId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-booking', {
      params: body
    });
  }

  searchBookings(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/search-bookings' , body);

  }


  checkPlotsForAvailability(projectId , plots , bookingId = null) : any{
    let body = new HttpParams();
    body = body.set('projectId', projectId);
    body = body.set('plots', plots);
    if(bookingId!=null){
       body = body.set('bookingId', bookingId);
    }
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/check-plots-availability-for-booking' , body);


  }

  searchPlots(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/search-plots', body );
  }

  plotsStatus(body) : any{
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/plots-status', body );

  }

}
