import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectCitiesService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  getCities() : any{
    return this.httpClient.get( this.dataHolderService.apiBaseUrl + '/cities' );
  }

  addCity(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/add-city' , body);
  }

  updateCity(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/update-city' , body);
  }

  deleteCity( attachmentId  ) : any{
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId );

    return this.httpClient.delete( this.dataHolderService.apiBaseUrl + '/delete-city', {
      params: body
    });
  }

}
