import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  contactUs(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/contact-us' , body);
  }

  searchContactUs(body): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/contact-us-messages', {
      params: body
    });
  }
}
