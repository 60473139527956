import { CustomPagesService } from './../custom-pages.service';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './../notifications.service';
import { DataHolderService } from './../data-holder.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-search-custom-pages',
  templateUrl: './search-custom-pages.component.html',
  styleUrls: ['./search-custom-pages.component.css' , './../add-edit-custom-pages/add-edit-custom-pages.component.css']
})
export class SearchCustomPagesComponent implements OnInit {

  Object = Object;

  searchPages: FormGroup;

  onecolumn: boolean = false;

  submitted: boolean = false;

  pages: Observable<any>;

  totalResults: number = 0;

  paginationLinks: any;

  isFormVisible : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute,
    private customPagesService: CustomPagesService
  ) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta(`Manage Custom Pages | ${this.dataHolderService.userInfo['first_name']} ${this.dataHolderService.userInfo['middle_name']} ${this.dataHolderService.userInfo['last_name']} | ${this.dataHolderService.projectMetaDeta.default_title}`);

    let formBuilderConfig = {

      is_active: ["-1", Validators.required],
      type_of_content: ["-1", Validators.required ],
      title: ["", [ Validators.minLength(1) ]],
      description: ["", [ Validators.minLength(1) ]],
      content_order: ["-1", [ Validators.required ]],
      is_visible_on_page_tabs: ["-1", [ Validators.required ]],
      url_key: ["", [ Validators.minLength(1) ]],
      project_id: ["-1", [ Validators.required ]],
      page: ["1", []],
      pagination: [10, [Validators.required]],
      sortBy: ["added_on", [Validators.required]],
      sortOrder: ["DESC", [Validators.required]],
    };

    this.searchPages = this.formBuilder.group(formBuilderConfig);
    this.checkForQueryParams();
    this.onFilterPages();

  }

  checkForQueryParams(): void {


    this.route.queryParams.subscribe(queryParams => {
      let hasQueryParams = false;

      if ("is_active" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          is_active: queryParams.is_active
        });
      }

      if ("type_of_content" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          type_of_content: queryParams.type_of_content
        });
      }

      if ("title" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          title: queryParams.title
        });
      }

      if ("description" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          description: queryParams.description
        });
      }

      if ("content_order" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          content_order: queryParams.content_order
        });
      }

      if ("is_visible_on_page_tabs" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          is_visible_on_page_tabs: queryParams.is_visible_on_page_tabs
        });
      }

      if ("url_key" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          url_key: queryParams.url_key
        });
      }

      if ("project_id" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          project_id: queryParams.project_id
        });
      }

      if ("sortBy" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          sortBy: queryParams.sortBy
        });
      }

      if ("sortOrder" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          sortOrder: queryParams.sortOrder
        });
      }

      if ("page" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          page: queryParams.page
        });
      }

      if ("pagination" in queryParams) {
        hasQueryParams = true;
        this.searchPages.patchValue({
          pagination: queryParams.pagination
        });
      }



      if (hasQueryParams) {
        this.onFilterPages();
      }


    });

  }

  onFilterPages() : void{
    this.submitted = true;
    if (this.searchPages.invalid) {
      this.notificationsService.toggleFailed(true, "Invalid form, check for errors and try again");
      return;
    }
    this.notificationsService.toggleLoading(true, "Filtering Pages...");
    this.customPagesService.searchPages(this.searchPages.value).subscribe((response: any) => {

      this.notificationsService.toggleLoading(false);

      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        this.totalResults = 0;
        return;
      }

      this.totalResults = response.total_results;
      this.pages = response.data;
      this.paginationLinks = response.pagination_data;
      if (document.querySelector("section.search-custom-pages")) {
        document.querySelector("section.search-custom-pages").scrollIntoView();
      }
      this.notificationsService.toggleSucceeded(true, response.message);

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

  updatePageNumber(e): void {
    // console.log(e.target);
    this.searchPages.patchValue({
      page: e.target.getAttribute("data-page-number") == null ? 1 : e.target.getAttribute("data-page-number")
    });

  }


  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }

}
