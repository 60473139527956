import { ProjectsService } from './../projects.service';
import { Component, OnInit, Input } from '@angular/core';
import { DataHolderService } from './../data-holder.service';
import { NotificationsService } from './../notifications.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-property-type-projects',
  templateUrl: './property-type-projects.component.html',
  styleUrls: ['./property-type-projects.component.css' ]
})
export class PropertyTypeProjectsComponent implements OnInit {

  filteredProjects;

  @Input() pageIsEmbedded: boolean = false;
  @Input() propertyTypeId = null;


  constructor(
    public projectsService: ProjectsService,
    public dataHolderService: DataHolderService,
    public notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {


    this.route.params.subscribe((params: Params) => {
      if (!this.pageIsEmbedded) {
        this.dataHolderService.updatePageMeta(`${this.projectsService.filteredProjects.heading} | Properties | ${this.dataHolderService.projectMetaDeta.default_title}`);
        this.filteredProjects = this.projectsService.filteredProjects;
      }
    });

    if(this.propertyTypeId){
      this.filter();
    }

  }

  filter(): void {
    this.projectsService.filterProjects('projectType', this.propertyTypeId).subscribe((response: any) => {
      if (response.status) {
        this.filteredProjects = response.data;
      }

    },
      error => {
        this.notificationsService.toggleFailed(true, "Unable to process your request, try again");
      })
  }

}
