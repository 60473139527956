<main>
  <section class="responsiveContainer">
    <form id="projectForm" [formGroup]="projectForm" (ngSubmit)="onProject($event)" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <span (click)="onecolumn = !onecolumn;" data-form-id="a1" class="toggleFormLayout"> <i class="fa fa-arrows"
            aria-hidden="true"></i> CHANGE VIEW</span>
        <div class="display-edit">
          <a routerLink="/dashboard"> DASHBOARD </a>
        </div>
        <h2 class="gradientColor">
          <span>{{ isCreate ? 'Add Project' : 'Edit Project - ' +projectForm.value.projectId  }}</span>
          <input type="hidden" name="projectId" formControlName="projectId">
        </h2>
      </div>
      <div class="form-body">
        <div class="form-left">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Project Title</label>
              <input formControlName="name" type="text" name="name" placeholder="Project title">
              <p class="errors">

                <span *ngIf="fetchedProject && projectForm.controls['name'].errors?.required"> *required</span>
                <span *ngIf="fetchedProject && projectForm.controls['name'].errors?.minlength"> *Minimum 1
                  character
                </span>

              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label>Town / City / Village </label>

              <select placeholder="Select City.." formControlName="main_town" name="main_town" id="main_town">
                <option *ngFor="let city of dataHolderService.projectMetaDeta.CITIES; let i=index;"
                  [value]="city.id">{{ city['name']  }}
                </option>
              </select>

              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['main_town'].errors?.required"> *required</span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Description (short)</label>
              <textarea formControlName="description" type="text" name="description"
                placeholder="Description"></textarea>
              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['description'].errors?.required"> *required</span>
                <span *ngIf="fetchedProject && projectForm.controls['description'].errors?.minlength"> *Minimum 1
                  character </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Address</label>
              <textarea formControlName="address" type="text" name="address" placeholder="Address"></textarea>
              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['address'].errors?.required"> *required</span>
                <span *ngIf="fetchedProject && projectForm.controls['address'].errors?.minlength"> *Minimum 1 character
                </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Area - Number of Acres</label>
              <input formControlName="acres" type="number" step="any" name="acres" placeholder="No. of acres">

              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['acres'].errors?.numeric"> *Enter valid numeric
                  area</span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-middle">

          <div *ngIf="!isCreate" class="form-field">
            <div class="input-container with-label">
              <label>Manage Project</label>
              <div class="inline-links">
                <a fragment="addProjectImage" [routerLink]="['./']">Add Project Image</a>
                <a fragment="viewProjectImages" [routerLink]="['./']"> View project Images </a>
                <a fragment="addProjectAttachment" [routerLink]="['./']">Add Project Attachment</a>
                <a fragment="viewProjectAttachments" [routerLink]="['./']"> View Project Attachments </a>
                <a  [routerLink]="['/add-plot',projectForm.value.projectId.split('-').pop()]"> Add Plot </a>
                <a  [routerLink]="['/plots']" [queryParams]="{ projectId : projectForm.value.projectId.split('-').pop()  }" > View Plots </a>
                <a  [routerLink]="['/add-custom-page']" [queryParams]="{ type_of_content : 3 , project_id : projectForm.value.projectId.split('-').pop()  }" > Add Project Custom Content </a>
                <a  [routerLink]="['/manage-custom-pages']" [queryParams]="{ type_of_content : 3 , project_id : projectForm.value.projectId.split('-').pop()  }" > View Project Custom Content </a>


              </div>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Google Maps - Iframe</label>
              <ul>
                <li>You can read <a class="textualLink"
                    href="https://wcmshelp.ucsc.edu/advanced/embedding-google/google-maps.html" target="_blanc">here
                  </a> about getting google maps iframe and google maps link</li>
                <li>Make sure you remove width and height attributes from iframe link</li>
              </ul>
              <textarea formControlName="gmap_iframe" name="gmap_iframe"
                placeholder="Iframe for embedding location.."></textarea>
              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['gmap_iframe'].errors?.minlength"> *Minimum 1
                  character </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Google Maps Link</label>

              <textarea formControlName="gmap_link" name="gmap_link" placeholder="Google Maps link.."></textarea>
              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['gmap_link'].errors?.minlength"> *Minimum 1
                  character </span>
              </p>
            </div>
          </div>

          <div class="form-field">

            <div class="input-container with-file-upload">
              <label>Upload Project Image</label>
              <div>
                <label (drop)="onFilesDropIMG($event)" (dragover)="preventDefaultOnDragIMG($event)"
                  (dragenter)="preventDefaultOnDragIMG($event)" class="drag-drop-container-label" for="image_url">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept="image/*" type="file" (change)="onFilesChangedIMG($event)" formControlName="image_url"
                    name="image_url[]" type="file" id="image_url">
                </label>
              </div>
              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['image_url'].errors?.extension"> *Only png,
                  jpg/jpeg, bmp, gif files are accepted</span>

                <span *ngIf="fetchedProject && projectForm.controls['image_url'].errors?.file"> *Only 1 file can be
                  uploaded</span>
              </p>

              <div *ngIf="projectImageUrl" class="existingProfile twoColumnsGrid">
                <div>
                  <a download target="_blank" href="{{ projectImageUrl }}">
                    <img src="{{ projectImageUrl }}">
                  </a>
                </div>
                <div>

                </div>
              </div>
              <div *ngIf="localFileNameIMG">
                <ul class="inline">
                  <li> <a class="textualLink">{{  localFileNameIMG }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfileIMG($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>

            </div>
          </div>

        </div>
        <div class="form-right">

          <div class="form-field">
            <div class="input-container with-select">
              <label for="project_type_ids">Project Categories</label>
              <ul>
                <li> Use <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select" target="_blanc"
                    class="textualLink"><kbd>Ctrl + mouse click </kbd> </a> to select / deselect</li>
              </ul>
              <select multiple="multiple" placeholder="Project Categories" formControlName="project_type_ids"
                name="project_type_ids[]" id="project_type_ids">
                <option *ngFor="let property of dataHolderService.projectMetaDeta.PROPERTY_TYPES"
                  [value]="property['id']">{{ property['name'] }}</option>
              </select>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label for="status">Project status</label>
              <select placeholder="Project status" formControlName="status" name="status" id="status">
                <option value="0">Upcoming</option>
                <option value="1">On Going</option>
                <option value="2">Completed</option>

              </select>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-select">
              <label for="has_plots">Has Digital Layout?</label>
              <select placeholder="Digital Laylout" formControlName="has_plots" name="has_plots" id="has_plots">
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
          </div>

          <div [ngStyle]="{ 'display' : projectForm.value.has_plots == '1' ? 'block' : 'none' }" class="form-field">

            <div class="input-container with-file-upload">
              <label>Upload SVG Layout</label>
              <div>
                <label (drop)="onFilesDropSVG($event)" (dragover)="preventDefaultOnDragSVG($event)"
                  (dragenter)="preventDefaultOnDragSVG($event)" class="drag-drop-container-label" for="svg_layout">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept=".svg" type="file" (change)="onFilesChangedSVG($event)" formControlName="svg_layout"
                    name="svg_layout[]" type="file" id="svg_layout">
                </label>
              </div>
              <p class="errors">
                <span *ngIf="fetchedProject && projectForm.controls['svg_layout'].errors?.extension"> *Only svg files
                  are accepted</span>

                <span *ngIf="fetchedProject && projectForm.controls['svg_layout'].errors?.file"> *Only 1 file can be
                  uploaded</span>
              </p>

              <div *ngIf="projectSvgImageUrl" class="existingProfile twoColumnsGrid">
                <div>
                  <a download target="_blank" href="{{ projectSvgImageUrl }}">
                    <img src="{{ projectSvgImageUrl }}">
                  </a>
                </div>
                <div>

                </div>
              </div>

              <div *ngIf="localFileNameSVG">
                <ul class="inline">
                  <li> <a class="textualLink">{{  localFileNameSVG }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfileSVG($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>

            </div>
          </div>

        </div>
      </div>
      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">{{ isCreate ? 'ADD PROJECT' : 'UPDATE PROJECT' }} </button>

      </div>
    </form>
  </section>
</main>

<ng-container *ngIf="!isCreate">



  <section class="responsiveContainer">

  <section>
    <h2>Project Images</h2>
    <p>These images are visible in project page - gallery section</p>

    <div class="tabularDataDisplay">
      <table>
        <thead>
          <tr>
            <th style="text-align: right;" colspan="5">
              <a id="addProjectImage"    data-attachment-is-create="true" (click)="onAddEditProjectImage($event)" class="grad1" style="color:white;"><span><i aria-hidden="true" class="fa fa-plus"></i>Add Image
                </span></a>
            </th>
          </tr>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Image</th>
            <th>Download</th>
            <th>Sort Order</th>

          </tr>
        </thead>

        <tbody  id="viewProjectImages" >
          <tr *ngFor="let img of Object.keys(projectsService.fetchedProjectInfo[projectForm.value.projectId].images)">

            <td>
              <a data-attachment-is-create="false"  attr.data-attachment-data="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img] | json }}" (click)="onAddEditProjectImage($event)" ><span><i aria-hidden="true" class="fa fa fa-pencil"></i> Edit </span></a>
            </td>

            <td>
              {{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['attachment_id'] }}
            </td>

            <td>
              <a target="_blank" download
                href="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['url'] }}">
                <img class="thum"
                  src="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['url'] }}">
              </a>
            </td>

            <td>
              <a target="_blank" download
                href="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['url'] }}">Download
              </a>
            </td>


            <td>
              {{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['sort_order'] ? projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['sort_order'] : 0 }}
            </td>

          </tr>
        </tbody>

      </table>
    </div>
  </section>
  <section>
    <h2>Project Attachments</h2>
    <p>These attachments are visible in the project page - Downloads section</p>

    <div class="tabularDataDisplay">
      <table>
        <thead>
          <tr>
            <th style="text-align: right;" colspan="6">
              <a id="addProjectAttachment"   data-attachment-is-create="true" (click)="onAddEditProjectAttachment($event)" class="grad1" style="color:white;"><span><i aria-hidden="true" class="fa fa-plus"></i>Add Attachment </span></a>
            </th>
          </tr>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Short Description</th>
            <th>Download Thumbnail</th>
            <th>Download Attachment</th>
            <th>Sort order</th>

          </tr>
        </thead>

        <tbody  id="viewProjectAttachments" >
          <tr *ngFor="let img of Object.keys(projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures)">

            <td>
              <a data-attachment-is-create="false"  attr.data-attachment-data="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img] | json }}" (click)="onAddEditProjectAttachment($event)" ><span><i aria-hidden="true" class="fa fa fa-pencil"></i> Edit </span></a>
            </td>

            <td>
              {{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['attachment_id'] }}
            </td>


            <td>
              {{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['short_description'] }}
            </td>

            <td>
              <a target="_blank" download
                href="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['thumbnail_url'] }}">
                <img class="thum"
                  src="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['thumbnail_url'] }}">
              </a>
            </td>


            <td>
              <a target="_blank" download
                href="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['url'] }}">Download </a>
            </td>


            <td>
              {{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['sort_order'] ? projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[img]['sort_order'] : 0 }}
            </td>

          </tr>
        </tbody>

      </table>
    </div>
  </section>
  </section>


  <section class="fullScreenPopup" *ngIf="projectImageFormVisible">

    <div class="container">

      <div (click)="projectImageFormVisible = !projectImageFormVisible;" class="closeFullScreenPopup">
        <button> <i class="fa fa-times" aria-hidden="true"></i> CANCEL </button>
      </div>

      <app-add-or-delete-project-images
      [isCreate]="projectImageFormIsCreate"
      [projectId]="projectForm.value.projectId"
      [attachmentData]="projectImageFormData"
      (latestFetchedProjectInfo)="updateProjectPage($event)">
     </app-add-or-delete-project-images>


    </div>



  </section>

  <section class="fullScreenPopup" *ngIf="projectAttachmentFormVisible">

    <div class="container">

      <div (click)="projectAttachmentFormVisible = !projectAttachmentFormVisible;" class="closeFullScreenPopup">
        <button> <i class="fa fa-times" aria-hidden="true"></i> CANCEL </button>
      </div>

      <app-add-or-delete-project-attachments
       [isCreate]="projectAttachmentIsCreate"
       [projectId]="projectForm.value.projectId"
      [attachmentData]="projectAttachmentFormData"
      (latestFetchedProjectInfo)="updateProjectPage($event)">
    </app-add-or-delete-project-attachments>




    </div>



  </section>




  <!---Project Images--->

  <!-- <section id="viewProjectImages" class="responsiveContainer">
    <form class="form-layout onecolumn" >
      <div class="form-heading">
        <h2 class="gradientColor"><span>PROJECT IMAGES</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <ul class="inline">
                <li>
                  <a class="textualLink" fragment="addProjectImage" [routerLink]="['./']">Upload Project Image</a>
                </li>
                <li>
                  <a class="textualLink" fragment="viewProjectImages" [routerLink]="['./']"> View Project Images </a>
                </li>
              </ul>
              <div class="inline-links">
                <a *ngFor="let img of Object.keys(projectsService.fetchedProjectInfo[projectForm.value.projectId].images)"
                  fragment="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['attachment_id'] }}"
                  [routerLink]="['./']">{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['attachment_id'] }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-middle">

        </div>
        <div class="form-right">

        </div>
      </div>
      <div class="form-button">

      </div>
    </form>
  </section>

  <app-add-or-delete-project-images [isCreate]="true" [projectId]="projectForm.value.projectId"
    [projectData]="projectsService.fetchedProjectInfo[projectForm.value.projectId].projectData"
    (latestFetchedProjectInfo)="updateProjectPage($event)">
  </app-add-or-delete-project-images>

  <section *ngIf="totalImages > 0">

    <app-add-or-delete-project-images
      *ngFor="let img of Object.keys(projectsService.fetchedProjectInfo[projectForm.value.projectId].images)"
      [isCreate]="false"
      [projectId]="projectForm.value.projectId"
      [projectData]="projectsService.fetchedProjectInfo[projectForm.value.projectId].projectData"
      [attachmentId]="projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]['attachment_id']"
      [attachmentData]="projectsService.fetchedProjectInfo[projectForm.value.projectId].images[img]"
      (latestFetchedProjectInfo)="updateProjectPage($event)">
    </app-add-or-delete-project-images>

  </section> -->

  <!---Project Attachments--->
  <!--
  <section id="viewProjectAttachments" class="responsiveContainer">
    <form class="form-layout" [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <h2 class="gradientColor"><span>PROJECT ATTACHMENTS</span></h2>
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <ul class="inline">
                <li>
                  <a class="textualLink" fragment="addProjectAttachment" [routerLink]="['./']">Upload Project
                    Attachment</a>
                </li>
                <li>
                  <a class="textualLink" fragment="viewProjectAttachments" [routerLink]="['./']"> View Project
                    Attachments </a>
                </li>
              </ul>
              <div class="inline-links">
                <a *ngFor="let bro of Object.keys(projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures)"
                  fragment="{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[bro]['attachment_id'] }}"
                  [routerLink]="['./']">{{ projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[bro]['attachment_id'] }}</a>
              </div>

            </div>
          </div>
        </div>
        <div class="form-middle">

        </div>
        <div class="form-right">

        </div>
      </div>
      <div class="form-button">

      </div>
    </form>
  </section>

  <app-add-or-delete-project-attachments [isCreate]="true" [projectId]="projectForm.value.projectId"
    [projectData]="projectsService.fetchedProjectInfo[projectForm.value.projectId].projectData"
    (latestFetchedProjectInfo)="updateProjectPage($event)">
  </app-add-or-delete-project-attachments>

  <section *ngIf="totalBrochures > 0">
    <app-add-or-delete-project-attachments
      *ngFor="let bro of Object.keys(projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures)"
      [isCreate]="false" [projectId]="projectForm.value.projectId"
      [projectData]="projectsService.fetchedProjectInfo[projectForm.value.projectId].projectData"
      [attachmentId]="projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[bro]['attachment_id']"
      [attachmentData]="projectsService.fetchedProjectInfo[projectForm.value.projectId].brochures[bro]"
      (latestFetchedProjectInfo)="updateProjectPage($event)">
    </app-add-or-delete-project-attachments>
  </section> -->

</ng-container>


