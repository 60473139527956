<main>
  <section class="responsiveContainer">
    <form [formGroup]="projectImageForm" (ngSubmit)="onAttachment($event)" #form="ngForm" class="form-layout onecolumn">
      <div class="form-heading">
        <h2 class="gradientColor"><span>{{ isCreate ? 'ADD CITY' : 'UPDATE CITY' }}</span>
        </h2>
        <input type="hidden" name="attachmentId" formControlName="attachmentId">

      </div>
      <div class="form-body">
        <div class="form-left">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Name of City / Town / Village </label>
              <input formControlName="name" type="text" name="name" placeholder="name" />
              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['name'].errors?.required">
                  *required</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['name'].errors?.maxlength">
                  *Maximum 150 chars</span>
            </div>
          </div>



        </div>
        <div class="form-middle">



        </div>

        <div class="form-right">

          <div class="form-field">

            <div class="input-container with-file-upload">
              <label>Upload Image</label>

              <div>
                <label (drop)="onFilesDrop($event)" (dragover)="preventDefaultOnDrag($event)"
                  (dragenter)="preventDefaultOnDrag($event)" class="drag-drop-container-label" for="sliderImage">
                  <span>Select a file or <i class="fa fa-cloud-upload" aria-hidden="true"></i> drop your file
                    here</span>
                  <input accept="image/*" type="file" (change)="onFilesChanged($event)" formControlName="image"
                    name="image[]" type="file" id="sliderImage">
                </label>
              </div>

              <p class="errors">
                <span *ngIf="fetchedAttachment && projectImageForm.controls['image'].errors?.extension"> *Only
                  jpeg,bmp,png,jpg,gif,svg,gif files are accepted</span>
                <span *ngIf="fetchedAttachment && projectImageForm.controls['image'].errors?.file"> *Only 1 file can
                  be
                  uploaded</span>
              </p>

              <div *ngIf="!isCreate" class="existingProfile twoColumnsGrid">
                <div>
                  <a download target="_blank" href="{{ attachmentData.image_url + '?id=' + attachmentData.id   }}">
                    <img src="{{  attachmentData.image_url + '?id=' + attachmentData.id   }}">
                  </a>
                </div>
              </div>

              <div *ngIf="localFileName">
                <ul class="inline">
                  <li> <a class="textualLink">{{ localFileName }} </a> </li>
                </ul>
                <button type="button" class="submit" (click)="cancelfile($event)"> <i class="fa fa-times"
                    aria-hidden="true"></i> REMOVE</button>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="form-button">
        <button [disabled]="disabled" class="" type="submit">SAVE</button>
        <!-- <button (click)="deleteProjectImage();" *ngIf="!isCreate" type="button">DELETE</button> -->

      </div>

    </form>
  </section>
</main>
