import { Injectable } from '@angular/core';
import { DataHolderService } from './data-holder.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PropertyTypesService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  getPropertTypes(): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/get-property-types');
  }

  addPropertyType(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/add-property-type', body);
  }

  updatePropertyType(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/update-property-type', body);
  }

  deletePropertyType(attachmentId): any {
    let body = new HttpParams();
    body = body.set('attachmentId', attachmentId);

    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/delete-property-type', {
      params: body
    });
  }

}
