<div class="fullWidthSection users">

</div>
<section class="responsiveContainer" style=" display: inline;">
  <h3 style="margin: 0; padding: 0; font-size: 2em; text-align: center;" class="gradientColor">ACCOUNTS
  </h3>

  <div class="responsiveContainer radio-toggler-container" style="margin-right: 15px;">

    <div class="radio-toggler noselect">
      <div class="radio-container">
        <input (change)="setViewVisibility('listView')" name="viewType" [checked]="viewType == 'listView'" type="radio"
          value="listView" id="listView">
        <label for="listView">
          <i class="fa fa-table" aria-hidden="true"></i> LIST
        </label>
        <input (change)="setViewVisibility('reportView')" name="viewType" [checked]="viewType == 'reportView'"
          type="radio" value="reportView" id="reportView">
        <label for="reportView">
          <i class="fa fa-th-large" aria-hidden="true"></i> SUMMARY
        </label>
      </div>
    </div>

    <pre></pre>

    <div class="noselect responsiveContainer" (click)="isFormVisible = !isFormVisible"
      style="cursor: pointer; color: black; font-weight: bolder; float: right; ">
      <span><i aria-hidden="true" class="fa fa-filter"></i> FILTERS & REPORTS</span>
    </div>

  </div>

</section>


<main [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">
  <section class="responsiveContainer">
    <form [formGroup]="searchUsers" (ngSubmit)="onFilterUsers()" #form="ngForm" class="form-layout"
      [ngClass]=" { 'onecolumn' : onecolumn }">
      <div class="form-heading">
        <input type="hidden" name="page" formControlName="page">
      </div>
      <div class="form-body">
        <div class="form-left">
          <div class="form-field">
            <div class="input-container with-label">
              <label>Name</label>
              <input formControlName="name" type="text" name="name" placeholder="Name">
              <p class="errors">

                <span *ngIf="submitted && searchUsers.controls['name'].errors?.minlength"> *Minimum 1 characters
                </span>
                <span *ngIf="submitted && searchUsers.controls['name'].errors?.maxlength"> *Maximum 100
                  characters </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label for="broker">Only Agents</label>

              <select data-page-number="1" (change)="updatePageNumber($event); onFilterUsers();" placeholder="Agent.."
                formControlName="isBroker" name="isBroker" id="broker">
                <option [value]="0">No
                </option>
                <option [value]="1">Yes
                </option>
                <option [value]="-1">Select Option
                </option>
              </select>
            </div>
          </div>

        </div>

        <div class="form-middle">

          <div class="form-field">
            <div class="input-container with-label">
              <label>Email</label>
              <input type="email" formControlName="email" name="email" placeholder="user@yours.com">
              <p class="errors">
                <span *ngIf="submitted && searchUsers.controls['email'].errors?.email"> *Invalid email </span>
              </p>
            </div>
          </div>

          <div class="form-field">
            <div class="input-container with-label">
              <label>Mobile No.</label>
              <input formControlName="mobile" type="text" name="mobile" placeholder="709xxxx9100">

              <p class="errors">
                <span *ngIf="submitted && searchUsers.controls['mobile'].errors?.minlength"> *Minimum 1
                  digit
                </span>
                <span *ngIf="submitted && searchUsers.controls['mobile'].errors?.maxlength"> *Maximum 20
                  digits
                </span>
                <span *ngIf="submitted && searchUsers.controls['mobile'].errors?.invalidMsg"> *Mobile
                  number
                  can contain only digits and an optional + prefix </span>
              </p>
            </div>
          </div>
        </div>
        <div class="form-right">
          <div class="form-field">
            <div class="input-container with-label">
              <label>User ID</label>
              <input formControlName="userId" type="text" name="userId"
                placeholder="{{ dataHolderService.projectMetaDeta.USER_ID_PREFIX }}">
              <p class="errors">
                <span *ngIf="submitted && searchUsers.controls['name'].errors?.minlength"> *Minimum 1 character
                </span>
              </p>
            </div>
          </div>
          <div class="form-field">
            <div class="input-container with-select">
              <label>Results per page</label>
              <select data-page-number="1" (change)="updatePageNumber($event); onFilterUsers();"
                placeholder="Select option.." formControlName="pagination" name="pagination">
                <option *ngFor="let l of this.dataHolderService.projectMetaDeta.PAGINATION_OPTIONS.others" [value]="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button (click)="updatePageNumber($event)" attr.data-page-number="1">FILTER</button>
        <div>
          <a class="submit" target="_blanc" [routerLink]="dataHolderService.apiBaseUrl + '/accounts-report'" [queryParams]="{
            name :  searchUsers.value.name,
            email :  searchUsers.value.email,
            mobile :  searchUsers.value.mobile,
            userId :  searchUsers.value.userId,
            isBroker :  searchUsers.value.isBroker,
            viewType : viewType
          }">Download Report</a>
        </div>
      </div>
    </form>
  </section>
</main>

<section class="user-gallery" *ngIf="totalResults > 0 && viewType == 'reportView'">
  <article *ngFor="let userId of Object.keys(users)">
    <div class="wrapper grad4">
      <div class="img-container">
        <img loading="lazy"
        [lazyLoad]='(users[userId].profile_picture_url.length > 5) ? (dataHolderService.apiBaseUrl + "/profile-picture?accountId=" + userId )  : "assets/images/dummy.png"'>
        <h3> <a [routerLink]="['/user-accounts/edit', userId ]" class="main-link">{{ users[userId].first_name + " " + ((users[userId]?.middle_name) ? users[userId].middle_name + " " : "" ) + users[userId].last_name  }}</a> </h3>
      </div>
      <ul>
        <li>
          <i aria-hidden="true" class="fa fa-envelope-o"></i>
          <a href="mailto:{{ users[userId].email }}"> {{ users[userId].email }} </a>
        </li>
        <li>
          <i aria-hidden="true" class="fa fa fa-phone"></i>
          <a href="tel:{{ users[userId].mobile_number }}"> {{ users[userId].mobile_number }} </a>
        </li>
        <li>
          <i aria-hidden="true" class="fa fa-user-circle-o"></i>
          <a> {{ userId }} </a>
        </li>
        <li>
          <i aria-hidden="true" class="fa fa fa-whatsapp"></i>
          <a target="_blanc" *ngIf="users[userId]?.whatsapp_number"
            href="{{ 'https://wa.me/' + users[userId]?.whatsapp_number + '/?text=Hello!'  }}"> {{
            users[userId]?.whatsapp_number }} </a>
        </li>
        <li>
          <i aria-hidden="true" class="fa fa-link"></i>
          <a *ngIf='users[userId]?.is_broker == "1"'> Agent : Yes</a>
          <a *ngIf='users[userId]?.is_broker != "1"'> Agent : No </a>

        </li>
        <li>
          <i aria-hidden="true" class="fa fa-users"></i>
          <a> Partner : {{ users[userId]?.is_partner == "true" ? "Yes" : "No" }} </a>
        </li>

        <li>
          <i aria-hidden="true" class="fa fa-calendar"></i>
          <a> User since : {{ users[userId]?.created_on | momentDateTransformPipe : ('Do MMM, YYYY') }} </a>
        </li>
        <li *ngIf='!users[userId].bookings'>
          <i aria-hidden="true" class="fa fa-money"></i>
          <a >Bookings : </a>

        </li>

        <ng-container *ngIf="users[userId].bookings">
          <li *ngFor="let bId of users[userId].bookings.split(',')">
            <i aria-hidden="true" class="fa fa-money"></i>
            <a [routerLink]="['/bookings', dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX + bId  ]">Booking : {{ dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX + bId }} </a>

          </li>
        </ng-container>


      </ul>
    </div>
  </article>

</section>

<section class="fullWidthTable" *ngIf="totalResults > 0 && viewType == 'listView'">
  <div class="tabularDataDisplay">
    <table>
      <thead>
        <tr style="color: white !important;" class="grad1">
          <th></th>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile No.</th>
          <th>User ID</th>
          <th>Whatsapp Number</th>
          <th>Agent</th>
          <th>Partner</th>
          <!-- <th>Bookings</th> -->
          <th>User Since</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let userId of Object.keys(users)">
          <td>
            <a [routerLink]="['/user-accounts/edit', userId ]">
              <span> <i aria-hidden="true" class="fa fa-pencil"></i>Edit</span>
            </a>
          </td>

          <td>{{ users[userId].first_name + " " + ((users[userId]?.middle_name) ? users[userId].middle_name + " " : "" ) + users[userId].last_name }}
          </td>

          <td> <p *ngIf="!users[userId].email"> - </p>
            <p>{{ users[userId].email }}</p>
          </td>

          <td><p *ngIf="!users[userId].mobile_number"> - </p>
            <p>{{ users[userId].mobile_number }}</p>
          </td>

          <td>{{ userId }}
          </td>

          <td><p *ngIf="!users[userId]?.whatsapp_number"> - </p>
            <p>{{ users[userId]?.whatsapp_number }}</p>
          </td>

          <td> <p *ngIf='users[userId]?.is_broker == "1"'>Yes</p>
            <p *ngIf='users[userId]?.is_broker != "1"'>No</p>
          </td>

          <td>{{ users[userId]?.is_partner == "true" ? "Yes" : "No" }}
          </td>

          <!-- <td>
            <p *ngIf='!users[userId].bookings'> - </p>


            <p *ngIf='users[userId].bookings'>{{ dataHolderService.projectMetaDeta.BOOKING_ID_PREFIX + users[userId].bookings + (users[userId].bookings == '1' ? ' booking' : '
            bookings') }}</p>
          </td> -->

          <td>{{ users[userId]?.created_on | momentDateTransformPipe : (' DD-MM-YYYY ') }}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</section>


<div class="pagination" [ngStyle]="{'display': pageIsEmbedded ? 'none' : 'block' }">
  <div *ngIf="totalResults < 1">
    <p> No results found, click on below link to view all users </p>
    <a [routerLink]="['/user-accounts']" [queryParams]="{ page : '1' }">All </a>
  </div>
  <div *ngIf="totalResults > 0">
    <p>Total results : {{ totalResults }} </p>
    <div>
      <a *ngFor="let paginationLink of paginationLinks "
        [ngClass]=" { 'current' : paginationLink == searchUsers.value.page }" [routerLink]="['/user-accounts']"
        [queryParams]="{
        name : searchUsers.value.name,
        email : searchUsers.value.email,
        mobile : searchUsers.value.mobile,
        userId : searchUsers.value.userId,
        isBroker : searchUsers.value.isBroker,
        pagination : searchUsers.value.pagination,
        page : paginationLink == '...' ? '1' : paginationLink,
        viewType : viewType
      }">
        {{ paginationLink }} </a>
    </div>
  </div>
</div>
