import { PlotsService } from './../plots.service';
import { DataHolderService } from './../data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NotificationsService } from './../notifications.service';
import { RxwebValidators, NumericValueType, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-add-edit-plot',
  templateUrl: './add-edit-plot.component.html',
  styleUrls: ['./add-edit-plot.component.css']
})
export class AddEditPlotComponent implements OnInit {

  Object = Object;

  @Input() isCreate: boolean | string;

  onecolumn: boolean = false;

  submitted: boolean = false;

  disabled: boolean = false;

  plotForm: FormGroup;


  constructor(
    private formBuilder: RxFormBuilder, public notificationsService: NotificationsService,
    public plotsService: PlotsService,
    public dataHolderService: DataHolderService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {

    this.plotForm = this.formBuilder.group({

      projectId: ["-1", [Validators.required]],
      plotNo: ["", [Validators.required, Validators.minLength(1)]],

      isNorth: ["1", [Validators.required]],
      isSouth: ["1", [Validators.required]],
      isWest: ["1", [Validators.required]],
      isEast: ["1", [Validators.required]],

      westDimensions: ["", [Validators.minLength(1)]],
      eastDimensions: ["", [Validators.minLength(1)]],
      northDimensions: ["", [Validators.minLength(1)]],
      southDimensions: ["", [Validators.minLength(1)]],


      area: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
      ]],

      additionalNotes: ["", [Validators.minLength(1), Validators.maxLength(500)]],

      gmaps_iframe: ["", [Validators.minLength(1), Validators.maxLength(2000)]],
      gmaps_link: ["", [Validators.minLength(1), Validators.maxLength(2000)]],
      geo_fence: ["", [Validators.minLength(1), Validators.maxLength(2000)]],

      lattitude: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
      ]],
      longitude: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
      ]],

      min_price: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
      ]],
      max_price: ["", [
        RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
      ]],

    });



    this.route.params.subscribe((params: Params) => {
      this.plotForm.controls.projectId.setValue(params["projectId"].split("-").pop());
    });

    if (this.isCreate) {
      this.dataHolderService.updatePageMeta(`Add Plot | ${this.dataHolderService.projectMetaDeta.default_title}`);

    }
    else{
      this.dataHolderService.updatePageMeta(`Edit Plot | ${this.dataHolderService.projectMetaDeta.default_title}`);
      this.updateForm();

    }

  }

  updateForm(): void {
    let plotsData = this.plotsService.fetchedPlotData[0];

    this.plotForm.controls.projectId.setValue(plotsData.project_id);

    this.plotForm.controls.plotNo.setValue(plotsData.plot_no);

    this.plotForm.controls.isNorth.setValue(plotsData.is_north_facing == '1' ? '1' : '0');
    this.plotForm.controls.isSouth.setValue(plotsData.is_south_facing == '1' ? '1' : '0');
    this.plotForm.controls.isWest.setValue(plotsData.is_west_facing == '1' ? '1' : '0');
    this.plotForm.controls.isEast.setValue(plotsData.is_east_facing == '1' ? '1' : '0');

    this.plotForm.controls.northDimensions.setValue(plotsData.north_dimensions ? plotsData.north_dimensions : '');
    this.plotForm.controls.southDimensions.setValue(plotsData.south_dimensions ? plotsData.south_dimensions : '');
    this.plotForm.controls.eastDimensions.setValue(plotsData.east_dimensions ? plotsData.east_dimensions : '');
    this.plotForm.controls.westDimensions.setValue(plotsData.west_dimensions ? plotsData.west_dimensions : '');

    this.plotForm.controls.area.setValue(plotsData.area ? plotsData.area : '');
    this.plotForm.controls.additionalNotes.setValue(plotsData.additional_notes ? plotsData.additional_notes : '');

    this.plotForm.controls.gmaps_iframe.setValue(plotsData.gmaps_iframe ? plotsData.gmaps_iframe : '');
    this.plotForm.controls.gmaps_link.setValue(plotsData.gmaps_link ? plotsData.gmaps_link : '');
    this.plotForm.controls.geo_fence.setValue(plotsData.geo_fence ? plotsData.geo_fence : '');

    this.plotForm.controls.lattitude.setValue(plotsData.lattitude ? plotsData.lattitude : '');
    this.plotForm.controls.longitude.setValue(plotsData.longitude ? plotsData.longitude : '');

    this.plotForm.controls.min_price.setValue(plotsData.min_price ? plotsData.min_price : '');
    this.plotForm.controls.max_price.setValue(plotsData.max_price ? plotsData.max_price : '');


  }

  onPlot() : void{

    if (this.plotForm.invalid) {
      this.submitted = true;
      this.notificationsService.toggleFailed(true, "Invalid data, make sure to follow guidelines before filling data");
      return;
    }

    if (!window.confirm("Are you sure ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm..");
      return;
    }

    this.disabled = true;
    if(this.isCreate){
      this.notificationsService.toggleLoading(true, "Adding new plot...");
      this.onPlotsAdd();
      return;
    }
    else{
      this.notificationsService.toggleLoading(true, "Updating plot...");
      this.onPlotsEdit();
      return;

    }



  }

  onPlotsAdd() : void{

    this.plotsService.addPlot(this.plotForm.value).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);

      setTimeout(() => {
        this.router.navigate( ['/edit-plot', this.plotForm.value.projectId , this.plotForm.value.plotNo  ]);
      }, 500);

    });

  }

  onPlotsEdit(): void {


    this.plotsService.updatePlot(this.plotForm.value).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);
    });


  }
  toggleFormLayout(): void {
    this.onecolumn = !this.onecolumn;
  }


  deletePlot() : void {
    if (!window.confirm("Do you want to delete the plot ?")) {
      this.notificationsService.toggleFailed(true, "You have to confirm");
      return;
    }
    this.notificationsService.toggleLoading(true, "Deleting plot " );

    this.plotsService.deletePlot(this.plotForm.value.projectId , this.plotForm.value.plotNo).subscribe((response: any) => {
      this.notificationsService.toggleLoading(false);
      this.disabled = false;
      if (!response.status) {
        this.notificationsService.toggleFailed(true, response.message);
        return;
      }
      this.notificationsService.toggleSucceeded(true, response.message);

      setTimeout(() => {
        this.router.navigate( ['/plots' ] ,{
          queryParams : { projectId : this.plotForm.value.projectId }
        } );
      }, 500);

    });

  }


}
