<section class="responsiveContainer">
  <div class="useful-links">
    <h3 class="border-heading">Useful Links</h3>

    <ul class="responsive600">
      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="http://registration.ap.gov.in/">
          <h4>Registration and Stamps Department</h4>
          <p>GOVERNMENT OF ANDHRA PRADESH</p>
          <img src="/assets/images/Govt-Logo-1.png" >

        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="https://registration.telangana.gov.in/">
          <h4>Registration and Stamps Department</h4>
          <p>GOVERNMENT OF TELANGANA</p>
          <img src="/assets/images/logo-2.png" class="responsive600 " style="width:100%; height: 70px;">

        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="http://rera.telangana.gov.in/">
          <h4>Telangana State Real Estate Regulatory Authority</h4>
          <img src="/assets/images/rera-logo-3.png" >
        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="http://meebhoomi.ap.gov.in/">
          <h4>Mee Bhoomi</h4>
          <img src="/assets/images/Meebhoomi2-4.png" class="responsive600 " style="width:100%; height: 70px;">
        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="http://apdpms.ap.gov.in/">
          <h4>Municipal Administration & Urban Development</h4>
          <p>DIRECTORATE OF TOWN & COUNTRY PLANNING</p>
          <img src="/assets/images/Directorate of Town-5.png" class="responsive600 " style="width:100%; height: 70px;">
        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="http://www.meeseva.gov.in/DeptPortal/UserInterface/LoginForm.aspx">
          <h4>Integrated Services Delivery Gateway</h4>
          <img src="/assets/images/AP-Top-BAN123-6.gif" class="responsive600 " style="width:100%; height: 70px;">
        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="https://ts.meeseva.telangana.gov.in/meeseva/home.htm.">
          <h4>Government Of Telangana (MEESEVA PORTAL)</h4>
          <p>INTEGRATED SERVICES DELIVERY GATEWAY</p>
          <img src="/assets/images/download-7.gif" class="responsive600 " style="width:100%; height: 70px;">
        </a>
      </li>

      <li>
        <a target="_blanc" rel=" nofollow noopener noreferrer" href="https://rera.ap.gov.in/RERA/Views/Home.aspx">
          <h4>Andhra Pradesh Real Estate Regulatory Authority</h4>
          <img src="/assets/images/finallogo-New4-8.jpg" >
        </a>
      </li>
    </ul>

  </div>
</section>
