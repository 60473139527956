import { Component, OnInit } from '@angular/core';
import { DataHolderService } from './../data-holder.service';

@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found-component.component.html',
  styleUrls: ['./not-found-component.component.css']
})
export class NotFoundComponentComponent implements OnInit {

  constructor(public dataHolderService : DataHolderService) { }

  ngOnInit(): void {
    this.dataHolderService.updatePageMeta('404 - Page Not Found | '  + this.dataHolderService.projectMetaDeta.default_title);

  }

}
